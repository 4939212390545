import PropTypes from 'prop-types';
import wodContextTypes from '../../common/utils/wodContextTypes';

const blueroseContextTypes = Object.assign({}, wodContextTypes, {
  columnsInRow: PropTypes.number.isRequired,
  isInitialRender: PropTypes.bool,
  isOverlayPage: PropTypes.bool,
  rowNum: PropTypes.number,
  rankNum: PropTypes.number,
  listContext: PropTypes.string,
  listType: PropTypes.string,
  isTallRow: PropTypes.bool,
});

export default blueroseContextTypes;
