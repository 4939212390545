import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'src/domain/libs/util';
import classnames from 'classnames';

import * as browserEvents from '../../../../sketch-platform/utils/browserEvents';
// @ts-ignore TS1192
import reactNl2br from '../../../../common/reactNl2br';
import Details from '../browse/titlemeta/Details';
import EventDetails from '../browse/Epg/EventDetails';

export default class DetailText extends Component {
  static get propTypes() {
    return {
      metadata: PropTypes.object.isRequired,
    };
  }
  static get contextTypes() {
    return {
      getModelData: PropTypes.func,
    };
  }

  constructor(props, context) {
    super(props, context);
    this.state = {
      open: false,
    };
    // @ts-ignore TS2339
    this.detailTextDivRef = React.createRef();
    // @ts-ignore TS2339
    this.detailTextRef = React.createRef();
    // @ts-ignore TS2339
    this.openBtn = React.createRef();
    this.handleOpenButton = this.handleOpenButton.bind(this);
    this.detailHeight = _.throttle(this.detailHeight.bind(this), 300, { leading: true, trailing: true });
    this.detailOpen = this.detailOpen.bind(this);
    this.detailClose = this.detailClose.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
  }
  componentDidMount() {
    // @ts-ignore TS2339
    this._isMounted = true;
    this.detailHeight();
    const browserInfo = this.context.getModelData('browserInfo');
    if (browserInfo.isIOS || browserInfo.isAndroid) {
      browserEvents.addEventListener('orientationchange', this.detailHeight);
    } else {
      browserEvents.addEventListener('resize', this.detailHeight);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      // @ts-ignore TS2339
      this.props.metadata.description != prevProps.metadata.description ||
      // @ts-ignore TS2339
      this.props.metadata.shortMessage != prevProps.metadata.shortMessage ||
      // @ts-ignore TS2339
      this.props.mediaQueryCheck != prevProps.mediaQueryCheck
    ) {
      if (
        // @ts-ignore TS2339
        this.props.metadata.cardInfo &&
        // @ts-ignore TS2339
        (this.props.metadata.description != prevProps.metadata.description ||
          // @ts-ignore TS2339
          this.props.mediaQueryCheck != prevProps.mediaQueryCheck)
      ) {
        this.setState({ open: false }, () => {
          this.detailHeight();
        });
      }
      // @ts-ignore TS2339
    } else if (prevProps.titleHidden != this.props.titleHidden) {
      this.detailHeight();
    }
  }
  componentWillUnmount() {
    // @ts-ignore TS2339
    this._isMounted = false;
    const browserInfo = this.context.getModelData('browserInfo');
    if (browserInfo.isIOS || browserInfo.isAndroid) {
      browserEvents.addEventListener('orientationchange', this.detailHeight);
    } else {
      browserEvents.removeEventListener('resize', this.detailHeight);
    }
  }

  handleOpenButton() {
    // @ts-ignore TS2339
    if (!this.state.open) {
      this.detailOpen();
    } else {
      this.detailClose();
    }
  }
  handleKeyDown(e) {
    if (e.which === 13 || e.keyCode === 13) {
      this.handleOpenButton();
    }
  }

  detailHeight() {
    // @ts-ignore TS2339
    if (this.props.planInfoRenderCheck) return null;
    // @ts-ignore TS2339
    const detailTextRef = this.detailTextRef.current;
    // @ts-ignore TS2339
    const openBtn = this.openBtn.current;
    const browserInfo = this.context.getModelData('browserInfo');
    let description;
    let shortMessage;
    // @ts-ignore TS2339
    if (this.props.metadata.description) {
      // @ts-ignore TS2339
      description = this.props.metadata.description;
      // @ts-ignore TS2339
    } else if (this.props.metadata.summary80) {
      // @ts-ignore TS2339
      description = this.props.metadata.summary80;
      // @ts-ignore TS2339
    } else if (this.props.metadata.cardInfo && this.props.metadata.cardInfo.description) {
      // @ts-ignore TS2339
      description = this.props.metadata.cardInfo.description;
    }
    // @ts-ignore TS2339
    if (this.props.metadata.shortMessage) {
      // @ts-ignore TS2339
      shortMessage = this.props.metadata.shortMessage;
    }
    // if (!description) return;
    if (detailTextRef && openBtn) {
      let columRight = document.getElementById('columRight');
      let columLeft = document.getElementById('columLeft');
      let detailTextRefHeight = detailTextRef.clientHeight;
      let lineHeight = getComputedStyle(detailTextRef).getPropertyValue('line-height');
      lineHeight = lineHeight.replace(/[^-\d\.]/g, '');

      detailTextRef.style.height = 'auto';
      openBtn.style.display = 'none';
      if (columRight && columLeft) {
        let watchTitle = document.getElementById('infoTitle');
        let watchTitleHeight = watchTitle ? watchTitle.clientHeight : 0;
        let tags = document.getElementsByClassName('tag-list')[0];
        let tagsHeight = tags
          ? tags.clientHeight + parseInt(window.getComputedStyle(tags).getPropertyValue('margin-top'))
          : 0;
        let columHeight;

        // @ts-ignore TS2339
        if (this.props.mediaQueryCheck == 2 || browserInfo.isAndroid || browserInfo.isIOS) {
          if (detailTextRefHeight > 40) {
            detailTextRef.style.height = `41px`;
            openBtn.style.display = 'block';
          } else {
            detailTextRef.style.height = 'auto';
            openBtn.style.display = 'none';
          }
        } else if (
          columRight.clientHeight < columLeft.clientHeight &&
          columLeft.clientHeight - columRight.clientHeight > 20
        ) {
          let textHeight = columLeft.clientHeight - (watchTitleHeight + tagsHeight);
          columHeight = textHeight - (columLeft.clientHeight - columRight.clientHeight);
          // @ts-ignore TS2339
          if (this.props.titlePage) {
            if (columHeight <= 0 && detailTextRefHeight > 40) {
              detailTextRef.style.height = `41px`;
              openBtn.style.display = 'block';
            } else if (columHeight > 0 && columHeight < columRight.clientHeight) {
              detailTextRef.style.height = `${columRight.clientHeight}px`;
              openBtn.style.display = 'block';
            } else if (columHeight > 0) {
              detailTextRef.style.height = `${columHeight}px`;
              openBtn.style.display = 'block';
            } else {
              detailTextRef.style.height = 'auto';
              openBtn.style.display = 'none';
            }
          } else {
            if (columHeight <= 0 && detailTextRefHeight > 40) {
              detailTextRef.style.height = `41px`;
              openBtn.style.display = 'block';
            } else if (columHeight > 0) {
              detailTextRef.style.height = `${columHeight}px`;
              openBtn.style.display = 'block';
            } else {
              detailTextRef.style.height = 'auto';
              openBtn.style.display = 'none';
            }
          }
        } else {
          detailTextRef.style.height = 'auto';
          openBtn.style.display = 'none';
        }
      } else {
        if (description && shortMessage && description.length + shortMessage.length >= 130) {
          // @ts-ignore TS2362
          detailTextRef.style.height = `${lineHeight * 3}px`;
          openBtn.style.display = 'block';
        } else if (description && description.length >= 130) {
          // @ts-ignore TS2362
          detailTextRef.style.height = `${lineHeight * 3}px`;
          openBtn.style.display = 'block';
        } else if (shortMessage && shortMessage.length >= 130) {
          // @ts-ignore TS2362
          detailTextRef.style.height = `${lineHeight * 3}px`;
          openBtn.style.display = 'block';
        } else if (detailTextRefHeight > 40) {
          detailTextRef.style.height = `41px`;
          openBtn.style.display = 'block';
        } else {
          detailTextRef.style.height = 'auto';
          openBtn.style.display = 'none';
        }
      }
      // @ts-ignore TS2339
      if (openBtn.style.display !== 'none' && this.state.open) {
        detailTextRef.style.height = 'auto';
      }
    }
  }
  detailOpen() {
    // @ts-ignore TS2339
    const detailTextRef = this.detailTextRef.current;
    // @ts-ignore TS2339
    const openBtn = this.openBtn.current;

    if (detailTextRef) {
      detailTextRef.style.height = 'auto';
      openBtn.style.display = 'block';
      this.setState({ open: true });
    }
  }
  detailClose() {
    this.setState({ open: false }, () => {
      this.detailHeight();
      // @ts-ignore TS2339
      const detailTextRef = this.detailTextRef.current;
      // @ts-ignore TS2339
      const openBtn = this.openBtn.current;
      const browserInfo = this.context.getModelData('browserInfo');

      if (detailTextRef && openBtn) {
        let columRight = document.getElementById('columRight');
        let columLeft = document.getElementById('columLeft');
        const textHeight = detailTextRef.clientHeight;
        let detailTextRefHeight = detailTextRef.clientHeight;
        let lineHeight = getComputedStyle(detailTextRef).getPropertyValue('line-height');
        lineHeight = lineHeight.replace(/[^-\d\.]/g, '');

        if (columRight && columLeft) {
          let watchTitle = document.getElementById('infoTitle');
          let watchTitleHeight = watchTitle ? watchTitle.clientHeight : 0;
          let tags = document.getElementsByClassName('tag-list')[0];
          let tagsHeight = tags
            ? tags.clientHeight + parseInt(window.getComputedStyle(tags).getPropertyValue('margin-top'))
            : 0;
          let columHeight;

          // @ts-ignore TS2339
          if (this.props.mediaQueryCheck == 2 || browserInfo.isAndroid || browserInfo.isIOS) {
            if (detailTextRefHeight > 40) {
              detailTextRef.style.height = `41px`;
              openBtn.style.display = 'block';
            } else {
              detailTextRef.style.height = 'auto';
              openBtn.style.display = 'none';
            }
          } else if (
            columRight.clientHeight < columLeft.clientHeight &&
            columLeft.clientHeight - columRight.clientHeight > 20
          ) {
            let textHeight = columLeft.clientHeight - (watchTitleHeight + tagsHeight);
            columHeight = textHeight - (columLeft.clientHeight - columRight.clientHeight);

            // @ts-ignore TS2339
            if (this.props.titlePage) {
              if (columHeight <= 0 && detailTextRefHeight > 40) {
                detailTextRef.style.height = `41px`;
                openBtn.style.display = 'block';
              } else if (columHeight > 0 && columHeight < columRight.clientHeight) {
                detailTextRef.style.height = `${columRight.clientHeight}px`;
                openBtn.style.display = 'block';
              } else if (columHeight > 0) {
                detailTextRef.style.height = `${columHeight}px`;
                openBtn.style.display = 'block';
              }
            } else {
              if (columHeight <= 0 && detailTextRefHeight > 40) {
                detailTextRef.style.height = `41px`;
                openBtn.style.display = 'block';
              } else if (columHeight > 0) {
                detailTextRef.style.height = `${columHeight}px`;
              }
            }
          }
        } else {
          // @ts-ignore TS2362
          if (textHeight > lineHeight * 3) {
            // @ts-ignore TS2362
            detailTextRef.style.height = `${lineHeight * 3}px`;
          }
        }
      }
    });
  }

  render() {
    // @ts-ignore TS2339
    const openBtn = this.openBtn.current;

    let communicationArea = null;
    // @ts-ignore TS2339
    if (!this.props.titlePage && this.props.metadata.communicationArea) {
      communicationArea = (
        <div className="communication-area">
          {/*
           // @ts-ignore TS2339 */}
          <span>{this.props.metadata.communicationArea}</span>
        </div>
      );
    }

    return (
      // @ts-ignore TS2339
      <div className={classnames('detail-text', { 'is-open': this.state.open })} ref={this.detailTextDivRef}>
        {/*
         // @ts-ignore TS2339 */}
        <div className="detail-text___descritpion" ref={this.detailTextRef}>
          {communicationArea}
          {/*
           // @ts-ignore TS2339 */}
          {this.props.metadata.isSuspend ? null : this.props.metadata.isMask ? (
            <p className="mention-message">この番組は放送とは異なる映像を配信しております。</p>
          ) : null}
          {/*
           // @ts-ignore TS2339 */}
          {!!this.props.metadata.shortMessage ? (
            // @ts-ignore TS2339
            <p className="short-message">{reactNl2br(this.props.metadata.shortMessage)}</p>
          ) : // @ts-ignore TS2339
          !!this.props.metadata.header ? (
            // @ts-ignore TS2339
            <p className="short-message">{reactNl2br(this.props.metadata.header)}</p>
          ) : null}
          {/*
           // @ts-ignore TS2339 */}
          {!!this.props.metadata.description ? (
            // @ts-ignore TS2339
            <p>{reactNl2br(this.props.metadata.description)}</p>
          ) : // @ts-ignore TS2339
          !!this.props.metadata.summary80 ? (
            // @ts-ignore TS2339
            <p>{reactNl2br(this.props.metadata.summary80)}</p>
          ) : // @ts-ignore TS2339
          !!this.props.metadata.cardInfo && !!this.props.metadata.cardInfo.description ? (
            // @ts-ignore TS2339
            <p>{reactNl2br(this.props.metadata.cardInfo.description)}</p>
          ) : null}
          {/* お断り */}
          {/*
           // @ts-ignore TS2339 */}
          {_.map(this.props.metadata.mentions, (mention, i) => {
            if (mention.type === 'eirin') return null;
            return (
              <p className="mention-message" key={`mention-${i}`}>
                {reactNl2br(mention.message)}
              </p>
            );
          })}
          {/*
           // @ts-ignore TS2339 */}
          {this.props.linearFlag ? (
            <EventDetails
              // @ts-ignore TS2339
              {...this.props.metadata}
              // @ts-ignore TS2339
              open={this.state.open && openBtn.style.display === 'none' ? true : false}
            />
          ) : // @ts-ignore TS2339
          !this.props.detailHidden ? (
            <Details
              // @ts-ignore TS2322
              open={this.state.open || (openBtn && openBtn.style.display === 'none') ? true : false}
              // @ts-ignore TS2339
              id={this.props.metadata.id}
              // @ts-ignore TS2339
              model={this.props.model}
            />
          ) : null}
        </div>
        {/* @ts-ignore TS2339 */}
        {!this.props.planInfoRenderCheck ? (
          // @ts-ignore TS2339
          <div className="open-gallery" ref={this.openBtn}>
            <div
              className="btn btn-very-small"
              onClick={this.handleOpenButton}
              onKeyDown={this.handleKeyDown}
              tabIndex={0}
            >
              {/* @ts-ignore TS2339 */}
              {this.state.open ? (
                <React.Fragment>
                  閉じる<i className="fa-angle_up"></i>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  もっと見る<i className="fa-angle_down"></i>
                </React.Fragment>
              )}
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}
