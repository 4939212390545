import hoistStatics from 'hoist-non-react-statics';
import React from 'react';

export default function panelHOC(WrappedComponent) {
  class Panel extends React.Component {
    constructor(props, context) {
      super(props, context);
      // @ts-ignore TS2339
      this._isMounted = false;
    }

    componentDidMount() {
      // @ts-ignore TS2339
      this._isMounted = true;
    }

    componentWillUnmount() {
      // @ts-ignore TS2339
      this._isMounted = false;
    }

    render() {
      return <div className="panel">{React.createElement(WrappedComponent, this.props)}</div>;
    }
  }

  const wrappedComponentName = WrappedComponent.displayName || WrappedComponent.name || 'Component';

  // @ts-ignore TS2339
  Panel.displayName = `panelHOC(${wrappedComponentName})`;
  // @ts-ignore TS2339
  Panel.bundleName = WrappedComponent.bundleName;
  // @ts-ignore TS2339
  Panel.headTitle = WrappedComponent.headTitle;

  return hoistStatics(Panel, WrappedComponent);
}
