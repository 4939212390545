import React, { useMemo } from 'react';
import _ from 'src/domain/libs/util';

import * as ERROR from '../../../../constants/error';

const ErrorView = props => {
  const error = useMemo(() => props.error || {}, [props.error]);
  const displayInfo = useMemo(() => {
    let title = 'エラー',
      text = error.message,
      code,
      buttons = [
        <a className="btn btn-border" onClick={props.onClickCancel}>
          キャンセル
        </a>,
      ];
    switch (error.message) {
      case ERROR.META_NOT_VIEWABLE:
        text = '現在この作品は視聴することができません。';
        break;
      case ERROR.UNAUTHORIZED:
        title = '認証エラー';
        text = '認証エラーが発生しました。お手数ですが、再度ログインをお願いします。';
        buttons.push(
          <a className="btn btn-fill" href="/login">
            ログイン
          </a>,
        );
        break;
      default:
        // @ts-ignore TS2532
        code = _.get(this.error, 'code') || 'Unknown';
    }
    return {
      title,
      text,
      code,
      buttons,
    };
  }, [error.message, props.onClickCancel]);

  return (
    <div className="usf-error-view">
      <div>
        {displayInfo.title ? <p className="usf-error-title">{displayInfo.title}</p> : null}
        {typeof displayInfo.text === 'string' ? <p className="">{displayInfo.text}</p> : displayInfo.text}
        {displayInfo.code ? <p>コード：{displayInfo.code}</p> : null}
      </div>
      <div className="usf-btn-block">{displayInfo.buttons.map(button => button)}</div>
    </div>
  );
};

export default ErrorView;
