import parseUrl from './parseUrl';

export default function(routeHandlers, pathname) {
  for (let parsed = parseUrl(pathname), i = 0; i < routeHandlers.length; i++) {
    const route = routeHandlers[i].route;
    if (route) {
      const matched = route.match(parsed.path);
      if (matched) {
        return Object.assign({}, routeHandlers[i], {
          url: parsed.url,
          path: parsed.path,
          params: matched,
          query: parsed.query,
          hash: parsed.hash,
        });
      }
    }
  }
  return null;
}