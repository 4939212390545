import React, { Component } from 'react';
import NotFound from '../../generic/components/errors/NotFound';
import history from '../../history';

export const NotFoundError = function(message) {
  this.message = message;
  var last_part;
  try {
    throw new Error();
  } catch (e) {
    last_part = e.stack.match(/[^\s]+$/);
  }
  this.stack = `${this.name} at ${last_part}`;
};
Object.setPrototypeOf(NotFoundError, Error);
NotFoundError.prototype = Object.create(Error.prototype);
NotFoundError.prototype.name = 'NotFoundError';
NotFoundError.prototype.message = '';
NotFoundError.prototype.constructor = NotFoundError;

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, info) {
    const message = error.message;
    if (typeof message === 'object' && message.redirect_link) {
      history.replace(message.redirect_link);
      return;
    }
    this.setState({ hasError: true, error: error });
  }

  render() {
    // @ts-ignore TS2339
    if (this.state.hasError) {
      // @ts-ignore TS2339
      if (this.state.error instanceof NotFoundError) {
        return <NotFound />;
      } else {
        return (
          <div className="panel">
            <div className="other-container">
              <h1>エラーが発生しました</h1>
              <div className="not_img sorry" />
              <p className="sub-title">誠に恐れ入りますが、しばらくしてから再度アクセスをお試しください</p>
              <a href="/" className="btn btn-gray btn-small">
                トップページに戻る
              </a>
            </div>
          </div>
        );
      }
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
