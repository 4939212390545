import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import _ from 'src/domain/libs/util';
import RowHeader from './RowHeader';
import { CONTENT_EVENTS, CLICK_AREA } from '../../../../common/GtmApp';
import { withLayoutContext } from '../../../common/context/LayoutContext';
import MainViewLink from '../../../common/components/MainViewLink';
import PaletteImpression from './PaletteImpression';

class BannerRow extends Component {
  static getPaths = function(models, options, props) {
    const indexes = function(models, options, props) {
      return {
        from: (props && props.fromNum) || 0,
        to:
          props && props.toNum
            ? props.toNum - (props.fromNum || 0)
            : options && options.numItems
            ? options.numItems
            : 7,
      };
    };
    const rootPath = this.getRootPath(models, options, props);
    let paths = [rootPath.concat([['id', 'title']])];
    paths = paths.concat([rootPath.concat(['objects', indexes(models, options, props)])]);
    return paths;
  };

  static getRootPath = function(models, options, props) {
    if (props.paletteId) {
      return ['palette', props.paletteId];
    }
    return [];
  };

  static get contextTypes() {
    return {
      models: PropTypes.object,
      getModelData: PropTypes.func,
      gtmApp: PropTypes.object,
    };
  }

  constructor(props, context) {
    super(props, context);
    this.handleItemClick = this.handleItemClick.bind(this);

    // @ts-ignore TS2339
    const rootPath = this.constructor.getRootPath(context.models, {}, props);
    // @ts-ignore TS2339
    this.items = [];
    this.palette = props.model.getSync(`palette.${props.paletteId}`);

    // @ts-ignore TS2339
    this.model = props.pathEvaluator ? props.pathEvaluator.batch(100) : props.model;

    this.state = {
      fetchDataError: null,
      generation: props.model.getVersion(rootPath),
    };
  }

  set palette(palette) {
    // @ts-ignore TS2551
    this.__palette = palette;
    // @ts-ignore TS2551
    if (_.get(this.__palette, 'objects')) {
      const items = _.compact(
        // @ts-ignore TS2551
        _.map(_.values(_.omit(this.__palette.objects, ['$__path', 'length'])), item => {
          if (item.creatives && Object.keys(item.creatives).length !== 0) {
            return item;
          }
        }),
      );
      // 先頭のバナーを表示するようにする
      // @ts-ignore TS2339
      this.item = _.first(items);
      // @ts-ignore TS2339
      if (this.props.noContents && _.isEmpty(this.item)) this.props.noContents(this.props.paletteId);
    }
  }

  get palette() {
    // @ts-ignore TS2551
    return this.__palette;
  }

  componentDidMount() {
    // @ts-ignore TS2339
    this._isMounted = true;
    this.fetchData(this.props);
  }

  componentWillUnmount() {
    // @ts-ignore TS2339
    this._isMounted = false;
  }

  handleItemClick() {
    // @ts-ignore TS2322
    this.sendToGtm(this.item);
  }

  sendToGtm(item) {
    if (!_.get(this.context, 'gtmApp')) return;
    let content = null;
    let ad = null;
    if (_.get(item, 'advertisingId')) {
      ad = {
        id: _.get(item, 'advertisingId'),
        name: _.get(item, 'name'),
      };
    } else {
      content = {
        refId: _.get(item, 'id'),
        name: _.get(item, 'name') || _.get(item, 'shortName'),
      };
    }
    const shelf = {
      id: _.get(this.props, 'paletteId'),
      name: _.get(item, 'name') || _.get(item, 'shortName'),
      order: _.get(this.props, 'orderNum'),
    };
    const schemaId = _.get(item, 'schemaId');
    this.context.gtmApp.pushDataLayerOnContentPageClick(
      CONTENT_EVENTS.CONTENT_CLICK,
      { shelf, content, schemaId, ad },
      { clickArea: CLICK_AREA.SHELF },
    );
  }

  fetchData(props) {
    const pathProps = _.clone(props);
    // @ts-ignore TS2339
    const rootPath = this.constructor.getRootPath(this.context.models, {}, pathProps);
    // @ts-ignore TS2339
    const paths = this.constructor.getPaths(this.context.models, {}, pathProps);

    // すでに通信している場合は実行しない
    if (this.state[JSON.stringify(paths)]) return;

    // @ts-ignore TS2339
    if (this.state.dispose) {
      // 過去のObservableを削除する、これによって通信が止まるわけではなく
      // Observableがなくなるのでイベントが発火されなくなる、というだけなので注意
      // @ts-ignore TS2339
      this.state.dispose();
    }

    this.state[JSON.stringify(paths)] = paths;

    const evaluator = props.model.fetch(paths);
    const dispose = evaluator.dispose;
    // @ts-ignore TS2339
    if (!this._isMounted) {
      Object.assign(this.state, { dispose });
    } else {
      this.setState({ dispose });
    }
    evaluator
      .then(res => {
        // @ts-ignore TS2339
        this.palette = _.get(res, `json.palette.${this.props.paletteId}`);

        delete this.state[JSON.stringify(paths)];

        const newState = {
          fetchDataError: null,
          dispose: null,
          generation: props.model.getVersion(rootPath),
        };

        // @ts-ignore TS2339
        if (this._isMounted) this.setState(newState);
        else Object.assign(this.state, newState);
      })
      .catch(e => {
        const newState = {
          fetchDataError: e,
          fetchingMoreRows: undefined,
          dispose: null,
        };
        delete this.state[JSON.stringify(paths)];
        // @ts-ignore TS2339
        if (this._isMounted) this.setState(newState);
        else Object.assign(this.state, newState);
      });
  }

  render() {
    // @ts-ignore TS2339
    if (_.isEmpty(this.item)) return null;

    const headerProps = {
      // @ts-ignore TS2339
      title: this.item.name,
      // @ts-ignore TS2339
      listContext: this.props.listContext,
    };
    // @ts-ignore TS2339
    const headerHidden = this.props.headerHidden;

    const bannerObj = {};
    // @ts-ignore TS2339
    _.forEach(this.item.creatives, creative => {
      bannerObj[creative.display_type] = _.omit(creative, ['display_type']);
    });

    // @ts-ignore TS2339
    const mbBanner = bannerObj.mb_banner && bannerObj.mb_banner.url;
    // @ts-ignore TS2339
    const tabletBanner = bannerObj.tablet_banner && bannerObj.tablet_banner.url;

    const bannerContent =
      // @ts-ignore TS2339
      this.props.spMode && mbBanner ? (
        // @ts-ignore TS2339
        <img key="mb" loading="lazy" src={mbBanner} alt={this.item.name} />
      ) : // @ts-ignore TS2339
      !this.props.spMode && tabletBanner ? (
        // @ts-ignore TS2339
        <img key="tab" loading="lazy" src={tabletBanner} alt={this.item.name} />
      ) : null;

    if (bannerContent == null) return null;

    // @ts-ignore TS2339
    const url = _.replace(this.item.url, this.context.getModelData('hosts', 'host'), '');
    return (
      <PaletteImpression
        paletteId={this.palette.id}
        paletteName={this.palette.title}
        // @ts-ignore TS2339
        orderNum={this.props.orderNum}
        // @ts-ignore TS2339
        useImpression={this.props.useImpression}
        // @ts-ignore TS2339
        onVisiblePalette={this.props.onVisiblePalette}
      >
        <div className="canvas-row">
          {!headerHidden ? <RowHeader {...headerProps} /> : null}
          <div className="advertising-banner">
            {/*
           // @ts-ignore TS2322 */}
            <MainViewLink href={url} onClick={this.handleItemClick}>
              {bannerContent}
            </MainViewLink>
          </div>
        </div>
      </PaletteImpression>
    );
  }
}

export default withLayoutContext(BannerRow);
