import React from 'react';

import PropTypes from 'prop-types';
import _ from 'src/domain/libs/util';
import TennisCard from './TennisCard';
import SoccerCard from './SoccerCard';
import RowHeader from './RowHeader';
import LoadingTitle from '../loader/LoadingTitle';
import Row from './Row';
import { CardContextProvider } from '../../../common/context/CardContext';
import { CLICK_AREA, CONTENT_EVENTS } from '../../../../common/GtmApp';
import LoadingRow from '../loader/LoadingRow';
import routes from '../../../common/routes';
import PaletteImpression from './PaletteImpression';

// 専用UIのパレット。肥大化するようならスポーツごとに分離する

class ExclusiveUiRow extends React.Component {
  static getPaths = function(models, options, props) {
    const pathProps = ['id', 'title', 'description', 's3Path', 's3Data', 'listContext'];
    if (!props.paletteId) {
      return [[pathProps]];
    }
    const rootPath = this.getRootPath(models, options, props);
    let paths = [];
    paths = paths.concat([rootPath.concat([pathProps])]);
    return paths;
  };

  static getRootPath = function(models, options, props) {
    if (props.paletteId) {
      return ['palette', props.paletteId];
    }
    return [];
  };

  static get contextTypes() {
    return {
      gtmApp: PropTypes.object,
      routeHandler: PropTypes.object,
    };
  }

  static get childContextTypes() {
    return {
      cardSize: PropTypes.string,
      artType: PropTypes.string,
    };
  }

  getChildContext() {
    let artType;
    // @ts-ignore TS2339
    if (this.palette && this.palette.s3Data) {
      // @ts-ignore TS2339
      if (this.palette.s3Data.genre === 'tennis') {
        artType = 'tennisCard';
        // @ts-ignore TS2339
      } else if (this.palette.s3Data.genre === 'soccer') {
        artType = 'soccerCard';
      }
    }
    return {
      cardSize: 'large',
      artType: artType,
    };
  }

  constructor(props, context) {
    super(props, context);
    // @ts-ignore TS2339
    this.rootRef = React.createRef();
    // @ts-ignore TS2339
    this.model = props.pathEvaluator ? props.pathEvaluator.batch(100) : props.model;
    // @ts-ignore TS2339
    const rootPath = this.constructor.getRootPath(context.models, {}, props);
    // @ts-ignore TS2339
    this.palette = this.model.getSync(rootPath);
    // @ts-ignore TS2339
    this.cardEls = {};
    this.state = {};
  }

  componentDidMount() {
    // @ts-ignore TS2339
    this._isMounted = true;
  }

  componentWillUnmount() {
    // @ts-ignore TS2339
    this._isMounted = false;
  }

  sendToGtm(item) {
    if (!_.get(this.context, 'gtmApp')) return;
    const content = {
      refId: item.ref_id,
    };
    const shelf = {
      // @ts-ignore TS2339
      ...this.palette,
      // @ts-ignore TS2339
      name: _.get(this.palette, 'title'),
      order: _.get(this.props, 'orderNum'),
    };
    this.context.gtmApp.pushDataLayerOnContentPageClick(
      CONTENT_EVENTS.CONTENT_CLICK,
      { content, shelf },
      { clickArea: CLICK_AREA.MATCH_SHELF },
    );
  }

  fetchData(props) {
    // @ts-ignore TS2339
    const exclusiveUiContentRootPath = this.constructor.getExclusiveUiContentRootPath(this.context.models, {}, props);
    // @ts-ignore TS2339
    const paths = this.constructor.getPaths(this.context.models, {}, props);

    // すでに通信している場合は実行しない
    if (this.state[JSON.stringify(paths)]) return;

    // @ts-ignore TS2339
    if (this.state.dispose) {
      // @ts-ignore TS2339
      this.state.dispose();
    }

    this.state[JSON.stringify(paths)] = paths;
    // @ts-ignore TS2339
    const evaluator = this.model.fetch(paths);
    const dispose = evaluator.dispose;
    this.setState({ dispose });
    const newState = {
      dispose: null,
    };
    evaluator
      .then(res => {
        // @ts-ignore TS2339
        this.exclusiveUiContent = _.get(res.json, exclusiveUiContentRootPath);

        this.setState(newState);
      })
      .catch(e => {
        // @ts-ignore TS2339
        newState.fetchDataError = e;
        delete this.state[JSON.stringify(paths)];
        this.setState(newState);
      });
  }

  render() {
    // @ts-ignore TS2339
    const s3Data = _.get(this.palette, 's3Data') || {};
    let sections = s3Data.sections
      ? s3Data.sections.filter(
          // cardが全て2枚以下のsectionを削除
          section => !_.every(section.items, item => _.isEmpty(item.card) || item.card.length < 2),
        )
      : null;
    if (_.isEmpty(sections)) {
      // @ts-ignore TS2339
      if (this.state.dispose) {
        return <LoadingRow />;
      }
      return null;
    }
    let height;
    // @ts-ignore TS2339
    Object.keys(this.cardEls).forEach(k => {
      // @ts-ignore TS2339
      const el = this.cardEls[k];
      if (!el) return;
      if (!height) {
        height = el.clientHeight;
      } else {
        height = Math.max(height, el.clientHeight);
      }
    });
    // if (this.rootRef.current) {
    //   const sliderEl =
    //     this.rootRef.current.querySelector('.slider') || this.rootRef.current.querySelector('.sp-slider-content');
    //   if (sliderEl) height = sliderEl.clientHeight;
    // }
    const CardComponent = s3Data.genre === 'soccer' ? SoccerCard : TennisCard;
    const headerProps = {
      // @ts-ignore TS2339
      title: this.palette.title,
      // @ts-ignore TS2339
      description: this.palette.description,
      // @ts-ignore TS2339
      listContext: this.props.listContext,
    };
    if (s3Data.genre === 'tennis') {
      // @ts-ignore TS2339
      headerProps.showLink = false;
      // @ts-ignore TS2339
      headerProps.hideAll = true;
    } else if (s3Data.genre === 'soccer') {
      if (s3Data.canvasPaletteId) {
        // @ts-ignore TS2339
        headerProps.linkLabel = '日程・試合結果';
        // @ts-ignore TS2339
        headerProps.id = s3Data.canvasPaletteId;
        // @ts-ignore TS2339
        headerProps.showAllHilight = true;
        // @ts-ignore TS2339
        headerProps.rightShowLink = true;
      } else {
        // @ts-ignore TS2339
        headerProps.showLink = false;
        // @ts-ignore TS2339
        headerProps.hideAll = true;
      }
    }
    // 現在ページが遷移先の場合は出さない
    if (headerProps.listContext && routes[headerProps.listContext].match(this.context.routeHandler.path)) {
      // @ts-ignore TS2339
      headerProps.showLink = false;
      // @ts-ignore TS2339
      headerProps.hideAll = true;
    }

    const showButton = routes.exclusiveUi.match(this.context.routeHandler.path);

    return (
      <PaletteImpression
        // @ts-ignore TS2339
        paletteId={this.palette.id}
        // @ts-ignore TS2339
        paletteName={this.palette.title}
        // @ts-ignore TS2339
        orderNum={this.props.orderNum}
        // @ts-ignore TS2339
        useImpression={this.props.useImpression}
        // @ts-ignore TS2339
        onVisiblePalette={this.props.onVisiblePalette}
      >
        {/* @ts-ignore TS2339 */}
        <div className="canvas-row" ref={this.rootRef}>
          <RowHeader {...headerProps} />
          <CardContextProvider value={this.getChildContext()}>
            <Row
              totalItems={sections.length}
              // @ts-ignore TS2339
              rowNum={this.props.rowNum}
              // @ts-ignore TS2322
              listContext={this.palette.listContext}
              loadingComponent={<LoadingTitle />}
              enablePaginationIndicator={false}
              enablePeek={true}
              enablePushOut={false}
            >
              {sections.map((item, idx) => (
                <CardComponent
                  rootRef={ref => {
                    // @ts-ignore TS2339
                    this.cardEls[idx] = ref;
                  }}
                  itemData={item}
                  height={height}
                  onClick={this.sendToGtm.bind(this)}
                  // @ts-ignore TS2339
                  key={`exclusive_ui_${this.props.rowNum}_${idx}`}
                  // @ts-ignore TS2339
                  showButton={showButton}
                />
              ))}
            </Row>
          </CardContextProvider>
        </div>
      </PaletteImpression>
    );
  }
}

export default ExclusiveUiRow;
