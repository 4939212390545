import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'src/domain/libs/util';
import classnames from 'classnames';

import ShareContents from '../ShareContents';

export default class RoomShare extends Component {
  static get contextTypes() {
    return {
      watchPartyApp: PropTypes.object.isRequired,
      getModelData: PropTypes.func.isRequired,
    };
  }

  static get propTypes() {
    return {
      watchPartyMode: PropTypes.bool,
      handleWatchPartyModeChange: PropTypes.func,
      closeModal: PropTypes.func,
      titleModel: PropTypes.object,
      seriesModel: PropTypes.object,
      linearModel: PropTypes.object,
    };
  }

  constructor(props, context) {
    super(props, context);
    this.copyUrl = this.copyUrl.bind(this);
  }

  copyUrl() {
    // @ts-ignore TS2339
    const url = this.context.watchPartyApp.getRoomUrl(this.context, this.props.titleModel);
    const copyFrom = document.createElement('textarea');
    // @ts-ignore TS2339
    const title = `${this.props.room.name}でウォッチパーティを一緒に楽しもう！`;
    // @ts-ignore TS2339
    copyFrom.textContent = `${title}\n${url}\nパスワード: ${this.props.room.customData.pass}`;
    const bodyElm = document.getElementsByTagName('body')[0];
    bodyElm.appendChild(copyFrom);
    copyFrom.select();
    document.execCommand('copy');
    bodyElm.removeChild(copyFrom);
  }

  render() {
    // @ts-ignore TS2339
    const url = this.context.watchPartyApp.getRoomUrl(this.context, this.props.titleModel);
    return (
      <React.Fragment>
        <div className="modal-block border-bottom">
          <div className="input-block">
            <label>URLで招待</label>
            <div className="invitation-wrapper">
              <dl>
                <dt>URL</dt>
                <dd className="ellipsized">{url}</dd>
                <dt>パスワード</dt>
                {/*
                 // @ts-ignore TS2339 */}
                <dd>{this.props.room.customData.pass}</dd>
              </dl>
              <button type="button" className="btn btn-fill invitation-btn" onClick={this.copyUrl}>
                コピー
              </button>
            </div>
          </div>
          <div className="input-block">
            <label>SNSで招待</label>
            <div className="share-contents-block">
              {/*
               // @ts-ignore TS2322 */}
              <ShareContents metadata={this.props.titleModel} urlShareDisabled={true} />
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
