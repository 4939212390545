import cookieDough from 'cookie-dough';
import { SETTINGS_KEY } from '../constants/cookie';

// 再生関連設定
// TODO:playbackSettingStoreとしたい
const cookie = cookieDough();
const setSettingsData = function(settingsData) {
  if (typeof window !== 'undefined') {
    if (window.localStorage) {
      window.localStorage.setItem(SETTINGS_KEY, JSON.stringify(settingsData));
    } else if (window.navigator.cookieEnabled) {
      cookie.set(SETTINGS_KEY, JSON.stringify(settingsData), { path: '/' });
    }
  }
};
const getSettingsData = function() {
  try {
    if (typeof window !== 'undefined') {
      if (window.localStorage) {
        return JSON.parse(window.localStorage.getItem(SETTINGS_KEY));
      } else if (window.navigator.cookieEnabled) {
        return JSON.parse(cookie.get(SETTINGS_KEY));
      }
    }
  } catch (e) {
    return null;
  }
};

export default {
  set: setSettingsData,
  get: getSettingsData,
};
