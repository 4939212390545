import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import _ from 'src/domain/libs/util';
import formatTime from '../../../../common/formatTime';
import * as DOMUtils from '../../../../sketch-platform/utils/DOMUtils';

type HorizontalProgressScrubberBarProps = {
  isAdMode?: boolean;
};

class HorizontalProgressScrubberBar extends React.PureComponent<HorizontalProgressScrubberBarProps> {
  static get propTypes() {
    return {
      buffered: PropTypes.number,
      currentPlayerTime: PropTypes.number,
      currentScrubTime: PropTypes.number,
      duration: PropTypes.number,
      isHidden: PropTypes.bool,
      isScrubbing: PropTypes.bool,
      onScrub: PropTypes.func,
      onScrubEnd: PropTypes.func,
      onScrubStart: PropTypes.func,
      player: PropTypes.object.isRequired,
      playerDimensions: PropTypes.object,
      step: PropTypes.number,
    };
  }

  static get contextTypes() {
    return {
      getModelData: PropTypes.func,
    };
  }

  touchMove?: boolean;

  constructor(props, context) {
    super(props, context);
    this.onClickCapture = this.onClickCapture.bind(this);
    this.handleMouseDown = this.handleMouseDown.bind(this);
    this.handleMouseMove = this.handleMouseMove.bind(this);
    this.handleMouseUp = this.handleMouseUp.bind(this);
    this.handleMouseLeave = this.handleMouseLeave.bind(this);
    this.handleMouseEnter = this.handleMouseEnter.bind(this);
    this.handleTouchMove = this.handleTouchMove.bind(this);
    this.handleTouchStart = this.handleTouchStart.bind(this);
    this.handleTouchEnd = this.handleTouchEnd.bind(this);

    this.handleFocus = this.handleFocus.bind(this);
    this.handleBlur = this.handleBlur.bind(this);

    this.stepBack = this.stepBack.bind(this);
    this.stepForward = this.stepForward.bind(this);

    // @ts-ignore TS2339
    this.tooltipImageRef = React.createRef();
    // @ts-ignore TS2339
    this.barRef = React.createRef();
    // @ts-ignore TS2339
    this.tooltipRef = React.createRef();
    // @ts-ignore TS2339
    this.tooltipTextRef = React.createRef();

    // @ts-ignore TS2339
    this.progressPercentage = 0;
    // @ts-ignore TS2339
    this.bufferedPercentage = 0;

    this.state = {
      cursorActive: false,
      cursorPosition: 0,
      scrubberDimensions: null,
      windowDimensions: null,
    };
  }

  componentDidMount() {
    // @ts-ignore TS2339
    if (this.barRef.current) {
      // @ts-ignore TS2339
      this.barRef.current.addEventListener('focus', this.handleFocus);
      // @ts-ignore TS2339
      this.barRef.current.addEventListener('blur', this.handleBlur);
    }
  }

  componentDidUpdate() {
    // @ts-ignore TS2339
    if (this.tooltipRef.current) {
      // const duration = this.props.duration;
      // @ts-ignore TS2339
      const duration = this.props.seekableDuration;
      // @ts-ignore TS2339
      if (this.props.currentScrubTime >= 0 && duration) {
        // @ts-ignore TS2339
        const tooltip = ReactDOM.findDOMNode(this.tooltipRef.current);
        // @ts-ignore TS2339
        const tooltipRect = DOMUtils.getRect(this.tooltipRef.current);
        // @ts-ignore TS2339
        const barRect = DOMUtils.getRect(this.barRef.current);
        // @ts-ignore TS2339
        const tooltipX = ((this.props.currentScrubTime - this.props.seekableStart) / duration) * barRect.width;
        const tooltipXX = tooltipRect.width - (barRect.width - tooltipX);

        let x = barRect.width - tooltipRect.width / 2;

        // @ts-ignore TS2339
        if (this.tooltipImageRef.current) {
          let marginLeft = `-${tooltipRect.width / 2}px`;
          if (tooltipX < tooltipRect.width / 2) {
            marginLeft = `${-tooltipX}px`;
          } else if (x <= tooltipX) {
            marginLeft = `${-tooltipXX}px`;
          }
          // @ts-ignore TS2339
          Object.assign(tooltip.style, { 'margin-left': marginLeft });
        } else {
          // @ts-ignore TS2339
          const tooltipTextRect = DOMUtils.getRect(this.tooltipTextRef.current);
          let marginLeft = `-${tooltipTextRect.width / 2}px`;
          if (tooltipX < tooltipTextRect.width / 2) {
            marginLeft = `${-tooltipX}px`;
          }
          // @ts-ignore TS2339
          Object.assign(tooltip.style, { 'margin-left': marginLeft });
        }
      }
    }
  }

  componentWillUnmount() {
    // @ts-ignore TS2339
    if (this.barRef.current) {
      // @ts-ignore TS2339
      this.barRef.current.removeEventListener('focus', this.handleFocus);
      // @ts-ignore TS2339
      this.barRef.current.removeEventListener('blur', this.handleBlur);
    }
  }

  onClickCapture(event) {
    event.stopPropagation();
    event.preventDefault();
    // @ts-ignore TS2339
    const position = DOMUtils.getPointerPosition(this.barRef.current, event).x;
    // @ts-ignore TS2339
    let duration = this.props.duration;
    // @ts-ignore TS2339
    let seekableDuration = this.props.seekableDuration;
    // @ts-ignore TS2339
    let currentTime = position * seekableDuration + this.props.seekableStart;
    if (duration !== Infinity && !!duration) {
      // @ts-ignore TS2339
      this.props.player.currentTime(_.min([currentTime, duration - 0.5]));
    } else {
      // @ts-ignore TS2339
      this.props.player.currentTime(currentTime);
    }
  }

  handleMouseEnter(event) {
    // @ts-ignore TS2339
    if (this.props.onScrubStart && event.target) {
      // @ts-ignore TS2339
      const position = DOMUtils.getPointerPosition(this.barRef.current, event).x;
      // @ts-ignore TS2339
      this.props.onScrubStart(position * this.props.seekableDuration + this.props.seekableStart);
    }
  }

  handleMouseMove(event) {
    // @ts-ignore TS2339
    if (this.props.onScrub && event.target) {
      // @ts-ignore TS2339
      const position = DOMUtils.getPointerPosition(this.barRef.current, event).x;
      // @ts-ignore TS2339
      this.props.onScrub(position * this.props.seekableDuration + this.props.seekableStart);
    }
  }

  handleMouseLeave(event) {
    // @ts-ignore TS2339
    if (this.props.onScrubEnd) {
      // @ts-ignore TS2339
      this.props.onScrubEnd();
    }
  }

  handleMouseDown(event) {
    this.handleMouseMove(event);
  }

  handleMouseUp() {}

  handleFocus() {}

  handleBlur() {}

  handleTouchStart(event) {
    if (!this.props.isAdMode) {
      this.touchMove = true;
      this.handleMouseEnter(event);
      this.handleTouchMove(event);
    }
  }

  handleTouchMove(event) {
    if (!event.currentTarget || this.props.isAdMode) return;

    // @ts-ignore TS2339
    const positionX = DOMUtils.getPointerPosition(event.currentTarget, event).x;
    // @ts-ignore TS2339
    this.progressPercentage = (positionX * 100).toFixed(2);

    const currentProgress = document.querySelector('.current-progress');
    // @ts-ignore TS2339
    if (currentProgress) currentProgress.style.width = this.progressPercentage + '%';
    const scrubberHead = document.querySelector('.scrubber-head');
    // @ts-ignore TS2339
    if (scrubberHead) scrubberHead.style.left = this.progressPercentage + '%';

    this.handleMouseMove(event);
  }

  handleTouchEnd(event) {
    if (!this.props.isAdMode) {
      this.touchMove = false;
      this.onClickCapture(event);
      this.handleMouseLeave(event);
    }
  }

  stepBack() {
    // @ts-ignore TS2339
    this.props.player.currentTime(this.props.player.currentTime() - this.props.step);
  }

  stepForward() {
    // @ts-ignore TS2339
    this.props.player.currentTime(this.props.player.currentTime() + this.props.step);
  }

  render() {
    // @ts-ignore TS2339
    let duration = this.props.duration;
    // @ts-ignore TS2339
    let seekableDuration = this.props.seekableDuration;
    // @ts-ignore TS2339
    let seekableStart = this.props.seekableStart;
    // @ts-ignore TS2339
    let seekableEnd = this.props.seekableEnd;
    // @ts-ignore TS2339
    let buffered = this.props.buffered;
    // @ts-ignore TS2339
    let currentTime = this.props.currentPlayerTime;

    // @ts-ignore TS2339
    if (!this.touchMove) {
      if (duration !== Infinity && !!duration) {
        // @ts-ignore TS2339
        this.progressPercentage = (((currentTime - seekableStart) * 100) / seekableDuration).toFixed(2);
      }
      if (!!buffered) {
        // @ts-ignore TS2339
        this.bufferedPercentage = (((buffered - seekableStart) * 100) / seekableDuration).toFixed(2);
      }
    }

    let scrubPercentage = 0;
    let trickplayImage = null;
    let trickplayStyle = {};
    // @ts-ignore TS2339
    let currentScrubTime = this.props.currentScrubTime - seekableStart;
    if (currentScrubTime >= 0 && duration) {
      // @ts-ignore TS2322
      scrubPercentage = ((currentScrubTime * 100) / seekableDuration).toFixed(2);
      trickplayStyle = { left: `${scrubPercentage}%` };

      // @ts-ignore TS2339
      if (this.props.player && this.props.player.seekThumbnailTrack()) {
        // @ts-ignore TS2339
        const track = this.props.player.seekThumbnailTrack();
        const cue = _.find(track.cues, cue => {
          return cue.startTime <= currentScrubTime && currentScrubTime < cue.endTime;
        });
        if (cue) {
          // tile.1.jpg#crwhi=10,10,160,90,9
          const [filename, query] = _.split(cue.text, '#');
          const file = _.replace(track.src, _.last(_.split(track.src, '/')), filename);
          const [key, params] = _.split(query, '=');
          const [col, row, width, height, interval] = _.split(params, ',');
          const diff = currentScrubTime - cue.startTime;
          // @ts-ignore TS2345
          let per = parseInt(diff / interval, 10);
          // @ts-ignore TS2345
          let colIdx = parseInt(per % col, 10);
          // @ts-ignore TS2345
          let rowIdx = parseInt(per / row, 10);
          let tpWidth = 190;

          // @ts-ignore TS2339
          if (this.props.watchSpMode) {
            tpWidth = 130;
          }
          // @ts-ignore TS2363
          let tpHeight = (tpWidth / width) * height;
          // let tpHeight = 73.125;
          // let tpWidth = (tpHeight / height) * width;
          // @ts-ignore TS2362
          let size = (width * col) / (width / tpWidth);
          trickplayImage = (
            // @ts-ignore TS2339
            <div className="tp-image" ref={this.tooltipImageRef} style={{ width: tpWidth, height: tpHeight }}>
              <div
                style={{
                  backgroundImage: `url('${file}')`,
                  backgroundSize: size,
                  backgroundPositionX: `-${tpWidth * colIdx}px`,
                  backgroundPositionY: `-${tpHeight * rowIdx}px`,
                }}
              />
            </div>
          );
        }
      } else {
        // @ts-ignore TS2345
        let per = parseInt(scrubPercentage, 10);
        // @ts-ignore TS2345
        let row = parseInt(per / 10, 10);
        // @ts-ignore TS2345
        let col = parseInt(per % 10, 10);
        if (scrubPercentage == 100) {
          row = 9;
          col = 9;
        }
        // BC仕様は1600固定160固定
        let size = 1600 / (160 / 130);
        let seekPreview;
        // @ts-ignore TS2339
        if (this.props.seekPreview && this.props.seekPreview.urls.length > 0) {
          // @ts-ignore TS2339
          seekPreview = this.props.seekPreview;
        } else {
          // @ts-ignore TS2339
          seekPreview = this.props.player.seekPreview();
        }
        if (seekPreview) {
          trickplayImage = (
            // @ts-ignore TS2339
            <div className="tp-image" ref={this.tooltipImageRef}>
              <div
                style={{
                  backgroundImage: `url('${seekPreview.urls[0]}')`,
                  backgroundSize: size,
                  backgroundPositionX: `-${130 * col}px`,
                  backgroundPositionY: `-${73.125 * row}px`,
                }}
              />
            </div>
          );
        }
      }
    }

    const props = {};
    const browserInfo = this.context.getModelData('browserInfo');
    if (!browserInfo.isTouchDevice && !browserInfo.isRequestDesktopWebsite) {
      // @ts-ignore TS2339
      props.onClickCapture = this.onClickCapture;
      // @ts-ignore TS2339
      props.onMouseEnter = this.handleMouseEnter;
      // @ts-ignore TS2339
      props.onMouseLeave = this.handleMouseLeave;
      // @ts-ignore TS2339
      props.onMouseDown = this.handleMouseDown;
      // @ts-ignore TS2339
      props.onMouseMove = this.handleMouseMove;
    } else {
      // @ts-ignore TS2339
      props.onTouchStart = this.handleTouchStart;
      // @ts-ignore TS2339
      props.onTouchMove = this.handleTouchMove;
      // @ts-ignore TS2339
      props.onTouchEnd = this.handleTouchEnd;
    }

    let tpText = currentScrubTime;
    let isMinus = false;
    if (seekableStart > 0 || seekableEnd > 0) {
      tpText = seekableDuration - tpText;
      isMinus = true;
    }

    return (
      // @ts-ignore TS2339
      <div className="scrubber-container" {...props} ref={this.barRef} tabIndex="0">
        <div className="scrubber-bar">
          <div className="track">
            {/*
             // @ts-ignore TS2339 */}
            <div className="buffered" style={{ width: `${this.bufferedPercentage}%` }}></div>
            {/*
             // @ts-ignore TS2339 */}
            <div className="current-progress" style={{ width: `${this.progressPercentage}%` }}></div>
            {!this.props.isAdMode && currentScrubTime >= 0 ? (
              <div className="play-head" style={{ display: 'block', left: `${scrubPercentage}%` }}></div>
            ) : null}
          </div>
          {!this.props.isAdMode && currentScrubTime >= 0 && duration ? (
            <div
              className={classnames('trickplay', 'trickplay-visible', {
                'trickplay-text-only': !trickplayImage,
                'trickplay-text-and-image': !!trickplayImage,
              })}
              style={trickplayStyle}
              // @ts-ignore TS2339
              ref={this.tooltipRef}
            >
              {trickplayImage}
              {/*
               // @ts-ignore TS2339 */}
              <div className="tp-text" ref={this.tooltipTextRef}>
                {isMinus ? '-' : ''}
                {formatTime(tpText)}
              </div>
            </div>
          ) : null}
          {/*
           // @ts-ignore TS2322 */}
          <div
            aria-label="シークタイムスクラバー"
            aria-valuemax={parseInt(duration, 10)}
            aria-valuemin="0"
            // @ts-ignore TS2339
            aria-valuenow={parseInt(this.state.currentPlayerTime, 10)}
            aria-valuetext={`${formatTime(duration)}中${formatTime(currentTime)}`}
            className="scrubber-head"
            // @ts-ignore TS2339
            style={{ left: `${this.progressPercentage}%` }}
          ></div>
        </div>
      </div>
    );
  }
}

export default HorizontalProgressScrubberBar;
