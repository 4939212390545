import routes from '../../../common/routes';
import { RouteHandler } from 'src/types/app/Route';
import { AuthContext } from '@domain/models/auth';

export type DeepLinkAppData = {
  token?: {
    onetime_token?: string;
  };
  path?: string;
};
class DeeplinkApp {
  routeHandler: RouteHandler;
  authContext: AuthContext;
  constructor(data: { routeHandler?: RouteHandler; authContext?: AuthContext } = {}) {
    this.routeHandler = data.routeHandler;
    this.authContext = data.authContext;
  }

  launch(data: any = {}) {
    if (!this.authContext) {
      this._moveToApp(data);
    } else if (!data.token) {
      this._moveToLoginPage();
    } else {
      this._moveToApp(data);
    }
  }

  _moveToApp({ token, path }: DeepLinkAppData) {
    // 基本はアクセスしているパス
    let routePath = this.routeHandler.path;
    if (!path) path = routePath;
    if (!path || path === '/') {
      path = '/home';
      // pvページは/contentに変換する
    } else if (routes.pv.match(path)) {
      path = routes.content.makePath({ id: this.routeHandler.params.id });
    }

    let launchUrl = `wod://${path}`;
    if (token?.onetime_token?.length > 0) {
      launchUrl += `?token=${window.encodeURIComponent(token.onetime_token)}`;
    }
    window.location.href = launchUrl;
  }

  _moveToLoginPage() {
    const returnTo = this.routeHandler.url;
    window.location.href = `/login/mobile?return_to=${encodeURIComponent(returnTo)}`;
  }
}

export default DeeplinkApp;
