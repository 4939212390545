import React from 'react';
import _ from 'src/domain/libs/util';
import datetime from 'src/domain/libs/datetime';

//
// props
// - course (必須)
// - subscription (必須)
// - updatedSubscription (任意:バウチャー適用後の情報)
//
const SubscriptionTrialInfo = props => {
  if (typeof _.get(props, 'course') !== 'object') {
    return null;
  }
  if (typeof _.get(props, 'subscription') !== 'object') {
    return null;
  }

  if (props.course.isNotViewTrialInfo) {
    return null;
  }

  // コースに無料期間設定なし
  if (!props.course.freeTrialDate) {
    return <div>なし</div>;
  }

  // 無料期間享受できない
  if (!props.subscription.trialEndAt) {
    return <div>なし（すでに無料トライアルをご利用済み）</div>;
  }

  const updatedSubscription = props.updatedSubscription;
  // バウチャー適用がある場合
  if (_.get(updatedSubscription, 'trialEndAt')) {
    return (
      <div className="item">
        <p className="strikeout">
          <span className="text">
            {datetime(props.subscription.trialEndAt, 'YYYY/MM/DD HH:mm:ss').format('YYYY/MM/DD')}
          </span>
          <span>まで適用</span>
        </p>
        <p>
          <span className="text">
            {datetime(updatedSubscription.trialEndAt, 'YYYY/MM/DD HH:mm:ss').format('YYYY/MM/DD')}
          </span>
          <span>まで適用</span>
        </p>
      </div>
    );
  }

  // 無料期間がある場合
  return (
    <div className="item">
      <p>
        <span className="text">
          {datetime(props.subscription.trialEndAt, 'YYYY/MM/DD HH:mm:ss').format('YYYY/MM/DD')}
        </span>
        <span>まで適用</span>
      </p>
    </div>
  );
};

export default SubscriptionTrialInfo;
