import _ from 'src/domain/libs/util';
import { PlaybackRuleData, PlaybackRule } from 'src/domain/models/playbackRule';

export default function playbackRule(data: PlaybackRuleData): PlaybackRule {
  const playbackRule = Object.assign(
    {
      enable_seek_flag: true,
      enable_pause_flag: true,
      enable_playback_rate_change_flag: true,
      enable_pip_flag: true,
    },
    data || {},
  );

  return {
    id: playbackRule.playback_rule_id,
    enableAirplay: playbackRule.enable_airplay_flag,
    enableBackgroundPlay: playbackRule.enable_background_play_flag,
    enableCatchup: playbackRule.enable_catchup_flag,
    enableChromecast: playbackRule.enable_chromecast_flag,
    enableExternalMonitor: playbackRule.enable_external_monitor_flag,
    enableMirroring: playbackRule.enable_mirroring_flag,
    enablePause: playbackRule.enable_pause_flag,
    enablePlaybackRateChange: playbackRule.enable_playback_rate_change_flag,
    enableScreenCapture: playbackRule.enable_screen_capture_flag,
    enableSeek: playbackRule.enable_seek_flag,
    enableStartOver: playbackRule.enable_start_over_flag,
    enablePip: playbackRule.enable_pip_flag,
    maximumBitrate: playbackRule.maximum_bitrate,
    minimumBitrate: playbackRule.minimum_bitrate,
    getRawData: function() {
      return data;
    },
  };
}
