import safeTimeout from '../../common/safeTimeout';

export function updateTag(tagName, keyName, keyValue, attrName, attrValue) {
  const node = document.head.querySelector(
    `${tagName}[${keyName}="${keyValue}"]`,
  );
  if (node && node.getAttribute(attrName) === attrValue) return;

  // Remove and create a new tag in order to make it work with bookmarks in Safari
  if (node) {
    node.parentNode.removeChild(node);
  }
  if (typeof attrValue === 'string') {
    const nextNode = document.createElement(tagName);
    nextNode.setAttribute(keyName, keyValue);
    nextNode.setAttribute(attrName, attrValue);
    document.head.appendChild(nextNode);
  }
}

export function updateMeta(name, content) {
  updateTag('meta', 'name', name, 'content', content);
}

export function updateCustomMeta(property, content) {
  updateTag('meta', 'property', property, 'content', content);
}

export function updateLink(rel, href) {
  updateTag('link', 'rel', rel, 'href', href);
}

export function runTransition(el, options, cb) {
  const beforeStyle = options.before;
  if (!!beforeStyle) {
    el.style.transitionDuration = null;
    Object.assign(el.style, beforeStyle);
  }
  el.style.transitionDuration = `${options.duration || 300}ms`;

  let afterStyle = Object.assign({}, options.after || options);
  delete afterStyle.duration;
  delete afterStyle.before;

  const endEventNames = ['webkitTransitionEnd', 'transitionend', 'oTransitionEnd', 'mozTransitionEnd']
  let _emit = false;
  const callback = (e) => {
    if (_emit) return;
    _emit = true;
    if (el) {
      endEventNames.map((eventName) => {
        el.removeEventListener(eventName, callback, false);
      });
    }
    if (cb) cb();
  };
  endEventNames.map((eventName) => {
    el.addEventListener(eventName, callback, false)
  });
  safeTimeout(() => {
    Object.assign(el.style, afterStyle);
  }, 5);
};

export function getRect(node) {
  return node && node.getBoundingClientRect();
};

export function getWindowRect() {
  const firstEl = document.getElementsByTagName("body")[0];
  const width = window.innerWidth || document.documentElement.clientWidth || firstEl.clientWidth;
  const height = window.innerHeight || document.documentElement.clientHeight|| firstEl.clientHeight;
  return {
    top: 0,
    bottom: height,
    left: 0,
    right: width,
    width: width,
    height: height
  }
};

export function isElementInViewport(node) {
  return this.isElementInRegion(this.getRect(node), this.getWindowRect());
};

export function isElementInRegion(rect, region) {
  let rectX = rect.left + (rect.right - rect.left)/2;
  let rectY = rect.top + (rect.bottom - rect.top)/2;
  return rect.left !== rect.right && region.left !== region.right && (rectX >= region.left&& rectX <= region.right&& rectY >= region.top && rectY <= region.bottom);
};

export function getScrollTop() {
  return window.pageYOffset;
};

export function getDistanceToTopOfElement(node) {
  return this.getRect(node).top - this.getWindowRect().height;
};

export function getDistanceToBottomOfElement(node) {
  const scrollTop = this.getScrollTop();
  const windowRect = this.getWindowRect();
  const rect = this.getRect(node);
  const scrollHeight = scrollTop + windowRect.height;
  return rect.top + scrollTop + rect.height - scrollHeight;
};

export function getTotalDocumentSize() {
  return {
    // @ts-ignore TS2339
    height: document.height !== undefined ? document.height : document.body.offsetHeight,
    // @ts-ignore TS2339
    width: document.width !== undefined ? document.width : document.body.offsetWidth
  };
};

export function findPosition(el) {
  let box;

  if (el.getBoundingClientRect && el.parentNode) {
    box = el.getBoundingClientRect();
  }

  if (!box) {
    return {
      left: 0,
      top: 0
    };
  }

  const docEl = document.documentElement;
  const body = document.body;

  const clientLeft = docEl.clientLeft || body.clientLeft || 0;
  const scrollLeft = window.pageXOffset || body.scrollLeft;
  const left = box.left + scrollLeft - clientLeft;

  const clientTop = docEl.clientTop || body.clientTop || 0;
  const scrollTop = window.pageYOffset || body.scrollTop;
  const top = box.top + scrollTop - clientTop;

  // Android sometimes returns slightly off decimal values, so need to round
  return {
    left: Math.round(left),
    top: Math.round(top)
  };
}

export function getPointerPosition(el, event) {
  const position = {};
  const box = findPosition(el);
  const boxW = el.offsetWidth;
  const boxH = el.offsetHeight;

  const boxY = box.top;
  const boxX = box.left;
  let pageY = event.pageY;
  let pageX = event.pageX;

  if (event.changedTouches) {
    pageX = event.changedTouches[0].pageX;
    pageY = event.changedTouches[0].pageY;
  }

  // @ts-ignore TS2339
  position.y = Math.max(0, Math.min(1, ((boxY - pageY) + boxH) / boxH));
  // @ts-ignore TS2339
  position.x = Math.max(0, Math.min(1, (pageX - boxX) / boxW));

  return position;
}
