import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'src/domain/libs/util';
import classnames from 'classnames';

import WatchPartyModal from './WatchPartyModal';

export default class WatchPartyButton extends Component {
  static get contextTypes() {
    return {
      watchPartyApp: PropTypes.object,
      gtmApp: PropTypes.object,
    };
  }
  static get propTypes() {
    return {
      watchPartyMode: PropTypes.bool,
      watchPartyType: PropTypes.string,
      handleWatchPartyModeChange: PropTypes.func,
      player: PropTypes.object,
      closeModal: PropTypes.func,
      showModal: PropTypes.func,
      titleModel: PropTypes.object,
      seriesModel: PropTypes.object,
      linearModel: PropTypes.object,
    };
  }
  constructor(props, context) {
    super(props, context);
    this._handleClick = this._handleClick.bind(this);
    // @ts-ignore TS2339
    this.watchPartyProfile = context.watchPartyApp.getProfile();

    this.state = {
      isPausedOnce: false,
    };
  }

  componentDidUpdate(prevProps) {
    // @ts-ignore TS2339
    if (!prevProps.player && !!this.props.player) {
      // 意図的に動画を一時停止した場合のみ起動したい
      // player.paused()では初回レンダー時に常にtrueになる
      // @ts-ignore TS2339
      this.props.player.on('pause', () => {
        this.setState({
          isPausedOnce: true,
        });
      });
    }
  }

  _handleClick() {
    // pauseさせる
    // @ts-ignore TS2339
    if (this.props.player && !this.props.watchPartyMode) {
      // @ts-ignore TS2339
      if (this.props.playContext.get('playbackRule').enablePause !== false) {
        // @ts-ignore TS2339
        this.props.player.pause();
      }
    }
    // @ts-ignore TS2339
    this.props.showModal(
      <WatchPartyModal
        // @ts-ignore TS2322
        userState={'master'}
        // @ts-ignore TS2339
        player={this.props.player}
        // @ts-ignore TS2339
        showModal={this.props.showModal}
        // @ts-ignore TS2339
        closeModal={this.props.closeModal}
        // @ts-ignore TS2339
        titleModel={this.props.titleModel}
        // @ts-ignore TS2339
        seriesModel={this.props.seriesModel}
        // @ts-ignore TS2339
        linearModel={this.props.linearModel}
        // @ts-ignore TS2339
        handleWatchPartyModeChange={this.props.handleWatchPartyModeChange}
        // @ts-ignore TS2339
        watchPartyMode={this.props.watchPartyMode}
      />,
      {
        classes: 'watchParty-modal',
        enableCloseWithOutsidePointerDown: false,
      },
    );
  }

  render() {
    // アプリケーション設定で閉じている場合ボタンを表示しない
    const closeFlag = this.context.watchPartyApp.getCloseFlag();
    // @ts-ignore TS2339
    return this.props.player &&
      // @ts-ignore TS2339
      !this.props.player.isGCasted() &&
      // @ts-ignore TS2339
      this.props.watchPartyType == 'closeType' &&
      closeFlag ? (
      <a
        href="javascript: void(0)"
        className={classnames('action-btn watch-party-btn', {
          // @ts-ignore TS2339
          active: this.props.watchPartyMode,
          // "": !this.props.watchPartyMode
        })}
        onClick={this._handleClick}
        // @ts-ignore TS2322
        tabIndex="0"
      >
        <i
          className={classnames('fa fa-watch-party', {
            // "fa-watch-party": !this.props.watchPartyMode,
            // "fa-watch-party": this.props.watchPartyMode
          })}
        ></i>
        <span>Watch Party</span>
      </a>
    ) : null;
  }
}
