import cookieDough from 'cookie-dough';
import { VOLUME_KEY } from '../constants/cookie';

const cookie = cookieDough();
const setVolumeData = function(volumeData) {
  if (typeof window !== 'undefined') {
    if (window.localStorage) {
      window.localStorage.setItem(VOLUME_KEY, JSON.stringify(volumeData));
    } else if (window.navigator.cookieEnabled) {
      cookie.set(VOLUME_KEY, JSON.stringify(volumeData), { path: '/' });
    }
  }
};
const getVolumeData = function() {
  try {
    if (typeof window !== 'undefined') {
      if (window.localStorage) {
        return JSON.parse(window.localStorage.getItem(VOLUME_KEY));
      } else if (window.navigator.cookieEnabled) {
        return JSON.parse(cookie.get(VOLUME_KEY));
      }
    }
  } catch (e) {
    return null;
  }
};

export default {
  set: setVolumeData,
  get: getVolumeData,
};
