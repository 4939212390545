import React from 'react';
import PropTypes from 'prop-types';
import BackgroundImage from '../../../common/components/BackgroundImage';

const ImageRotatorCard = props => {
  return (
    <React.Fragment>
      {/*
       // @ts-ignore TS2322 */}
      <BackgroundImage className="image-rotator-image" url={props.url} />
      {/*
       // @ts-ignore TS2322 */}
      <BackgroundImage className="image-rotator-image" url={props.preloadImage} />
    </React.Fragment>
  );
};

ImageRotatorCard.propTypes = {
  duration: PropTypes.number,
  index: PropTypes.number,
  url: PropTypes.string,
  numRotations: PropTypes.number,
};

ImageRotatorCard.defaultProps = {
  numRotations: 0,
};

export default ImageRotatorCard;
