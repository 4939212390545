import React from 'react';
import classnames from 'classnames';
import _ from 'src/domain/libs/util';

import BackgroundImage from '../../../common/components/BackgroundImage';

const BillboardIndicatorCard = (props, context) => {
  let style = {};
  if (props.animating) {
    let sliderContentTransform = '';
    if (props.scrollWidth !== 0) {
      sliderContentTransform = `translate(${props.scrollWidth}px, 0px)`;
    }
    style = {
      MsTransform: sliderContentTransform,
      WebkitTransform: sliderContentTransform,
      transform: sliderContentTransform,
      WebkitTransition: `-webkit-transform ${props.slideSpeed}ms ease 0s`,
      OTransition: `-o-transform ${props.slideSpeed}ms ease 0s`,
      MozTransition: `transform ${props.slideSpeed}ms ease 0s, -moz-transform 0.75s ease 0s`,
      transition: `transform ${props.slideSpeed}ms ease 0s, -webkit-transform 0.75s ease 0s, -moz-transform 0.75s ease 0s, -o-transform 0.75s ease 0s`,
      pointerEvents: 'none',
    };
  }
  return (
    <li
      className={classnames({ active: props.active })}
      // @ts-ignore TS2322
      tabIndex="0"
      onClick={props.onClick}
      data-page={props.page}
      style={style}
    >
      <div className="indicator-title">
        <BackgroundImage
          // @ts-ignore TS2322
          className={classnames('artwork indicator-title-thumbnail', { noImage: !props.thumbnailUrl })}
          url={props.thumbnailUrl}
          useLazyload={false}
        />
      </div>
      {props.progress !== null ? (
        <div className="indicator-bar">
          <div style={{ width: `${props.progress}%` }}></div>
        </div>
      ) : null}
    </li>
  );
};

export default React.memo(BillboardIndicatorCard);
