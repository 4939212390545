import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const LoadingListBox = (props, context) => {
  const browserInfo = context.getModelData('browserInfo');
  const pulsate = props.pulsate;
  const displayWhenNotPulsing = props.displayWhenNotPulsing;
  const boxClassNames = props.boxClassNames;
  const delay = props.delay + 's';
  const style = pulsate
    ? {
        WebkitAnimationDelay: delay,
        AnimationDelay: delay,
      }
    : {};

  boxClassNames['pulsate'] = pulsate;
  boxClassNames['pulsate-transparent'] = pulsate && !displayWhenNotPulsing;
  boxClassNames['no-pulsate'] = !pulsate && displayWhenNotPulsing;

  return (
    <div className="loadingListBox clearfix">
      <div className={classnames(boxClassNames, 'imgBox')}>
        <div className="img ratio-16x9"></div>
      </div>
      <div className={classnames(boxClassNames, 'ti')}></div>
      <div className={classnames(boxClassNames, 'tx')}></div>
      <div className={classnames(boxClassNames, 'tx')}></div>
      <div className={classnames(boxClassNames, 'tx')}></div>
    </div>
  );
};

LoadingListBox.propTypes = {
  boxClassName: PropTypes.object,
  delay: PropTypes.number,
  displayWhenNotPulsing: PropTypes.bool,
  pulsate: PropTypes.bool,
};

LoadingListBox.defaultProps = {
  boxClassNames: {},
  delay: 0,
  displayWhenNotPulsing: false,
  pulsate: false,
};
LoadingListBox.contextTypes = {
  getModelData: PropTypes.func,
};

export default LoadingListBox;
