/**配信加入（WOWOWと直接契約）／CASなし */
const STREAMING_DIRECT_WITHOUT_CAS = 1;
/**配信加入（WOWOWと直接契約）／CASあり */
const STREAMING_DIRECT_WITH_CAS = 2;
/**配信加入（WOWOWと直接契約）／CASなし／複数 */
const STREAMING_DIRECT_WITHOUT_CAS_MULTI = 3;
/**配信加入（WOWOWと直接契約）／CASあり／複数 */
const STREAMING_DIRECT_WITH_CAS_MULTI = 4;
/**放送加入 */
const BROADCAST = 5;
/**アプリストア経由加入 */
const APP_STORE_WITHOUT_CAS = 6;
/**アプリストア経由加入(CASあり) */
const APP_STORE_WITH_CAS = 7;
/**配信加入（WOWOWと直接契約）／随時課金／CASなし */
const STREAMING_DIRECT_WITHOUT_CAS_PAY_AT_SUBSCRIBE = 8;
/**配信加入（WOWOWと直接契約）／随時課金／CASあり */
const STREAMING_DIRECT_WITH_CAS_PAY_AT_SUBSCRIBE = 9;

/**現在のデフォルトのコース */
const DEFAULT = STREAMING_DIRECT_WITHOUT_CAS_PAY_AT_SUBSCRIBE;

export {
  STREAMING_DIRECT_WITHOUT_CAS,
  STREAMING_DIRECT_WITH_CAS,
  STREAMING_DIRECT_WITHOUT_CAS_MULTI,
  STREAMING_DIRECT_WITH_CAS_MULTI,
  BROADCAST,
  APP_STORE_WITHOUT_CAS,
  APP_STORE_WITH_CAS,
  STREAMING_DIRECT_WITHOUT_CAS_PAY_AT_SUBSCRIBE,
  STREAMING_DIRECT_WITH_CAS_PAY_AT_SUBSCRIBE,
  DEFAULT,
};
