import Falcor from 'falcor';
import pathSyntax from 'falcor-path-syntax';
import _ from 'src/domain/libs/util';

var PathEvaluator = Falcor.Model;
PathEvaluator.prototype.fetch = function fetch(paths) {
  var rejected = false;
  var resolved = false;
  const response = this.get.apply(this, paths);
  const self = this;
  if (!response.__promise) {
    response.__promise = new Promise((resolve, reject) => {
      response
        .then(function() {
          if (rejected) return;
          if (arguments.length === 0 || typeof arguments[0] === 'undefined') {
            try {
              self._root.cache = {};
              self.get
                .apply(self, paths)
                .then(function() {
                  if (rejected) return;
                  resolve.apply(self, arguments);
                })
                .catch(function() {
                  if (rejected) return;
                  reject.apply(self, arguments);
                });
            } catch (e) {
              console.log(e);
            }
          } else {
            resolved = true;
            resolve.apply(self, arguments);
          }
        })
        .catch(function() {
          if (rejected) return;
          reject.apply(self, arguments);
        });
    });
  }
  response.__promise.dispose = function() {
    rejected = true;
  };
  return response.__promise;
};

PathEvaluator.prototype.getSync = function(path, defaultValue) {
  if (!path) return defaultValue;
  if (typeof path === 'string') {
    path = path.split('.');
  }
  try {
    const value = extractFromCache(this, path);
    return value || defaultValue;
  } catch (e) {
    console.error(e);
    return defaultValue;
  }
};

// pull things out of a falcor graph, following
// refs and unboxing values as necessary
export function extractFromCache(model, pathArg, paths = {}) {
  let path = pathSyntax.fromPath(pathArg);
  if (Object.prototype.toString.call(path) !== '[object Array]') {
    return;
  }
  const obj = model._root.cache;
  model._syncCheck = function() {};
  const value = model._getBoundValue(model, path, true);

  if (!value || !value.found) {
    return;
  } else if (value.value) {
    let ret;
    if (value.value.$type !== 'atom') {
      _.map(value.value, (gen, key) => {
        if (key.indexOf('$') === 0 || typeof gen === 'undefined') {
          // $expires等はよく考える必要がある
          return;
        }

        try {
          let value;
          if (gen.$type === 'ref') {
            if (_.difference(path, gen.value).length == 0) {
              // 自分自身を参照してしまっている場合は何もしない
            } else {
              // metaのprev,nextのようなデータを参照すると無限ループする為
              const pathKey = JSON.stringify(gen.value);
              if (!paths.hasOwnProperty(pathKey)) {
                paths[pathKey] = undefined;
                value = extractFromCache(model, gen.value, paths);
                paths[pathKey] = value;
              }
            }
          } else if (gen.$type === 'atom') {
            value = gen.value;
          } else if (gen.$_absolutePath && !gen.$type) {
            // この形でデータを取得すると理屈上無限ループすることがあるようなので諦める
            const pathKey = JSON.stringify(gen.$_absolutePath);
            if (!paths.hasOwnProperty(pathKey)) {
              paths[pathKey] = undefined;
              if (_.difference(path, gen.$_absolutePath).length == 0) {
              } else {
                value = extractFromCache(model, gen.$_absolutePath, paths);
                paths[pathKey] = value;
              }
            }
          }
          if (typeof value !== 'undefined' && (typeof value !== 'object' || !_.isEmpty(value))) {
            if (typeof ret === 'undefined') {
              ret = {};
            }
            ret[key] = value;
          }
        } catch (e) {
          throw e;
        }
      });
    } else {
      ret = value.value.value;
    }
    return ret;
  }
}

export default PathEvaluator;
