import { useMemo } from 'react';

import { Product } from 'src/types/context/Product';

const useEnableProducts = ({ products = [], isProductPage }: { products: Product[]; isProductPage: boolean }) => {
  const _products = useMemo(() => {
    let __products = products;
    // エピソードページでは販売前の商品は出さない
    if (!isProductPage) {
      __products = products.filter(product => !product.isSaleEnded);
    }
    return __products;
  }, [isProductPage, products]);

  return _products;
};

export default useEnableProducts;
