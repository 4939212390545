import { useMemo } from 'react';
import _ from 'src/domain/libs/util';

import routes from '../routes';

const useLinkProps = (item, simulcasts) => {
  const linkProps = useMemo(() => {
    let props;
    if (item.type === 'media') {
      props = { params: { id: item.id }, to: routes.watchNow };
      if (item.refId) {
        props = { params: { id: item.refId }, to: routes.content };
      }
    } else if (item.type === 'group') {
      props = { params: { id: item.id }, to: routes.title };
      if (item.refId) {
        props = { params: { id: item.refId }, to: routes.program };
      }
    } else if (item.type === 'linear_channel') {
      const simulcast = _.find(simulcasts, simul => simul.refId == item.linearChannelMeta.refId);
      props = { params: { channelName: simulcast.name }, to: routes.simulcast };
    } else if (item.advertisingId) {
      // TODO:広告
    }
    return props;
  }, [item.advertisingId, item.id, item.linearChannelMeta, item.refId, item.type, simulcasts]);

  return linkProps;
};

export default useLinkProps;
