import React from 'react';
import _ from 'src/domain/libs/util';

import OAPPlayerCore from './OAPPlayerCore';
import OAPPlayerControls from './OAPPlayerControls';
class OAPPlayer extends React.Component {
  static get defaultProps() {
    return {
      muted: true,
      useControls: true,
    };
  }

  constructor(props, context) {
    super(props, context);

    this.onPlayerReady = this.onPlayerReady.bind(this);
    this.onPlayerLoaded = this.onPlayerLoaded.bind(this);
    this.onPlayerLoadstart = this.onPlayerLoadstart.bind(this);
    this.onPlayerPause = this.onPlayerPause.bind(this);
    this.onPlayerPlay = this.onPlayerPlay.bind(this);
    this.onPlayerPlaying = this.onPlayerPlaying.bind(this);
    this.onPlayerEnded = this.onPlayerEnded.bind(this);
    this.onPlayerDurationChange = this.onPlayerDurationChange.bind(this);
    this.onPlayerPlaybackRateChange = this.onPlayerPlaybackRateChange.bind(this);
    this.onPlayerVolumeChange = this.onPlayerVolumeChange.bind(this);
    this.onPlayerSuspend = this.onPlayerSuspend.bind(this);
    this.onPlayerRebuffering = this.onPlayerRebuffering.bind(this);
    this.onPlayerFirstplay = this.onPlayerFirstplay.bind(this);
    this.onPlayerTimeUpdate = this.onPlayerTimeUpdate.bind(this);
    this.onPlayerError = this.onPlayerError.bind(this);
    this.handleKeyboardFocus = this.handleKeyboardFocus.bind(this);
    this.onClickPlay = this.onClickPlay.bind(this);
    this.onClickVolume = this.onClickVolume.bind(this);
    this.onMouseMove = this.onMouseMove.bind(this);

    // @ts-ignore TS2339
    this.keyboardFocus = false;
    // @ts-ignore TS2339
    this.playStartAnalytics = {};

    this.state = {
      uiState: 'active',
      ready: false,
      loaded: false,
      paused: props.autoplay != false,
      rebuffering: false,
      ended: false,
      volume: 1,
      canplaythrough: false,
      playStartCompleted: false,
      muted: props.muted,
    };
  }

  componentDidMount() {
    // @ts-ignore TS2339
    this._isMounted = true;
    // @ts-ignore TS2339
    this.setState({ oap: this.props.oap });
  }

  componentWillUnmount() {
    // @ts-ignore TS2339
    this._isMounted = false;
    // @ts-ignore TS2339
    if (this.playStartAnalytics.timerId) {
      // @ts-ignore TS2339
      clearInterval(this.playStartAnalytics.timerId);
      // @ts-ignore TS2339
      delete this.playStartAnalytics.timerId;
    }
  }

  onPlayerReady(obj) {
    // console.log('OAPPlayer.onPlayerReady')
    // @ts-ignore TS2339
    this._player = obj;
    this.setState({ ready: true });
    // @ts-ignore TS2339
    if (this.props.onPlayerReady) {
      // @ts-ignore TS2339
      this.props.onPlayerReady(this._player);
    }
  }

  onPlayerLoaded() {
    // console.log('OAPPlayer.onPlayerLoaded')
    this.setState({ loaded: true });
    // @ts-ignore TS2339
    if (this.props.onPlayerLoaded) {
      // @ts-ignore TS2339
      this.props.onPlayerLoaded();
    }
  }

  onPlayerLoadstart() {
    // console.log('OAPPlayer.onPlayerLoadstart')
    // @ts-ignore TS2339
    if (this.props.onPlayerLoadstart) {
      // @ts-ignore TS2339
      this.props.onPlayerLoadstart();
    }
  }

  onPlayerPause() {
    // console.log('OAPPlayer.onPlayerPause')
    this.setState({ paused: true, rebuffering: false });
    // @ts-ignore TS2339
    if (this.props.onPlayerPause) {
      // @ts-ignore TS2339
      this.props.onPlayerPause();
    }
    // @ts-ignore TS2339
    if (this.playStartAnalytics.timerId) {
      // @ts-ignore TS2339
      clearInterval(this.playStartAnalytics.timerId);
      // @ts-ignore TS2339
      delete this.playStartAnalytics.timerId;
    }
  }

  onPlayerPlay() {
    // console.log('OAPPlayer.onPlayerPlay')
    // @ts-ignore TS2339
    if (this.autoPlayErrorFlg) this.autoPlayErrorFlg = false;
    this.setState({ paused: false, ended: false, rebuffering: false });
    // @ts-ignore TS2339
    if (this.props.onPlayerPlay) {
      // @ts-ignore TS2339
      this.props.onPlayerPlay();
    }
  }

  onPlayerPlaying() {
    // console.log('OAPPlayer.onPlayerPlaying')
    this.setState({ firstplaying: true });
    // @ts-ignore TS2339
    if (!this.playStartAnalytics.isSent) {
      const accumlate = () => {
        // @ts-ignore TS2339
        this.playStartAnalytics.accumPlayingSec++;
      };
      // @ts-ignore TS2339
      this.playStartAnalytics = {
        startDate: new Date().getTime(),
        // @ts-ignore TS2339
        playerFirstPlayTime: this.playStartAnalytics.playerFirstPlayTime || this._player.currentTime(),
        isSent: false,
        // @ts-ignore TS2339
        accumPlayingSec: this.playStartAnalytics.accumPlayingSec || 0,
        // @ts-ignore TS2339
        timerId: this.playStartAnalytics.timerId || setInterval(accumlate.bind(this), 1000),
      };
    }
  }

  onPlayerEnded() {
    // console.log('OAPPlayer.onPlayerEnded')
    this.setState({ ended: true, rebuffering: false });
    // @ts-ignore TS2339
    if (this.props.onPlayerEnded) {
      // @ts-ignore TS2339
      this.props.onPlayerEnded();
    }
  }

  onPlayerDurationChange(duration) {
    // console.log('OAPPlayer.onPlayerDurationChange')
    // @ts-ignore TS2339
    if (this.props.onPlayerDurationChange) {
      // @ts-ignore TS2339
      this.props.onPlayerDurationChange(duration);
    }
  }

  onPlayerPlaybackRateChange(playbackRate) {
    // console.log('OAPPlayer.onPlayerPlaybackRateChange')
    this.setState({ playbackRate }, () => {
      // @ts-ignore TS2339
      if (this.props.onPlayerPlaybackRateChange) {
        // @ts-ignore TS2339
        this.props.onPlayerPlaybackRateChange(playbackRate);
      }
    });
  }

  onPlayerVolumeChange(volume, muted) {
    // console.log('OAPPlayer.onPlayerVolumeChange')
    const newState = { muted };
    if (volume == 0) {
      newState.muted = true;
    } else {
      // @ts-ignore TS2339
      newState.volume = volume;
    }
    this.setState(newState, () => {
      // @ts-ignore TS2339
      if (this.props.onPlayerVolumeChange) {
        // @ts-ignore TS2339
        this.props.onPlayerVolumeChange(this.state.volume, this.state.muted);
      }
    });
  }

  onPlayerSuspend() {
    // console.log('OAPPlayer.onPlayerSuspend')
    this.setState({ emptied: true, canplaythrough: false }, () => {
      // @ts-ignore TS2339
      if (this.props.onSuspend) {
        // @ts-ignore TS2339
        this.props.onSuspend();
      }
    });
  }

  onPlayerRebuffering() {
    // console.log('OAPPlayer.onPlayerRebuffering')
    this.setState({ rebuffering: true, canplaythrough: false });
  }

  onPlayerFirstplay() {
    // console.log('OAPPlayer.onPlayerFirstplay')
    // @ts-ignore TS2339
    if (!this.state.loading) {
      this.setState({ playStartCompleted: true });
      // @ts-ignore TS2339
      if (this.props.onPlayerFirstplay) {
        // @ts-ignore TS2339
        this.props.onPlayerFirstplay();
      }
    }
  }

  onPlayerTimeUpdate(currentTime) {
    // console.log('OAPPlayer.onPlayerTimeUpdate')
    // @ts-ignore TS2339
    this.__currentTime = currentTime;
    // @ts-ignore TS2339
    if (this.props.onPlayerTimeUpdate) {
      // @ts-ignore TS2339
      this.props.onPlayerTimeUpdate(currentTime);
    }
  }

  onPlayerError(error) {
    // console.log('OAPPlayer.onPlayerError')
    // @ts-ignore TS2339
    if (!this._isMounted) return;
    this.setState({ oap: null });
    // @ts-ignore TS2339
    if (this.props.onPlayerError) {
      // @ts-ignore TS2339
      this.props.onPlayerError(error);
    }
  }

  // OAPPlayerControls

  onClickPlay() {
    let isPlay = true;
    // @ts-ignore TS2339
    if (this.state.paused) {
      // @ts-ignore TS2339
      this._player.play();
      // @ts-ignore TS2339
    } else if (typeof this._player.paused === 'function' && this._player.paused()) {
      // @ts-ignore TS2339
      this._player.play();
    } else {
      // @ts-ignore TS2339
      this._player.pause();
      isPlay = false;
    }
    // @ts-ignore TS2339
    if (this.props.onClickPlay) {
      // @ts-ignore TS2339
      this.props.onClickPlay(isPlay);
    }
  }

  onClickVolume(e) {
    e.preventDefault();
    e.stopPropagation();

    // @ts-ignore TS2339
    if (this.state.muted) {
      this.setState({ muted: false }, () => {
        // @ts-ignore TS2339
        this._player.muted(false);
      });
    } else {
      this.setState({ muted: true }, () => {
        // @ts-ignore TS2339
        this._player.muted(true);
      });
    }
  }

  onMouseMove() {
    return;
    // @ts-ignore TS2339
    if (this.activeTimeoutId) {
      // @ts-ignore TS2339
      clearTimeout(this.activeTimeoutId);
      // @ts-ignore TS2339
      delete this.activeTimeoutId;
    }
    this.setState({ uiState: 'active' });
    // @ts-ignore TS2339
    if (!this.__keepActive) {
      // @ts-ignore TS2339
      this.activeTimeoutId = setTimeout(() => {
        this.setState({ uiState: 'inactive' });
      }, 3000);
    }
  }

  handleKeyboardFocus(keyboardFocus) {
    // @ts-ignore TS2339
    this.keyboardFocus = keyboardFocus;
  }

  render() {
    // @ts-ignore TS2339
    if (!this.state.oap) return null;

    const isActiveControls =
      // @ts-ignore TS2339
      (this.state.uiState === 'active' && this.state.playStartCompleted) ||
      // @ts-ignore TS2339
      (this.state.uiState === 'active' && this.state.paused);

    let playToggleButton;
    // @ts-ignore TS2339
    if (this.state.playStartCompleted) {
      // @ts-ignore TS2339
      if (this.state.seeking) {
        // @ts-ignore TS2339
        playToggleButton = this.state.paused ? 'pause' : 'play';
        // @ts-ignore TS2339
      } else if (this.state.ended) {
        playToggleButton = 'ended';
        // @ts-ignore TS2339
      } else if (this.state.paused) {
        playToggleButton = 'pause';
      } else {
        playToggleButton = 'play';
      }
    } else {
      playToggleButton = 'pause';
    }

    return (
      <div className="oap-player-wrapper">
        <div className="oap-player loading">
          <OAPPlayerCore
            // @ts-ignore TS2322
            autoplay={this.props.autoplay}
            // @ts-ignore TS2339
            muted={this.state.muted}
            // @ts-ignore TS2339
            ovpId={this.state.oap.ovp_id || this.state.oap.ovpId}
            // @ts-ignore TS2339
            ovpVideoId={this.state.oap.ovp_video_id || this.state.oap.ovpVideoId}
            onReady={this.onPlayerReady}
            onLoaded={this.onPlayerLoaded}
            onLoadStart={this.onPlayerLoadstart}
            onPause={this.onPlayerPause}
            onPlay={this.onPlayerPlay}
            onPlaying={this.onPlayerPlaying}
            onEnded={this.onPlayerEnded}
            onDurationChange={this.onPlayerDurationChange}
            onPlaybackRateChange={this.onPlayerPlaybackRateChange}
            onVolumeChange={this.onPlayerVolumeChange}
            onSuspend={this.onPlayerSuspend}
            onRebuffering={this.onPlayerRebuffering}
            onFirstplay={this.onPlayerFirstplay}
            onTimeUpdate={this.onPlayerTimeUpdate}
            onError={this.onPlayerError}
          />
          {/*
           // @ts-ignore TS2339 */}
          {this.props.useControls && this.state.firstplaying && (
            <OAPPlayerControls
              // @ts-ignore TS2339
              uiState={this.state.uiState}
              // @ts-ignore TS2339
              player={this._player}
              // @ts-ignore TS2339
              paused={this.state.paused}
              // @ts-ignore TS2322
              muteOnly={this.props.muteOnly}
              // @ts-ignore TS2339
              muted={this.state.muted}
              // @ts-ignore TS2339
              volume={this.state.volume}
              playToggleButton={playToggleButton}
              onClickPlay={this.onClickPlay}
              onClickVolume={this.onClickVolume}
              onMouseMove={this.onMouseMove}
              isActive={isActiveControls}
              onKeyboardFocus={this.handleKeyboardFocus}
            />
          )}
        </div>
      </div>
    );
  }
}

export default OAPPlayer;
