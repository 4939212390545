import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SimpleNotification from './SimpleNotification';
import NetworkNotification from './NetworkNotification';

class SystemNotifications extends Component {
  static get contextTypes() {
    return {
      shiftFlashMessage: PropTypes.func,
    };
  }

  constructor(props, context) {
    super(props, context);

    // @ts-ignore TS2339
    this.flashMessages = context.shiftFlashMessage();

    this.state = {
      activeNetwork: true,
      ratingNotification: [],
      flashMessage: null,
    };

    this.handleShowFlashMessage = this.handleShowFlashMessage.bind(this);
    this.handleNetworkStatusChange = this.handleNetworkStatusChange.bind(this);
  }

  handleShowFlashMessage(flashMessage) {
    if (flashMessage) {
      this.setState({ flashMessage: flashMessage });
      // @ts-ignore TS2339
      this.flashMessageTimerId = setTimeout(() => {
        // @ts-ignore TS2339
        this.handleShowFlashMessage(this.flashMessages.shift());
      }, 3000);
    } else {
      this.setState({ flashMessage: null });
    }
  }

  handleNetworkStatusChange() {
    setTimeout(
      () => {
        // @ts-ignore TS2339
        if (this._isMounted) {
          this.setState({ activeNetwork: navigator.onLine });
        }
      },
      window.navigator.onLine ? 1 : 100,
    );
  }

  componentDidMount() {
    // @ts-ignore TS2339
    this._isMounted = true;
    // @ts-ignore TS2339
    if (window && this.flashMessages) {
      // @ts-ignore TS2339
      this.handleShowFlashMessage(this.flashMessages.shift());
    }
    if (navigator && navigator.onLine) {
      window.addEventListener('online', this.handleNetworkStatusChange);
      window.addEventListener('offline', this.handleNetworkStatusChange);
    }
  }

  componentWillUntmount() {
    // @ts-ignore TS2339
    this._isMounted = false;
    window.removeEventListener('online', this.handleNetworkStatusChange);
    window.removeEventListener('offline', this.handleNetworkStatusChange);
    // @ts-ignore TS2339
    if (this.flashMessageTimerId) {
      // @ts-ignore TS2339
      clearTimeout(this.flashMessageTimerId);
      // @ts-ignore TS2339
      delete this.flashMessageTimerId;
    }
  }

  render() {
    // @ts-ignore TS2339
    if (this.state.flashMessage) {
      // @ts-ignore TS2339
      return <SimpleNotification {...this.state.flashMessage} />;
    }
    // @ts-ignore TS2339
    if (this.state.activeNetwork) {
      return null;
    } else {
      return <NetworkNotification />;
    }
  }
}

export default SystemNotifications;
