import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Link } from '../../../sketch-platform/ui/routing';
import { parseUrl } from '../../../sketch-platform/ui/routing';
import _ from 'src/domain/libs/util';
import { Route } from 'src/types/app/Route';

type MainViewLinkProps = {
  onClick?: (e: React.MouseEvent) => void;
  onFocus?: (e: React.MouseEvent) => void;
  onMouseEnter?: (e: React.MouseEvent) => void;
  onBlur?: (e: React.MouseEvent) => void;
  resolver?: (args1: any, args2: any) => any;
  to?: Route;
  role?: string;
  ariaLabel?: string;
  className?: string;
  params?: { [key: string]: any };
  query?: { [key: string]: any };
  href?: string;
  name?: string;
  tabIndex?: number;
  id?: string;
  target?: '_blank';
};
class MainViewLink extends PureComponent<MainViewLinkProps> {
  static get contextTypes() {
    return {
      isKidsPage: PropTypes.bool,
      models: PropTypes.object.isRequired,
      routeHandler: PropTypes.object.isRequired,
      routeHandlers: PropTypes.array,
      getModelData: PropTypes.func,
    };
  }

  static replaceUrl = function(href, host) {
    let url = _.replace(href, 'wod:/', '').replace(host, '');
    return url;
  };

  constructor(props: MainViewLinkProps, context) {
    super(props, context);

    // @ts-ignore TS2339
    this.preventClick = false;
  }

  handleClick(e) {
    // @ts-ignore TS2339
    if (this.preventClick) {
      e.preventDefault();
      e.stopPropagation();
    }
    // @ts-ignore TS2339
    this.preventClick = true;
    setTimeout(() => {
      // @ts-ignore TS2339
      this.preventClick = false;
    }, 483);

    // @ts-ignore TS2339
    if (typeof this.props.onClick === 'function') {
      // @ts-ignore TS2339
      this.props.onClick(e, this.props);
    }
  }

  render() {
    let to;
    // @ts-ignore TS2339
    if (this.props.resolver) {
      // @ts-ignore TS2339
      to = this.props.resolver(this.context.models, this.context.isKidsPage);
    } else {
      // @ts-ignore TS2339
      to = this.props.to;
    }

    try {
      const routeHandlers = this.context.routeHandlers;
      let href;
      let routesMatchFlag;
      // @ts-ignore TS2339
      let target = this.props.target;

      // routeオブジェクトが渡された場合
      if (to) {
        let params = this.props.params || {};
        let query = this.props.query;
        // @ts-ignore TS2339
        let hash = this.props.hash;
        href = to.makePath(params, query, hash);
        routesMatchFlag =
          routeHandlers &&
          _.find(routeHandlers, function(routeHandler) {
            return to._regex === routeHandler.route._regex;
          });

        // hrefが渡された場合
        // @ts-ignore TS2339
      } else if (this.props.href) {
        // @ts-ignore TS2339
        href = this.props.href;
        const host = _.get(this.context.getModelData('hosts'), 'host');
        // 削る
        href = MainViewLink.replaceUrl(href, host);
        // 削った上でhttpから始まる場合は外部リンク
        // @ts-ignore TS2339
        if (this.props.autoTargetBlank && _.startsWith(href, 'http')) {
          target = '_blank';
        }
        const { path } = parseUrl(href);
        routesMatchFlag =
          routeHandlers &&
          _.find(routeHandlers, function(routeHandler) {
            return !!routeHandler.route.match(path);
          });
      }

      // SPAでの遷移
      if (routesMatchFlag) {
        return React.createElement(
          Link,
          _.assignIn(
            _.omit(this.props, [
              'resolver',
              'to',
              'params',
              'query',
              'startTime',
              'episode',
              'responsiveButton',
              'replaceHistory',
              'model',
              'notPrimaryButton',
              'flatButton',
              'videoId',
              'episodeId',
              'ariaLabel',
              'autoTargetBlank',
            ]),
            { href, onClick: this.handleClick.bind(this) },
          ),
          this.props.children,
        );

        // 通常のaタグ
      } else if (href) {
        return React.createElement(
          'a',
          _.extend(_.omit(this.props, ['resolver', 'to', 'params', 'query', 'onAfterNavigation', 'autoTargetBlank']), {
            href,
            target,
          }),
          this.props.children,
        );
      }
    } catch (e) {
      const URL = this.context.routeHandler ? this.context.routeHandler.path : '';
      // @ts-ignore TS2339
      e.message += ' with class ' + (this.props.className || '') + '. Current URL: ' + URL;
      throw e;
    }
    return React.createElement(
      'span',
      _.omit(this.props, ['resolver', 'to', 'params', 'query', 'onAfterNavigation']),
      this.props.children,
    );
  }
}

export default MainViewLink;
