import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'src/domain/libs/util';

import Palette from './Palette';
// import Episodes from './titlemeta/Episodes';
import Details from './titlemeta/Details';
import EpisodeSeasonContent from './EpisodeSeasonContent';

import canAccessBySlug from '../../utils/slug';
// import CardContext, { CardContextProvider } from '../../../common/context/CardContext';

class TitleWatchContents extends Component {
  static getPaths = function(models, options, props) {
    let paths = [];
    const model = props.pathEvaluator || props.model.pathEvaluator;
    if (props.id && model) {
      paths = paths.concat(Details.getPaths(models, options, { id: props.id }));
    }
    paths = paths.concat([['meta', props.id, ['schemaId', 'type', 'hierarchyTypes', 'recommend', 'titleMetaId']]]);
    if (props.relatedMetaId) {
      paths = paths.concat([['meta', props.relatedMetaId, ['relatedKey']]]);
    }
    return paths;
  };

  static getRootPath = function(models, options, props) {
    if (props.id) {
      return ['meta', props.id];
    }
    return [];
  };

  static getPrefetchPaths = function(models, options, props) {
    return EpisodeSeasonContent.getPaths(models, options, props);
  };

  static get propTypes() {
    return {
      history: PropTypes.object,
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      isPrefetchingPaths: PropTypes.bool,
    };
  }

  static get contextTypes() {
    return {
      models: PropTypes.object,
      history: PropTypes.object,
      routeHandler: PropTypes.object,
      getModelData: PropTypes.func,
    };
  }

  constructor(props, context) {
    super(props, context);

    // @ts-ignore TS2339
    const rootPath = this.constructor.getRootPath(context.models, null, props);
    // @ts-ignore TS2339
    this.item = this.props.model.getSync(rootPath) || {};
    if (props.relatedMetaId) {
      // @ts-ignore TS2339
      this.relatedKey = props.model.getSync(['meta', props.relatedMetaId, ['relatedKey']]);
    }
    this.state = {
      dispose: null,
      // @ts-ignore TS2339
      generation: this.props.model.getVersion(rootPath),
      fetchDataError: null,
    };
  }

  componentDidMount() {
    // @ts-ignore TS2339
    this._isMounted = true;
    this.fetchData(this.props);
  }

  componentWillUnmount() {
    // @ts-ignore TS2339
    this._isMounted = false;
    // @ts-ignore TS2339
    if (this.state.dispose) this.state.dispose();
  }

  fetchData(props) {
    // @ts-ignore TS2339
    if (!this.props.id) return;

    let pathProps = _.clone(props);
    // @ts-ignore TS2339
    if (this.item && canAccessBySlug(this.item)) {
      // slugでアクセスされた場合はprops.idにslugが入るので、取得したIDで上書き
      // @ts-ignore TS2339
      pathProps.id = this.item.id;
    }
    // @ts-ignore TS2339
    if (this.item) {
      // @ts-ignore TS2339
      pathProps.schemaId = this.item.schemaId;
    }
    // @ts-ignore TS2339
    const paths = this.constructor.getPaths(this.context.models, {}, pathProps);

    // すでに通信している場合は実行しない
    if (this.state[JSON.stringify(paths)]) return;

    // @ts-ignore TS2339
    if (this.state.dispose) {
      // 過去のObservableを削除する、これによって通信が止まるわけではなく
      // Observableがなくなるのでイベントが発火されなくなる、というだけなので注意
      // @ts-ignore TS2339
      this.state.dispose();
    }

    this.state[JSON.stringify(paths)] = paths;

    // @ts-ignore TS2339
    const evaluator = this.props.model.fetch(paths);
    const dispose = evaluator.dispose;
    // @ts-ignore TS2339
    if (this._isUpdated === false || !this._isMounted) {
      Object.assign(this.state, { dispose });
    } else {
      this.setState({ dispose });
    }
    // @ts-ignore TS2339
    const rootPath = this.constructor.getRootPath(this.context.models, {}, pathProps);
    evaluator
      .then(res => {
        // @ts-ignore TS2339
        this.item = _.get(res, ['json'].concat(rootPath), {});
        if (props.relatedMetaId) {
          // @ts-ignore TS2339
          this.relatedKey = _.get(res, ['json'].concat(['meta', props.relatedMetaId, 'relatedKey']));
        }

        delete this.state[JSON.stringify(paths)];

        const newState = {
          fetchDataError: null,
          dispose: null,
          // @ts-ignore TS2339
          generation: this.props.model.getVersion(rootPath),
        };

        // @ts-ignore TS2339
        if (this._isMounted) this.setState(newState);
        else Object.assign(this.state, newState);
      })
      .catch(e => {
        const newState = {
          fetchDataError: e,
          fetchingMoreRows: undefined,
          dispose: null,
        };
        delete this.state[JSON.stringify(paths)];
        // @ts-ignore TS2339
        if (this._isMounted) this.setState(newState);
        else Object.assign(this.state, newState);
      });
  }

  render() {
    // @ts-ignore TS2339
    if (this.props.id && Object.keys(this.item).length === 0) return null;

    // const schemaContextValue = {
    //   pageSchemaId: this.item.schemaId,
    // };

    // slugでアクセスすることが可能な場合はprops.idにslugが入る可能性があるので、取得したIDで上書き
    // @ts-ignore TS2339
    const props = canAccessBySlug(this.item) ? Object.assign({}, this.props, { id: this.item.id }) : this.props;
    // let episodes = null;
    // if (this.item.type === 'group') {
    //   const hierarchyTypes = (this.item.hierarchyTypes && this.item.hierarchyTypes.length > 0) ? this.item.hierarchyTypes : [ {id: null} ];
    //   episodes = (
    //     <div className="episodes-container">
    //       {hierarchyTypes.map((hierarchyType, index) => {
    //         hierarchyType = {
    //           index,
    //           ...hierarchyType
    //         };
    //         return (
    //           <CardContextProvider value={schemaContextValue} key={`episodes_${props.id}_${hierarchyType.id}`}>
    //             <Episodes
    //               id={props.id}
    //               selectedEpisodeId={_.get(this.props, 'metadata.id')}
    //               model={this.props.model}
    //               hierarchyType={hierarchyType}
    //               onChangeEpisode={this.props.onChangeEpisode} />
    //           </CardContextProvider>
    //         );
    //       })}
    //     </div>
    //   );
    // }

    let related = null;
    const browserInfo = this.context.getModelData('browserInfo');
    // @ts-ignore TS2339
    if (this.relatedKey) {
      const paletteProps = {
        isGrid: false,
        useRowHeader: true,
        titleSmallSize: false,
        hideAllWhenNoResults: false,
      };
      if (browserInfo.isIOS || browserInfo.isAndroid) {
        paletteProps.isGrid = true;
        paletteProps.useRowHeader = false;
        paletteProps.titleSmallSize = true;
        paletteProps.hideAllWhenNoResults = true;
      }

      related = (
        <Palette
          // @ts-ignore TS2339
          key={`related-${this.relatedKey}`}
          listContext={'editorial'}
          // @ts-ignore TS2339
          paletteId={this.relatedKey}
          // @ts-ignore TS2322
          model={this.props.model}
          // @ts-ignore TS2339
          ignoreIds={this.props.ignoreIds}
          {...paletteProps}
        />
      );
    }

    let recommend = null;
    let details = null;
    const title = 'この作品をご覧になったあなたへ';
    // watch経由
    // @ts-ignore TS2339
    if (this.props.metadata) {
      // @ts-ignore TS2339
      if (this.props.metadata.recommend) {
        recommend = (
          <Palette
            // @ts-ignore TS2339
            key={`recommend-${this.props.metadata.recommend}`}
            listContext={'editorial'}
            // @ts-ignore TS2339
            paletteId={this.props.metadata.recommend}
            // @ts-ignore TS2322
            model={this.props.model}
            title={title}
            isGrid={true}
            useRowHeader={false}
            titleSmallSize={true}
            hideAllWhenNoResults={true}
            shouldSkipGtm={true}
          />
        );
        // @ts-ignore TS2339
      } else if (this.item.recommend) {
        recommend = (
          <Palette
            // @ts-ignore TS2339
            key={`recommend-${this.item.recommend}`}
            listContext={'editorial'}
            // @ts-ignore TS2339
            paletteId={this.item.recommend}
            // @ts-ignore TS2322
            model={this.props.model}
            title={title}
            isGrid={true}
            useRowHeader={false}
            titleSmallSize={true}
            hideAllWhenNoResults={true}
            shouldSkipGtm={true}
          />
        );
      }
    } else {
      // @ts-ignore TS2339
      if (this.item.recommend) {
        recommend = (
          <Palette
            // @ts-ignore TS2339
            key={`recommend-${this.item.recommend}`}
            listContext={'editorial'}
            // @ts-ignore TS2339
            paletteId={this.item.recommend}
            // @ts-ignore TS2322
            model={this.props.model}
            title={title}
            isGrid={true}
            useRowHeader={false}
            titleSmallSize={true}
            hideAllWhenNoResults={true}
            shouldSkipGtm={true}
          />
        );
      }
    }

    return (
      <React.Fragment>
        {/*
         // @ts-ignore TS2322 */}
        <EpisodeSeasonContent {...this.props} id={props.id} />
        {related}
        {recommend}
      </React.Fragment>
    );
  }
}

export default TitleWatchContents;
