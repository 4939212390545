'use strict';
import _ from 'src/domain/libs/util';

export default function(models) {
  return _.get(
    models,
    'memberContext.data.profileData.active.isAccountOwner',
    _.get(models, 'models.memberContext.data.profileData.active.isAccountOwner'),
  );
}
