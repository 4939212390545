import React, { useCallback } from 'react';
import classnames from 'classnames';

type ToggleProps = {
  onClick?: VoidFunction;
  open?: boolean;
};

const Toggle = (props: ToggleProps) => {
  const onClick = useCallback(
    e => {
      if (props.onClick) props.onClick();
    },
    [props],
  );

  return (
    <button
      onClick={onClick}
      type="button"
      className={classnames('sidemenu-toggle sidemenu-hamburger', { open: 'sidemenu-open' })}
    >
      <span className="sr-only">toggle navigation</span>
      <span className="sidemenu-hamburger-icon"></span>
    </button>
  );
};

export default Toggle;
