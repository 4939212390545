import React, { useMemo } from 'react';

import * as ERROR from '../../../../../../constants/error';
import WatchInfo from '../WatchInfo';
import PlanInfoContent from './PlanInfoContent';
import HowtoPlayProvider from '../../functional/HowtoPlayProvider';
import EventInfo from '../../../browse/Epg/EventInfo';

const PlanInfo = props => {
  const showWatchInfo = useMemo(() => props.meta.type !== 'linear_channel', [props.meta.type]);

  // 無駄にhowto_playを叩かないための対策
  return props.error && [ERROR.UNAUTHORIZED, ERROR.LICENSE_INVALID].includes(props.error.type) ? (
    <HowtoPlayProvider model={props.model} meta={props.meta}>
      <PlanInfoContent {...props} showWatchInfo={showWatchInfo} />
    </HowtoPlayProvider>
  ) : showWatchInfo ? (
    <WatchInfo {...props} scheduleButton={null} planInfoRenderCheck={false} />
  ) : (
    <EventInfo {...props} myListButton={null} planInfoRenderCheck={false} />
  );
};

export default React.memo(PlanInfo);
