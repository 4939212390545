import React from 'react';

const BillboardCardLoading = () => {
  return (
    <div className="billboard-slider-row loading">
      <div className="row-content">
        <div className="billboard-slider">
          <div className="billboard-card">
            <div className="billboard-card-box">
              <div className="pulsate title"></div>
              <div className="pulsate description"></div>
              <div className="pulsate description"></div>
            </div>
            <div className="billboard-card-link">
              <div className="pulsate billboard-link"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(BillboardCardLoading);
