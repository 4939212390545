import { EventEmitter } from 'fbemitter';
import _ from 'src/domain/libs/util';
import fsApi from './fullscreen-api';

class FullScreenApp {
  static enbableFullScreenApi() {
    // @ts-ignore TS2339
    return fsApi.fullscreenEnabled;
  }

  constructor(options = {}) {
    // @ts-ignore TS2339
    this._options = options;
    // @ts-ignore TS2339
    this._emitter = new EventEmitter();
    // @ts-ignore TS2339
    this._listeners = [];

    this.init = this.init.bind(this);
    this.on = this.on.bind(this);
    this.off = this.off.bind(this);
    this.addEmitterListener = this.addEmitterListener.bind(this);
    this.removeEmitterListener = this.removeEmitterListener.bind(this);
    this.getIsFullScreen = this.getIsFullScreen.bind(this);
    this.onFullScreenChange = this.onFullScreenChange.bind(this);
    this.onEnterFullScreen = this.onEnterFullScreen.bind(this);
    this.onExitFullScreen = this.onExitFullScreen.bind(this);
    this.onFullScreenError = this.onFullScreenError.bind(this);
    this.toggleFullScreen = this.toggleFullScreen.bind(this);
    this.enterFullScreen = this.enterFullScreen.bind(this);
    this.exitFullScreen = this.exitFullScreen.bind(this);
    // @ts-ignore TS2551
    this._getFullScreenNode = function() {
      if (document) return document.getElementById('appMountPoint');
    };
  }

  set getFullScreenNode(getFullScreenNode) {
    if (typeof getFullScreenNode === 'function') {
      // @ts-ignore TS2551
      this._getFullScreenNode = getFullScreenNode;
    }
  }

  get getFullScreenNode() {
    // @ts-ignore TS2551
    return this._getFullScreenNode;
  }

  init() {
    if (document == null) return;
    // @ts-ignore TS2339
    document.addEventListener(fsApi.fullscreenchange, this.onFullScreenChange);
    // @ts-ignore TS2339
    document.addEventListener(fsApi.fullscreenerror, this.onFullScreenError);
  }

  reset() {
    if (document == null) return;
    // @ts-ignore TS2339
    document.removeEventListener(fsApi.fullscreenchange, this.onFullScreenChange);
    // @ts-ignore TS2339
    document.removeEventListener(fsApi.fullscreenerror, this.onFullScreenError);
  }

  on(eventName, fn) {
    this.addEmitterListener(eventName, fn);
  }

  off(eventName, fn) {
    this.removeEmitterListener(eventName, fn);
  }

  addEmitterListener(eventName, fn) {
    // @ts-ignore TS2339
    this._listeners.push({
      // @ts-ignore TS2339
      subscription: this._emitter.addListener(eventName, fn),
      eventName: eventName,
      fn: fn,
    });
  }

  removeEmitterListener(eventName, fn) {
    // @ts-ignore TS2339
    const index = _.findIndex(this._listeners, listener => {
      if (fn) {
        // @ts-ignore TS2339
        return listener.eventName == eventName && listener.fn == fn;
      } else {
        // @ts-ignore TS2339
        return listener.eventName == eventName;
      }
    });
    if (index !== -1) {
      // @ts-ignore TS2339
      this._listeners[index].subscription.remove();
      // @ts-ignore TS2339
      this._listeners.splice(index, 1);
    }
  }

  getIsFullScreen() {
    // @ts-ignore TS2339
    return !!document && !!document[fsApi.fullscreenElement];
  }

  onFullScreenChange(e) {
    const isFullScreen = this.getIsFullScreen();
    // @ts-ignore TS2339
    this._emitter.emit('onFullScreenChange', isFullScreen);
    if (isFullScreen) {
      this.onEnterFullScreen();
    } else {
      this.onExitFullScreen();
    }
  }

  onEnterFullScreen() {
    const target = this.getFullScreenNode();
    target.classList.add('full-screen');
  }

  onExitFullScreen() {
    const target = this.getFullScreenNode();
    target.classList.remove('full-screen');
  }

  onFullScreenError(e) {
    // @ts-ignore TS2339
    this._emitter.emit('onFullScreenError', e);
    console.error('fullscreen error occuered', e);
  }

  toggleFullScreen() {
    const isFullScreen = this.getIsFullScreen();
    if (isFullScreen) {
      this.exitFullScreen();
    } else {
      this.enterFullScreen();
    }
  }

  enterFullScreen() {
    const target = this.getFullScreenNode();
    // @ts-ignore TS2339
    if (fsApi.requestFullscreen && !document[fsApi.fullscreenElement]) {
      // @ts-ignore TS2339
      this._emitter.emit('beforeFullScreenChange', true);
      setTimeout(() => {
        try {
          // @ts-ignore TS2339
          target[fsApi.requestFullscreen]();
        } catch (e) {}
      }, 10);
    }
  }

  exitFullScreen() {
    const target = this.getFullScreenNode();
    // @ts-ignore TS2339
    if (document[fsApi.fullscreenElement] && document[fsApi.fullscreenElement] === target) {
      // @ts-ignore TS2339
      this._emitter.emit('beforeFullScreenChange', false);
      setTimeout(() => {
        try {
          // @ts-ignore TS2339
          document[fsApi.exitFullscreen]();
        } catch (e) {}
      }, 10);
    }
  }
}

export default FullScreenApp;
