import React from 'react';

class WodIframe extends React.PureComponent {
  constructor(props, context) {
    super(props, context);
    this.receiveMessage = this.receiveMessage.bind(this);
    // @ts-ignore TS2551
    this.ref = React.createRef();
  }

  componentDidMount() {
    // @ts-ignore TS2339
    this._isMounted = true;
    window.addEventListener('message', this.receiveMessage);
  }

  componentWillUnmount() {
    // @ts-ignore TS2339
    this._isMounted = false;
    window.removeEventListener('message', this.receiveMessage);
  }

  receiveMessage(e) {
    if (e.data.type === 'displaySize') {
      // @ts-ignore TS2551
      const el = this.ref.current || {};
      const message = {
        type: 'displaySize',
        height: el.clientHeight || 0,
        width: el.clientWidth || 0,
      };
      // 高さと幅を子ページに返信
      e.source.postMessage(message, e.origin);
    }
  }

  render() {
    // @ts-ignore TS2339
    if (!this.props.src) return null;
    // @ts-ignore TS2339
    return <iframe height="100%" width="100%" src={this.props.src} ref={this.ref} />;
  }
}

export default WodIframe;
