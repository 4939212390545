import React, { useCallback } from 'react';
import _ from 'src/domain/libs/util';

const StartOverModal = (props, context) => {
  const onClickFromStart = useCallback(() => {
    if (props.onClickFromStart) props.onClickFromStart();
  }, [props]);
  const onClickFromLive = useCallback(() => {
    if (props.onClickFromLive) props.onClickFromLive();
  }, [props]);

  return (
    <React.Fragment>
      <p className="error-view-title">
        この動画は追っかけ再生に対応しています。
        <br />
        どこから再生しますか？
      </p>
      <a onClick={onClickFromStart} className="btn btn-border">
        <i className="fa-undo-alt"></i>
        {props.textFromStart}
      </a>
      <a onClick={onClickFromLive} className="btn btn-fill">
        <i className="fa-play-circle"></i>
        {props.textFromLive}
      </a>
    </React.Fragment>
  );
};

StartOverModal.defaultProps = {
  textFromStart: '最初から再生',
  textFromLive: 'ライブを再生',
};

export default StartOverModal;
