export const getPurchaseLabel = function(product = {}, meta = {}) {
  // ボタンは一律'購入'
  return '購入';
  // 再度用件変えられると面倒なので残しておく
  // ボタンラベル優先
  // @ts-ignore TS2339
  if (product.buttonLabel) return product.buttonLabel;

  // ライブかつレンタルの場合は購入
  // @ts-ignore TS2339
  if (meta.schemaId == 4 && product.productType == 'product_type_rental') return '購入';

  // @ts-ignore TS2339
  return product.displayProductType;
};
