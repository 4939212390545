import React from 'react';
import PropTypes from 'prop-types';
import LoadingTitle from './LoadingTitle';

const LoadingSlider = (props, context) => {
  let titles = [];
  let itemsInRow;
  let columnsInRow;

  if (props.itemsInRow == 2 || context.columnsInRow == 2) {
    itemsInRow = 3;
    columnsInRow = 3;
  } else {
    itemsInRow = props.itemsInRow;
    columnsInRow = context.columnsInRow;
  }
  for (let i = 0; i < (itemsInRow || columnsInRow || 7); i++) {
    titles.push(
      <LoadingTitle
        pulsate={props.pulsate}
        displayWhenNotPulsing={typeof props.displayWhenNotPulsing === 'undefined' ? true : props.displayWhenNotPulsing}
        lockupClassName={props.lockupClassName}
        key={`loading-row-${i}`}
      />,
    );
  }
  return (
    <div className="slider">
      <div className="sliderMask loading">{titles}</div>
    </div>
  );
};

LoadingSlider.propTypes = {
  pulsate: PropTypes.bool,
};

LoadingSlider.contextTypes = {
  columnsInRow: PropTypes.number,
};

export default LoadingSlider;
