import React, { useCallback, useMemo } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import queryString from 'query-string';

import _ from 'src/domain/libs/util';
import { CLICK_AREA } from 'src/common/GtmApp';
import { RETURN_ROUTES } from '../LoginButton';
import { CAMPAIGN_FLAG } from 'src/constants/flag';

const SIGNUP_PATH = '/static/join/';

type SignupButtonProps = {
  className?: string;
};

const SignupButton = (props: SignupButtonProps, context) => {
  const joinBtnText = useMemo(() => (CAMPAIGN_FLAG ? '割引キャンペーン中' : 'ご加入はこちら'), []);
  const onClick = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();
      const query = {};
      const pathname = _.get(window, 'location.pathname', '');
      // watch・titleページの場合はログインのリンクにreturn_toをつけて遷移させる
      if (RETURN_ROUTES.some(route => route.match(pathname))) {
        query['return_to'] = pathname;
      }
      context.gtmApp.pushDataLayerOnSubscriptionClick(CLICK_AREA.SIGNUP.HEADER);

      const queryStr = _.isEmpty(query) ? '' : `?${queryString.stringify(query)}`;
      window.location.href = SIGNUP_PATH + queryStr;
      return false;
    },
    [context],
  );

  return (
    <a
      href={SIGNUP_PATH}
      className={classnames(
        'btn btn-fill btn-very-small',
        {
          'accent-color': !CAMPAIGN_FLAG,
          'campaign-color': CAMPAIGN_FLAG,
        },
        props.className,
      )}
      onClick={onClick}
    >
      {joinBtnText}
    </a>
  );
};

SignupButton.contextTypes = {
  gtmApp: PropTypes.object,
};

export default SignupButton;
