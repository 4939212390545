import React, { useCallback } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import MainViewLink from 'src/apps/common/components/MainViewLink';
import routes from 'src/apps/common/routes';
import { MENU_CATEGORY } from 'src/common/GtmApp';

type MyListButtonProps = {
  className?: string;
};

const MyListButton = (props: MyListButtonProps, context) => {
  const onClick = useCallback(
    (e: React.MouseEvent) => {
      context.gtmApp.pushDataLayerOnMenuClick(MENU_CATEGORY.MYLIST);
    },
    [context],
  );

  return (
    <MainViewLink
      className={classnames('btn btn-fill grey-color btn-very-small', props.className)}
      to={routes.mylist}
      onClick={onClick}
    >
      マイリスト
    </MainViewLink>
  );
};

MyListButton.contextTypes = {
  gtmApp: PropTypes.object,
};

export default MyListButton;
