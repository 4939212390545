import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import VerticalVolumeScrubberBar from './VerticalVolumeScrubberBar';

class VolumeButton extends React.PureComponent {
  static get propTypes() {
    return {
      player: PropTypes.object.isRequired,
      startHover: PropTypes.func,
      stopHover: PropTypes.func,
      muted: PropTypes.bool,
      volume: PropTypes.number,
      step: PropTypes.number,
    };
  }

  static get contextTypes() {
    return {
      getModelData: PropTypes.func,
    };
  }

  constructor(props, context) {
    super(props, context);
    this.onClick = this.onClick.bind(this);
    this.onMouseEnter = this.onMouseEnter.bind(this);
    this.onMouseLeave = this.onMouseLeave.bind(this);
    this.onMouseEnterVolume = this.onMouseEnterVolume.bind(this);
    this.onMouseLeaveVolume = this.onMouseLeaveVolume.bind(this);
    this.onFocus = this.onFocus.bind(this);
    this.showScrubberBar = this.showScrubberBar.bind(this);
    this.hideScrubberBar = this.hideScrubberBar.bind(this);

    this.state = { scrubberBar: false };
  }

  componentDidUpdate(prevProps, prevStates, snapshot) {
    // @ts-ignore TS2339
    if (!this.props.isShowContorls) {
      this.hideScrubberBar();
    }
    // @ts-ignore TS2339
    if (this.props.volume != prevProps.volume) {
      this.showScrubberBar();
    }
  }

  componentWillUnmount() {
    this.hideScrubberBar();
  }

  onClick(e) {
    // @ts-ignore TS2339
    if (this.props.onClick) {
      // @ts-ignore TS2339
      this.props.onClick(e);
    }
    // @ts-ignore TS2339
    if (this.props.releaseFocus) {
      // @ts-ignore TS2339
      this.props.releaseFocus(e);
    }
  }

  showScrubberBar() {
    this.setState({ scrubberBar: true });
  }

  hideScrubberBar() {
    this.setState({ scrubberBar: false });
  }

  onMouseEnter(e) {
    this.showScrubberBar();

    // @ts-ignore TS2339
    if (this.props.startHover) {
      // @ts-ignore TS2339
      this.props.startHover('volume');
    }
  }

  onMouseLeave(e) {
    // @ts-ignore TS2339
    if (this.props.stopHover) {
      // @ts-ignore TS2339
      this.props.stopHover('volume');
    }
  }

  onMouseEnterVolume(e) {}

  onMouseLeaveVolume(e) {
    this.hideScrubberBar();
  }

  onFocus(e) {
    // @ts-ignore TS2339
    if (this.props.startHover) {
      // @ts-ignore TS2339
      this.props.startHover('volume');
    }
  }

  render() {
    let icon;
    // @ts-ignore TS2339
    if (this.props.muted) {
      icon = 'fa-volume-mute';
    // @ts-ignore TS2339
    } else if (this.props.volume >= 0.5) {
      icon = 'fa-volume-high';
    } else {
      icon = 'fa-volume-mind';
    }

    const props = {};
    const browserInfo = this.context.getModelData('browserInfo');
    if (!browserInfo.isIOS && !browserInfo.isAndroid) {
      // @ts-ignore TS2339
      props.onMouseEnter = this.onMouseEnter;
      // @ts-ignore TS2339
      props.onMouseLeave = this.onMouseLeave;
      // @ts-ignore TS2339
      props.onFocus = this.onFocus;
      // @ts-ignore TS2339
      props.onBlur = this.onMouseLeave;
    }

    return (
      <div
        // @ts-ignore TS2339
        ref={this.props.forwardedRef}
        // @ts-ignore TS2339
        className={classnames('volume-wapper', { active: this.state.scrubberBar })}
        onMouseEnter={this.onMouseEnterVolume}
        onMouseLeave={this.onMouseLeaveVolume}
      >
        <button
          {...props}
          type="button"
          className={classnames('touchable player-control-element player-button-control fa', icon)}
          // @ts-ignore TS2322
          tabIndex="0"
          role="button"
          aria-label="音量"
          onClick={this.onClick}
        ></button>

        <VerticalVolumeScrubberBar
          // @ts-ignore TS2339
          player={this.props.player}
          // @ts-ignore TS2339
          volume={this.props.muted ? 0 : this.props.volume}
          // @ts-ignore TS2322
          releaseFocus={this.props.releaseFocus}
          // @ts-ignore TS2339
          step={this.props.step}
          // @ts-ignore TS2339
          startHover={this.props.startHover}
          // @ts-ignore TS2339
          stopHover={this.props.stopHover}
          enterVolume={this.showScrubberBar}
          leaveVolume={this.hideScrubberBar}
          // @ts-ignore TS2339
          onMouseMove={this.props.onMouseMove}
        />
      </div>
    );
  }
}

// export default VolumeButton;
export default React.forwardRef(function volumeButton(props, ref) {
  // @ts-ignore TS2322
  return <VolumeButton {...props} forwardedRef={ref} />;
});
