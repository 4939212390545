import _ from 'src/domain/libs/util';
import * as DOMUtils from './DOMUtils';
import getOrientation from '../../apps/common/utils/getOrientation';

let scrollTop = 0;
let scrollDistance = 0;

let timeoutID;
const callbacks = {
  unload: [],
  resize: [],
  orientationchange: [],
  scrollStart: [],
  scroll: [],
  scrollEnd: [],
};

const handleScroll = function(e) {
  if (timeoutID) {
    clearTimeout(timeoutID);
  } else {
    scrollTop = DOMUtils.getScrollTop();
    callbacks.scrollStart.forEach(function(cb) {
      if (typeof cb === 'function') {
        cb(e);
      }
    });
  }

  scrollDistance = DOMUtils.getScrollTop() - scrollTop;
  callbacks.scroll.forEach(function(cb) {
    if (typeof cb === 'function') {
      cb(e);
    }
  });

  timeoutID = setTimeout(function() {
    callbacks.scrollEnd.forEach(function(cb) {
      if (typeof cb === 'function') {
        cb(e);
      }
    }),
      clearTimeout(timeoutID),
      (timeoutID = null);
  }, 150);
};

const handleThrottleScroll = _.throttle(handleScroll, 100);

const handleResize = function(e) {
  callbacks.resize.forEach(function(cb) {
    if (typeof cb === 'function') {
      cb(e);
    }
  });
};
const handleThrottleResize = _.throttle(handleResize, 100);

const getHandleOrientationchange = function() {
  let orientationchangeTimer = null;
  let beforeOrientation = getOrientation();
  const onOrientationchangeFire = e => {
    if (orientationchangeTimer) {
      clearTimeout(orientationchangeTimer);
      orientationchangeTimer = null;
    }
    // ブラウザによってorientationchangeの発火タイミングにばらつきがある。
    // orientationchange発火時に画面の向きが変わっていない場合は再度実行する。
    orientationchangeTimer = setTimeout(() => {
      const currentOrientation = getOrientation();
      if (!beforeOrientation || currentOrientation !== beforeOrientation) {
        beforeOrientation = currentOrientation;
        callbacks.orientationchange.forEach(function(cb) {
          if (typeof cb === 'function') {
            cb(e, currentOrientation);
          }
        });
      } else {
        onOrientationchangeFire(e);
      }
    }, 50);
  };
  return onOrientationchangeFire;
};

const handleOrientationchange = getHandleOrientationchange();

//const handleWheel = function(e) {
//  if (Math.abs(e.deltaX) >= Math.abs(e.deltaY)) {
//    e.preventDefault();
//  }
//};

const handleUnload = function(e) {
  callbacks.unload.forEach(function(cb) {
    if (typeof cb === 'function') {
      cb(e);
    }
  });
};

let initialized = false;
export function addEventListener(eventName, cb) {
  var cbs = callbacks[eventName];
  if (Array.isArray(cbs)) {
    cbs.push(cb);
    if (!initialized) {
      initialized = true;
      // @ts-ignore TS2339
      document.body.unlonad = handleUnload;
      window.addEventListener('scroll', handleThrottleScroll, false);
      window.addEventListener('resize', handleThrottleResize, false);
      window.addEventListener('orientationchange', handleOrientationchange, false);
      //      if (document && document.body) {
      //        document.body.addEventListener("wheel", handleWheel, false);
      //      }
    }
  }
}

export function removeEventListener(eventName, cb) {
  var cbs = callbacks[eventName];
  if (Array.isArray(cbs)) {
    let index = cbs.indexOf(cb);
    if (index !== -1) {
      cbs.splice(index, 1);
    }
    if (
      initialized &&
      callbacks.scroll.length === 0 &&
      callbacks.scrollStart.length === 0 &&
      callbacks.scrollEnd.length === 0 &&
      callbacks.resize.length === 0 &&
      callbacks.orientationchange.length === 0 &&
      callbacks.unload.length === 0
    ) {
      initialized = false;
      var r = document && document.body;
      window.removeEventListener('scroll', handleThrottleScroll, false);
      window.removeEventListener('resize', handleThrottleResize, false);
      window.removeEventListener('orientationchange', handleOrientationchange, false);
      //      if (document && document.body) {
      //        document.body.removeEventListener("wheel", handleWheel, false)
      //      }
    }
  }
}

export function getScrollDistance() {
  return scrollDistance;
}

export function scrollTo(x, y) {
  window && window.scrollTo(x, y);
}

export function scrollDelta(delta) {
  window && window.scrollTo(0, DOMUtils.getScrollTop() + delta);
}
