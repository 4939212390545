export default function getColumnsInRow() {
  if (typeof window === 'undefined') return;

  let columnsInRow = 1;
  if (window.matchMedia('screen and (min-width: 2400px)').matches) {
    columnsInRow = 7;
  } else if (window.matchMedia('screen and (min-width: 1800px) and (max-width: 2399px)').matches) {
    columnsInRow = 6;
  } else if (window.matchMedia('screen and (min-width: 1320px) and (max-width: 1799px)').matches) {
    columnsInRow = 5;
  } else if (window.matchMedia('screen and (min-width: 1024px) and (max-width: 1319px)').matches) {
    columnsInRow = 4;
  } else if (window.matchMedia('screen and (min-width: 768px) and (max-width: 1023px)').matches) {
    columnsInRow = 3;
  } else if (window.matchMedia('screen and (max-width: 767px)').matches) {
    columnsInRow = 2;
  }

  return columnsInRow;
}
