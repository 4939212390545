import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'src/domain/libs/util';

export default class SelectBox extends Component {
  static get propTypes() {
    return {
      options: PropTypes.array,
      range: PropTypes.object,
      prompt: PropTypes.string,
      includeblank: PropTypes.bool,
      customblank: PropTypes.object,
    };
  }

  static get defaultProps() {
    return {
      options: [],
      range: null,
      prompt: null,
      includeblank: null,
      customblank: null,
    };
  }

  constructor(props, context) {
    super(props, context);
    // @ts-ignore TS2339
    this.selectTagRef = React.createRef();
  }

  get value() {
    // @ts-ignore TS2339
    if (this.selectTagRef.current) {
      // @ts-ignore TS2339
      return this.selectTagRef.current.value;
    } else {
      return null;
    }
  }

  /**
   * 要素の非活性状態を設定します。
   * @param value true 非活性, false 活性
   */
  set disabled(value) {
    // @ts-ignore TS2339
    if (this.selectTagRef.current) {
      // @ts-ignore TS2339
      this.selectTagRef.current.disabled = value;
    }
  }

  render() {
    const props = this.props;

    // @ts-ignore TS2339
    this.options = props.options;
    // @ts-ignore TS2339
    this.range = props.range;
    // @ts-ignore TS2339
    this.includeblank = props.includeblank;
    // @ts-ignore TS2339
    this.customblank = props.customblank;
    // @ts-ignore TS2339
    this.prompt = props.prompt;

    // selectboxはreadOnlyがないので、disabledにしてinputで送る
    // @ts-ignore TS2339
    const disabled = this.props.readOnly;
    // @ts-ignore TS2339
    const shouldRenderHidden = this.props.readOnly;
    return (
      <React.Fragment>
        {/*
         // @ts-ignore TS2339 */}
        <select ref={this.selectTagRef} {...props} includeblank={null} disabled={disabled}>
          {this._renderOptions()}
        </select>
        {shouldRenderHidden ? (
          // @ts-ignore TS2339
          <input type="hidden" value={this.props.defaultValue} name={this.props.name}></input>
        ) : null}
      </React.Fragment>
    );
  }

  _renderOptions() {
    let options;
    // @ts-ignore TS2339
    if (this.range && this.range['from'] && this.range['to']) {
      // @ts-ignore TS2339
      options = Array.from(new Array(this.range['to'] - this.range['from'] + 1)).map((v, i) => i + this.range['from']);
    } else {
      // @ts-ignore TS2339
      options = this.options;
    }

    if (_.get(options, '[0].value', _.get(options, '[0]'))) {
      // @ts-ignore TS2339
      if (this.prompt) {
        // @ts-ignore TS2339
        options.unshift({ label: this.props.prompt, value: '' });
        // @ts-ignore TS2339
      } else if (this.includeblank) {
        options.unshift({ label: '', value: '' });
        // @ts-ignore TS2339
      } else if (this.customblank) {
        // @ts-ignore TS2339
        options.splice(options.indexOf(this.customblank.indexValue), 0, {
          // @ts-ignore TS2339
          label: this.customblank.label,
          // @ts-ignore TS2339
          value: this.customblank.value,
        });
      }
    }

    return options.map((o, i) => {
      if (typeof o == 'object') {
        if (_.hasIn(o, 'label') && _.hasIn(o, 'value')) {
          return (
            <option key={`${o['value']}-${i}`} value={o['value']}>
              {o['label']}
            </option>
          );
        } else {
          return (
            <option key={`${o[1]}-${i}`} value={o[1]}>
              {o[0]}
            </option>
          );
        }
      } else {
        return (
          <option key={`${o}-${i}`} value={o}>
            {o}
          </option>
        );
      }
    });
  }
}
