import React from 'react';
import PropTypes from 'prop-types';
import _ from 'src/domain/libs/util';

//
// 支払い方法登録ページへ遷移させるform
//
class RegistForm extends React.PureComponent {
  static get contextTypes() {
    return {
      models: PropTypes.object,
    };
  }

  constructor(props, context) {
    super(props, context);

    // @ts-ignore TS2339
    this.formRef = React.createRef();
    // @ts-ignore TS2339
    this.courseIdRef = React.createRef();
    // @ts-ignore TS2339
    this.productIdRef = React.createRef();
    // @ts-ignore TS2339
    this.voucherCodeRef = React.createRef();
    // @ts-ignore TS2339
    this.userVoucherIdRef = React.createRef();
    // @ts-ignore TS2339
    this.returnToRef = React.createRef();
    // @ts-ignore TS2339
    this.cancelToRef = React.createRef();
    // @ts-ignore TS2339
    this.paymentMethodIdRef = React.createRef();
  }

  formSubmit(values) {
    const table = {
      // @ts-ignore TS2339
      courseId: this.courseIdRef,
      // @ts-ignore TS2339
      productId: this.productIdRef,
      // @ts-ignore TS2339
      voucherCode: this.voucherCodeRef,
      // @ts-ignore TS2339
      userVoucherId: this.userVoucherIdRef,
      // @ts-ignore TS2339
      returnTo: this.returnToRef,
      // @ts-ignore TS2339
      cancelTo: this.cancelToRef,
      // @ts-ignore TS2339
      paymentMethodId: this.paymentMethodIdRef,
    };

    // 引数で与えられた値をhiddenのvalueにセットする
    _.forEach(table, (ref, key) => {
      if (ref.current && values[key]) {
        ref.current.value = values[key];
      }
    });

    // formActionをセットしてsubmitする
    // @ts-ignore TS2339
    if (this.formRef.current) {
      // @ts-ignore TS2339
      this.formRef.current.action = values.formAction;
      // @ts-ignore TS2339
      this.formRef.current.submit();
    }
  }

  render() {
    return (
      // @ts-ignore TS2339
      <form ref={this.formRef} method="post" action="">
        {/*
         // @ts-ignore TS2339 */}
        <input type="hidden" name="meta_id" value={this.props.metaId} />
        {/*
         // @ts-ignore TS2339 */}
        <input ref={this.courseIdRef} type="hidden" name="course_id" />
        {/*
         // @ts-ignore TS2339 */}
        <input ref={this.productIdRef} type="hidden" name="product_id" />
        {/*
         // @ts-ignore TS2339 */}
        <input ref={this.voucherCodeRef} type="hidden" name="voucher_code" />
        {/*
         // @ts-ignore TS2339 */}
        <input ref={this.userVoucherIdRef} type="hidden" name="user_voucher_id" />
        {/*
         // @ts-ignore TS2339 */}
        <input ref={this.returnToRef} type="hidden" name="return_to" />
        {/*
         // @ts-ignore TS2339 */}
        <input ref={this.cancelToRef} type="hidden" name="cancel_to" value="" />
        {/*
         // @ts-ignore TS2339 */}
        <input ref={this.paymentMethodIdRef} type="hidden" name="payment_method_id" value="" />
      </form>
    );
  }
}

export default RegistForm;
