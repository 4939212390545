'use strict';

// タブの切り替え等で実行できなくなった時のためにsetTimeoutではなく短い期間に到るまでの間は、setIntervalで監視する
export default function safeTimeout(cb, offset) {
  var maxOffset = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 484;
  if (offset < maxOffset) {
    setTimeout(cb, offset);
  }
  const execDate = Date.now() + offset;
  var intervalId = setInterval(function() {
    var now = Date.now();
    if (now >= execDate) {
      cb();
      clearInterval(intervalId);
    } else {
      if (now + maxOffset >= execDate) {
        clearInterval(intervalId);
        setTimeout(cb, execDate - now);
      }
    }
  }, maxOffset);
}
