import React, { Component, useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import classnames from 'classnames';
import _ from 'src/domain/libs/util';

import GalleryContent from './GalleryContent';
import SearchSuggestions from './SearchSuggestions';
import MainViewLink from '../../../common/components/MainViewLink';
import SearchEventRow from './SearchEventRow';
import EventCard from './Epg/EventCard';
import GalleryTab from './GalleryTab';
import routes from '../../../common/routes';

const NoResults = props => {
  return (
    <div className="noResultsWrapper">
      <div className="noResults">
        <p>検索条件に一致する作品はありませんでした。</p>
        <div className="no_img" />
        <ul>
          <li>キーワードを変えて検索してください</li>
          <li>映画やドラマのタイトル、出演者を入れて検索してください</li>
          <li>ジャンル名で検索してください</li>
        </ul>
        {/*
         // @ts-ignore TS2322 */}
        <MainViewLink href="/prg_request" className="btn btn-border">
          番組リクエストする
        </MainViewLink>
      </div>
    </div>
  );
};

const galleryTitle = term => {
  return !!term ? `「${term}」で検索した結果` : null;
};

class Search extends Component {
  static tabInfo = [
    { key: 'search', name: 'オンデマンド', sort: true, query: { type: 'od' } },
    { key: 'searchEvent', name: '放送同時配信', sort: false, query: { type: 'ev' } },
  ];

  static get contextTypes() {
    return {
      getModelData: PropTypes.func.isRequired,
      models: PropTypes.object,
      history: PropTypes.object,
      routeHandler: PropTypes.object,
      authApp: PropTypes.object,
      i18n: PropTypes.object,
      i18nContext: PropTypes.string,
      service: PropTypes.string,
      gtmApp: PropTypes.object,
    };
  }

  constructor(props, context) {
    super(props, context);
    // @ts-ignore TS2339
    this.model = (this.props.pathEvaluator || this.props.model.pathEvaluator).batch(100);
    this.handleChangeQuery = this.handleChangeQuery.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSearchLength = this.handleSearchLength.bind(this);
    this.sendToGtm = this.sendToGtm.bind(this);
    this.state = { tabItemLength: {} };
  }

  componentDidMount() {
    // @ts-ignore TS2339
    this._isMounted = true;
    if (_.get(this.props, 'term')) {
      this.sendToGtm(galleryTitle(_.get(this.props, 'term')));
    }
  }

  componentWillUnmount() {
    // @ts-ignore TS2339
    this._isMounted = false;
  }

  handleChangeQuery(query) {
    // @ts-ignore TS2339
    if (this._isMounted) this.setState({ query: query });
  }

  sendToGtm(title) {
    if (!_.get(this.context, 'gtmApp')) return;
    this.context.gtmApp.pageView(title);
  }

  handleChange(term) {
    const { routeHandler, history } = this.context;
    let query = {
      q: term,
    };
    if (routeHandler.query.ft !== undefined) {
      // @ts-ignore TS2339
      query.ft = routeHandler.query.ft;
    }
    // @ts-ignore TS2554
    const to = routes.search.makePath({ service: this.context.service }, query);
    if (routeHandler.route._regex === routes.search._regex) {
      history.replace(to);
    } else {
      history.push(to);
    }
  }

  handleSearchLength(tabItemLength) {
    this.setState({ tabItemLength });
  }

  render() {
    const browserInfo = this.context.getModelData('browserInfo');
    // @ts-ignore TS2339
    if (!this.props.term) {
      return (
        <div className="noResultsWrapper">
          <div className="noResults">
            <div className="no_img" />
            <ul>
              <li>キーワードまたは絞り込みで検索してください</li>
              <li>映画やドラマのタイトル、出演者を入れて検索してください</li>
              <li>ジャンル名で検索してください</li>
            </ul>
          </div>
        </div>
      );
    }

    const { routeHandler, history } = this.context;
    let tab;
    // type指定している場合
    if (_.get(routeHandler, 'query.type')) {
      tab = _.find(Search.tabInfo, tab => {
        return _.get(tab.query, 'type') == _.get(routeHandler, 'query.type');
      });
    } else {
      tab = _.find(Search.tabInfo, tab => {
        // @ts-ignore TS2339
        return _.has(this.state.tabItemLength, tab.key) && this.state.tabItemLength[tab.key] > 0;
      });
    }
    // なければデフォルト
    if (!tab) tab = _.first(Search.tabInfo);
    const activeTabIndex = _.findIndex(Search.tabInfo, tab);
    const handleClick = hash => {
      const to = routeHandler.route.makePath({}, routeHandler.query, hash);
      history.replace(to);
    };
    const links = _.map(Search.tabInfo, (tab, index) => {
      const query = Object.assign({}, routeHandler.query, tab.query);
      const itemLength = () => {
        // @ts-ignore TS2339
        if (_.has(this.state.tabItemLength, tab.key)) return ` (${this.state.tabItemLength[tab.key]})`;
        return '';
      };
      return (
        <MainViewLink
          key={`tab_${tab.key}`}
          // @ts-ignore TS2322
          to={routeHandler.route}
          params={routeHandler.params}
          query={query}
          // @ts-ignore TS2339
          className={classnames({ active: this.state.tabItemLength[tab.key] > 0 || activeTabIndex == index })}
        >
          {tab.name}
          {itemLength()}
        </MainViewLink>
      );
    });
    const galleryProps = [
      {
        keyPrefix: 'search',
        listType: false,
      },
      {
        keyPrefix: 'searchEvent',
        cardComponent: EventCard,
        showPopCardMylistButton: false,
        listType: true,
        itemsInRow: 1,
        showSynopsis: true,
      },
    ];

    const header = (
      <React.Fragment>
        <div className="searchTitleHeader">
          <div className="gallery-header clearfix">
            <div className="title">
              {/*
               // @ts-ignore TS2339 */}
              <span className="gallery-title">{galleryTitle(this.props.term)}</span>
            </div>
          </div>
          {/*
           // @ts-ignore TS2322 */}
          <SearchSuggestions term={this.props.term} model={this.model} />
        </div>
        {/*
         // @ts-ignore TS2322 */}
        <GalleryTab links={links} activeTabIndex={activeTabIndex} />
        {/* <div className="gallery-search-filter">
          <SearchTarget
            target={(this.props.routeHandler) ? this.props.routeHandler.query['target'] || 'titles' : 'titles'}
            onChangeQuery={this.handleChangeQuery} />
        </div> */}
      </React.Fragment>
    );

    // return (
    //   <Layouter
    //     model={this.model}
    //     term={this.props.term}
    //     onClickCard={this.handleClick}
    //     filterType={(this.props.routeHandler) ? this.props.routeHandler.query['ft'] || 'a' : 'a'}
    //     searchFilter={(this.props.routeHandler) ? `${(this.props.routeHandler.query['sf'] || 'p:a')}|target:${(this.props.routeHandler.query['target'] || 'titles')}` : 'p:a'}
    //     keyPrefix={"search"}
    //     keyEventPrefix={'searchEvent'}
    //     header={header} />
    // )

    return (
      <GalleryContentWithCounter
        // @ts-ignore TS2322
        header={header}
        // @ts-ignore TS2339
        noResults={<NoResults term={this.props.term} />}
        // @ts-ignore TS2339
        model={this.model}
        // @ts-ignore TS2339
        term={this.props.term}
        onSearchLength={this.handleSearchLength}
        // @ts-ignore TS2339
        filterType={this.props.routeHandler ? this.props.routeHandler.query['ft'] || 'a' : 'a'}
        searchFilter={
          // @ts-ignore TS2339
          this.props.routeHandler
            ? // @ts-ignore TS2339
              `${this.props.routeHandler.query['sf'] || 'p:a'}|target:${this.props.routeHandler.query['target'] ||
                'titles'}`
            : 'p:a'
        }
        keyPrefix={'search'}
        {...galleryProps[activeTabIndex]}
      />
    );
    /*
              <SearchFilter
                filterType={(this.props.routeHandler) ? this.props.routeHandler.query['ft'] || 'a' : 'a'}
                searchFilter={(this.props.routeHandler) ? this.props.routeHandler.query['sf'] || 'p:a' : 'p:a'}
                onChangeQuery={this.handleChangeQuery}
              />
              */
  }
}

class GalleryContentWithCounter extends React.Component {
  static getPaths = function(models, options, props) {
    let paths = [];

    // タブ分の件数を取得
    _.map(Search.tabInfo, tab => {
      paths = paths.concat([
        GalleryContent.getRootPath(models, options, Object.assign({}, props, { keyPrefix: tab.key })).concat([
          'length',
        ]),
      ]);
    });

    return paths;
  };

  static initState = {
    dispose: null,
    generation: -1,
    fetchDataError: null,
  };

  constructor(props, context) {
    super(props, context);
    this.state = _.clone(GalleryContentWithCounter.initState);
    // @ts-ignore TS2339
    this.fetchedPaths = {};
  }

  static contextTypes = {
    getModelData: PropTypes.func.isRequired,
    history: PropTypes.object,
    routeHandler: PropTypes.object,
    columnsInRow: PropTypes.number,
  };

  componentDidMount() {
    // @ts-ignore TS2339
    this._isMounted = true;
    this.fetchData(this.props);
  }

  componentWillReceiveProps(nextProps) {
    // @ts-ignore TS2339
    if (nextProps.term !== this.props.term) {
      this.setState(_.clone(GalleryContentWithCounter.initState), () => {
        this.fetchData(nextProps);
      });
    }
  }

  componentWillUnmount() {
    // @ts-ignore TS2339
    this._isMounted = false;
    // @ts-ignore TS2339
    if (this.state.dispose) this.state.dispose();
  }

  fetchData(props, context = this.context) {
    // @ts-ignore TS2339
    const paths = this.constructor.getPaths(this.context.models, {}, props);

    // すでに通信している場合は実行しない
    // @ts-ignore TS2339
    if (this.fetchedPaths[JSON.stringify(paths)]) return;

    // @ts-ignore TS2339
    if (this.state.dispose) {
      // 過去のObservableを削除する、これによって通信が止まるわけではなく
      // Observableがなくなるのでイベントが発火されなくなる、というだけなので注意
      // @ts-ignore TS2339
      this.state.dispose();
    }

    // @ts-ignore TS2339
    this.fetchedPaths[JSON.stringify(paths)] = paths;

    const evaluator = props.model.fetch(paths);
    const dispose = evaluator.dispose;
    // @ts-ignore TS2339
    if (this._isUpdated === false || !this._isMounted) {
      Object.assign(this.state, { dispose });
    } else {
      this.setState({ dispose });
    }
    evaluator
      .then(res => {
        const itemLength = {};
        _.map(Search.tabInfo, tab => {
          const rootPath = GalleryContent.getRootPath(
            this.context.models,
            {},
            Object.assign({}, props, { keyPrefix: tab.key }),
          ).concat(['length']);
          itemLength[tab.key] = _.get(res, ['json'].concat(rootPath)) || 0;
        });
        // @ts-ignore TS2339
        if (this.props.onSearchLength) this.props.onSearchLength(itemLength);
        // @ts-ignore TS2339
        delete this.fetchedPaths[JSON.stringify(paths)];
        // @ts-ignore TS2339
        if (this._isMounted) {
          this.setState({
            fetchDataError: null,
            dispose: null,
            // @ts-ignore TS2339
            generation: this.state.generation + 1,
            loading: false,
          });
        }
      })
      .catch(e => {
        const newState = {
          fetchDataError: e,
          fetchingMoreRows: undefined,
          dispose: null,
        };
        // @ts-ignore TS2339
        delete this.fetchedPaths[JSON.stringify(paths)];
        // @ts-ignore TS2339
        if (this._isMounted) this.setState(newState);
        else this.state = newState;
      });
  }

  render() {
    return <GalleryContent {...this.props} />;
  }
}

class Layouter extends React.Component {
  static getPaths = function(models, options, props) {
    let paths = [];
    paths = paths.concat([GalleryContent.getRootPath(models, options, props).concat('length')]);
    return paths.concat([SearchEventRow.getRootPath(models, options, props).concat('length')]);
  };

  static getGalleryContentRootPath = function(models, options, props) {
    return GalleryContent.getRootPath(models, options, props);
  };

  static getSearchEventRootPath = function(models, options, props) {
    return SearchEventRow.getRootPath(models, options, props);
  };

  static initState = {
    dispose: null,
    generation: -1,
    fetchDataError: null,
    galleryContentLength: -1,
    searchEventLength: -1,
  };

  constructor(props, context) {
    super(props, context);
    this.state = Layouter.initState;
    // @ts-ignore TS2339
    this.fetchedPaths = {};
  }

  static contextTypes = {
    getModelData: PropTypes.func.isRequired,
    history: PropTypes.object,
    routeHandler: PropTypes.object,
    authApp: PropTypes.object,
    // i18n: PropTypes.object,
    // i18nContext: PropTypes.string,
    // service: PropTypes.string,
    // gtmApp: PropTypes.object,
    // isKidsProfile: PropTypes.bool,
  };

  componentDidMount() {
    // @ts-ignore TS2339
    this._isMounted = true;
    this.fetchData(this.props);
  }

  componentWillReceiveProps(nextProps) {
    // @ts-ignore TS2339
    if (nextProps.term !== this.props.term) {
      this.setState(Layouter.initState, () => {
        this.fetchData(nextProps);
      });
    }
  }

  componentWillUnmount() {
    // @ts-ignore TS2339
    this._isMounted = false;
    // @ts-ignore TS2339
    if (this.state.dispose) this.state.dispose();
  }

  fetchData(props, context = this.context) {
    // @ts-ignore TS2339
    const galleryContentRootPath = this.constructor.getGalleryContentRootPath(this.context.models, {}, props);
    // @ts-ignore TS2339
    const searchEventRootPath = this.constructor.getSearchEventRootPath(this.context.models, {}, props);
    // @ts-ignore TS2339
    const paths = this.constructor.getPaths(this.context.models, {}, props);

    // すでに通信している場合は実行しない
    // @ts-ignore TS2339
    if (this.fetchedPaths[JSON.stringify(paths)]) return;

    // @ts-ignore TS2339
    if (this.state.dispose) {
      // 過去のObservableを削除する、これによって通信が止まるわけではなく
      // Observableがなくなるのでイベントが発火されなくなる、というだけなので注意
      // @ts-ignore TS2339
      this.state.dispose();
    }

    // @ts-ignore TS2339
    this.fetchedPaths[JSON.stringify(paths)] = paths;

    const evaluator = props.model.fetch(paths);
    const dispose = evaluator.dispose;
    // @ts-ignore TS2339
    if (this._isUpdated === false || !this._isMounted) {
      Object.assign(this.state, { dispose });
    } else {
      this.setState({ dispose });
    }
    evaluator
      .then(res => {
        const galleryContentLength = _.get(res, ['json'].concat(galleryContentRootPath).concat('length'));
        const searchEventLength = _.get(res, ['json'].concat(searchEventRootPath).concat('length'));
        // @ts-ignore TS2339
        delete this.fetchedPaths[JSON.stringify(paths)];
        // @ts-ignore TS2339
        if (this._isMounted) {
          this.setState({
            fetchDataError: null,
            dispose: null,
            // @ts-ignore TS2339
            generation: this.state.generation + 1,
            loading: false,
            galleryContentLength,
            searchEventLength,
          });
        }
      })
      .catch(e => {
        const newState = {
          fetchDataError: e,
          fetchingMoreRows: undefined,
          dispose: null,
        };
        // @ts-ignore TS2339
        delete this.fetchedPaths[JSON.stringify(paths)];
        // @ts-ignore TS2339
        if (this._isMounted) this.setState(newState);
        else this.state = newState;
      });
  }

  render() {
    const browserInfo = this.context.getModelData('browserInfo');

    const galleryProps = {
      // @ts-ignore TS2339
      model: this.props.model,
      // @ts-ignore TS2339
      term: this.props.term,
      // @ts-ignore TS2339
      onClickCard: this.props.handleClick,
      // @ts-ignore TS2339
      keyPrefix: this.props.keyPrefix,
      // @ts-ignore TS2339
      filterType: this.props.filterType,
      // @ts-ignore TS2339
      searchFilter: this.props.searchFilter,
    };

    const eventProps = {
      // @ts-ignore TS2339
      model: this.props.model,
      // @ts-ignore TS2339
      term: this.props.term,
      // @ts-ignore TS2339
      keyEventPrefix: this.props.keyEventPrefix,
      // @ts-ignore TS2339
      filterType: this.props.filterType,
      // @ts-ignore TS2339
      searchFilter: this.props.searchFilter,
    };

    // @ts-ignore TS2339
    if (this.state.galleryContentLength == -1) return null;

    let headline = 20;
    headline = 5; // デバッグ
    // if (browserInfo.isIOS || browserInfo.isAndroid) {
    //   headline = 6;
    // }

    // @ts-ignore TS2339
    if (this.state.galleryContentLength === 0 || this.state.searchEventLength === 0) {
      headline = 0;
    }

    // @ts-ignore TS2339
    if (this.state.galleryContentLength <= headline) {
      return (
        <React.Fragment>
          <GalleryContent
            header={
              <React.Fragment>
                <div
                  className={classnames('searchTitleHeader', { spMode: browserInfo.isIOS || browserInfo.isAndroid })}
                >
                  {/*
                   // @ts-ignore TS2339 */}
                  {this.props.header}
                </div>
              </React.Fragment>
            }
            {...galleryProps}
            // @ts-ignore TS2322
            noResults={<NoResults term={this.props.term} />}
          />
          {/*
           // @ts-ignore TS2339 */}
          {this.state.searchEventLength > 0 && (
            <GalleryContent
              header={
                <React.Fragment>
                  <div
                    className={classnames('searchTitleHeader', { spMode: browserInfo.isIOS || browserInfo.isAndroid })}
                  >
                    <div className="searchTitleHeader">
                      <div className="gallery-header clearfix">
                        <div className="title">
                          <span className="gallery-title">同時配信</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </React.Fragment>
              }
              // @ts-ignore TS2339
              listType={this.props.keyPrefix == 'searchEvent' ? true : false}
              {...eventProps}
              // @ts-ignore TS2339
              keyPrefix={this.props.keyEventPrefix}
              cardComponent={EventCard}
              showPopCardMylistButton={false}
              // @ts-ignore TS2322
              hideNoResults={this.state.searchEventLength > 0}
              // @ts-ignore TS2339
              noResults={<NoResults term={this.props.term} />}
            />
          )}
        </React.Fragment>
      );
    }

    return (
      <GalleryContent
        header={
          <React.Fragment>
            <div className={classnames('searchTitleHeader', { spMode: browserInfo.isIOS || browserInfo.isAndroid })}>
              {/*
               // @ts-ignore TS2339 */}
              {this.props.header}
              {/*
               // @ts-ignore TS2339 */}
              {this.state.searchEventLength > 0 ? (
                <React.Fragment>
                  <GalleryContent
                    {...galleryProps}
                    // @ts-ignore TS2322
                    noResults={<NoResults term={this.props.term} />}
                    toNum={headline}
                    lazyload={false}
                  />
                  {/*
                   // @ts-ignore TS2322 */}
                  <SearchEventRow {...eventProps} onClick={this.props.handleClick} />
                </React.Fragment>
              ) : null}
            </div>
          </React.Fragment>
        }
        {...galleryProps}
        // @ts-ignore TS2322
        listType={this.props.keyPrefix == 'searchEvent' ? true : false}
        // @ts-ignore TS2339
        hideNoResults={this.state.searchEventLength > 0}
        // @ts-ignore TS2339
        noResults={<NoResults term={this.props.term} />}
        fromNum={headline}
      />
    );
  }
}

// const SearchRoute = componentFromStream((props$) => {
//   const stream$ = props$
//     .map(({routeHandler}) => routeHandler.query.q)
//     .debounceTime(500)
//     .distinctUntilChanged()
//     .switchMap(term => Observable.of(term))
//   return props$.combineLatest(
//     stream$,
//     (props, term) => {
//       return (<Search term={term} {...props} />);
//     }
//   );
// })
const SearchRoute = (() => {
  const intervalMs = 500;
  let debounceTimer;
  return (props, context) => {
    const [renderSearch, setRenderSearch] = useState(false);
    const term = props.routeHandler.query.q;
    useEffect(() => {
      if (debounceTimer) {
        clearTimeout(debounceTimer);
      }
      debounceTimer = setTimeout(() => {
        debounceTimer = null;
        setRenderSearch(true);
      }, intervalMs);
    }, [term]);
    return renderSearch ? <Search term={term} {...props} /> : null;
  };
})();

// @ts-ignore TS2339
SearchRoute.displayName = 'SearchRoute';

export default SearchRoute;
