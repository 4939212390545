import _ from 'src/domain/libs/util';
import cookieDough from 'cookie-dough';
import { LAST_CHANNEL } from '../constants/cookie';

const cookie = cookieDough();

export default {
  defaultChannelName: 'Prime',
  set: function(refId) {
    if (typeof window !== 'undefined' || !refId) {
      if (window.localStorage) {
        window.localStorage.setItem(LAST_CHANNEL, JSON.stringify(refId));
      }
      if (window.navigator.cookieEnabled) {
        cookie.set(LAST_CHANNEL, JSON.stringify(refId), { path: '/' });
      }
    }
  },
  get: function(models) {
    let lastChannel;
    try {
      if (typeof window !== 'undefined') {
        if (window.localStorage) {
          lastChannel = JSON.parse(window.localStorage.getItem(LAST_CHANNEL));
        } else if (window.navigator.cookieEnabled) {
          lastChannel = JSON.parse(cookie.get(LAST_CHANNEL));
        }
      }
    } catch (e) {
      console.log(e);
      return null;
    }
    if (!lastChannel) {
      lastChannel = _.get(
        _.find(_.get(models, 'simulcast.data'), channel => channel.name === this.defaultChannelName),
        'refId',
      );
      this.set(lastChannel);
    }
    return lastChannel;
  },
};
