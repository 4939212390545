import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import _ from 'src/domain/libs/util';

import * as browserEvents from 'src/sketch-platform/utils/browserEvents';
import { LINKS } from 'src/constants/links';

export default class Attention extends Component {
  static contextTypes = {
    models: PropTypes.object,
    getModelData: PropTypes.func,
    authApp: PropTypes.object,
    cookies: PropTypes.object,
    service: PropTypes.string,
  };

  constructor(props, context) {
    super(props, context);
    // @ts-ignore TS2339
    this.contentRef = React.createRef();
    this.adjust = this.adjust.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.state = {
      items: [],
    };
    this.build();
  }

  componentDidMount() {
    // @ts-ignore TS2339
    this._isMounted = true;
    const browserInfo = this.context.getModelData('browserInfo');
    if (browserInfo.isIOS || browserInfo.isAndroid) {
      browserEvents.addEventListener('orientationchange', this.adjust);
    } else {
      browserEvents.addEventListener('resize', this.adjust);
    }
    this.adjust();
  }

  componentDidUpdate(beforeProps, beforeState) {
    this.adjust();
  }

  componentWillUnmount() {
    // @ts-ignore TS2339
    this._isMounted = false;
    const browserInfo = this.context.getModelData('browserInfo');
    if (browserInfo.isIOS || browserInfo.isAndroid) {
      browserEvents.removeEventListener('orientationchange', this.adjust);
    } else {
      browserEvents.removeEventListener('resize', this.adjust);
    }
  }

  adjust() {
    let fullScreen = false;
    const appMountPoint = document.getElementById('appMountPoint');
    if (appMountPoint) {
      fullScreen = appMountPoint.classList.contains('full-screen');
    }
    if (fullScreen) return;

    // @ts-ignore TS2339
    const el = this.contentRef.current;
    const attentionArea = document.querySelector('.attention-area');
    if (el && attentionArea) {
      // @ts-ignore TS2339
      attentionArea.style.paddingTop = el.clientHeight + 'px';
    } else if (attentionArea) {
      // @ts-ignore TS2339
      attentionArea.style.paddingTop = 0;
    }
  }

  build() {
    // @ts-ignore TS2339
    this.items = [];
    const browserInfo = this.context.getModelData('browserInfo');
    // OSがサポート外またはブラウザがサポート外の場合
    if (!browserInfo.os.isSupport || !browserInfo.features.playable) {
      // @ts-ignore TS2339
      this.items.push({
        title: '重要なお知らせ',
        message: (
          <React.Fragment>
            ご利用いただいている環境は推奨環境ではありません。推奨環境は
            <a href={LINKS.SUPPORT.ENABLE_DEVICES} target="_blank" className="accent-color">
              こちら
            </a>
            をご確認ください。
          </React.Fragment>
        ),
      });
    }
    // @ts-ignore TS2339
    if (this._isMounted) this.setState({ items: this.items });
    // @ts-ignore TS2339
    else this.state.items = this.items;
  }

  handleClose(e, item) {
    // 非表示の設定はブラウザごとに行われるものとし、ブラウザを閉じるまでの間有効なものとする。
    // setCookie(this.context.cookies, item.cookieKey, true);
    this.build();
  }

  render() {
    // @ts-ignore TS2339
    if (_.isEmpty(this.state.items)) return null;

    // @ts-ignore TS2339
    const items = this.state.items.map((item, i) => {
      return (
        <div key={`attention-${i}`} className={classnames('attention')}>
          <div className="attention-inner">
            <em>{item.title}</em>
            <p>{item.message}</p>
          </div>
        </div>
      );
    });
    // @ts-ignore TS2339
    return <div ref={this.contentRef}>{items}</div>;
  }
}
