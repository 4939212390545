import React from 'react';
import useHowtoPlay from '../hooks/useHowtoPlay';

const HowtoPlayProvider = props => {
  const howtoPlayData = useHowtoPlay({ model: props.model, metaId: props.meta.id });

  return React.Children.map(props.children, child => {
    switch (typeof child) {
      case 'string':
        // 子要素がテキストノードだった場合はそのまま return
        return child;

      case 'object':
        // React要素だった場合は props を渡す
        return React.cloneElement(child, {
          products: howtoPlayData.products,
          courses: howtoPlayData.courses,
          rights: howtoPlayData.rights,
          loadingHowtoPlayData: howtoPlayData.loading,
          ...props,
        });

      default:
        // それ以外の場合はとりあえず null 返しとく
        return null;
    }
  });
};

export default HowtoPlayProvider;
