import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import _ from 'src/domain/libs/util';

import WodPlayer from '../player/WodPlayer';

class CheckModal extends React.Component {
  static contextTypes = {
    getModelData: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    // @ts-ignore TS2339
    this._isMounted = true;
  }

  componentWillUnmount() {
    // @ts-ignore TS2339
    this._isMounted = false;
  }

  render() {
    // お試し再生はライブとVODでわける
    const sampleMovieMetaId = this.context.getModelData('sampleMovieMetaId');

    return (
      <React.Fragment>
        {/*
         // @ts-ignore TS2322 */}
        <div class="modal-title">お試し再生</div>
        <div className="modal-text">
          {/*
           // @ts-ignore TS2322 */}
          <p class="text text-center">
            再生ボタンを押して、最後まで映像および音声が正常に出力されているかご確認ください。
            <br />
            ※再生されない場合は
            <a href="https://support.wowow.co.jp/s/article/778" target="_blank">
              こちら
            </a>
            をご確認ください。
          </p>
          <div id="spec">
            <div className="section-panel spec_inner_movie">
              <div className="movie">
                <div className="sample-movie">
                  <WodPlayer
                    config={this.context.getModelData('config')}
                    autoplay={false}
                    controls={true}
                    // @ts-ignore TS2322
                    id={sampleMovieMetaId}
                    enableFullScreen={false}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="btn-block">
          {/*
           // @ts-ignore TS2339 */}
          <button className="btn btn-border" onClick={this.props.closeModal}>
            閉じる
          </button>
        </div>
      </React.Fragment>
    );
  }
}

export default CheckModal;
