import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import _ from 'src/domain/libs/util';

import MainViewLink from '../../../common/components/MainViewLink';
import routes from '../../../common/routes';
import classnames from 'classnames';
import * as browserEvents from '../../../../sketch-platform/utils/browserEvents';
import MyListButton from '../browse/MyListButton';
import BackgroundImage from '../../../common/components/BackgroundImage';
import OAPPlayer from '../../../common/components/player/OAPPlayer';
import SetDate from '../browse/SetDate';
import MetaTags from '../player/MetaTags';
import activeProfile from '../../../../utils/activeProfile';
import { getIsDelivery } from '../../../common/utils/metaUtil';
import { isOnAir } from '../../../common/utils/eventUtil';

type BillboardCardProps = {
  autoplay: boolean;
};
export default class BillboardCard extends React.PureComponent<BillboardCardProps> {
  static getPaths = function(models, options, props = {}) {
    let rootPath = [];
    // @ts-ignore TS2339
    if (props.titleId) {
      // @ts-ignore TS2339
      rootPath = rootPath.concat(['meta', props.titleId]);
    }
    return [
      rootPath.concat([
        [
          'id',
          'refId',
          'name',
          'thumbnailUrl',
          'mainVisualType',
          'mylisted',
          'canMyList',
          'type',
          'slug',
          'schemaId',
          'oap',
          'uniqueId',
        ],
      ]),
    ];
  };

  static getRootPath = function(models, options, props = {}) {
    // @ts-ignore TS2339
    if (!props.titleId) return;
    // @ts-ignore TS2339
    return ['meta', props.titleId];
  };

  static get propTypes() {
    return {
      titleId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      model: PropTypes.object.isRequired,
    };
  }

  static get contextTypes() {
    return {
      getModelData: PropTypes.func,
      models: PropTypes.object,
      routeHandler: PropTypes.object,
      history: PropTypes.object,
    };
  }

  constructor(props, context) {
    super(props, context);

    // @ts-ignore TS2339
    this.titleLinkRef = React.createRef();

    // @ts-ignore TS2339
    const rootPath = this.constructor.getRootPath(context.models, {}, props);
    if (props.itemData) {
      this.item = props.itemData;
    } else if (rootPath) {
      this.item = props.model.getSync(rootPath);
    }
    this.onClickOther = this.onClickOther.bind(this);
    this.onPointerOver = this.onPointerOver.bind(this);
    this.onPointerOut = this.onPointerOut.bind(this);
    this.startSlider = this.startSlider.bind(this);
    this.stopSlider = this.stopSlider.bind(this);
    this.onClickPlay = this.onClickPlay.bind(this);
    this.onPlayerVolumeChange = this.onPlayerVolumeChange.bind(this);
    this.onPlayerTimeUpdate = this.onPlayerTimeUpdate.bind(this);
    this.onPlayerReady = this.onPlayerReady.bind(this);
    this.onPlayerError = this.onPlayerError.bind(this);
    this.getShowPlayButton = this.getShowPlayButton.bind(this);
    this.getShowFavoriteButton = this.getShowFavoriteButton.bind(this);
    this.getShowDescriptionButton = this.getShowDescriptionButton.bind(this);
    this.getPlayButtonPath = this.getPlayButtonPath.bind(this);
    this.onClickPlayButton = this.onClickPlayButton.bind(this);
    this.onClickDescriptionButton = this.onClickDescriptionButton.bind(this);
    this.onClickThumbnail = this.onClickThumbnail.bind(this);

    this.state = {
      dispose: null,
      fetchDataError: null,
      generation: props.model.getVersion(rootPath),
    };
    // @ts-ignore TS2339
    this.enterTimerID = null;
    // @ts-ignore TS2339
    this.leaveTimerID = null;
    // @ts-ignore TS2339
    this.defaultPrevented = false;
  }

  componentDidMount() {
    // @ts-ignore TS2339
    this._isMounted = true;
    // @ts-ignore TS2339
    if (!this.props.itemData) this.fetchData(this.props);

    // @ts-ignore TS2339
    if (this.props.itemTabbable && this.item.oap && this.props.showOap) {
      this.stopSlider();
    }
  }

  componentWillReceiveProps(nextProps) {
    // @ts-ignore TS2339
    if (nextProps.itemTabbable && nextProps.itemTabbable != this.props.itemTabbable) {
      if (nextProps.showOap && this.item.oap) this.stopSlider(nextProps);
    }
  }

  componentWillUnmount() {
    // @ts-ignore TS2339
    this._isMounted = false;
    // @ts-ignore TS2339
    if (this.state.dispose) this.state.dispose();
  }

  set item(item) {
    // @ts-ignore TS2551
    this.__item = item;
    if (_.has(item, 'advertisingId')) {
      // leadEpisode、edgeEpisodeを引く場合
      if (
        item.schemaId === 1 &&
        item.seriesMeta &&
        item.seriesMeta.metaId &&
        ((item.pageTransitionType === 'latest_episode' && !item.edgeEpisode) ||
          (item.pageTransitionType === 'lead_episode' && !item.leadEpisode))
      ) {
        this.fetchAdvertisingSeriesMetaData(this.props);
      }
    } else if (item.type === 'group') {
      this.fetchViewingMetaData(this.props);
    }
  }

  get item() {
    // @ts-ignore TS2551
    return this.__item;
  }

  fetchData(props) {
    // @ts-ignore TS2339
    const rootPath = this.constructor.getRootPath(this.context.models, {}, props);
    if (!rootPath) return;

    // @ts-ignore TS2339
    const paths = this.constructor.getPaths(this.context.models, {}, props);
    const evaluator = props.model.fetch(paths);
    // @ts-ignore TS2339
    this.state.dispose = evaluator.dispose;
    evaluator
      .then(res => {
        if (res) {
          if (!this.item) this.item = {};
          Object.assign(this.item, _.omit(_.get(res.json, rootPath, {}), ['$__path']));
        } else {
          delete this.item;
        }
        const newState = {
          fetchDataError: null,
          dispose: null,
          generation: props.model.getVersion(rootPath),
        };
        // @ts-ignore TS2339
        if (this._isMounted) this.setState(newState);
        else Object.assign(this.state, newState);
      })
      .catch(e => {
        console.error(e.stack);
        const newState = {
          fetchDataError: e,
          dispose: null,
        };
        // @ts-ignore TS2339
        if (this._isMounted) this.setState(newState);
        else this.state = Object.assign(newState);
      });
  }

  fetchAdvertisingSeriesMetaData(props) {
    const rootPath = ['meta', this.item.seriesMeta.metaId];
    const paths = [rootPath.concat([['leadEpisode', 'edgeEpisode']])];
    const evaluator = props.model.fetch(paths);

    // @ts-ignore TS2339
    if (this._isMounted) this.state.dispose = evaluator.dispose;
    else this.state = Object.assign({ dispose: evaluator.dispose });
    evaluator
      .then(res => {
        if (res) Object.assign(this.item.seriesMeta, _.omit(_.get(res.json, rootPath, {}), ['$__path']));
        const newState = {
          fetchDataError: null,
          dispose: null,
          generation: props.model.getVersion(rootPath),
        };
        // @ts-ignore TS2339
        if (this._isMounted) this.setState(newState);
        else Object.assign(this.state, newState);
      })
      .catch(e => {
        console.error(e.stack);
        const newState = {
          fetchDataError: e,
          dispose: null,
        };
        // @ts-ignore TS2339
        if (this._isMounted) this.setState(newState);
        else this.state = Object.assign(newState);
      });
  }

  fetchViewingMetaData(props = this.props) {
    const rootPath = ['meta', this.item.id, 'viewingEpisode'];
    const paths = [rootPath.concat([['id', 'refId', 'name', 'cardInfo']])];
    // @ts-ignore TS2339
    const evaluator = props.model.fetch(paths);

    // @ts-ignore TS2339
    if (this._isMounted) this.state.dispose = evaluator.dispose;
    else this.state = Object.assign({ dispose: evaluator.dispose });
    evaluator
      .then(res => {
        const viewingEpisode = _.omit(_.get(res.json, rootPath), ['$__path']);
        this.item.viewingEpisode = !_.isEmpty(viewingEpisode) ? viewingEpisode : null;
        const newState = {
          fetchDataError: null,
          dispose: null,
          // @ts-ignore TS2339
          generation: props.model.getVersion(rootPath),
        };
        // @ts-ignore TS2339
        if (this._isMounted) this.setState(newState);
        else Object.assign(this.state, newState);
      })
      .catch(e => {
        console.error(e.stack);
        const newState = {
          fetchDataError: e,
          dispose: null,
        };
        // @ts-ignore TS2339
        if (this._isMounted) this.setState(newState);
        else this.state = Object.assign(newState);
      });
  }

  onClickOther(e) {
    // @ts-ignore TS2339
    if (this.props.onClickOther) {
      // @ts-ignore TS2339
      this.props.onClickOther(this.item);
    }
    // イベントが重複しないようにインスタンス変数でdefaultPreventedを持つ
    // @ts-ignore TS2339
    this.defaultPrevented = true;
    // @ts-ignore TS2339
    const dom = ReactDOM.findDOMNode(this.titleLinkRef.current);
    // @ts-ignore TS2339
    if (dom) dom.click();
  }

  onPointerOver(e) {
    // @ts-ignore TS2339
    const dom = ReactDOM.findDOMNode(this.titleLinkRef.current);
    // @ts-ignore TS2339
    if (dom) dom.classList.add('hover');
  }

  onPointerOut(e) {
    // @ts-ignore TS2339
    const dom = ReactDOM.findDOMNode(this.titleLinkRef.current);
    // @ts-ignore TS2339
    if (dom) dom.classList.remove('hover');
  }

  startSlider(props = this.props) {
    // @ts-ignore TS2339
    if (props.itemTabbable && props.onAutoplay) props.onAutoplay(true);
  }

  stopSlider(props = this.props) {
    // @ts-ignore TS2339
    if (props.itemTabbable && props.onAutoplay) props.onAutoplay(false);
  }

  onClickPlay(isPlay) {
    if (isPlay) {
      this.stopSlider();
    } else {
      this.startSlider();
    }
  }

  onPlayerVolumeChange(volume, muted) {
    // @ts-ignore TS2339
    if (this.props.onMuted) this.props.onMuted(muted);
  }

  onPlayerTimeUpdate(currentTime) {
    // @ts-ignore TS2339
    if (this.props.onPlayerTimeUpdate) this.props.onPlayerTimeUpdate(currentTime);
  }

  onPlayerReady(player) {
    // @ts-ignore TS2339
    if (this.props.onPlayerReady) this.props.onPlayerReady(player);
  }

  onPlayerError(error) {
    this.startSlider();
  }

  getShowPlayButton(item = {}) {
    let showPlayButton = false;
    // @ts-ignore TS2339
    if (item.type === 'group') {
      // @ts-ignore TS2339
      const pickupMeta = item.viewingEpisode || item.leadEpisode;
      if (pickupMeta) showPlayButton = getIsDelivery(pickupMeta.deliveryStartAt, pickupMeta.deliveryEndAt);
      // @ts-ignore TS2339
    } else if (item.type === 'media') {
      // 配信外の場合は詳細ボタン
      showPlayButton = getIsDelivery(_.get(item, 'cardInfo.deliveryStartAt'), _.get(item, 'cardInfo.deliveryEndAt'));
      // @ts-ignore TS2339
    } else if (item.type === 'linear_channel') {
      showPlayButton = true;
    } else {
      // @ts-ignore TS2339
      if (item.uniqueId) {
        // 放送外の場合は詳細ボタン
        showPlayButton = isOnAir(item);
      }
    }
    if (showPlayButton) {
      // @ts-ignore TS2554
      const profile = activeProfile(this.context.models);
      if (profile) {
        const config = this.context.getModelData('config');
        const isEven = profile.refId % 2 === 0;
        if (isEven) {
          showPlayButton = showPlayButton && !!_.get(config, 'musthead.show_play_button_even_member_id');
        } else {
          showPlayButton = showPlayButton && !!_.get(config, 'musthead.show_play_button_odd_member_id');
        }
      } else {
        showPlayButton = false;
      }
    }
    return showPlayButton;
  }

  getShowFavoriteButton(item = {}) {
    let showFavoriteButton = false;
    // @ts-ignore TS2339
    if (item.type === 'group') {
      showFavoriteButton = true;
      // @ts-ignore TS2339
    } else if (item.type === 'media') {
      showFavoriteButton = true;
    }
    if (showFavoriteButton) {
      // @ts-ignore TS2554
      const profile = activeProfile(this.context.models);
      if (profile) {
        const config = this.context.getModelData('config');
        const isEven = profile.refId % 2 === 0;
        if (isEven) {
          showFavoriteButton = !!_.get(config, 'musthead.show_favorite_button_even_member_id');
        } else {
          showFavoriteButton = !!_.get(config, 'musthead.show_favorite_button_odd_member_id');
        }
      } else {
        showFavoriteButton = false;
      }
    }
    return showFavoriteButton;
  }

  getShowDescriptionButton(item = {}) {
    let showDescriptionButton = false;
    // @ts-ignore TS2339
    if (item.type === 'group') {
      showDescriptionButton = true;
      // @ts-ignore TS2339
    } else if (item.type === 'media') {
      // 配信中の場合は再生ボタン
      showDescriptionButton = !getIsDelivery(
        _.get(item, 'cardInfo.deliveryStartAt'),
        _.get(item, 'cardInfo.deliveryEndAt'),
      );
    } else {
      // @ts-ignore TS2339
      if (item.advertisingId) {
        showDescriptionButton = true;
        // @ts-ignore TS2339
      } else if (item.uniqueId) {
        // 放送中の場合は再生ボタン
        showDescriptionButton = !isOnAir(item);
      }
    }
    if (showDescriptionButton) {
      // @ts-ignore TS2554
      const profile = activeProfile(this.context.models);
      if (profile) {
        const config = this.context.getModelData('config');
        const isEven = profile.refId % 2 === 0;
        if (isEven) {
          showDescriptionButton = !!_.get(config, 'musthead.show_description_button_even_member_id');
        } else {
          showDescriptionButton = !!_.get(config, 'musthead.show_description_button_odd_member_id');
        }
      } else {
        showDescriptionButton = false;
      }
    }
    return showDescriptionButton;
  }

  getPlayButtonPath(item = {}) {
    let path = null;
    // @ts-ignore TS2339
    if (item.type === 'group') {
      // @ts-ignore TS2339
      const pickupMeta = item.viewingEpisode || item.leadEpisode;
      let params = { id: pickupMeta.id };
      let route = routes.watchNow;
      if (pickupMeta.refId) {
        params = { id: pickupMeta.refId };
        route = routes.content;
      }
      // @ts-ignore TS2554
      path = route.makePath(params);
      // @ts-ignore TS2339
    } else if (item.type === 'media') {
      // @ts-ignore TS2339
      let params = { id: item.id };
      let route = routes.watchNow;
      // @ts-ignore TS2339
      if (item.refId) {
        // @ts-ignore TS2339
        params = { id: item.refId };
        route = routes.content;
      }
      // @ts-ignore TS2554
      path = route.makePath(params);
      // @ts-ignore TS2339
    } else if (item.type === 'linear_channel') {
      // @ts-ignore TS2339
      const simulcast = _.find(
        this.context.getModelData('simulcast'),
        // @ts-ignore TS2339
        channel => item.refId == channel.refId,
      );
      // @ts-ignore TS2554
      path = routes.simulcast.makePath({ channelName: simulcast.name });
    } else {
      // @ts-ignore TS2339
      if (item.uniqueId) {
        const simulcast = _.find(
          this.context.getModelData('simulcast'),
          // @ts-ignore TS2339
          channel => item.linearChannelMeta.refId == channel.refId,
        );
        // @ts-ignore TS2554
        path = routes.simulcastDetail.makePath({ uniqueId: item.uniqueId, channelName: simulcast.name });
      }
    }

    return path;
  }

  onClickPlayButton(e) {
    e.preventDefault();
    e.stopPropagation();
    // @ts-ignore TS2339
    if (this.props.onClickPlayButton) {
      // @ts-ignore TS2339
      this.props.onClickPlayButton(this.item);
    }
    const path = this.getPlayButtonPath(this.item);
    if (path) this.context.history.push(path);
  }

  onClickDescriptionButton(e) {
    e.preventDefault();
    e.stopPropagation();
    // @ts-ignore TS2339
    if (this.props.onClickDescriptionButton) {
      // @ts-ignore TS2339
      this.props.onClickDescriptionButton(this.item);
    }
    // イベントが重複しないようにインスタンス変数でdefaultPreventedを持つ
    // @ts-ignore TS2339
    this.defaultPrevented = true;
    // @ts-ignore TS2339
    const dom = ReactDOM.findDOMNode(this.titleLinkRef.current);
    // @ts-ignore TS2339
    if (dom) dom.click();
  }

  onClickThumbnail(e) {
    // @ts-ignore TS2339
    if (this.defaultPrevented) return;
    // @ts-ignore TS2339
    if (this.props.onClickThumbnail) {
      // @ts-ignore TS2339
      this.props.onClickThumbnail(this.item);
    }
  }

  render() {
    let linkParams;
    let billboardUrl = '';
    if (!_.isEmpty(this.item)) {
      linkParams = { id: this.item.id };
      if (this.item.thumbnailUrl) {
        billboardUrl = this.item.thumbnailUrl;
        billboardUrl = billboardUrl.replace('[width]', '1400').replace('[height]', '800');
      }
    }

    let linkProps = {
      to: this.item.type === 'media' || this.item.type === 'linear_channel' ? routes.watchNow : routes.title,
      params: linkParams,
    };
    if (this.item.refId) {
      linkProps = {
        to: this.item.type === 'media' ? routes.content : routes.program,
        params: { id: this.item.refId },
      };
      if (this.item.type === 'linear_channel') {
        const simulcast = _.find(this.context.getModelData('simulcast'), item => item.refId == this.item.refId);
        linkProps = {
          to: routes.simulcast,
          params: { channelName: simulcast.name },
        };
      }
    }
    if (this.item.uniqueId) {
      linkProps = {
        to: routes.watchNow,
        params: { id: this.item.linearChannelMeta.metaId },
      };
    }

    let seriesTitle =
      this.item.seriesMeta && this.item.seriesMeta.shortName
        ? this.item.seriesMeta.shortName
        : this.item.seriesMeta && this.item.seriesMeta.name
        ? this.item.seriesMeta.name
        : null;
    let seasonTitle =
      this.item.seasonMeta && this.item.seasonMeta.shortName
        ? this.item.seasonMeta.shortName
        : this.item.seasonMeta && this.item.seasonMeta.name
        ? this.item.seasonMeta.name
        : null;
    let title = this.item.shortName || this.item.name;
    let description = '';
    let deliveryStartDate = null;

    // 広告
    if (_.has(this.item, 'advertisingId')) {
      //上書き => パレット側で上書きされている || creativeにurlがある
      billboardUrl = this.item.thumbnailUrl || _.get(this.item, 'creatives[0].url');
      seasonTitle = null;
      title = this.item.shortName || this.item.name;
      linkProps = {
        // @ts-ignore TS2322
        href: this.item.url,
      };
      // シリーズ
      if (this.item.schemaId === 1) {
        const seriesMeta = this.item.seriesMeta;
        const episodeMeta = this.item.episodeMeta;
        // 遷移先
        description = this.item.header
          ? this.item.header
          : this.item.cardInfo && this.item.cardInfo.description
          ? this.item.cardInfo.description
          : null;
        switch (this.item.pageTransitionType) {
          case 'series':
            if (seriesMeta) {
              if (!billboardUrl) billboardUrl = seriesMeta.thumbnailUrl;
              linkProps = {
                to: routes.title,
                params: { id: seriesMeta.metaId },
              };
              if (seriesMeta.refId) {
                linkProps = {
                  to: routes.program,
                  params: { id: seriesMeta.refId },
                };
              }
            }
            break;
          case 'selected_episode':
            if (episodeMeta) {
              if (!billboardUrl) billboardUrl = episodeMeta.thumbnailUrl;
              linkProps = {
                to: routes.watchNow,
                params: { id: episodeMeta.metaId },
              };
              if (episodeMeta.refId) {
                linkProps = {
                  to: routes.content,
                  params: { id: episodeMeta.refId },
                };
              }
            }
            break;
          case 'latest_episode':
            const edgeEpisode = seriesMeta.edgeEpisode;
            if (edgeEpisode) {
              if (!billboardUrl) billboardUrl = edgeEpisode.thumbnailUrl;
              linkProps = {
                to: routes.watchNow,
                params: { id: edgeEpisode.metaId },
              };
              if (edgeEpisode.refId) {
                linkProps = {
                  to: routes.content,
                  params: { id: edgeEpisode.refId },
                };
              }
            }
            break;
          case 'lead_episode':
            const leadEpisode = seriesMeta.leadEpisode;
            if (leadEpisode) {
              if (!billboardUrl) billboardUrl = leadEpisode.thumbnailUrl;
              linkProps = {
                to: routes.watchNow,
                params: { id: leadEpisode.metaId },
              };
              if (leadEpisode.refId) {
                linkProps = {
                  to: routes.content,
                  params: { id: leadEpisode.refId },
                };
              }
            }
            break;
          default:
            // @ts-ignore TS2322
            linkProps = { href: this.item.url };
            break;
        }
      }
      // ライブ
      else if (this.item.schemaId === 2 && this.item.liveMeta) {
        if (this.item.deliveryStartDate) {
          // @ts-ignore TS2322
          deliveryStartDate = <SetDate date={this.item.deliveryStartDate} format={'short'} txt={'〜'} />;
        }
        if (!billboardUrl) billboardUrl = this.item.liveMeta.thumbnailUrl;
        linkProps = {
          to: routes.watchNow,
          params: { id: this.item.liveMeta.metaId },
        };
        if (this.item.liveMeta.refId) {
          linkProps = {
            to: routes.content,
            params: { id: this.item.liveMeta.refId },
          };
        }
      }
      // リニアチャンネル
      else if (this.item.schemaId === 3 && this.item.linearChannelMeta) {
        description = this.item.header
          ? this.item.header
          : this.item.cardInfo && this.item.cardInfo.description
          ? this.item.cardInfo.description
          : null;

        if (this.item.deliveryStartDate) {
          // @ts-ignore TS2322
          deliveryStartDate = <SetDate date={this.item.deliveryStartDate} format={'short'} txt={'〜'} />;
        }
        if (!billboardUrl) billboardUrl = this.item.linearChannelMeta.thumbnailUrl;
        linkProps = {
          to: routes.watchNow,
          params: { id: this.item.linearChannelMeta.metaId },
        };
        if (this.item.linearChannelMeta.refId) {
          const simulcast = _.find(
            this.context.getModelData('simulcast'),
            item => item.refId == this.item.linearChannelMeta.refId,
          );
          linkProps = {
            to: routes.simulcast,
            params: { channelName: simulcast.name },
          };
        }
      }
      // パレット
      else if (this.item.schemaId == 4) {
        description = this.item.header
          ? this.item.header
          : this.item.cardInfo && this.item.cardInfo.description
          ? this.item.cardInfo.description
          : null;
        // @ts-ignore TS2322
        linkProps = { href: this.item.url };
      }
    } else {
      // シリーズ
      if (this.item.schemaId === 1) {
        description = this.item.header
          ? this.item.header
          : this.item.cardInfo && this.item.cardInfo.description
          ? this.item.cardInfo.description
          : null;
      }
      // シーズン
      else if (this.item.schemaId === 2) {
        description = this.item.header
          ? this.item.header
          : this.item.cardInfo && this.item.cardInfo.description
          ? this.item.cardInfo.description
          : null;
      }
      // エピソード
      else if (this.item.schemaId === 3) {
        description = this.item.header
          ? this.item.header
          : this.item.cardInfo && this.item.cardInfo.description
          ? this.item.cardInfo.description
          : null;
      }
      // ライブ
      else if (this.item.schemaId === 4) {
        if (this.item.deliveryStartAt) {
          // @ts-ignore TS2322
          deliveryStartDate = <SetDate date={this.item.deliveryStartAt} format={'short'} txt={'〜'} />;
        }
        description = this.item.header
          ? this.item.header
          : this.item.cardInfo && this.item.cardInfo.description
          ? this.item.cardInfo.description
          : null;
      }
      //リニアチャンネル
      else if (!!this.item.uniqueId) {
        let channelName;
        const simulcast = _.find(
          this.context.getModelData('simulcast'),
          item => item.refId == this.item.linearChannelMeta.refId,
        );
        if (this.item.linearChannelMeta && this.item.linearChannelMeta.name) {
          if (simulcast) channelName = _.lowerCase(simulcast.name);
          seasonTitle = <img src={`../images/logo_${channelName}_white.svg`} className="channel-logo" />;
        }
        if (this.item.startAt) {
          // @ts-ignore TS2322
          deliveryStartDate = <SetDate date={this.item.startAt} format={'short'} txt={'〜'} />;
        }
        description = this.item.header ? this.item.header : this.item.shortMessage || this.item.description;

        linkProps = {
          to: routes.simulcastDetail,
          params: { channelName: simulcast.name, uniqueId: this.item.uniqueId },
        };
      }
    }
    let seriesTitleEl;
    if (seasonTitle && seriesTitle && seriesTitle != seasonTitle) {
      seriesTitleEl = <div className="series-title">{seriesTitle}</div>;
    } else if (title && seriesTitle && seriesTitle != title) {
      seriesTitleEl = <div className="series-title">{seriesTitle}</div>;
    }

    //ボタンの表示調整
    let playBtn = null,
      myListBtn = null,
      moreBtn = null;
    if (this.getShowPlayButton(this.item)) {
      playBtn = (
        <div className="btn btn-fill white-color btn-very-small play-button" onClick={this.onClickPlayButton}>
          <i className="fa-play"></i>再生する
        </div>
      );
    }
    if (this.getShowFavoriteButton(this.item)) {
      myListBtn = (
        <MyListButton
          item={this.item}
          // @ts-ignore TS2339
          model={this.props.model}
          btnType={'short'}
          btnStyle={true}
          btnColorGray={true}
          // @ts-ignore TS2322
          classes={'btn-very-small btn-fill'}
          id={this.item.id}
          billboard={true}
        />
      );
    }
    if (this.getShowDescriptionButton(this.item)) {
      moreBtn = (
        <div className="btn btn-fill grey-color btn-very-small more-button" onClick={this.onClickDescriptionButton}>
          詳細<i className="fa-angle_right"></i>
        </div>
      );
    }

    const btnContent =
      playBtn || myListBtn || moreBtn ? (
        <React.Fragment>
          {playBtn}
          {myListBtn}
          {moreBtn}
        </React.Fragment>
      ) : null;

    const displaySpTitle = _.get(this.item, 'cardInfo.displaySpTitle', false);
    // api側のレスポンス値が文字列になっているので、＝＝＝で比較
    const isDisplaySpTitle = displaySpTitle === true || displaySpTitle === 'true';

    return (
      <div className="billboard-card">
        {this.item ? (
          <React.Fragment>
            {/*
             // @ts-ignore TS2339 */}
            {this.props.spMode && btnContent ? <div className="billboard-card-btn">{btnContent}</div> : null}
            {/*
             // @ts-ignore TS2339 */}
            {!this.props.spMode ? (
              <div
                className="billboard-card-box"
                onClick={this.onClickOther}
                onPointerOver={this.onPointerOver}
                onPointerOut={this.onPointerOut}
              >
                {seriesTitleEl}
                {seasonTitle || title ? (
                  <h3 className="title">
                    {seasonTitle != title ? seasonTitle : null}
                    {seasonTitle && title && seasonTitle != title ? ' ' : null}
                    {title}
                  </h3>
                ) : null}
                {/*
                // @ts-ignore TS2339 */}
                {<MetaTags metadata={this.item} hiddenGenre={true} enddayBadge={true} />}
                {deliveryStartDate ? <div className="delivery-date">{deliveryStartDate}</div> : null}
                <div className="description">{description ? <p>{description}</p> : null}</div>
                {/*
                // @ts-ignore TS2339 */}
                {btnContent ? <div className="billboard-card-box-btn">{btnContent}</div> : null}
              </div>
            ) : null}
          </React.Fragment>
        ) : null}
        <div className="billboard-card-link" onClick={this.onClickThumbnail}>
          {/*
           // @ts-ignore TS2339 */}
          <MainViewLink ref={this.titleLinkRef} className="billboard-link" {...linkProps} tabIndex={-1}>
            <div className="video-preload-title">
              {title ? <div className="video-preload-title-label">{title}</div> : null}
            </div>
            <BackgroundImage
              // @ts-ignore TS2322
              className={classnames('artwork billboard-card-thumbnail', { noImage: !billboardUrl })}
              url={billboardUrl}
              useLazyload={false}
            />
            {/*
              // @ts-ignore TS2322 */}
            {this.props.spMode && isDisplaySpTitle ? (
              <React.Fragment>
                {/* @ts-ignore TS2322 */}
                <MetaTags metadata={this.item} hiddenGenre={true} enddayBadge={true} isOnArt={true} />
                <h3 className="title">{this.item.name}</h3>
              </React.Fragment>
            ) : null}
          </MainViewLink>
          {/*
           // @ts-ignore TS2339 */}
          {this.props.itemTabbable && this.props.showOap && (
            <OAPPlayer
              // @ts-ignore TS2322
              oap={this.item.oap}
              // @ts-ignore TS2339
              muted={this.props.muted}
              onClickPlay={this.onClickPlay}
              onPlayerEnded={this.startSlider}
              onPlayerVolumeChange={this.onPlayerVolumeChange}
              onPlayerTimeUpdate={this.onPlayerTimeUpdate}
              onPlayerReady={this.onPlayerReady}
              onPlayerError={this.onPlayerError}
              autoplay={this.props.autoplay}
            />
          )}
        </div>
      </div>
    );
  }
}
