import { useMemo } from 'react';

// 「もっと見る」の文字数 6 + よしなに改行されることがあるので 1 = 7
const SEE_MORE_OFFSET = 7;
const useOmitDescription = ({ description, descriptionBoxWidth, fontSize }) => {
  const omitDescription = useMemo(() => {
    let _omitDescription = description;
    if (!_omitDescription || !descriptionBoxWidth || !fontSize) {
      return null;
    }
    //フォントサイズとボックスの幅を数値で取得
    const wordCount = Math.floor(descriptionBoxWidth / fontSize) * 2 - SEE_MORE_OFFSET;
    //２行分のボックスに収納できる文字数
    //もしテキストの文字数がオーバーしたらテキストの取得/置き換え
    if (_omitDescription.length >= wordCount) {
      _omitDescription = _omitDescription.substring(0, wordCount) + '…';
    }
    return _omitDescription;
  }, [description, descriptionBoxWidth, fontSize]);

  return omitDescription;
};

export default useOmitDescription;
