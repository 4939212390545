import Route from './Route';

export { default as Link } from './Link';
export { default as match } from './match';
export { default as resolveElement } from './resolveElement';
export { default as provideRoutingContext } from './provideRoutingContext';
export { default as parseUrl } from './parseUrl';

export const createRoute = function(opts) {
  return Route.create(opts);
};

export const createRoutes = function(routes) {
  return routes.map(route => Route.create(route));
};
