import React from 'react';
import _ from 'src/domain/libs/util';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import MainViewLink from '../../../common/components/MainViewLink';
import routes from '../../../common/routes';
import datetime from 'src/domain/libs/datetime';
import { QUERY as WATCH_QUERY } from './Watch';
import { TennisMatch, TennisCourt } from '@wowow/types/api/exclusive-ui';

type TennisCardProps = {
  rootRef: any;
  itemData: TennisCardItemData;
  height?: number;
  onClick: (item) => void;
  key: string;
};

type TennisCardItemData = {
  type: 'matches' | 'courts';
  title: string;
  description: string;
  items: TennisCardMatch[] | TennisCourt[];
};

type TennisCardMatch = TennisMatch & {
  matchType?: string;
};

type TennisCardLinkProps = {
  to?: any;
  params?: {
    id?: string;
  };
};

const STATUS_LABELS = {
  1: '中止',
  2: '中断',
  3: 'ライブ前',
  4: 'ライブ中',
  5: '終了',
  6: '未定',
};

const TennisCard = (props: TennisCardProps, context) => {
  const itemData = props.itemData;
  const browserInfo = context.getModelData('browserInfo');
  const getOnClick = (item: TennisCardMatch | TennisCourt = {}) => {
    return e => {
      e.preventDefault();
      e.stopPropagation();
      if (!item.meta) return;
      if (props.onClick) props.onClick(item.meta);
      const q = {};
      if (itemData.type === 'matches') {
        const matchesItem = item as TennisCardMatch;
        const startAt = matchesItem.startAt ? datetime(matchesItem.startAt, 'YYYY/MM/DD HH:mm:ss') : null;
        const endAt = matchesItem.endAt ? datetime(matchesItem.endAt, 'YYYY/MM/DD HH:mm:ss') : null;
        // 試合開始前、試合開始時間、diffの記載なし
        if (!startAt || matchesItem.playStartDiff == null || startAt.isAfterNow()) {
          q[WATCH_QUERY.LIVE_EDGE] = true;
          // 試合中
        } else if (startAt.isSameOrBeforeNow() && (!endAt || endAt.isAfterNow())) {
          q[WATCH_QUERY.MATCH_START_TIME] = matchesItem.playStartDiff;
          // 試合終了
        } else {
          q[WATCH_QUERY.TIME] = matchesItem.playStartDiff;
        }
      }
      context.history.push(routes.content.makePath({ id: item.meta.ref_id }, q, null));
    };
  };

  let description;
  let isBeforeMatchEnded = true;
  const row = _.map(itemData.items, (sctionsItem, idx) => {
    // 試合別で表示
    if (itemData.type === 'matches') {
      const item = sctionsItem as TennisCardMatch;
      if (!item.matchType) return null;
      const top = [];
      const bottom = [];
      const stage = item.stage ? item.stage : null;
      _.forEach(item.card, (player, idx) => {
        let el;
        let country = _.get(player, 'country') ? <span>({player.country})</span> : null;

        if (_.get(player, 'name')) {
          el = (
            <span className="name">
              {player.name}
              {country}
            </span>
          );
        }

        if (item.matchType === 'singles') {
          if (idx === 0) {
            top.push(el);
          } else {
            bottom.push(el);
          }
        } else {
          if (idx < 2) {
            top.push(el);
          } else {
            bottom.push(el);
          }
        }
      });
      let status = 6;
      const startAt = item.startAt ? datetime(item.startAt, 'YYYY/MM/DD HH:mm:ss') : null;
      const endAt = item.endAt ? datetime(item.endAt, 'YYYY/MM/DD HH:mm:ss') : null;
      if (item.calledOffFlag) {
        status = 1;
      } else if (item.suspendedFlag) {
        status = 2;
      } else if (startAt && startAt.isAfterNow()) {
        status = 3;
      } else if ((endAt && endAt.isAfterNow()) || (startAt && !endAt && startAt.isBeforeNow())) {
        status = 4;
      } else if (endAt && endAt.isBeforeNow()) {
        status = 5;
      }
      let statusLabel = STATUS_LABELS[status];
      if (item.liveStartConditionText) statusLabel = item.liveStartConditionText;
      if (status === 3) {
        statusLabel = startAt.format('HH:mm');
        // 前試合が終了していない場合は、開始時間変動の可能性があるので*をつけておく
        if (!isBeforeMatchEnded) statusLabel = `${statusLabel} 予定`;
      }
      const active = status === 4;
      isBeforeMatchEnded = [1, 2, 5].includes(status);

      const content = (
        <React.Fragment>
          <div className="tennis-card__inner__match__order">
            <span>{stage}</span>
            {!!statusLabel && <span className="status">{statusLabel}</span>}
          </div>
          <div className="tennis-card__inner__match__person">
            <div
              className={classnames('tennis-card__inner__match__person__name top', {
                doubles: item.matchType === 'doubles',
              })}
            >
              {top}
            </div>
            {/* <span className="tennis-card__inner__match__person__vs">vs</span> */}
            <div
              className={classnames('tennis-card__inner__match__person__name bottom', {
                doubles: item.matchType === 'doubles',
              })}
            >
              {bottom}
            </div>
          </div>
        </React.Fragment>
      );
      if (browserInfo.isIOS || browserInfo.isAndroid) {
        const linkProps: TennisCardLinkProps = {};
        if (_.get(item, 'meta.ref_id')) {
          linkProps.to = routes.content;
          linkProps.params = { id: item.meta.ref_id };
        }
        return (
          <MainViewLink
            className={classnames('tennis-card__inner__match', { active, clickable: !!item.meta })}
            {...linkProps}
            onClick={getOnClick(item)}
          >
            {content}
          </MainViewLink>
        );
      } else {
        return (
          <div
            onClick={getOnClick(item)}
            className={classnames('tennis-card__inner__match', { active, clickable: !!item.meta })}
          >
            {content}
          </div>
        );
      }
      // コートごとに表示
    } else if (itemData.type === 'courts') {
      const item = sctionsItem as TennisCourt;
      if (!item || !item.name) return null;
      const content = (
        <React.Fragment>
          <div className="tennis-card__inner__court__name">{item.name}</div>
          {!!item.description && <div className="tennis-card__inner__court__description">{item.description} </div>}
        </React.Fragment>
      );
      if (browserInfo.isIOS || browserInfo.isAndroid) {
        const linkProps: TennisCardLinkProps = {};
        if (_.get(item, 'meta.ref_id')) {
          linkProps.to = routes.content;
          linkProps.params = { id: item.meta.ref_id };
        }
        return (
          <MainViewLink
            onClick={getOnClick(item)}
            className={classnames('tennis-card__inner__court', { clickable: !!item.meta })}
            {...linkProps}
          >
            {content}
          </MainViewLink>
        );
      } else {
        return (
          <div
            onClick={getOnClick(item)}
            className={classnames('tennis-card__inner__court', { clickable: !!item.meta })}
          >
            {content}
          </div>
        );
      }
    }
  }).filter(card => !!card);

  if (itemData.description) {
    description = itemData.description;
  }
  const style: { height?: number } = {};
  if (props.height) {
    style.height = props.height;
  }
  return (
    <div className="card title-card tennis-card" style={style} ref={props.rootRef}>
      <div className="tennis-card__title">
        {itemData.title}
        {description ? <div className="tennis-card__title-description">{description}</div> : null}
      </div>
      <div className="tennis-card__inner">{row}</div>
    </div>
  );
};

TennisCard.getPathPropKey = function() {
  return ['sections', 'genre'];
};
TennisCard.contextTypes = {
  history: PropTypes.object,
  getModelData: PropTypes.func,
};
export default TennisCard;
