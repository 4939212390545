import React from 'react';
import PropTypes from 'prop-types';
import SortRow from './SortRow';

const SelectPeriodRow = props => {
  const sortOrders = [
    { so: 'daily', name: '前日' },
    { so: 'weekly', name: '週間' },
    { so: 'monthly', name: '月間' },
  ];
  const sortRowProps = {
    sortOrders: sortOrders,
    sortOrder: props.selectPeriod,
    onSortOrderChange: props.onSelectPeriodChange,
    hideSortLabel: true,
  };
  return <SortRow {...sortRowProps} />;
};

SelectPeriodRow.defaultProps = {
  selectPeriod: PropTypes.oneOf(['daily', 'weekly', 'monthly']).isRequired,
  onSelectPeriodChange: PropTypes.func.isRequired,
};

export default SelectPeriodRow;
