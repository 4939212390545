import React, { Component } from 'react';
import GalleryContent from './GalleryContent';

export default class PopularTitles extends Component {
  render() {
    return (
      <GalleryContent
        header={
          <div className="gallery-header">
            <div className="title">
              <span className="gallery-title">人気の作品</span>
            </div>
          </div>
        }
        // @ts-ignore TS2339
        model={this.props.pathEvaluator || this.props.model.pathEvaluator}
        keyPrefix={'populartitles'}
      />
    );
  }
}

// @ts-ignore TS2339
PopularTitles.getPrefetchPaths = function(models, options, props) {
  return GalleryContent.getPaths(
    models,
    options,
    Object.assign({}, GalleryContent.defaultProps, props, { keyPrefix: 'populartitles' }),
  );
};
