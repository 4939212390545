import React from 'react';
import { wrapDisplayName } from '../utils/displayName';

// @ts-ignore TS2554
const DrawerContext = React.createContext();
export default DrawerContext;
export const withDrawerContext = Component => {
  const withDrawerContext = React.forwardRef((props, ref) => {
    return (
      <DrawerContext.Consumer>
        {/*
         // @ts-ignore TS2698 */}
        {context => React.createElement(Component, { ref, ...props, ...context })}
      </DrawerContext.Consumer>
    );
  });
  // @ts-ignore TS2554
  withDrawerContext.displayName = wrapDisplayName(Component, 'withSideMenuContext');
  if (typeof Component.getPaths === 'function') {
    // @ts-ignore TS2339
    withDrawerContext.getPaths = Component.getPaths.bind(Component);
  }
  if (typeof Component.getPrefetchPaths === 'function') {
    // @ts-ignore TS2339
    withDrawerContext.getPrefetchPaths = Component.getPrefetchPaths.bind(Component);
  }
  if (typeof Component.getPrefetchedPaths === 'function') {
    // @ts-ignore TS2339
    withDrawerContext.getPrefetchedPaths = Component.getPrefetchedPaths.bind(Component);
  }
  if (typeof Component.afterPrefetch === 'function') {
    // @ts-ignore TS2339
    withDrawerContext.afterPrefetch = Component.afterPrefetch.bind(Component);
  }
  if (typeof Component.getCanvasId === 'function') {
    // @ts-ignore TS2339
    withDrawerContext.getCanvasId = Component.getCanvasId.bind(Component);
  }
  return withDrawerContext;
};
