import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const LoadingBox = (props) => {
  const pulsate = props.pulsate;
  const displayWhenNotPulsing = props.displayWhenNotPulsing;
  const boxClassNames = props.boxClassNames;
  const delay = props.delay + 's';
  const style = pulsate ? {
    WebkitAnimationDelay:delay,
    AnimationDelay:delay
  } : {};

  boxClassNames["pulsate"] = pulsate;
  boxClassNames["pulsate-transparent"] = pulsate && !displayWhenNotPulsing;
  boxClassNames["no-pulsate"] = !pulsate && displayWhenNotPulsing;

  return React.createElement("div",{
    className:classnames(boxClassNames),
    style
  });
}

LoadingBox.propTypes = {
  boxClassName: PropTypes.object,
  delay: PropTypes.number,
  displayWhenNotPulsing: PropTypes.bool,
  pulsate: PropTypes.bool,
};

LoadingBox.defaultProps = {
  boxClassNames: {},
  delay: 0,
  displayWhenNotPulsing: true,
  pulsate: false,
};

export default LoadingBox
