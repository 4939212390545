import React from 'react';
import PropTypes from 'prop-types';
import LoadingBox from './LoadingBox';

const LoadingTitle = (props) => {
  let loadingBox = React.createElement(LoadingBox, Object.assign({boxClassNames: {'ratio-16x9': true}}, props));
  if (props.lockupClassName) {
    // @ts-ignore TS2322
    loadingBox = React.createElement('div', {className: props.lockupClassName}, loadingBox)
  }

  return React.createElement('div', {
    className: "card small-title-card loading-card",
  }, loadingBox);
}

LoadingTitle.propTypes = {
  lockupClassName: PropTypes.string,
  delay: PropTypes.number,
  displayWhenNotPulsing: PropTypes.bool,
  pulsate: PropTypes.bool,
};

LoadingTitle.defaultProps = {
  className: '',
  delay: 0,
  displayWhenNotPulsing: false,
  pulsate: false,
};

export default LoadingTitle
