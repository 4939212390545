import datetime from 'src/domain/libs/datetime';

export const thumbnailPublished = function(thumbnail_start_at, thumbnail_end_at) {
  const now = datetime();
  const s = datetime(new Date(thumbnail_start_at));
  const e = datetime(new Date(thumbnail_end_at));
  if (thumbnail_start_at && thumbnail_end_at) {
  } else if (thumbnail_start_at && !thumbnail_end_at) {
    return now.isBetween(s, e, null, '[)');
  } else if (thumbnail_start_at && !thumbnail_end_at) {
    return now.isSameOrAfter(s);
  } else if (!thumbnail_start_at && thumbnail_end_at) {
    return now.isBefore(e);
  }
  return true;
};

export const isCatchup = function(catchupMeta) {
  if (catchupMeta) {
    const now = datetime();
    const s = datetime(new Date(catchupMeta.deliveryStartAt));
    const e = datetime(new Date(catchupMeta.deliveryEndAt));
    if (catchupMeta.deliveryStartAt && catchupMeta.deliveryEndAt) {
      return now.isBetween(s, e, null, '[)');
    } else if (catchupMeta.deliveryStartAt && !catchupMeta.deliveryEndAt) {
      return now.isSameOrAfter(s);
    } else if (!catchupMeta.deliveryStartAt && catchupMeta.deliveryEndAt) {
      return now.isBefore(e);
    }
  }
  return false;
};

export const isOnAir = function(event) {
  if (!event) return false;
  const now = datetime();
  const startAt = event.start_at || event.startAt;
  const endAt = event.end_at || event.endAt;
  const s = datetime(new Date(startAt));
  const e = datetime(new Date(endAt));
  return now.isBetween(s, e, null, '[)');
};
