'use strict';

const sepDigit = function(value?: string) {
  if (value == null) {
    return null;
  }
  return String(parseInt(value)).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,');
};

export default sepDigit;
