import React, { Component } from 'react';
import classnames from 'classnames';

const SimpleNotification = props => {
  const icons = {
    success: 'fa-check-circle',
    warning: 'fa-exclamation-triangle',
    error: 'fa-exclamation-triangle',
    info: 'fa-exclamation-circle',
  };
  return (
    <div className={classnames('simple-notification', props.type, { animated: props.animated })}>
      <p title={props.title}>
        <span className={classnames('fa', icons[props.type])}>
          <span className="simple-notification-message">{props.message}</span>
        </span>
      </p>
    </div>
  );
};

export default SimpleNotification;
