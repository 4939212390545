import React, { Component } from 'react';
import PropTypes from 'prop-types';

import _ from 'src/domain/libs/util';
import AdvertisingCard from 'src/apps/bluerose/components/browse/AdvertisingCard';
import TitleCard from 'src/apps/bluerose/components/browse/TitleCard';
import EventCard from 'src/apps/bluerose/components/browse/Epg/EventCard';
import LoadingTitle from 'src/apps/bluerose/components/loader/LoadingTitle';
import Row from 'src/apps/bluerose/components/browse/Row';
import { CardContextProvider } from 'src/apps/common/context/CardContext';

export default class FeatureList extends Component {
  static get propTypes() {
    return {
      model: PropTypes.object.isRequired,
    };
  }

  static get contextTypes() {
    return {
      routeHandler: PropTypes.object,
      routeHandlers: PropTypes.object,
      models: PropTypes.object,
      history: PropTypes.object,
      getModelData: PropTypes.func,
    };
  }
  static get childContextTypes() {
    return {
      titleType: PropTypes.string,
      artType: PropTypes.string,
      cardSize: PropTypes.string,
    };
  }
  getChildContext() {
    // @ts-ignore TS2339
    if (this.props.palette) {
      return {
        // @ts-ignore TS2339
        artType: this.props.palette.artType || 'default',
        // @ts-ignore TS2339
        cardSize: this.props.palette.cardSize || 'medium',
        // @ts-ignore TS2339
        titleType: this.props.palette.titleType || 'name',
      };
    } else {
      return {};
    }
  }

  constructor(props, context) {
    super(props, context);
    this.state = {};
    // @ts-ignore TS2339
    this._isMounted = false;
  }

  componentDidMount() {
    // @ts-ignore TS2339
    this._isMounted = true;
  }

  componentWillUnmount() {
    // @ts-ignore TS2339
    this._isMounted = false;
  }
  handleSliderMove(e) {
    // @ts-ignore TS2339
    if (this.props.onSliderMove) {
      // @ts-ignore TS2339
      this.props.onSliderMove(e);
    }
  }
  render() {
    // @ts-ignore TS2339
    if (!this.props.items || this.props.items.length === 0) {
      return null;
    }
    // @ts-ignore TS2339
    const items = this.props.items;
    // @ts-ignore TS2339
    const palette = this.props.palette;

    const titles = _.compact(
      items.map((item, index) => {
        if (!_.isEmpty(item.creatives)) {
          return (
            <AdvertisingCard
              // @ts-ignore TS2322
              popType="none"
              // @ts-ignore TS2339
              model={this.props.model}
              rankNum={index}
              rowNum={1}
              advertisngId={item.id}
              key={`feature-card-${index}`}
              itemData={item}
              titleDisplaySetting={palette.cardTitleDisplaySetting}
              titleHidden={palette.titleType === 'hidden' ? true : false}
            />
          );
        } else if (item.uniqueId) {
          return (
            <EventCard
              // @ts-ignore TS2322
              popType="none"
              // @ts-ignore TS2339
              model={this.props.model}
              key={`feature-card-${index}`}
              rankNum={index}
              rowNum={1}
              eventId={item.uniqueId}
              // @ts-ignore TS2339
              channelNameHidden={this.props.channelNameHidden}
              itemData={item}
              titleDisplaySetting="default"
            />
          );
        } else {
          if (!item.id) return;
          return (
            <TitleCard
              // @ts-ignore TS2322
              popType="none"
              // @ts-ignore TS2339
              model={this.props.model}
              key={`feature-card-${index}`}
              rankNum={index}
              rowNum={1}
              titleId={item.id}
              itemData={item}
              titleDisplaySetting="default"
            />
          );
        }
      }),
    );
    let title;
    // @ts-ignore TS2339
    if (this.props.palette && this.props.palette.title) {
      // @ts-ignore TS2339
      title = this.props.palette.title;
    }

    return (
      <React.Fragment>
        <div className="feature-menu-cont__title">{title}</div>
        <div className={'canvas-row'} data-list-context={'relateds'}>
          <CardContextProvider value={Object.assign(this.context, this.getChildContext())}>
            <Row
              totalItems={items.length}
              // @ts-ignore TS2339
              rowNum={this.props.rowNum}
              handleSliderMove={this.handleSliderMove.bind(this)}
              // @ts-ignore TS2322
              listContext={'relateds'}
              loadingComponent={<LoadingTitle />}
              enablePaginationIndicator={false}
              enablePeek={true}
              enablePushOut={false}
            >
              {titles}
            </Row>
          </CardContextProvider>
        </div>
      </React.Fragment>
    );
  }
}
