import blueroseContextTypes from '../../utils/blueroseContextTypes';
import React from 'react';
import LoadingTitle from './LoadingTitle';

const LoadingGallery = (props, context) => {
  const columnsInRow = props.mbListLayout == 'box' ? 1 : !props.isPortrait ? 3 : context.columnsInRow || 6;
  const pulsateSet = {7:[0,1,2,4,2,4,3],6:[0,1,2,4,2,4,3],5:[0,1,6,1,6,1,1],4:[0,1,4,1,6,0,1],3:[0,1,4,1,6,0,1],2:[0,1,4,1,6,0,1],1:[0,1,4,1,6,0,1]};
  let boxChildren = [];
  const rowChildren=[];
  let pulsateIndex = 0;
  let pulsateTimming = 0;
  let rowIndex = 0;
  for (let m = 0; m < 24; m++) {
    var delay = 0;
    if (pulsateTimming++ === pulsateSet[columnsInRow][pulsateIndex]) {
      delay = 2*Math.random() + m % 6;
      pulsateIndex++;
      pulsateTimming = 0;
      if (pulsateIndex > 5) {
        pulsateIndex = 1;
      }
    }
    boxChildren.push(React.createElement(LoadingTitle, {
      delay,
      pulsate: !pulsateTimming,
      displayWhenNotPulsing: false,
      boxClassNames: {'ratio-16x9': true},
      key: `loading-box-${m}`
    }));
    if (m % columnsInRow == columnsInRow - 1) {
      rowChildren.push(React.createElement(
        "div", {
          className: 'row',
          'data-column-items': columnsInRow, 
          key:`loadingRow-${rowIndex}`
        }, 
          boxChildren));
      boxChildren = [];
      rowIndex++;
    }
  }

  return React.createElement("div", {key: "loading-boxes", className: "gallery-loader"}, rowChildren)
};

LoadingGallery.contextTypes = blueroseContextTypes;

export default LoadingGallery
