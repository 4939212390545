import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import assign from 'object-assign';
import _ from 'src/domain/libs/util';

import MainViewLink from '../../../common/components/MainViewLink';
import Slider from '@logiclogic/react-slider';
import PresTrackedContainer from '../../../common/components/PresTrackedContainer';
import * as browserEvents from '../../../../sketch-platform/utils/browserEvents';
import routes from '../../../common/routes';

import SortRow from './SortRow';
import TitleCard from './TitleCard';
import EpisodeCard from './EpisodeCard';
import LoadingRow from './../loader/LoadingRow';
import LoadingTitle from './../loader/LoadingTitle';
import MyListButton from './MyListButton';
import SpSlider from './SpSlider';
import BackgroundImage from '../../../common/components/BackgroundImage';
import canAccessBySlug from '../../utils/slug';
import SwitchGallery from './SwitchGallery';

// 未使用
class TitlePalette extends React.Component {
  static getPaths = function(models, options, props) {
    if (!props.id) {
      return [
        [
          [
            'id',
            'name',
            'shortName',
            'thumbnailUrl',
            'type',
            'defaultSort',
            'slug',
            'schemaId',
            'recommend',
            'cardInfo',
          ],
        ],
      ];
    }

    const indexes = function(models, options, props) {
      return {
        from: (props && props.fromNum) || 0,
        to:
          props && props.toNum
            ? props.toNum - (props.fromNum || 0)
            : options && options.numItems
            ? options.numItems
            : 11,
      };
    };

    const paths = [
      ['meta', props.id, ['id', 'name', 'shortName', 'thumbnailUrl', 'defaultSort', 'slug', 'schemaId', 'cardInfo']],
    ]
      .concat(
        // @ts-ignore TS2339
        EpisodeCard.getPaths().map(function(path) {
          return ['meta', props.id, 'children', props.sortOrder, indexes(models, options, props)].concat(path);
        }),
      )
      .concat([['meta', props.id, 'children', props.sortOrder, 'length']]);
    return paths;
  };

  static get propTypes() {
    return {
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    };
  }

  static get contextTypes() {
    return {
      columnsInRow: PropTypes.number,
      popType: PropTypes.string,
      isInitialRender: PropTypes.bool,
      isOverlayPage: PropTypes.bool,
      models: PropTypes.object,
      getModelData: PropTypes.func,
      routeHandler: PropTypes.object,
    };
  }

  static get childContextTypes() {
    return {
      isTallRow: PropTypes.bool,
      listContext: PropTypes.string,
    };
  }

  constructor(props, context) {
    super(props, context);

    // @ts-ignore TS2339
    this.isFirstSetOfResults = true;
    // @ts-ignore TS2339
    this.meta = props.model.getSync(`meta.${props.id}`);
    if (props.children) {
      // @ts-ignore TS2339
      this.items = props.children;
      // @ts-ignore TS2339
      this.totalItems = props.children.length;
    } else {
      // @ts-ignore TS2339
      this.items = [];
      // @ts-ignore TS2339
      this.totalItems = props.model.getSync(`meta.${props.id}.children.df.length`) || 0;
    }
    this.checkMediaQuery = _.throttle(this.checkMediaQuery.bind(this), 300, { leading: true, trailing: true });
    this.handleSortOrderChange = this.handleSortOrderChange.bind(this);
    this.handleMouseEnter = this.handleMouseEnter.bind(this);
    this.handleMouseLeave = this.handleMouseLeave.bind(this);
    this.handleMouseMove = this.handleMouseMove.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleRowFocus = this.handleRowFocus.bind(this);

    this.state = {
      colSpan: 1,
      dispose: null,
      fetchDataError: null,
      fullDataLoaded: false,
      generation: -1,
      key: '',
      prevGeneration: 1,
      prevKey: '',
      isDataLoaded: false,
    };

    // @ts-ignore TS2339
    this.state.sortOrder = this.meta.defaultSort || 'ena';
  }

  getChildContext() {
    return {
      // @ts-ignore TS2339
      listContext: this.props.listContext,
      // @ts-ignore TS2339
      isTallRow: this.props.listContext === 'originals',
    };
  }

  componentDidMount() {
    // @ts-ignore TS2339
    this._isMounted = true;
    const browserInfo = this.context.getModelData('browserInfo');
    if (browserInfo.isIOS || browserInfo.isAndroid) {
      browserEvents.addEventListener('orientationchange', this.checkMediaQuery);
    } else {
      browserEvents.addEventListener('resize', this.checkMediaQuery);
    }
    this.checkMediaQuery();
    this.fetchData(this.props);
  }

  componentWillUnmount() {
    // @ts-ignore TS2339
    this._isMounted = false;
    const browserInfo = this.context.getModelData('browserInfo');
    if (browserInfo.isIOS || browserInfo.isAndroid) {
      browserEvents.removeEventListener('orientationchange', this.checkMediaQuery);
    } else {
      browserEvents.removeEventListener('resize', this.checkMediaQuery);
    }
    // @ts-ignore TS2339
    if (this.state.dispose) this.state.dispose();
  }

  checkMediaQuery() {
    let colSpan = 1;
    if (window.matchMedia('screen and (min-width: 800px)').matches) {
      colSpan = 3;
    }
    // @ts-ignore TS2339
    if (colSpan !== this.state.colSpan) {
      this.setState({ colSpan });
    }
  }

  handleSortOrderChange(so) {
    // @ts-ignore TS2339
    if (so !== this.state.sortOrder) {
      // @ts-ignore TS2339
      this.isFirstSetOfResults = true;
      // @ts-ignore TS2339
      this.items = [];
      // @ts-ignore TS2339
      if (this.state.dispose) this.state.dispose();
      this.setState({ sortOrder: so, dispose: null, generation: -1, fetchDataError: null, fromNum: 0 }, () => {
        this.fetchData(this.props);
      });
    }
  }

  handleMouseEnter(e) {
    // @ts-ignore TS2339
    this.isMouse = true;
  }

  handleMouseLeave(e) {
    // @ts-ignore TS2339
    this.isMouse = false;
  }

  handleMouseMove(e) {
    // @ts-ignore TS2339
    this.isMouse = true;
    this.setState({ keyboardFocus: false });
  }

  handleRowFocus(event) {
    // @ts-ignore TS2339
    if (this.isMouse) return;
    if (event.target.classList.contains('sliderRefocus')) {
      this.setState({ keyboardFocus: true });
    }
  }

  handleKeyDown(event) {
    // Tab
    if (event.which === 9 && !event.shiftKey) {
      if (event.target.classList.contains('handleNext')) {
        event.target.tabIndex = -1;
        this.setState({ keyboardFocus: false });
      } else {
        const links = event.currentTarget.querySelectorAll('a[tabindex="0"]');
        const index = _.findIndex(links, link => {
          // @ts-ignore TS2339
          return link.href == event.target.href;
        });
        if (links.length == index + 1) {
          const arrow = event.currentTarget.querySelector('.handleNext');
          if (arrow) {
            arrow.tabIndex = 0;
          } else {
            this.setState({ keyboardFocus: false });
          }
        }
      }

      // Tab with Shift
    } else if (event.which === 9 && event.shiftKey) {
      if (event.target.classList.contains('handlePrev')) {
        event.target.tabIndex = -1;
        this.setState({ keyboardFocus: false });
      } else {
        const links = event.currentTarget.querySelectorAll('a[tabindex="0"]');
        const index = _.findIndex(links, link => {
          // @ts-ignore TS2339
          return link.href == event.target.href;
        });
        if (index === 0) {
          const arrow = event.currentTarget.querySelector('.handlePrev');
          if (arrow) {
            arrow.tabIndex = 0;
          } else {
            this.setState({ keyboardFocus: false });
          }
        }
      }
    }
  }

  render() {
    const browserInfo = this.context.getModelData('browserInfo');

    const titles = _.compact(
      // @ts-ignore TS2339
      this.items.map((item, index) => {
        if (!item.id) return;
        return (
          <EpisodeCard
            // @ts-ignore TS2322
            episodeId={item.id}
            popType={this.context.popType}
            // @ts-ignore TS2339
            model={this.props.model}
            // @ts-ignore TS2339
            key={`title_${item.id}_${this.props.rowNum}_${index}`}
            rankNum={index}
            // @ts-ignore TS2339
            rowNum={this.props.rowNum}
            showEpisodeDuration={false}
          />
        );
      }),
    );

    const sortRowProps = {
      // @ts-ignore TS2339
      model: this.props.model,
      // @ts-ignore TS2339
      sortOrder: this.state.sortOrder,
      onSortOrderChange: this.handleSortOrderChange,
    };

    // const episodesLinkProps = {
    //   to: routes.titleEpisodes,
    //   params: {id: this.meta.id}
    // }
    // if (titles.length > 1) {
    //   episodesLinkProps.query = {so: this.state.sortOrder}
    // }

    // @ts-ignore TS2339
    let headerLinkParams = { id: this.meta.id };
    // @ts-ignore TS2339
    if (canAccessBySlug(this.meta)) {
      // @ts-ignore TS2339
      headerLinkParams.id = this.meta.slug;
    }

    return (
      <div className="row" data-column-items={this.context.columnsInRow}>
        <div className="title-palette-header">
          <div className="title-palette-header-wrapper">
            {/*
             // @ts-ignore TS2339 */}
            {this.meta.id ? (
              <h2>
                <div className="row-title">
                  <MainViewLink
                    // @ts-ignore TS2322
                    to={
                      // @ts-ignore TS2339
                      this.meta.type === 'media' || this.meta.type === 'linear_channel' ? routes.watchNow : routes.title
                    }
                    params={headerLinkParams}
                    className="row-header-title"
                  >
                    {/*
                     // @ts-ignore TS2339 */}
                    <div className="row-header-title-inner">{this.meta.shortName}</div>
                  </MainViewLink>
                  {/* <MainViewLink to={(this.meta.type === 'media' || this.meta.type === 'linear_channel') ? routes.watchNow : routes.titleEpisodes} params={headerLinkParams} className="row-arrow">
                    <div className="see-more-link">すべて見る</div><div className="row-chevron icon-caret-right fa fa-angle_right"></div>
                  </MainViewLink> */}
                </div>
                {/*
                 // @ts-ignore TS2339 */}
                <div className="synopsis">{this.meta.cardInfo.synopsis}</div>
              </h2>
            ) : null}
          </div>
          {/*
           // @ts-ignore TS2339 */}
          {this.meta.type === 'media' || this.meta.type === 'linear_channel' ? null : (
            <div className="section-header">{titles.length > 1 ? <SwitchGallery {...sortRowProps} /> : null}</div>
          )}
        </div>
        <PresTrackedContainer>
          {/*
           // @ts-ignore TS2339 */}
          {this.isFirstSetOfResults && this.state.dispose ? (
            <LoadingRow />
          ) : // @ts-ignore TS2339
          this.items.length === 0 ? null : browserInfo.isTouchDevice ? (
            <SpSlider>{titles}</SpSlider>
          ) : (
            <div
              className={classnames('row-content', {
                'slider-hover-trigger-layer': this.context.columnsInRow !== 1,
                // @ts-ignore TS2339
                'keyboard-focus': this.state.keyboardFocus,
              })}
              onMouseEnter={this.handleMouseEnter}
              onMouseLeave={this.handleMouseLeave}
              onMouseMove={this.handleMouseMove}
              onFocus={this.handleRowFocus}
              onKeyDown={this.handleKeyDown}
            >
              <Slider
                autoAdjustWidth={false}
                itemsInRow={this.context.columnsInRow}
                // @ts-ignore TS2339
                totalItems={this.totalItems}
                onSliderMove={this.handleSliderMove.bind(this)}
                loadingComponent={<LoadingTitle />}
                arrowTabbable={false}
                initialSlide={0}
                enableLooping={false}
                enablePeek={true}
                // @ts-ignore TS2339
                enablePaginationIndicator={this.props.enablePaginationIndicator}
                enableSwipe={browserInfo.isIOS || browserInfo.isAndroid}
              >
                {titles}
              </Slider>
            </div>
          )}
        </PresTrackedContainer>
      </div>
    );
  }

  handleSliderMove() {
    this.fetchData(this.props);
  }

  fetchData(props, context = this.context) {
    if (props.children) return;

    // @ts-ignore TS2339
    const pathProps = Object.assign({}, props, { sortOrder: this.state.sortOrder });
    // @ts-ignore TS2339
    if (this.items) {
      // @ts-ignore TS2339
      pathProps.toNum = this.totalItems;
    }
    // @ts-ignore TS2339
    const paths = this.constructor.getPaths(context.models, {}, pathProps);

    // すでに通信している場合は実行しない
    if (this.state[JSON.stringify(paths)]) return;

    // @ts-ignore TS2339
    if (this.state.dispose) {
      // 過去のObservableを削除する、これによって通信が止まるわけではなく
      // Observableがなくなるのでイベントが発火されなくなる、というだけなので注意
      // @ts-ignore TS2339
      this.state.dispose();
    }

    this.state[JSON.stringify(paths)] = paths;

    const evaluator = props.model.fetch(paths);
    const dispose = evaluator.dispose;
    // @ts-ignore TS2339
    if (this._isUpdated === false || !this._isMounted) {
      Object.assign(this.state, { dispose });
    } else {
      this.setState({ dispose });
    }
    evaluator
      .then(res => {
        const newState = {
          fetchDataError: null,
          dispose: null,
          // @ts-ignore TS2339
          generation: this.state.generation + 1,
        };

        // @ts-ignore TS2339
        this.meta = _.get(res, `json.meta.${this.props.id}`);
        // @ts-ignore TS2339
        if (!this.state.sortOrder) newState.sortOrder = this.meta.defaultSort || 'ena';

        // @ts-ignore TS2339
        this.items = _.compact(
          _.values(
            // @ts-ignore TS2339
            _.omit(_.get(res, `json.meta.${this.props.id}.children.${this.state.sortOrder}`), ['$__path', 'length']),
          ),
        );
        // @ts-ignore TS2339
        this.totalItems = _.get(res, `json.meta.${this.props.id}.children.${this.state.sortOrder}.length`, 0);

        delete this.state[JSON.stringify(paths)];
        // @ts-ignore TS2339
        this.isFirstSetOfResults = false;

        // @ts-ignore TS2339
        if (this._isMounted) this.setState(newState);
        else Object.assign(this.state, newState);
      })
      .catch(e => {
        const newState = {
          fetchDataError: e,
          fetchingMoreRows: undefined,
          dispose: null,
        };
        delete this.state[JSON.stringify(paths)];
        // @ts-ignore TS2339
        if (this._isMounted) this.setState(newState);
        else Object.assign(this.state, newState);
      });
  }
}

export default TitlePalette;
