import React, { Component } from 'react';
import classnames from 'classnames';

const sleep = function(time) {
  // @ts-ignore TS2794
  return new Promise((resolve, reject) => setTimeout(() => resolve(), time));
};

class LoadingBar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      show: true,
      width: 0,
      wait: false,
      full: false,
    };
  }

  componentDidMount() {
    // @ts-ignore TS2339
    this._isMounted = true;
  }

  componentWillReceiveProps(nextProps) {
    // @ts-ignore TS2339
    if (this.props.progress != nextProps.progress) {
      // @ts-ignore TS2339
      if (!this._isMounted) return;
      this.setState({ width: nextProps.progress }, async () => {
        // @ts-ignore TS2339
        if (this.state.width == 100) {
          // @ts-ignore TS2339
          if (!this._isMounted) return;
          this.setState({ wait: true });
          await sleep(700);
          // @ts-ignore TS2339
          if (!this._isMounted) return;
          this.setState({
            full: true,
          });
          await sleep(250);
          // @ts-ignore TS2339
          if (!this._isMounted) return;
          this.setState({
            show: false,
            width: 0,
            wait: false,
          });
          setTimeout(() => {
            // @ts-ignore TS2339
            if (!this._isMounted) return;
            this.setState({
              show: true,
              full: false,
            });
          });
        }
      });
    }
  }

  componentWillUnmount() {
    // @ts-ignore TS2339
    this._isMounted = false;
  }

  render() {
    // @ts-ignore TS2339
    const { show, full, width } = this.state;
    if (!show) return null;
    return (
      <div className={classnames('loading-bar', { 'loading-bar--full': full })}>
        <div className="loading-bar-glow" style={{ width: `${width}%` }} />
      </div>
    );
  }
}

export default LoadingBar;
