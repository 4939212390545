import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

type DoubleTapAreaProps = {
  classes: object;
  step: number;
  player: any;
  onClick: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
};

class DoubleTapArea extends React.PureComponent<DoubleTapAreaProps> {
  lastTap: number = 0;
  static get propTypes() {
    return {
      classes: PropTypes.object,
      step: PropTypes.number,
      player: PropTypes.object,
      onClick: PropTypes.func,
    };
  }

  static get contextTypes() {
    return {
      getModelData: PropTypes.func,
    };
  }

  constructor(props: DoubleTapAreaProps, context) {
    super(props, context);
    this.handleDoubleTap = this.handleDoubleTap.bind(this);
  }

  handleDoubleTap(e) {
    const currentTime = new Date().getTime();
    const tapLength = currentTime - this.lastTap;

    if (tapLength < 300 && tapLength > 0) {
      if (this.props.onClick) {
        this.props.onClick(e);
        if (e.defaultPrevented) {
          return;
        }
      }
      this.props.player.currentTime(this.props.player.currentTime() + this.props.step);
    }
    this.lastTap = currentTime;
  }

  render() {
    if (!this.props.player || !this.props.step) return null;

    return (
      <div
        {...this.props}
        className={classnames('double-tap-area', this.props.classes)}
        onClick={this.handleDoubleTap}
      ></div>
    );
  }
}

export default React.forwardRef(function doubleTapArea(props, ref) {
  // @ts-ignore TS2322
  return <DoubleTapArea {...props} forwardedRef={ref} />;
});
