import datetime from 'src/domain/libs/datetime';

declare namespace displayDate {
  type Format = 'full' | 'fullja' | 'short' | 'time';
  type Options = {
    /** 引数のdateをdatetimeに食わせる場合のフォーマット*/
    inputFormat?: string;
    prefix?: string;
    suffix?: string;
  };
  type Date = datetime.ConfigType;
}

const displayDate = (format: displayDate.Format, date: displayDate.Date, options?: displayDate.Options) => {
  if (date == null) {
    return null;
  }
  const _date = datetime(date, options?.inputFormat).locale('ja');
  const ahmm = _date.formatahmm();
  let formated: string = '';
  if (format === 'full') {
    formated = `${_date.formatYYYYMMDDdd()} ${ahmm}`;
  } else if (format === 'fullja') {
    formated = `${_date.formatYYYYMMDDddJa()} ${ahmm}`;
  } else if (format === 'short') {
    formated = `${_date.formatMDdd()} ${ahmm}`;
  } else if (format === 'time') {
    formated = `${ahmm}`;
  }

  return `${options?.prefix ? options.prefix : ''}${formated}${options?.suffix ? options.suffix : ''}`;
};

export default displayDate;
