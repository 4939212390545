import React from 'react';
import _ from 'src/domain/libs/util';
import PaymentInstrumentInfo from './PaymentInstrumentInfo';

const PaymentInfo = props => {
  if (!props.paymentInstrument) {
    return null;
  }

  return (
    <div className="recipe-table-row clearfix">
      <div className="title">お支払い方法</div>
      <div className="item">
        <PaymentInstrumentInfo {...props} />
      </div>
    </div>
  );
};

//
// props
// - course
//
export default props => {
  return (
    <div className="recipe-table">
      <div className="recipe-table-row clearfix">
        <div className="title">契約名</div>
        <div className="item">{props.course.displayName}</div>
      </div>

      {!props.course.activePricing ? null : (
        <div className="recipe-table-row clearfix">
          <div className="title">月額</div>
          <div className="item">
            {_.get(props.course, 'activePricing.displayPaymentAmount')}
            (税込)
          </div>
        </div>
      )}

      <PaymentInfo {...props} />

      {!props.course.promotionText ? null : (
        <div className="recipe-table-row clearfix">
          <div className="title">お知らせ</div>
          <div className="item">{props.course.promotionText}</div>
        </div>
      )}
    </div>
  );
};
