import React, { useCallback } from 'react';
import VisibilitySensor from 'react-visibility-sensor';

type PaletteImpressionProps = {
  paletteId?: number | string;
  paletteName: string | null;
  orderNum: number | string;
  onVisiblePalette: (
    paletteId: PaletteImpressionProps['paletteId'],
    paletteName: PaletteImpressionProps['paletteName'],
    orderNum: PaletteImpressionProps['orderNum'],
  ) => void;
  useImpression?: boolean;
  children: React.ReactNode;
};

const PaletteImpression = ({
  paletteId,
  paletteName,
  orderNum,
  onVisiblePalette,
  useImpression,
  children,
}: PaletteImpressionProps) => {
  const onChange = useCallback(
    (isVisible: boolean) => {
      if (isVisible && paletteId && onVisiblePalette) {
        onVisiblePalette(paletteId, paletteName, orderNum);
      }
    },
    [onVisiblePalette, orderNum, paletteId, paletteName],
  );
  if (!useImpression) {
    return <>{children}</>;
  }
  return (
    <VisibilitySensor onChange={onChange} partialVisibility={true} offset={{ top: 0, bottom: 0 }}>
      {children}
    </VisibilitySensor>
  );
};

export default PaletteImpression;
