'use strict';

import React from 'react';

const newlineRegex = /(\r\n|\r|\n)/g;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const reactNl2br = function(str: any) {
  if (typeof str === 'number') {
    return str;
  } else if (typeof str !== 'string') {
    return '';
  }

  return str.split(newlineRegex).map(function(line, index) {
    if (line.match(newlineRegex)) {
      return React.createElement('br', { key: index });
    }
    return line;
  });
};

export default reactNl2br;