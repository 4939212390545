import { createStore } from 'redux';

export default function createApp(reducers, initialState, binder = {}) {
  const store = createStore(function() {
  });
  return Object.assign({
    getStore: function() {
      return store;
    }
  }, binder);
};
