import React from 'react';
import PropTypes from 'prop-types';
import _ from 'src/domain/libs/util';
import window from 'global/window';

import HtmlSnippet from '../../../HtmlSnippet';
import panelHOC from '../../../../hocs/panelHOC';

class TermsConfirm extends React.Component {
  static getPaths = HtmlSnippet.getPaths;
  static getRootPath = HtmlSnippet.getRootPath;

  static get contextTypes() {
    return {
      getModelData: PropTypes.func,
      models: PropTypes.object,
    };
  }

  static get propTypes() {
    return {
      model: PropTypes.object.isRequired,
      snippetId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    };
  }

  constructor(props, context) {
    super(props, context);
    // @ts-ignore TS2339
    this.htmlSnippet = null;
    // @ts-ignore TS2339
    this.model = props.model;
    // @ts-ignore TS2339
    this.snippetId = props.snippetId;

    // @ts-ignore TS2339
    const rootPath = this.constructor.getRootPath(context.models, {}, props);
    this.state = {
      fetchDataError: null,
      // @ts-ignore TS2339
      generation: this.model.getVersion(rootPath),
    };
  }

  componentDidMount() {
    // @ts-ignore TS2339
    this._isMounted = true;
    this.fetchData(this.props, this.context);
  }

  componentWillReceiveProps(nextProps, nextContext) {
    this.fetchData(nextProps, nextContext);
  }

  componentWillUnmount() {
    // @ts-ignore TS2339
    this._isMounted = false;
  }

  fetchData(props, context) {
    // @ts-ignore TS2339
    if (!this.snippetId) {
      return;
    }
    // @ts-ignore TS2339
    const _props = _.assign({ snippetId: this.snippetId }, props);

    // @ts-ignore TS2339
    const paths = this.constructor.getPaths(context.models, {}, _props);
    // @ts-ignore TS2339
    const rootPath = this.constructor.getRootPath(context.models, {}, _props);
    // @ts-ignore TS2339
    const evaluator = this.model.fetch(paths);
    // @ts-ignore TS2339
    this.state.dispose = evaluator.dispose;
    evaluator
      .then(res => {
        const htmlSnippet = _.get(res.json, rootPath);
        if (_.get(htmlSnippet, 'content')) {
          // @ts-ignore TS2339
          this.htmlSnippet = htmlSnippet;
        }

        const newState = {
          fetchDataError: null,
          dispose: null,
          // @ts-ignore TS2339
          generation: this.model.getVersion(rootPath),
        };
        // @ts-ignore TS2339
        if (this._isMounted) this.setState(newState);
        else Object.assign(this.state, newState);
      })
      .catch(e => {
        throw e;
        // const newState = {
        //   fetchDataError: e,
        //   dispose: null
        // };
        // if (this._isMounted) this.setState(newState);
        // else Object.assign(this.state, newState);
      });
  }

  render() {
    let content;

    // @ts-ignore TS2339
    if (!this.htmlSnippet) {
      content = (
        <div className="loading-spinner-container">
          <div className="loading-spinner"></div>
        </div>
      );
    } else {
      content = (
        <React.Fragment>
          <div className="inner-content">
            {/*
             // @ts-ignore TS2322 */}
            <HtmlSnippet {...this.props} snippetId={this.snippetId} model={this.model} />
          </div>
          <div className="action-buttons">
            {/*
             // @ts-ignore TS2339 */}
            <a href="javascript:void(0)" className="btn btn-gray" onClick={this.props.closeModal}>
              閉じる
            </a>
          </div>
        </React.Fragment>
      );
    }

    return (
      <div className="up-sell-flow">
        <div className="flow-header"></div>
        <div className="flow-content">{content}</div>
      </div>
    );
  }
}

export default panelHOC(TermsConfirm);
