import React from 'react';
import _ from 'src/domain/libs/util';

export default props => {
  const submitError = props.submitError;
  if (!submitError) {
    return null;
  }
  // console.error(submitError);

  let messages = [];
  if (_.get(submitError, 'message')) {
    messages = _.concat(messages, submitError.message);
  }
  const errorMessages = messages.map((message, i) => {
    return (
      <p className="text" key={`message-` + i}>
        {message}
      </p>
    );
  });

  let errorCode;
  if (_.get(submitError, 'dcode')) {
    errorCode = (
      <p className="text errorCode" key="message-error-code">
        エラーコード：{submitError.dcode}
      </p>
    );
  }

  return (
    <div className="message-box">
      <p className="notes">
        <i className="fa fa-exclamation-triangle"></i>
        申し訳ありません。処理中にエラーが発生しました。
      </p>
      {errorMessages}
      {errorCode}
    </div>
  );
};
