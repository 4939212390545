import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Button from './Button';

class CastButton extends React.PureComponent {
  static get propTypes() {
    return {
      player: PropTypes.object.isRequired,
      isHidden: PropTypes.bool,
      onClick: PropTypes.func,
      startHover: PropTypes.func,
      stopHover: PropTypes.func,
    };
  }

  static get contextTypes() {
    return {
      getModelData: PropTypes.func,
    };
  }

  constructor(props, context) {
    super(props, context);

    // @ts-ignore TS2339
    this.castButtonRef = React.createRef();

    this.onClick = this.onClick.bind(this);
    this.onMouseEnter = this.onMouseEnter.bind(this);
    this.onMouseLeave = this.onMouseLeave.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
  }

  onClick(e) {
    // @ts-ignore TS2339
    if (this.props.isHidden) {
      return;
    }
    // @ts-ignore TS2339
    if (this.props.onClick) {
      // @ts-ignore TS2339
      this.props.onClick(e);
    }
    // @ts-ignore TS2339
    if (this.props.releaseFocus) {
      // @ts-ignore TS2339
      this.props.releaseFocus(e);
    }
  }

  onMouseEnter(e) {
    // @ts-ignore TS2339
    if (this.props.startHover) {
      // @ts-ignore TS2339
      this.props.startHover('cast');
    }
  }

  onMouseLeave(e) {
    // @ts-ignore TS2339
    if (this.props.stopHover) {
      // @ts-ignore TS2339
      this.props.stopHover('');
    }
  }

  handleKeyDown(event) {
    // Enter and Space
    if (event.which === 13 || event.which === 32) {
      event.preventDefault();
      // @ts-ignore TS2339
      this.castButtonRef.current.click();
    }
  }

  render() {
    const props = {};
    const browserInfo = this.context.getModelData('browserInfo');
    if (!browserInfo.isIOS && !browserInfo.isAndroid) {
      // @ts-ignore TS2339
      props.onMouseEnter = this.onMouseEnter;
      // @ts-ignore TS2339
      props.onMouseLeave = this.onMouseLeave;
      // @ts-ignore TS2339
      props.onFocus = this.onMouseEnter;
      // @ts-ignore TS2339
      props.onBlur = this.onMouseLeave;
      // @ts-ignore TS2339
      props.onKeyDown = this.handleKeyDown;
    }
    return (
      <div className="player-control-element">
        <div
          {...props}
          // @ts-ignore TS2339
          ref={this.props.forwardedRef}
          id="castbutton"
          className={classnames(
            'touchable',
            'player-control-element',
            'player-control-element-blurred',
            'player-button-control',
          )}
          // @ts-ignore TS2322
          tabIndex="0"
          role="button"
          aria-label={'テレビで再生'}
          onClick={this.onClick}
        >
          {/*
           // @ts-ignore TS2339 */}
          <google-cast-launcher ref={this.castButtonRef}></google-cast-launcher>
        </div>
      </div>
    );
  }
}

// export default CastButton;
export default React.forwardRef(function castButton(props, ref) {
  // @ts-ignore TS2322
  return <CastButton {...props} forwardedRef={ref} />;
});
