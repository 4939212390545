import AppContext from './AppContext';

class App {

  constructor(initialState, options) {
    // @ts-ignore TS2339
    this.initialState = initialState;
    // @ts-ignore TS2339
    this.options = options || {};

    // @ts-ignore TS2339
    if (this.options.handleRouteChange) {
      // @ts-ignore TS2339
      this.handleRouteChange = this.options.handleRouteChange;
    }

    // @ts-ignore TS2339
    if (this.options.stringProvider) {
      // @ts-ignore TS2339
      this.stringProvider = this.options.stringProvider;
    }
  }

  createContext(state, options) {
    const _options = Object.assign({}, options, {
      // @ts-ignore TS2339
      stringProvider: this.stringProvider
    });
    // @ts-ignore TS2339
    let _state = Object.assign({}, this.initialState, state);
    // @ts-ignore TS2339
    if (this.options.handleCreateContext) {
      // @ts-ignore TS2339
      _state = this.options.handleCreateContext(_state);
    }
    return new AppContext(this, _state, _options);
  }

}

export default App;
