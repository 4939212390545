import React from 'react';
import _ from 'src/domain/libs/util';
import ValidationMessage from '../../ValidationMessage';
import CheckboxDiv from '../../CheckboxDiv';

//
// props
// - course
// - inputAgreeflagref
// - messages
// - onClick
// - agreementField
//
export default props => {
  // デフォルトはterms
  const agreementField = props.agreementField || 'terms';
  if (!_.includes(['terms', 'notesFlag'], agreementField)) {
    throw new TypeError('ArgumentError');
  }

  if (!_.get(props.course, agreementField)) {
    return null;
  }

  let agreementTarget;
  if (agreementField == 'terms') {
    agreementTarget = [];
    _.forEach(props.course.terms, (term, i) => {
      if (_.get(term, 'currentRevision.url')) {
        agreementTarget.push(
          <a key={`term-${i}`} href={term.currentRevision.url} target="_blank">
            {_.get(term, 'displayName')}
          </a>,
        );
      }
      // @ts-ignore TS2365
      if (i < props.course.terms.length - 1) {
        agreementTarget.push(<span key={`comma-${i}`}>、</span>);
      }
    });
    if (_.isEmpty(agreementTarget)) {
      return null;
    }
  } else {
    agreementTarget = '上記内容';
  }

  return (
    <div className="input-block terms-check-block">
      <CheckboxDiv ref={props.inputAgreeFlagRef} name="" value="1" onClick={props.onClick}>
        <fieldset>
          <legend className="title">
            {agreementTarget}
            に同意する
            <span className="required">必須</span>
          </legend>
        </fieldset>
      </CheckboxDiv>
      <ValidationMessage message={props.message} />
    </div>
  );
};
