import React from 'react';

const NoPPVView = () => {
  return (
    <div className="plan-info__item ppv">
      <div className="plan-info__item__li">
        <div className="price-wrapper">販売期間外なため、現在ご購入いただけません。</div>
      </div>
    </div>
  );
};

export default React.memo(NoPPVView);
