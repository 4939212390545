import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import _ from 'src/domain/libs/util';

import Axios from '../../../../common/Axios';
// @ts-ignore TS1192
import reactNl2br from '../../../../common/reactNl2br';

class ExerciseModal extends React.Component {
  static contextTypes = {
    getModelData: PropTypes.func,
    columnsInRow: PropTypes.number,
  };

  constructor(props) {
    super(props);
    this.state = {};
    // @ts-ignore TS2339
    this.axios = new Axios();
  }

  componentDidMount() {
    // @ts-ignore TS2339
    this._isMounted = true;
    // this.fetchData(this.props);
  }

  componentWillUnmount() {
    // @ts-ignore TS2339
    this._isMounted = false;
  }

  render() {
    // const exerciseTime = _.get(this.state, 'viewableExerciseTime');
    // const exerciseTimeUntil = _.get(this.state, 'viewableExerciseTimeUntil');
    let text = _.get(this.props, 'error.display.text');
    if (text) {
      // @ts-ignore TS2339
      text = text.replace(/。/g, '。\n');
    }
    return (
      <React.Fragment>
        {!!text && (
          <div className="modal-text">
            {/*
             // @ts-ignore TS2322 */}
            <p class="text text-center">{reactNl2br(text)}</p>
          </div>
        )}
        <div className="btn-block">
          {/* 作品ページに戻す */}
          {/*
           // @ts-ignore TS2339 */}
          <button className="btn btn-border" onClick={this.props.onCancel}>
            キャンセル
          </button>
          {/*
           // @ts-ignore TS2339 */}
          <a className="btn btn-fill" href="javascript:void(0)" onClick={this.props.onClick}>
            再生
          </a>
        </div>
      </React.Fragment>
    );
  }
}

export default ExerciseModal;
