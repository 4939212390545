import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import _ from 'src/domain/libs/util';
import classnames from 'classnames';
import GalleryContent from '../GalleryContent';

import SwitchGallery from '../SwitchGallery';
import EpisodeCard from '../EpisodeCard';
import MainViewLink from '../../../../common/components/MainViewLink';
import routes from '../../../../common/routes';
import DropDown from '../../../../common/components/DropDown';
import URLGenerator from '../../../../../sketch-platform/ui/routing/URLGenerator';
import * as browserEvents from '../../../../../sketch-platform/utils/browserEvents';
import * as DOMUtils from '../../../../../sketch-platform/utils/DOMUtils';

const Pager = (props, context) => {
  if (props.max == 1) return null;

  const goto = p => {
    const { history, routeHandler } = context;
    if (routeHandler.query[props.paramName] == p) {
      return null;
    }
    const options = {
      path: routeHandler.path,
      query: Object.assign({}, routeHandler.query, {
        [props.paramName]: p,
      }),
    };
    const to = URLGenerator.createRelative(options);
    if (props.onChangeQuery) {
      history.push(to, { norender: true });
      routeHandler.query = options.query;
      props.onChangeQuery(options.query);
    } else {
      history.push(to);
    }
  };
  const renderOptions = () => {
    const opsions = [];
    for (let i = 1; i <= props.max; i++) {
      const execSort = () => {
        goto(i);
      };
      opsions.push(
        <a
          className="sub-menu-link"
          role="link"
          // @ts-ignore TS2322
          tabIndex="0"
          onClick={e => {
            e.preventDefault();
            execSort();
          }}
          onKeyDown={e => {
            if (e.which === 13 || e.keyCode === 13) {
              e.preventDefault();
              execSort();
            }
          }}
        >
          {i}ページ
        </a>,
      );
    }
    return opsions;
  };
  const dropDownProps = {
    cols: 1,
    defaultValue: `${props.page}ページ`,
    options: renderOptions(),
    widthRestricted: true,
  };
  return (
    <div className="pager-gallery">
      {props.page == 1 ? null : (
        <a href="javascript:void(0);" className="left" onClick={() => goto(props.page - 1)}>
          <i className="fa fa-angle_left"></i>
        </a>
      )}
      <DropDown {...dropDownProps} />
      {props.max == props.page ? null : (
        <a href="javascript:void(0);" className="right" onClick={() => goto(props.page + 1)}>
          <i className="fa fa-angle_right"></i>
        </a>
      )}
    </div>
  );
};
Pager.contextTypes = {
  history: PropTypes.object,
  routeHandler: PropTypes.object,
};

// 未使用
export default class Episodes extends Component {
  static getPaths = function(models, options, props) {
    const rootPath = this.getRootPath(models, options, props);
    let paths = [];
    if (props.sortOrder) {
      let pathSet = ['episodes'];
      if (props.hierarchyType && props.hierarchyType.id) {
        pathSet.push(props.hierarchyType.id);
      }
      pathSet.push(props.sortOrder);
      // @ts-ignore TS2339
      paths = EpisodeCard.getPaths().map(path => {
        return rootPath.concat(
          pathSet.concat(
            // @ts-ignore TS2769
            [
              {
                from: (props && props.fromNum) || 0,
                to:
                  props && props.toNum ? props.toNum : (props && props.fromNum ? props.fromNum : 0) + props.perPage - 1,
              },
            ].concat(path),
          ),
        );
      });
      paths = paths.concat([rootPath.concat(pathSet.concat(['length']))]);
    }
    return paths.concat([rootPath.concat([['defaultSort']])]);
  };

  static getRootPath = function(models, options, props = {}) {
    // @ts-ignore TS2339
    if (props.id) {
      // @ts-ignore TS2339
      return ['meta', props.id];
    }
    return [];
  };

  static get propTypes() {
    return {
      isLoading: PropTypes.bool,
      onSliderMove: PropTypes.func,
      showEpisodeDuration: PropTypes.bool,
      showEpisodeNumber: PropTypes.bool,
      showEpisodeSummary: PropTypes.bool,
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
      model: PropTypes.object.isRequired,
      itemsInRow: PropTypes.number,
      perPage: PropTypes.number,
      hierarchyType: PropTypes.object,
    };
  }

  static get defaultProps() {
    return {
      perPage: 24,
    };
  }

  static get contextTypes() {
    return {
      routeHandler: PropTypes.object,
      history: PropTypes.object,
      models: PropTypes.object,
      cookies: PropTypes.object,
      columnsInRow: PropTypes.number,
      getModelData: PropTypes.func,
    };
  }

  static get childContextTypes() {
    return {
      columnsInRow: PropTypes.number,
    };
  }

  getChildContext() {
    const browserInfo = this.context.getModelData('browserInfo');
    const columnsInRow = browserInfo.isIOS || browserInfo.isAndroid ? 1 : this.context.columnsInRow;
    return { columnsInRow };
  }

  constructor(props, context) {
    super(props, context);

    // @ts-ignore TS2339
    this.episodesRef = React.createRef();
    // @ts-ignore TS2339
    this.isFirstSetOfResults = true;
    // @ts-ignore TS2339
    this.numTitlesToFetch = props.perPage;
    this.handleSliderMove = this.handleSliderMove.bind(this);
    this.handleNextClick = this.handleNextClick.bind(this);
    this.handleSortOrderChange = this.handleSortOrderChange.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.handleOpenGallery = this.handleOpenGallery.bind(this);

    const browserInfo = context.getModelData('browserInfo');
    if (browserInfo.isIOS || browserInfo.isAndroid) {
      this.onScroll = _.throttle(this.onScroll.bind(this), 100);
    }

    this.state = {
      dispose: null,
      generation: -1,
      fetchDataError: null,
      openGallery: false,
      hasEpisodes: false,
    };

    // @ts-ignore TS2339
    const rootPath = this.constructor.getRootPath(context.models, null, props);
    // @ts-ignore TS2339
    this.item = props.model.getSync(rootPath) || {};

    // @ts-ignore TS2339
    this.state.sortOrder = context.routeHandler.query['so'] || this.item.defaultSort || 'ena';
    // @ts-ignore TS2339
    this.episodes = [];
  }

  componentDidMount() {
    // @ts-ignore TS2339
    this._isMounted = true;
    const browserInfo = this.context.getModelData('browserInfo');
    if (browserInfo.isIOS || browserInfo.isAndroid) {
      browserEvents.addEventListener('scroll', this.onScroll);
    }
    this.fetchData(this.props);
  }

  componentWillReceiveProps(nextProps, nextContext) {
    // @ts-ignore TS2339
    if (nextProps.id !== this.props.id) {
      // @ts-ignore TS2339
      this.isFirstSetOfResults = true;
      // @ts-ignore TS2339
      this.episodes = [];
      // @ts-ignore TS2339
      if (this.state.dispose) this.state.dispose();
      this.setState({ generation: -1, fetchDataError: null, dispose: null, fromNum: 0 }, () => {
        this.fetchData(nextProps);
      });
    }
  }

  componentWillUnmount() {
    // @ts-ignore TS2339
    this._isMounted = false;
    const browserInfo = this.context.getModelData('browserInfo');
    if (browserInfo.isIOS || browserInfo.isAndroid) {
      browserEvents.removeEventListener('scroll', this.onScroll);
    }
    // @ts-ignore TS2339
    if (this.state.dispose) this.state.dispose();
  }

  onScroll(e) {
    // console.log('Episodes.js: onScroll')
    // @ts-ignore TS2339
    if (!this.state.openGallery) return;
    if (this.isWithinDistanceBuffer(300)) {
      // @ts-ignore TS2554
      this.fetchMoreRows(true);
    }
  }

  isWithinDistanceBuffer(distanceBuffer) {
    // @ts-ignore TS2339
    if (!this.episodesRef) return;
    // @ts-ignore TS2339
    const el = this.episodesRef.current;
    if (el) {
      return DOMUtils.getDistanceToBottomOfElement(el) < distanceBuffer;
    }
    return false;
  }

  fetchMoreRows() {
    // console.log('Episodes.js: fetchMoreRows')
    // @ts-ignore TS2339
    if (this.episodes && this.numTitlesToFetch > this.episodes.length && !this.state.fetchingMoreRows) {
      // @ts-ignore TS2339
      this.state.fetchingMoreRows = true;
      this.fetchData(this.props);
    }
  }

  handleSliderMove(nextState, actionType) {
    if (
      // @ts-ignore TS2339
      this.numTitlesToFetch > this.episodes.length &&
      // @ts-ignore TS2339
      nextState.lowestVisibleItemIndex >= this.episodes.length - 10 &&
      // @ts-ignore TS2339
      !this.state.fetchingMoreRows
    ) {
      // @ts-ignore TS2339
      this.state.fetchingMoreRows = true;
      this.fetchData(this.props);
    }
    // @ts-ignore TS2339
    if (this.props.onSliderMove) {
      // @ts-ignore TS2339
      this.props.onSliderMove();
    }
  }

  handleNextClick() {
    this.fetchMoreRows();
  }

  handleSortOrderChange(s) {
    let so = s.so;
    // @ts-ignore TS2339
    if (so !== this.state.sortOrder) {
      // @ts-ignore TS2339
      this.isFirstSetOfResults = true;
      // @ts-ignore TS2339
      this.episodes = [];
      // @ts-ignore TS2339
      if (this.state.dispose) this.state.dispose();
      this.setState({ sortOrder: so, dispose: null, generation: -1, fetchDataError: null, fromNum: 0 }, () => {
        this.fetchData(this.props);
      });
    }
  }

  handlePageChange() {
    // @ts-ignore TS2339
    this.isFirstSetOfResults = true;
    // @ts-ignore TS2339
    if (this.state.dispose) this.state.dispose();
    this.scrollToEpisodeTop();
    this.setState({ dispose: null, generation: -1, fetchDataError: null, fromNum: 0 }, () => {
      this.fetchData(this.props);
    });
  }

  handleOpenGallery() {
    // @ts-ignore TS2339
    if (this.state.openGallery) this.scrollToEpisodeTop();
    // @ts-ignore TS2339
    this.setState({ openGallery: !this.state.openGallery });
  }

  scrollToEpisodeTop() {
    // @ts-ignore TS2339
    const el = this.episodesRef.current;
    // el.offsetTopが0になる
    if (el) browserEvents.scrollTo(0, el.parentNode.offsetTop);
  }

  render() {
    const browserInfo = this.context.getModelData('browserInfo');

    let spinLoader;
    // @ts-ignore TS2339
    if ((browserInfo.isIOS || browserInfo.isAndroid) && this.state.fetchingMoreRows === true) {
      spinLoader = (
        <div className="gallery-spin-loader">
          <div className="loader-cont"></div>
        </div>
      );
    }

    const episodes = _.compact(
      // @ts-ignore TS2339
      _.map(this.episodes, (episode, index) => {
        if (!episode.id) return;
        return (
          <EpisodeCard
            // @ts-ignore TS2322
            model={this.props.model}
            rankNum={episode.episodeNumber}
            episodeModel={episode}
            episodeId={episode.id}
            // @ts-ignore TS2339
            selected={episode.id == this.props.selectedEpisodeId}
            // @ts-ignore TS2339
            key={`episode_${this.props.id}_${episode.id}_${episode.episodeNumebr}`}
            enableCardClick={browserInfo.isIOS || browserInfo.isAndroid ? true : null}
            // @ts-ignore TS2339
            onChangeEpisode={this.props.onChangeEpisode}
          />
        );
      }),
    );
    const sortGalleryProps = {
      // @ts-ignore TS2339
      model: this.props.model,
      // @ts-ignore TS2339
      sortOrder: this.state.sortOrder,
      onChangeQuery: this.handleSortOrderChange,
    };

    const hierarchyTypeId = parseInt(this.context.routeHandler.query['ht'], 10) || null;
    let modelRoot = 'episodes';
    let cardComponent = EpisodeCard;

    // @ts-ignore TS2339
    const headerTitle = this.props.hierarchyType.name ? this.props.hierarchyType.name : 'エピソード';

    let header = (
      <React.Fragment>
        <div className="gallery-header">
          <div className="title">
            <span className="gallery-title">{headerTitle}</span>
          </div>
          {episodes.length > 1 ? <SwitchGallery {...sortGalleryProps} /> : null}
        </div>
      </React.Fragment>
    );
    let footer = null;
    if (browserInfo.isIOS || browserInfo.isAndroid) {
      // スマホはスクロールでページングする
    } else {
      const paramName =
        _.get(this.props, 'hierarchyType.index') != null ? `p${_.get(this.props, 'hierarchyType.index')}` : '';
      const page = parseInt(this.context.routeHandler.query[paramName], 10) || 1;
      footer = (
        <Pager
          // @ts-ignore TS2339
          max={Math.ceil(this.totalCount / this.props.perPage)}
          paramName={paramName}
          page={page}
          onChangeQuery={this.handlePageChange}
        />
      );
    }
    // @ts-ignore TS2339
    const chunckSize = this.props.itemsInRow || this.context.columnsInRow;
    // const showOpen = 12 < episodes.length;
    const showOpen = browserInfo.isIOS || browserInfo.isAndroid ? 4 < episodes.length : chunckSize < episodes.length;
    // @ts-ignore TS2339
    return this.state.hasEpisodes ? (
      <div
        // @ts-ignore TS2339
        ref={this.episodesRef}
        // @ts-ignore TS2339
        className={classnames('episodes-wrapper', { close: showOpen && !this.state.openGallery })}
      >
        <GalleryContent
          // @ts-ignore TS2339
          id={this.props.id}
          header={header}
          // @ts-ignore TS2322
          footer={footer}
          keyPrefix={'meta'}
          modelRoot={modelRoot}
          cardComponent={cardComponent}
          // @ts-ignore TS2339
          sortOrder={this.state.sortOrder}
          // @ts-ignore TS2339
          model={this.props.model}
          hierarchyTypeId={hierarchyTypeId}
        >
          {episodes}
        </GalleryContent>
        {spinLoader}
        {showOpen ? (
          <div className="open-gallery">
            <div className="btn btn-very-small" onClick={this.handleOpenGallery}>
              {/*
               // @ts-ignore TS2339 */}
              {this.state.openGallery ? (
                <React.Fragment>
                  閉じる<i className="fa-angle_up"></i>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  もっと見る<i className="fa-angle_down"></i>
                </React.Fragment>
              )}
            </div>
          </div>
        ) : null}
      </div>
    ) : null;
  }

  fetchData(props) {
    if (!props.id) return;

    const browserInfo = this.context.getModelData('browserInfo');

    let pathProps = null;
    if (browserInfo.isIOS || browserInfo.isAndroid) {
      // @ts-ignore TS2339
      pathProps = Object.assign({}, props, { toNum: this.numTitlesToFetch - 1 }, { sortOrder: this.state.sortOrder });
    } else {
      const paramName = _.get(props, 'hierarchyType.index') != null ? `p${_.get(props, 'hierarchyType.index')}` : '';
      let page = parseInt(this.context.routeHandler.query[paramName], 10) || 1;
      page = page - 1;
      pathProps = Object.assign(
        {},
        props,
        { fromNum: page * props.perPage, toNum: page * props.perPage + props.perPage - 1 },
        // @ts-ignore TS2339
        { sortOrder: this.state.sortOrder },
      );
    }

    // @ts-ignore TS2339
    const paths = this.constructor.getPaths(this.context.models, {}, pathProps);

    // すでに通信している場合は実行しない
    if (this.state[JSON.stringify(paths)]) return;

    // @ts-ignore TS2339
    if (this.state.dispose) {
      // 過去のObservableを削除する、これによって通信が止まるわけではなく
      // Observableがなくなるのでイベントが発火されなくなる、というだけなので注意
      // @ts-ignore TS2339
      this.state.dispose();
    }

    this.state[JSON.stringify(paths)] = paths;

    const evaluator = props.model.fetch(paths);
    const dispose = evaluator.dispose;
    // @ts-ignore TS2339
    if (this._isUpdated === false || !this._isMounted) {
      Object.assign(this.state, { dispose });
    } else {
      this.setState({ dispose });
    }
    // @ts-ignore TS2339
    const beforeItemLength = this.episodes ? this.episodes.length : 0;
    evaluator
      .then(res => {
        const newState = {
          fetchDataError: null,
          dispose: null,
          // @ts-ignore TS2339
          generation: this.state.generation + 1,
          fetchingMoreRows: undefined,
        };

        if (res) {
          // @ts-ignore TS2339
          this.item = _.get(res, `json.meta.${props.id}`);
          // @ts-ignore TS2339
          if (!this.state.sortOrder) newState.sortOrder = this.item.defaultSort || 'ena';
          const dataPath = props.hierarchyType.id
            ? // @ts-ignore TS2339
              `json.meta.${props.id}.episodes.${props.hierarchyType.id}.${this.state.sortOrder}`
            : // @ts-ignore TS2339
              `json.meta.${props.id}.episodes.${this.state.sortOrder}`;
          const data = _.get(res, dataPath);
          // @ts-ignore TS2339
          this.episodes = _.clone(_.values(_.omitBy(data, (val, key) => key.indexOf('$') === 0 || key === 'length')));
          if (data.hasOwnProperty('length')) {
            // @ts-ignore TS2339
            this.totalCount = data.length;
          }
        } else {
          // @ts-ignore TS2339
          this.totalCount = this.episodes.length;
        }

        // @ts-ignore TS2339
        this.numTitlesToFetch += props.perPage;
        // @ts-ignore TS2339
        if (this.numTitlesToFetch > this.totalCount) this.numTitlesToFetch = this.totalCount;
        delete this.state[JSON.stringify(paths)];
        // @ts-ignore TS2339
        newState.hasEpisodes = this.totalCount > 0;
        // @ts-ignore TS2339
        this.isFirstSetOfResults = false;

        // @ts-ignore TS2339
        if (this._isMounted) {
          this.setState(newState, () => {
            // @ts-ignore TS2339
            if (beforeItemLength >= this.episodes.length) {
              // @ts-ignore TS2339
              this.numTitlesToFetch = this.episodes.length;
            }
          });
        } else {
          Object.assign(this.state, newState);
        }
      })
      .catch(e => {
        const newState = {
          fetchDataError: e,
          fetchingMoreRows: undefined,
          dispose: null,
        };
        delete this.state[JSON.stringify(paths)];
        // @ts-ignore TS2339
        if (this._isMounted) this.setState(newState);
        else this.state = newState;
      });
  }
}
