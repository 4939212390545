import React from 'react';
import PropTypes from 'prop-types';
import _ from 'src/domain/libs/util';
import datetime from 'src/domain/libs/datetime';

type SetDateProps = {
  prefix?: string;
  date: any;
  format: string;
  txt?: string;
};

const SetDate = (props: SetDateProps) => {
  if (!props.date) return null;
  let date = datetime(props.date);

  let day = date.format('M/D(dd)');
  let fullDay = date.format('YYYY/MM/DD(dd)');
  let fullDayJa = date.format('YYYY年M月D日(dd)');
  let a = date.format('a');
  let h = date.format('h');
  // @ts-ignore TS2367
  if (h == 12) {
    // @ts-ignore TS2322
    h = 0;
  }
  let m = date.format('mm');

  if (props.format == 'full') {
    return (
      <React.Fragment>
        {props.prefix}
        {fullDay} {a}
        {h}:{m}
        {props.txt}
      </React.Fragment>
    );
  } else if (props.format == 'fullja') {
    return (
      <React.Fragment>
        {props.prefix}
        {fullDayJa} {a}
        {h}:{m}
        {props.txt}
      </React.Fragment>
    );
  } else if (props.format == 'short') {
    return (
      <React.Fragment>
        {props.prefix}
        {day} {a}
        {h}:{m}
        {props.txt}
      </React.Fragment>
    );
  } else if (props.format == 'time') {
    return (
      <React.Fragment>
        {props.prefix}
        {a}
        {h}:{m}
        {props.txt}
      </React.Fragment>
    );
  }
};
SetDate.getPaths = function(models, options, props) {
  return [[[]]];
};
SetDate.propTypes = {
  format: PropTypes.string,
};
SetDate.defaultProps = {
  format: 'short',
};

export default SetDate;
