import _ from 'src/domain/libs/util';
import { QuestionSchema } from 'src/types/context/Question';
import { QuestionSchemaData } from 'src/domain/models/questionSchema';

export const QUESTION_TYPE = {
  CHECK_BOX: 'CHECK_BOX',
  SELECT_BOX: 'SELECT_BOX',
  TEXT: 'TEXT',
  INTEGER: 'INTEGER',
  STRING: 'STRING',
  HIDDEN: 'HIDDEN',
};

const getHidden = function(data: QuestionSchemaData) {
  return !!data.management_flag;
};
const getOverwritable = function(data: QuestionSchemaData) {
  return !!data.systemonly;
};

export const getQuestionType = (data: QuestionSchemaData) => {
  if (getHidden(data)) {
    return QUESTION_TYPE.HIDDEN;
  } else if (data.type == 'const' && data.multiple == true) {
    return QUESTION_TYPE.CHECK_BOX;
  } else if (data.type == 'const' && data.multiple == false) {
    return QUESTION_TYPE.SELECT_BOX;
  } else if (data.type == 'text') {
    return QUESTION_TYPE.TEXT;
  } else if (data.type == 'integer') {
    return QUESTION_TYPE.INTEGER;
  } else if (data.type == 'string') {
    return QUESTION_TYPE.STRING;
  }
  return null;
};
// TODO: ちゃんとなおす
const questionSchema = function(data: QuestionSchemaData): QuestionSchema | null {
  if (data == null) return null;
  let maxLength = parseInt(data.max_length, 10);
  if (isNaN(maxLength)) {
    maxLength = undefined;
  }
  let minLength = parseInt(data.min_length, 10);
  if (isNaN(minLength)) {
    minLength = undefined;
  }
  return {
    ...data,
    hidden: getHidden(data),
    readOnly: data.readonly_flag,
    halfAlphaOnlyFlag: !!data.half_alpha_only_flag,
    fullCharOnlyFlag: !!data.full_char_only_flag,
    halfCharOnlyFlag: !!data.half_char_only_flag,
    isOverwritable: getOverwritable(data),
    isOverwritten: false,
    minLength,
    maxLength,
    //@ts-ignore TS2339
    questionType: getQuestionType(data),
  };
};

export default questionSchema;
