import React from 'react';
import { wrapDisplayName } from '../utils/displayName';

// @ts-ignore TS2554
const LayoutContext = React.createContext();
export default LayoutContext;
export const withLayoutContext = Component => {
  const withLayoutContext = React.forwardRef((props, ref) => {
    return (
      <LayoutContext.Consumer>
        {/*
         // @ts-ignore TS2698 */}
        {context => React.createElement(Component, { ref, ...props, ...context })}
      </LayoutContext.Consumer>
    );
  });
  // @ts-ignore TS2554
  withLayoutContext.displayName = wrapDisplayName(Component, 'withLayoutContext');
  if (typeof Component.getPaths === 'function') {
    // @ts-ignore TS2339
    withLayoutContext.getPaths = Component.getPaths.bind(Component);
  }
  if (typeof Component.getPrefetchPaths === 'function') {
    // @ts-ignore TS2339
    withLayoutContext.getPrefetchPaths = Component.getPrefetchPaths.bind(Component);
  }
  if (typeof Component.getPrefetchedPaths === 'function') {
    // @ts-ignore TS2339
    withLayoutContext.getPrefetchedPaths = Component.getPrefetchedPaths.bind(Component);
  }
  if (typeof Component.afterPrefetch === 'function') {
    // @ts-ignore TS2339
    withLayoutContext.afterPrefetch = Component.afterPrefetch.bind(Component);
  }
  if (typeof Component.getCanvasId === 'function') {
    // @ts-ignore TS2339
    withLayoutContext.getCanvasId = Component.getCanvasId.bind(Component);
  }
  return withLayoutContext;
};
