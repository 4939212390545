import React, { useMemo } from 'react';
import Falcor from 'falcor';
import PropTypes from 'prop-types';

import SetDate from '../../browse/SetDate';
import usePPVLimit from '../hooks/usePPVLimit';
import AppContext from 'src/types/context/App';

type PPVLimitProps = {
  metaId: number | string;
  isPPV: boolean;
  model: Falcor.Model;
};

type PPVLimitContext = {
  getModelData: AppContext.GetModelData;
};

const PPVLimit = (props: PPVLimitProps, context: PPVLimitContext) => {
  const authContext = useMemo(() => context.getModelData('authContext'), [context]);
  const ppvLimit = usePPVLimit({ metaId: props.metaId, model: props.model, isPPV: props.isPPV, authContext });

  return ppvLimit ? (
    <div className="date-box">
      <dl>
        <dt>PPV期限</dt>
        <dd>
          <SetDate date={ppvLimit} format={'fullja'} txt=" まで" />
        </dd>
      </dl>
    </div>
  ) : null;
};

PPVLimit.contextTypes = {
  getModelData: PropTypes.func,
};

export default PPVLimit;
