import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import _ from 'src/domain/libs/util';

const SubmitButtonArea = (props, context) => {
  let actionName = props.actionName || '購入';

  if (props.nextStep) {
    actionName = '決済情報の登録へ';
  } else {
    if (props.submitting) {
      actionName += '処理中...';
    } else {
      actionName += 'する';
    }
  }

  let submitButton;
  if (props.submitting) {
    submitButton = (
      <a href="javascript:void(0);" className="paymentsubmit-button btn btn-fill inactive">
        {actionName}
      </a>
    );
  } else {
    submitButton = (
      <a
        href="javascript:void(0)"
        onClick={props.handleSubmit}
        className={classnames('paymentsubmit-button btn btn-fill', { disabled: props.disabled })}
      >
        {actionName}
      </a>
    );
  }

  // 通常はキャンセルボタンは表示するが、
  // アプリ内webviewのときは表示しない
  let cancelButton;
  if (!_.get(context.getModelData('inapp'), 'inapp')) {
    // モーダルしないときは透過表示しない
    const cancelButtonClass = classnames('paymentsubmit-button', 'btn', 'btn-border', {
      'btn-line-overlay': props.useModal,
    });
    cancelButton = (
      // @ts-ignore TS2322
      <button className={cancelButtonClass} tabIndex="0" role="button" onClick={props.handleCancel}>
        キャンセル
      </button>
    );
  }

  return (
    <div className="paymentsubmit-area">
      {cancelButton}
      {submitButton}
    </div>
  );
};

SubmitButtonArea.contextTypes = {
  getModelData: PropTypes.func,
};

SubmitButtonArea.propTypes = {
  actionName: PropTypes.string,
  nextStep: PropTypes.bool,
  submitting: PropTypes.bool,
  handleSubmit: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
};

export default SubmitButtonArea;
