import React, { Component } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { IS_DEEP_LINK } from './SpNavFooter';

class Pagetop extends Component {
  static get propTypes() {
    return {
      duration: PropTypes.number,
    };
  }

  static get defaultProps() {
    return {
      duration: 500,
    };
  }

  constructor(props, context) {
    super(props, context);
    // @ts-ignore TS2339
    this.rootRef = props.rootRef || React.createRef();
    this.syncScroll = this.syncScroll.bind(this);
    this.handlePagetop = this.handlePagetop.bind(this);
    this.startScroll = this.startScroll.bind(this);
    this.drowScroll = this.drowScroll.bind(this);
    this.state = {
      visible: false,
    };
  }

  componentDidMount() {
    // @ts-ignore TS2339
    this._isMounted = true;
    this.syncScroll();
  }

  componentWillUnmount() {
    // @ts-ignore TS2339
    this._isMounted = false;
    // @ts-ignore TS2339
    if (this.syncScrollTimmer) window.cancelAnimationFrame(this.syncScrollTimmer);
    // @ts-ignore TS2339
    delete this.syncScrollTimmer;
    // @ts-ignore TS2339
    if (this.scrollStepTimmer) window.cancelAnimationFrame(this.scrollStepTimmer);
    // @ts-ignore TS2339
    delete this.scrollStepTimmer;
  }

  inOutQuad(n) {
    n *= 2;
    if (n < 1) return 0.5 * n * n;
    return -0.5 * (--n * (n - 2) - 1);
  }

  startScroll(now) {
    // @ts-ignore TS2339
    this.stop = false;
    // @ts-ignore TS2339
    this.duration = this.props.duration;
    // @ts-ignore TS2551
    this.start = now;
    // @ts-ignore TS2339
    this.offset = window.pageYOffset;
    this.drowScroll(now);
  }

  drowScroll(now) {
    // @ts-ignore TS2339
    if (window.pageYOffset === 0 || this.stop) {
      window.scrollTo(0, 0);
      // @ts-ignore TS2339
      if (this.scrollStepTimmer) window.cancelAnimationFrame(this.scrollStepTimmer);
    } else {
      // @ts-ignore TS2339
      if (this.stop) return;
      // @ts-ignore TS2551
      if (now - this.start >= this.duration) this.stop = true;
      // @ts-ignore TS2551
      let p = (now - this.start) / this.duration;
      // @ts-ignore TS2339
      window.scrollTo(0, this.offset - this.offset * this.inOutQuad(p));
      // @ts-ignore TS2339
      this.scrollStepTimmer = window.requestAnimationFrame(this.drowScroll);
    }
  }

  handlePagetop() {
    // @ts-ignore TS2339
    this.scrollStepTimmer = window.requestAnimationFrame(this.startScroll);
  }

  syncScroll() {
    const Scroll = Math.max(document.documentElement.scrollTop, document.body.scrollTop);
    const diff = 800;
    const visible = diff < Scroll;
    // @ts-ignore TS2339
    if (this.state.visible !== visible) {
      // @ts-ignore TS2339
      const { spFooterRef = {} } = this.props;
      if (spFooterRef.current && !spFooterRef.current.classList.contains(IS_DEEP_LINK)) {
        const spFooterHeight = spFooterRef.current.clientHeight;
        // @ts-ignore TS2339
        if (this.rootRef.current) {
          const translateY = visible ? `translateY(-${spFooterHeight + 10}px)` : '';
          // @ts-ignore TS2339
          this.rootRef.current.style.transform = translateY;
        }
      }
      this.setState({ visible });
    }
    // @ts-ignore TS2339
    if (this._isMounted) {
      // @ts-ignore TS2339
      this.syncScrollTimmer = window.requestAnimationFrame(this.syncScroll);
    }
  }

  render() {
    return (
      <div
        // @ts-ignore TS2339
        className={classnames('pagetopBtn', { visible: this.state.visible })}
        onClick={this.handlePagetop}
        // @ts-ignore TS2339
        ref={this.rootRef}
      >
        <i className="fa fa-pagetop"></i>
      </div>
    );
  }
}

export default Pagetop;
