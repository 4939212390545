'use strict';

export default function(func, options) {
  var defaultOptions = {
    retry: 1,
    retryDelay: 1,
    errorHandler: function(e) {
      return true;
    },
  };
  options = Object.assign({}, defaultOptions, options);
  const exec = count => {
    console.log('promiseRetry exec: ' + func.name + ' retry: ' + count);
    return func();
  };
  var count = 0;
  var cancel = false;
  let promise = exec(count);
  for (var i = 0; i < options.retry; i++) {
    promise = promise.catch(e => {
      if (cancel || !options.errorHandler(e)) {
        cancel = true;
        return Promise.reject(e);
      }
      console.log('promiseRetry error:', e);
      const backoff = new Promise(function(resolve) {
        setTimeout(function() {
          // @ts-ignore TS2794
          resolve();
        }, options.retryDelay);
      });
      return backoff.then(() => {
        return exec(++count);
      });
    });
  }
  return promise;
}
