import { useCallback, useEffect, useState } from 'react';
import _ from 'src/domain/libs/util';

import usePrevious from './usePrevious';
import { Model } from 'falcor';

const useMeta = ({ model, metaId, propKeys }: { model: Model; metaId: string | number; propKeys: string[] }) => {
  const [meta, setMeta] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const prevMetaId = usePrevious(metaId);
  const prevPropKeys = usePrevious(propKeys);

  const fetch = useCallback(async () => {
    if (!metaId) return;
    setLoading(true);
    if (error) {
      setError(null);
    }
    const rootPath: any = ['meta', metaId];
    const paths = [rootPath.concat([propKeys])];
    try {
      const res = await model.fetch(paths);
      const _meta = _.omit(_.get(res.json, rootPath), ['$__path']);
      setMeta(_meta);
    } catch (e) {
      setError(e);
      console.error(e);
    }
    setLoading(false);
  }, [error, model, metaId, propKeys]);

  useEffect(() => {
    if ((metaId && metaId !== prevMetaId) || !prevPropKeys || propKeys.length != prevPropKeys.length) {
      if (metaId !== prevMetaId) {
        setMeta(null);
      }
      fetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [metaId, propKeys]);

  return { meta, loading, error };
};

export default useMeta;
