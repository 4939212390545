import React from 'react';
import PropTypes from 'prop-types';
import routes, { getHomeRoute } from '../../../common/routes';
import MainViewLink from '../MainViewLink';
import classnames from 'classnames';

const ViewingConfirmation = (props, context) => {
  const inapp = context.getModelData('inapp', 'inapp');
  let actionName = '契約／購入';
  if (props.actionType == 'subscribe') {
    actionName = '契約';
  } else if (props.actionType == 'purchase') {
    actionName = '購入';
  }

  return (
    <div className="viewing-confirmation">
      {inapp ? null : (
        <div
          className={classnames('viewing-confirmation-link', {
            'unauthorized-view-link': props.parentComponent == 'UnauthorizedView',
          })}
        >
          {/*
           // @ts-ignore TS2322 */}
          <MainViewLink to={routes.viewingConfirmation} className="link" target="_blank">
            {actionName}前に視聴環境を確認する
          </MainViewLink>
        </div>
      )}
      <div
        className={classnames('viewing-confirmation-text', {
          'unauthorized-view-text': props.parentComponent == 'UnauthorizedView',
        })}
      >
        端末によっては視聴できない作品もあるため、{actionName}前に必ず、視聴の際に使用する端末で動作を確認してください
      </div>
    </div>
  );
};

ViewingConfirmation.contextTypes = {
  getModelData: PropTypes.func,
};

export default ViewingConfirmation;
