import _ from 'src/domain/libs/util';
import url from 'url';

import DeleteApp from './DeleteApp';
import { CLICK_AREA, CONTENT_EVENTS } from './GtmApp';
import { PAGING_INITIAL_KEY } from '../constants';

type ModelType = 'meta' | 'attribute';

class MyListDeleteApp extends DeleteApp {
  modelType: ModelType;
  constructor(service, gtmApp, model) {
    super();
    service = Object.assign({}, service);
    service.pathname = _.join(_.concat(service.path, 'users/favorites/delete'), '/');
    // @ts-ignore TS2339
    this._url = url.format(service);
    // @ts-ignore TS2339
    this.gtmApp = gtmApp;
    // @ts-ignore TS2339
    this.model = model;
    this.modelType = 'meta';
  }

  async send(authContext, userId) {
    await this._sendToGtm();
    const params = {
      model_type: this.modelType,
      model_id: this.deleteIds().join(','),
      user_id: userId || authContext.id,
    };
    return super.send(params, authContext);
  }

  async _sendToGtm() {
    // @ts-ignore TS2339
    if (!this.gtmApp) return;
    // @ts-ignore TS2551
    const deletingTargets = _.filter(this._targets, target => _.includes(this.deleteIds(), target.id));
    const quantity = (await this.getTotalCount()) - deletingTargets.length;
    deletingTargets.forEach(deletingTarget => {
      const hasRelationProgram = _.get(deletingTarget, 'seriesMeta') && _.get(deletingTarget, 'seasonMeta');
      const isMediaMeta = _.get(deletingTarget, 'type') === 'media';
      const relationProgram = hasRelationProgram
        ? {
            refId: _.get(deletingTarget, 'seriesMeta.refId'),
            name: _.get(deletingTarget, 'seriesMeta.name'),
          }
        : null;
      const program = isMediaMeta
        ? {
            refId: _.get(deletingTarget, 'seasonMeta.refId') || _.get(deletingTarget, 'seriesMeta.refId'),
            name: _.get(deletingTarget, 'seasonMeta.name') || _.get(deletingTarget, 'seriesMeta.name'),
          }
        : {
            refId: _.get(deletingTarget, 'refId'),
            name: _.get(deletingTarget, 'name'),
            rental: _.get(deletingTarget, 'rental'),
            subscription: _.get(deletingTarget, 'subscription'),
          };
      const content = isMediaMeta
        ? {
            refId: _.get(deletingTarget, 'refId'),
            name: _.get(deletingTarget, 'name') || _.get(deletingTarget, 'shortName'),
            rental: _.get(deletingTarget, 'rental'),
            subscription: _.get(deletingTarget, 'subscription'),
          }
        : {
            rental: _.get(deletingTarget, 'rental'),
            subscription: _.get(deletingTarget, 'subscription'),
          };
      const attributes = _.get(deletingTarget, 'attributes');
      const genres = _.get(deletingTarget, 'genres');
      const middleGenres = _.get(deletingTarget, 'middleGenres');
      const schemaId = _.get(deletingTarget, 'schemaId');
      const clickArea =
        this.modelType === 'meta' ? CLICK_AREA.FAVORITE_DELETE.MY_LIST : CLICK_AREA.FAVORITE_DELETE_ATTRIBUTE.MY_LIST;
      const eventName =
        this.modelType === 'meta' ? CONTENT_EVENTS.FAVORITE_DELETE : CONTENT_EVENTS.FAVORITE_DELETE_ATTRIBUTE;

      // attribute_id, attribute_type, attribute_name

      const metaParams = { relationProgram, program, content, attributes, genres, middleGenres, schemaId, quantity };
      const attributeParams = {
        attribute_id: _.get(deletingTarget, 'id'),
        attribute_type: _.get(deletingTarget, 'type'),
        attribute_name: _.get(deletingTarget, 'name'),
      };
      // @ts-ignore TS2339
      this.gtmApp.pushDataLayerOnContentPageClick(eventName, this.modelType === 'meta' ? metaParams : attributeParams, {
        clickArea,
      });
    });
  }

  //GTM連携用。非公開などは考慮できないのでAPIで取れた値を出す。
  async getTotalCount() {
    const lengthPath = ['mylist', 'cd', PAGING_INITIAL_KEY, 1, 'length'];
    const paths = [lengthPath];
    try {
      // @ts-ignore TS2339
      const res = await this.model.fetch(paths);
      const totalCount = _.get(res.json, lengthPath);
      return totalCount;
    } catch (err) {
      // gtm連携できないだけなので握りつぶす
      // console.log(err);
    }
  }

  _checkResponse(response) {
    if (response.result) return true;
    const code = _.get(response, 'error.code');
    // 既に削除済み
    if (code == 8004) {
      return true;
    }
    return false;
  }

  setModelType(type: ModelType) {
    this.modelType = type;
  }
}

export default MyListDeleteApp;
