import React from 'react';

export default function resolveElement(components, props) {
  components = Array.prototype.slice.call(components || []);
  let element = null;
  while (components.length > 0) {
    const component = components.pop();
    element = React.createElement(component, props, element);
  }
  return element;
}
