import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'src/domain/libs/util';

import activeProfile, { isFree } from '../../../../../utils/activeProfile';

export default class LicenseInvalidView extends Component {
  static get contextTypes() {
    return {
      getModelData: PropTypes.func,
      models: PropTypes.object,
      isIframe: PropTypes.bool,
      cookies: PropTypes.object,
    };
  }

  constructor(props, context) {
    super(props, context);
    this.onClickTvodBox = this.onClickTvodBox.bind(this);
    this.doAction = this.doAction.bind(this);

    this.state = {
      tvodBoxOpen: false,
    };
  }

  onClickTvodBox() {
    // @ts-ignore TS2339
    if (this.state.tvodBoxOpen) {
      this.setState({ tvodBoxOpen: false });
    } else {
      this.setState({ tvodBoxOpen: true });
    }
  }

  doAction(product) {
    if (_.isObject(product)) {
      // @ts-ignore TS2339
      this.props.doAction({ selected: { type: 'product', id: product.id } });
    }
  }

  render() {
    const profile = activeProfile(this.context.models) || {};
    const browserInfo = this.context.getModelData('browserInfo');
    const userInfo = this.context.getModelData('userInfo');

    let titleText = '';
    let text = '';
    let url = '';
    let tvodText = '';
    let btn = null;
    let list = false;
    // @ts-ignore TS2339
    let playbackRestrictionType = this.props.activeEpisode.playbackRestrictionType;

    if (
      // @ts-ignore TS2339
      this.props.activeEvent &&
      // @ts-ignore TS2339
      this.props.activeEvent.permitUserStatuses.length == 1 &&
      // @ts-ignore TS2339
      this.props.activeEvent.permitUserStatuses[0] == 'broadcast'
    ) {
      playbackRestrictionType = 100;
    } else if (
      // @ts-ignore TS2339
      this.props.activeEvent &&
      // @ts-ignore TS2339
      this.props.activeEvent.permitUserStatuses.length == 2 &&
      // @ts-ignore TS2339
      _.includes(this.props.activeEvent.permitUserStatuses, 'stream') &&
      // @ts-ignore TS2339
      _.includes(this.props.activeEvent.permitUserStatuses, 'broadcast')
    ) {
      playbackRestrictionType = 200;
    }

    // 無料ユーザ（Sのみ）
    // ・なし
    // 無料ユーザ（Tのみ）
    // ・B-CAS必須
    // 無料ユーザ（ST）
    // ・B-CAS必須
    // 契約済みユーザ
    // ・B-CAS必須
    // ・トライアルNG

    if (
      // @ts-ignore
      this.props.activeEpisode.rental &&
      // @ts-ignore
      _.isEmpty(this.props.courses) &&
      // @ts-ignore
      !_.isEmpty(this.props.products) &&
      // @ts-ignore
      this.props.playerErrorView
    ) {
      //TVODのみ
      titleText = '本作品をご視聴いただくには、別途料金が必要です。';
      // @ts-ignore
    } else if (isFree(profile) && this.props.playerErrorView) {
      //無料会員
      titleText = '本作品をご視聴いただくには、WOWOWへのご加入とWEBアカウントの紐づけが必要です。';
      // text = '視聴できない理由として、以下のいずれかに該当している可能性がございます。';
      url = 'https://support.wowow.co.jp/answer/628d74cf97715248bcfe81fc';
      // list = (
      //     <div className="watch-main-visual__metadata__message-box">
      //       <div className="btn-container">
      //         <ul>
      //           <li>WOWOWにご加入されていない</li>
      //           <li>ご契約情報とWEBアカウントが紐づけされていない</li>
      //         </ul>
      //       </div>
      //       {url ?
      //         <div className='btn-container'>
      //           <p className="plan-annotation">詳しくは<a href={url} target="_blank" className="accent-color">こちら</a></p>
      //         </div>
      //       : null}
      //     </div>
      // )
    } else if (playbackRestrictionType == 100) {
      //BCAS未登録NG
      titleText = '本作品は、放送視聴登録（B-CASカード/ACAS番号の登録）がない方はご視聴いただけません。';
      url = 'https://support.wowow.co.jp/answer/628d75cbc5aeea7a71e9d610';

      if (userInfo.isOwner && !isFree(profile)) {
        btn = (
          <a
            href="/support/subscribe_ex/regist_entry"
            className={`btn btn-fill btn-very-small external-link`}
            target="_blank"
          >
            放送視聴登録をする
          </a>
        );
      }
      // @ts-ignore
      if (isFree(profile) && !this.props.activeEpisode.rental && !this.props.playerErrorView) {
        titleText = null;
        url = null;
        btn = null;
      }
    } else if (playbackRestrictionType == 200) {
      //トライアルNG
      // titleText = '本作品は、無料トライアル中の方はご視聴いただけません。';
      // url = 'https://support.wowow.co.jp/answer/628d758a4343e6c77c8bede1';
      // @ts-ignore TS2339
    } else if (this.props.activeEvent && this.props.activeEvent.isMask) {
      titleText = 'この番組は放送のみでお楽しみいただけます。';
      text = '詳しくはお客さまサポート・よくあるご質問をご確認ください。';
      url = 'https://support.wowow.co.jp/s/category?prmcat=category3';
    }

    // @ts-ignore TS2339
    if (this.props.playerErrorView) {
      //プレイヤー上
      return (
        <div className="error-view">
          <div className="error-view-content">
            {titleText ? (
              <div className="error-view-heading">
                <p className="whoops text-center">{titleText}</p>
              </div>
            ) : null}
            <div className="error-view-body">
              <div className="error-view-detail">
                {text && typeof text === 'string' ? <p className="error-view-title">{text}</p> : text ? text : null}
                {list}
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <React.Fragment>
          {titleText ? <div className="watch-main-visual__metadata__message-title">{titleText}</div> : null}
          {text ? <div className="watch-main-visual__metadata__message-text">{text}</div> : null}
          {list}
          {btn}
          {/* @ts-ignore */}
          {(browserInfo.isAndroid || browserInfo.isIOS || this.props.watchSpMode) && url && !list ? (
            <a href={url} target="_blank" className="watch-main-visual__metadata__message-notice btn">
              詳しくはこちら
            </a>
          ) : null}
          {/* @ts-ignore */}
          {!(browserInfo.isAndroid || browserInfo.isIOS || this.props.watchSpMode) && url && !list ? (
            <div className="watch-main-visual__metadata__message-notice">
              詳しくは
              <a href={url} target="_blank" className="accent-color">
                こちら
              </a>
            </div>
          ) : null}
          {/* {this.renderProduct()} */}
        </React.Fragment>
      );
    }
  }
}
