import { EventEmitter } from 'fbemitter';
import generateUuid from './generateUuid';
import cookieDough from 'cookie-dough';
import { DSID_KEY } from '../constants/cookie';
import history from '../apps/history';
import Axios from './Axios';
import url from 'url';
import _ from 'src/domain/libs/util';
import GCastPlayerApp from './GCastPlayerApp';
import promiseRetry from '../utils/promiseRetry';
import { WebVTTParser } from 'webvtt-parser';
import vuidDataStore from '../utils/vuidDataStore';
import routes from '../apps/common/routes';
import FullScreenApp from './FullScreenApp';
import { CAPTIONS, SUBTITLES } from '../constants/player';

let VUID;
let DSID;
const BEACON_SPAN = 30;

// 非表示強制字幕のラベル
const NONE_FN_LABEL = {
  JA: 'NONE_JA',
  EN: 'NONE_EN',
};

export const TEXT_TRACK_MODE = {
  SHOWING: 'showing',
  DISABLED: 'disabled',
};
export const AUDIO_LANGUAGE = {
  JA: 'ja',
  EN: 'en',
  // 未設定。音声一本の場合はマニフェストに言語情報が載らないのでundになる。
  UND: 'und',
};
export const TEXT_TRACK_LANGUAGE = {
  JA: 'ja',
  EN: 'en',
  // 非表示
  NONE: 'none',
};

interface LocalPlayer {
  on(eventName: string, callback: (e: any | null) => void): void;
  off(eventName: string, callback: (e: any | null) => void): void;
  play(): any;
  playbackRate(): any;
  paused(): boolean;
  duration(): number;
  currentTime(time?: number): number;
  requestFullscreen(): void;
  exitFullscreen(): void;
}

class PlayerApp {
  private _localPlayer: LocalPlayer | null;
  _emitter: EventEmitter;

  constructor(options = {}) {
    // @ts-ignore TS2339
    this._options = options;
    // @ts-ignore TS2339
    this._playerId = generateUuid();
    // @ts-ignore TS2339
    this._emitter = new EventEmitter();
    // @ts-ignore TS2339
    this._cookies = cookieDough();
    // @ts-ignore TS2551
    this._gCastPlayer = GCastPlayerApp.getInstance(options);
    // @ts-ignore TS2339
    this._fsApp = this._options.fsApp;
    // @ts-ignore TS2551
    if (this._gCastPlayer) {
      // @ts-ignore TS2551
      this._gCastPlayer.activePlayerId = this._playerId;
    }

    this._localPlayer = null;
    // @ts-ignore TS2339
    this._listeners = [];
    // @ts-ignore TS2339
    this._metaId = null;
    // @ts-ignore TS2339
    this._overSeeking = false;
    // @ts-ignore TS2339
    this._state = null;

    this.loaded(false);
    this.started(false);
    this.canplayed(false);
    // @ts-ignore TS2339
    this.__autoplay = false;
    // @ts-ignore TS2339
    this.__currentPlayerTime = 0;
    // @ts-ignore TS2551
    this.__paused = true;
    // @ts-ignore TS2339
    this.__seeking = false;
    // @ts-ignore TS2339
    this.__pt = 0;
    // @ts-ignore TS2339
    this.__pFrom = undefined;
    // @ts-ignore TS2339
    this.__beaconIntervalId = undefined;
    // @ts-ignore TS2551
    this.__milestone = 0;
    // @ts-ignore TS2339
    this.__firstPlay = false;

    this.handleHistoryChange = this.handleHistoryChange.bind(this);
    if (typeof window !== 'undefined') {
      history.listen(this.handleHistoryChange);
    }

    this.reflashSeekThumbnailTrack = this.reflashSeekThumbnailTrack.bind(this);

    this.onLocalPlayerLoadstart = this.onLocalPlayerLoadstart.bind(this);
    this.onLocalPlayerProgress = this.onLocalPlayerProgress.bind(this);
    this.onLocalPlayerStateChange = this.onLocalPlayerStateChange.bind(this);
    this.onLocalPlayerPause = this.onLocalPlayerPause.bind(this);
    this.onLocalPlayerPlay = this.onLocalPlayerPlay.bind(this);
    this.onLocalPlayerPlaying = this.onLocalPlayerPlaying.bind(this);
    this.onLocalPlayerLoadedmetadata = this.onLocalPlayerLoadedmetadata.bind(this);
    this.onLocalPlayerEnded = this.onLocalPlayerEnded.bind(this);
    this.onLocalPlayerError = this.onLocalPlayerError.bind(this);
    this.onLocalPlayerDurationchange = this.onLocalPlayerDurationchange.bind(this);
    this.onLocalPlayerRatechange = this.onLocalPlayerRatechange.bind(this);
    this.onLocalPlayerVolumeChange = this.onLocalPlayerVolumeChange.bind(this);
    this.onLocalPlayerStalled = this.onLocalPlayerStalled.bind(this);
    this.onLocalPlayerEmptied = this.onLocalPlayerEmptied.bind(this);
    this.onLocalPlayerSuspend = this.onLocalPlayerSuspend.bind(this);
    this.onLocalPlayerWaiting = this.onLocalPlayerWaiting.bind(this);
    this.onLocalPlayerBuffering = this.onLocalPlayerBuffering.bind(this);
    this.onLocalPlayerBuffered = this.onLocalPlayerBuffered.bind(this);
    this.onLocalPlayerCanplay = this.onLocalPlayerCanplay.bind(this);
    this.onLocalPlayerSeeked = this.onLocalPlayerSeeked.bind(this);
    this.onLocalPlayerSeeking = this.onLocalPlayerSeeking.bind(this);
    this.onLocalPlayerCanplaythrough = this.onLocalPlayerCanplaythrough.bind(this);
    this.onLocalPlayerFirstplay = this.onLocalPlayerFirstplay.bind(this);
    this.onLocalPlayerLoadeddata = this.onLocalPlayerLoadeddata.bind(this);
    this.onLocalPlayerTimeupdate = this.onLocalPlayerTimeupdate.bind(this);
    this.onLocalPlayerSrcFallback = this.onLocalPlayerSrcFallback.bind(this);
    this.onLocalPlayerPlayTerminated = this.onLocalPlayerPlayTerminated.bind(this);
    this.onLocalPlayerAutoplayFailure = this.onLocalPlayerAutoplayFailure.bind(this);
    this.onLocalPlayerAutoplaySuccess = this.onLocalPlayerAutoplaySuccess.bind(this);
    this.onLocalPlayerEnterPictureInPicture = this.onLocalPlayerEnterPictureInPicture.bind(this);
    this.onLocalPlayerLeavePictureInPicture = this.onLocalPlayerLeavePictureInPicture.bind(this);
    this.onLocalPlayerWebkitPresentationModeChanged = this.onLocalPlayerWebkitPresentationModeChanged.bind(this);
    this.onLocalPlayerOutputRestricted = this.onLocalPlayerOutputRestricted.bind(this);
    this.textTracksChange = this.textTracksChange.bind(this);
    this.textTracksSelectedlanguagechange = this.textTracksSelectedlanguagechange.bind(this);
    this.textTracksLabelchange = this.textTracksLabelchange.bind(this);
    this.audioTracksChange = this.audioTracksChange.bind(this);
    this.toggleFullScreen = this.toggleFullScreen.bind(this);
    this.enterFullscreen = this.enterFullscreen.bind(this);
    this.exitFullScreen = this.exitFullScreen.bind(this);
    this.onFullscreenChange = this.onFullscreenChange.bind(this);
    this.onEnterFullScreen = this.onEnterFullScreen.bind(this);
    this.onExitFullScreen = this.onExitFullScreen.bind(this);
    this.beforeFullScreenChange = this.beforeFullScreenChange.bind(this);
    this.getIsFullScreen = this.getIsFullScreen.bind(this);
    this.onLocalPlayerAdModeStart = this.onLocalPlayerAdModeStart.bind(this);
    this.onLocalPlayerAdModeEnd = this.onLocalPlayerAdModeEnd.bind(this);
    this.onLocalPlayerAdBreakStart = this.onLocalPlayerAdBreakStart.bind(this);
    this.onLocalPlayerAdBreakEnd = this.onLocalPlayerAdBreakEnd.bind(this);
    this.onLocalPlayerAdsAdStarted = this.onLocalPlayerAdsAdStarted.bind(this);
    this.onLocalPlayerAdsAdEnded = this.onLocalPlayerAdsAdEnded.bind(this);
    this.onLocalPlayerAdsPlay = this.onLocalPlayerAdsPlay.bind(this);
    this.onLocalPlayerAdsPause = this.onLocalPlayerAdsPause.bind(this);
    this.onLocalPlayerAdsAdTimeUpdate = this.onLocalPlayerAdsAdTimeUpdate.bind(this);
    this.on = this.on.bind(this);
    this.off = this.off.bind(this);

    // @ts-ignore TS2339
    this._axios = new Axios();
  }

  handleHistoryChange(location, action) {
    //console.log('history: ', location, action);
    // ep詳細以外に遷移する場合はフルスクリーンを解除する
    if (
      this.getIsFullScreen() &&
      [routes.watchNow, routes.content, routes.pv].every(route => !route.match(location.pathname))
    ) {
      this.exitFullScreen();
    }
    // @ts-ignore TS2551
    if (!this._gCastPlayer._apiAvailable) return;
    if (window.location.pathname == location.pathname) return;
    // @ts-ignore TS2339
    var session = window.cast.framework.CastContext.getInstance().getCurrentSession();
    if (session) {
      // @ts-ignore TS2551
      this._gCastPlayer.playerId = null;
      // @ts-ignore TS2551
      this._gCastActive = false;
    }
  }

  setMetaId(metaId) {
    // @ts-ignore TS2339
    this._metaId = metaId;
    // @ts-ignore TS2551
    if (this._gCastPlayer) {
      // @ts-ignore TS2551
      this._gCastPlayer.setMetaId(metaId);
    }
  }

  setLocalPlayer(localPlayer, options = {}) {
    //console.log('PlayerApp: setLocalPlayer');

    this._localPlayer = localPlayer;

    if (this._localPlayer) {
      this.loaded(false);
      this.started(false);
      this.canplayed(false);
      // @ts-ignore TS2339
      this.__autoplay = false;
      // @ts-ignore TS2339
      this.__autoplayError = false;
      // @ts-ignore TS2339
      this.__currentPlayerTime = 0;
      // @ts-ignore TS2339
      this.__pt = 0;
      // @ts-ignore TS2339
      this.__pFrom = undefined;
      // @ts-ignore TS2339
      this.__beaconIntervalId = undefined;
      // @ts-ignore TS2551
      this.__milestone = 0;

      // @ts-ignore TS2339
      if (options.paused !== undefined) {
        // @ts-ignore TS2551
        this.__paused = options.paused;
        // @ts-ignore TS2339
        this.__autoplay = !this.__paused;
      }
      // @ts-ignore TS2339
      this.__seeking = false;
      // @ts-ignore TS2339
      this._overSeeking = false;
      // @ts-ignore TS2339
      this._state = null;

      this._localPlayer.on('loadstart', this.onLocalPlayerLoadstart);
      this._localPlayer.on('progress', this.onLocalPlayerProgress);
      this._localPlayer.on('pause', this.onLocalPlayerPause);
      this._localPlayer.on('play', this.onLocalPlayerPlay);
      // imaでもこのイベントが2回飛ぶのでこのイベントを使うのをやめる
      // this._localPlayer.on('playing', this.onLocalPlayerPlaying);
      this._localPlayer.on('statechange', this.onLocalPlayerStateChange);
      this._localPlayer.on('loadedmetadata', this.onLocalPlayerLoadedmetadata);
      this._localPlayer.on('ended', this.onLocalPlayerEnded);
      this._localPlayer.on('error', this.onLocalPlayerError);
      this._localPlayer.on('durationchange', this.onLocalPlayerDurationchange);
      this._localPlayer.on('ratechange', this.onLocalPlayerRatechange);
      this._localPlayer.on('volumechange', this.onLocalPlayerVolumeChange);
      // this._localPlayer.on('texttrackchange', (e) => { console.log('texttrackchange', e) });
      this._localPlayer.on('audiotrackchange', this.audioTracksChange);
      this._localPlayer.on('stalled', this.onLocalPlayerStalled);
      this._localPlayer.on('emptied', this.onLocalPlayerEmptied);
      this._localPlayer.on('suspend', this.onLocalPlayerSuspend);
      this._localPlayer.on('waiting', this.onLocalPlayerWaiting);
      this._localPlayer.on('buffering', this.onLocalPlayerBuffering);
      this._localPlayer.on('buffered', this.onLocalPlayerBuffered);
      this._localPlayer.on('canplay', this.onLocalPlayerCanplay);
      this._localPlayer.on('seeked', this.onLocalPlayerSeeked);
      this._localPlayer.on('seeking', this.onLocalPlayerSeeking);
      this._localPlayer.on('canplaythrough', this.onLocalPlayerCanplaythrough);
      this._localPlayer.on('firstplay', this.onLocalPlayerFirstplay);
      this._localPlayer.on('loadeddata', this.onLocalPlayerLoadeddata);
      this._localPlayer.on('timeupdate', this.onLocalPlayerTimeupdate);
      this._localPlayer.on('src-fallback', this.onLocalPlayerSrcFallback);
      this._localPlayer.on('play-terminated', this.onLocalPlayerPlayTerminated);
      this._localPlayer.on('autoplay-failure', this.onLocalPlayerAutoplayFailure);
      this._localPlayer.on('autoplay-success', this.onLocalPlayerAutoplaySuccess);
      this._localPlayer.on('enterpictureinpicture', this.onLocalPlayerEnterPictureInPicture);
      this._localPlayer.on('leavepictureinpicture', this.onLocalPlayerLeavePictureInPicture);
      this._localPlayer.on('webkitpresentationmodechanged', this.onLocalPlayerWebkitPresentationModeChanged);
      this._localPlayer.on('output-restricted', this.onLocalPlayerOutputRestricted);
      this._localPlayer.on('admodestart', this.onLocalPlayerAdModeStart);
      this._localPlayer.on('admodeend', this.onLocalPlayerAdModeEnd);
      this._localPlayer.on('adbreakstart', this.onLocalPlayerAdBreakStart);
      this._localPlayer.on('adbreakend', this.onLocalPlayerAdBreakEnd);
      this._localPlayer.on('ads-ad-started', this.onLocalPlayerAdsAdStarted);
      this._localPlayer.on('ads-ad-ended', this.onLocalPlayerAdsAdEnded);
      this._localPlayer.on('ads-play', this.onLocalPlayerAdsPlay);
      this._localPlayer.on('ads-pause', this.onLocalPlayerAdsPause);
      this._localPlayer.on('ads-ad-timeupdate', this.onLocalPlayerAdsAdTimeUpdate);
      // @ts-ignore TS2551
      const textTracks = this._localPlayer.textTracks();
      textTracks.addEventListener('change', this.textTracksChange);
      textTracks.addEventListener('selectedlanguagechange', this.textTracksSelectedlanguagechange);
      textTracks.addEventListener('labelchange', this.textTracksLabelchange);

      // @ts-ignore TS2551
      const audioTracks = this._localPlayer.audioTracks();
      audioTracks.addEventListener('change', this.audioTracksChange);

      // @ts-ignore TS2339
      if (this._fsApp) {
        // @ts-ignore TS2339
        this._fsApp.on('onFullScreenChange', this.onFullscreenChange);
        // @ts-ignore TS2339
        this._fsApp.on('beforeFullScreenChange', this.beforeFullScreenChange);
      }
    }
  }

  onLocalPlayerOutputRestricted(e) {
    // console.log('output-restricted', e);
    // @ts-ignore TS2339
    this._emitter.emit('output-restricted');
  }

  onLocalPlayerEnterPictureInPicture(e) {
    // console.log('onLocalPlayerEnterPictureInPicture', e);
    // @ts-ignore TS2339
    this._emitter.emit('enterpictureinpicture');
  }
  onLocalPlayerLeavePictureInPicture(e) {
    // console.log('onLocalPlayerEnterPictureInPicture', e);
    // @ts-ignore TS2339
    this._emitter.emit('leavepictureinpicture');
  }
  onLocalPlayerWebkitPresentationModeChanged(e) {
    // console.log('onLocalPlayerWebkitPresentationModeChanged', e);
    if (this.isInPictureInPicture()) {
      // @ts-ignore TS2339
      this._emitter.emit('enterpictureinpicture');
    } else {
      // @ts-ignore TS2339
      this._emitter.emit('leavepictureinpicture');
    }
  }

  textTracksChange(e) {
    // console.log('textTracksChange', e);
    // @ts-ignore TS2339
    this._emitter.emit('textTracksChange');
  }
  textTracksSelectedlanguagechange(e) {
    // console.log('textTracksSelectedlanguagechange', e);
  }
  textTracksLabelchange(e) {
    // console.log('textTracksLabelchange', e);
  }

  audioTracksChange(e) {
    // @ts-ignore TS2339
    this._emitter.emit('audioTracksChange');
  }

  loaded(flag) {
    // console.log('PlayerApp: loaded: ' + flag);
    // @ts-ignore TS2551
    if (flag === undefined) return this.__loaded;
    // @ts-ignore TS2551
    if (flag === this.__loaded) return;
    if (flag) {
      // @ts-ignore TS2551
      this.__loaded = flag;
      // @ts-ignore TS2339
      this._emitter.emit('loaded');
      // @ts-ignore TS2339
      if (this.__autoplay && !this.__started) {
        // playが先に呼ばれる場合startedの更新はしていない為
        // このタイミングでpausedでなければstaredを呼ぶ
        if (!this.localPlayer().paused()) {
          this.started(true);
        } else {
          // console.log('PlayerApp: play 1')
          setTimeout(async () => {
            try {
              await this.localPlayer().play();
            } catch (e) {
              console.error(e);
              // @ts-ignore TS2339
              this.__autoplayError = true;
              // @ts-ignore TS2339
              this._emitter.emit('autoPlayError');
            }
          }, 0);
        }
      }
      // @ts-ignore TS2551
      if (this.__started) {
        // @ts-ignore TS2339
        this._emitter.emit('playStartCompleted');
      }
    } else {
      // @ts-ignore TS2551
      this.__loaded = flag;
    }
  }

  started(flag) {
    // console.log('PlayerApp: started: ' + flag);
    // @ts-ignore TS2551
    if (flag === undefined) return this.__started;
    // @ts-ignore TS2551
    if (flag === this.__started) return;
    if (flag) {
      // @ts-ignore TS2551
      if (this.__loaded) {
        // @ts-ignore TS2551
        this.__started = flag;
        // @ts-ignore TS2339
        if (this.__firstPlay) {
          // @ts-ignore TS2339
          this._emitter.emit('firstplay');
        }
        // @ts-ignore TS2339
        this._emitter.emit('playStartCompleted');
      }
    } else {
      // @ts-ignore TS2551
      this.__started = flag;
    }
  }

  onLocalPlayerAdModeStart() {
    // console.log('PlayerApp: onLocalPlayerAdModeStart');
    // @ts-ignore TS2339
    this._emitter.emit('admodestart');
  }

  onLocalPlayerAdModeEnd() {
    // console.log('PlayerApp: onLocalPlayerAdModeEnd');
    // @ts-ignore TS2339
    this._emitter.emit('admodeend');
  }

  onLocalPlayerAdBreakStart() {
    // console.log('PlayerApp: onLocalPlayerAdBreakStart');
    // @ts-ignore TS2339
    this._emitter.emit('adbreakstart');
  }

  onLocalPlayerAdBreakEnd() {
    // console.log('PlayerApp: onLocalPlayerAdBreakEnd');
    // @ts-ignore TS2339
    this._emitter.emit('adbreakend');
  }

  onLocalPlayerAdsAdStarted() {
    // console.log('PlayerApp: onLocalPlayerAdsAdStarted');
    // @ts-ignore TS2551
    if (!this._loaded) {
      this.loaded(true);
    }
    this.started(true);
    // @ts-ignore TS2339
    this._emitter.emit('ads-ad-started');
  }

  onLocalPlayerAdsAdEnded() {
    // console.log('PlayerApp: onLocalPlayerAdsAdEnded');
    // @ts-ignore TS2339
    this._emitter.emit('ads-ad-ended');
  }

  onLocalPlayerAdsPlay() {
    // console.log('PlayerApp: onLocalPlayerAdsPlay');
    // @ts-ignore TS2339
    this._emitter.emit('ads-play');
  }

  onLocalPlayerAdsPause() {
    // console.log('PlayerApp: onLocalPlayerAdsPause');
    // @ts-ignore TS2339
    this._emitter.emit('ads-pause');
  }

  onLocalPlayerAdsAdTimeUpdate() {
    //console.log('PlayerApp: onLocalPlayerAdsAdTimeUpdate');
    // @ts-ignore TS2339
    this._emitter.emit('ads-ad-timeupdate');
  }

  canplayed(flag) {
    // console.log('PlayerApp: canplayed: ' + flag);
    // @ts-ignore TS2551
    if (flag === undefined) return this.__canplayed;
    // @ts-ignore TS2551
    if (flag === this.__canplayed) return;
    if (flag) {
      // @ts-ignore TS2551
      this.__canplayed = flag;
      // canplayが呼ばれる前にplayが呼ばれるケースがあり
      // 先にplayされた場合はこのタイミングで再生させる
      // @ts-ignore TS2339
      if (this.__autoplay && this.__started && !this.__paused) {
        // console.log('PlayerApp: play 2')
        setTimeout(() => {
          this.localPlayer().play();
        }, 0);
      }
    } else {
      // @ts-ignore TS2551
      this.__canplayed = flag;
    }
  }

  // player events

  onLocalPlayerLoadstart() {
    // console.log('PlayerApp: onLocalPlayerLoadstart');
    // @ts-ignore TS2339
    this._emitter.emit('loadstart');
  }

  onLocalPlayerLoadedmetadata() {
    // console.log('PlayerApp: onLocalPlayerLoadedmetadata');
    // @ts-ignore TS2339
    this._emitter.emit('loadedmetadata');
  }

  onLocalPlayerLoadeddata() {
    // console.log('PlayerApp: onLocalPlayerLoadeddata');
    this.triggerLoadEvent();
    // @ts-ignore TS2339
    this._emitter.emit('loadeddata');
  }

  onLocalPlayerProgress() {
    // console.log('PlayerApp: onLocalPlayerProgress');
    // @ts-ignore TS2339
    this._emitter.emit('progress');
  }

  onLocalPlayerStateChange() {
    const player = this.localPlayer();
    if (player) {
      // @ts-ignore TS2339
      const playerState = player.getState();
      // console.log('onLocalPlayerStateChange', playerState);
      if (playerState === 'playing') {
        this._emitter.emit('playing');
      }
    }
  }

  onLocalPlayerPause() {
    // console.log('PlayerApp: onLocalPlayerPause');
    // @ts-ignore TS2339
    if (this.playReload) return;

    const exec = () => {
      this.stopBeacon();
      // @ts-ignore TS2339
      if (typeof this.__pFrom !== 'undefined') {
        // @ts-ignore TS2345
        let addPt = parseInt((new Date().getTime() - this.__pFrom) / 1000, 10);
        if (!isNaN(addPt)) {
          // @ts-ignore TS2339
          this.__pt += addPt;
        }
        // @ts-ignore TS2554
        this.trackEvent('pause', { pt: parseInt(this.__pt, 10) });
      }
      // @ts-ignore TS2339
      this.__pFrom = undefined;
      // @ts-ignore TS2551
      this.__paused = true;

      // @ts-ignore TS2339
      if (this.pauseTimer) clearTimeout(this.pauseTimer);
      // @ts-ignore TS2339
      delete this.pauseTimer;
      // @ts-ignore TS2339
      this._state = 'pause';
      // @ts-ignore TS2339
      this._emitter.emit('pause');

      // シーク中だったらendedしない
      // @ts-ignore TS2339
      if (this._overSeeking && !this.__seeking) {
        this.onLocalPlayerEnded();
      }
    };

    // mac safari フルスクリーンにした時にpause とplay が連続で通知されるケースがある
    // @ts-ignore TS2339
    this.pauseTimer = setTimeout(exec, 0);
  }

  onLocalPlayerPlay() {
    // console.log('PlayerApp: onLocalPlayerPlay');

    // @ts-ignore TS2339
    this.__autoplayError = false;

    // @ts-ignore TS2339
    if (this.playReload) return;

    // @ts-ignore TS2554
    if (!this.loaded() || !this.canplayed()) {
      // @ts-ignore TS2339
      this.__autoplay = true;
    }
    this.started(true);
    // @ts-ignore TS2339
    this.__pFrom = new Date().getTime();
    // @ts-ignore TS2554
    this.trackEvent('play', { pt: parseInt(this.__pt, 10) });
    this.startBeacon();
    // @ts-ignore TS2551
    this.__paused = false;
    // @ts-ignore TS2339
    this.__seeking = false;

    // @ts-ignore TS2339
    if (this.pauseTimer) clearTimeout(this.pauseTimer);
    // @ts-ignore TS2339
    delete this.pauseTimer;
    // @ts-ignore TS2339
    this._state = 'play';
    // @ts-ignore TS2339
    this._emitter.emit('play');
  }

  onLocalPlayerPlaying() {
    // console.log('PlayerApp: onLocalPlayerPlaying');

    // 自動再生エラーしてるのにイベントが発生する
    // が、本当に再生されている場合があるのでpauseを判断する
    // @ts-ignore TS2339
    if (this.__autoplayError && this.paused()) return;

    // @ts-ignore TS2554
    if (!this.started()) this.started(true);

    // @ts-ignore TS2339
    this.seekToEnded = false;
    // @ts-ignore TS2339
    if (!this.__pFrom) {
      // @ts-ignore TS2339
      this.__pFrom = new Date().getTime();
    }
    // @ts-ignore TS2551
    if (!this.__paused) {
      // @ts-ignore TS2551
      this.__paused = false;
    }
    // @ts-ignore TS2339
    this.__seeking = false;
    // @ts-ignore TS2339
    this._state = 'play';
    // @ts-ignore TS2339
    this._emitter.emit('playing');
  }

  onLocalPlayerEnded() {
    // console.log('PlayerApp: onLocalPlayerEnded');
    // @ts-ignore TS2339
    if (this.playReload) {
      // @ts-ignore TS2339
      this.localPlayer().currentTime(0);
      return;
    }
    this.stopBeacon();
    // @ts-ignore TS2339
    if (typeof this.__pFrom !== 'undefined') {
      // @ts-ignore TS2345
      let addPt = parseInt((new Date().getTime() - this.__pFrom) / 1000, 10);
      if (!isNaN(addPt)) {
        // @ts-ignore TS2339
        this.__pt += addPt;
      }
      // @ts-ignore TS2554
      this.trackEvent('ended', { pt: parseInt(this.__pt, 10) });
    } else {
      // @ts-ignore TS2554
      this.trackEvent('ended');
    }
    // @ts-ignore TS2339
    this.endedTimer = setTimeout(() => {
      // @ts-ignore TS2339
      if (this.endedTimer) clearTimeout(this.endedTimer);
      // @ts-ignore TS2339
      delete this.endedTimer;
    }, 100);
    // @ts-ignore TS2339
    this._state = 'ended';
    // @ts-ignore TS2339
    this._emitter.emit('ended');
    // @ts-ignore TS2339
    this.__pFrom = undefined;
    // @ts-ignore TS2339
    this._overSeeking = false;
  }

  onLocalPlayerError() {
    // console.log('PlayerApp: onLocalPlayerError');
  }

  onLocalPlayerDurationchange() {
    // console.log('PlayerApp: onLocalPlayerDurationchange');
    if (this.localPlayer().duration() === Infinity) {
      this.startReflashSeekThumbnailTrack();

      // ライブの時にこのタイミングでplayさせると再生されない
      // チャンネルの時にこのタイミングでplayさせないと再生されない
      // 送らせて処理させる
      setTimeout(() => {
        this.triggerLoadEvent();
      }, 1000);
    }
    // @ts-ignore TS2339
    this._emitter.emit('durationchange', this.localPlayer().duration());
  }

  onLocalPlayerRatechange() {
    // console.log('PlayerApp: onLocalPlayerRatechange');
    const playbackRate = this.localPlayer().playbackRate();
    if (playbackRate === 0) return;
    // @ts-ignore TS2339
    this._emitter.emit('ratechange', playbackRate);
  }

  onLocalPlayerVolumeChange() {
    // console.log('PlayerApp: onLocalPlayerVolumeChange', this.localPlayer().volume());
    // @ts-ignore TS2339
    this._emitter.emit('volumechange', this.localPlayer().volume());
  }

  onLocalPlayerStalled() {
    // console.log('PlayerApp: onLocalPlayerStalled');
    // @ts-ignore TS2339
    this._emitter.emit('stalled');
  }

  onLocalPlayerEmptied() {
    // console.log('PlayerApp: onLocalPlayerEmptied');
    // @ts-ignore TS2339
    this._emitter.emit('emptied');
  }

  onLocalPlayerSuspend() {
    // console.log('PlayerApp: onLocalPlayerSuspend');
    // @ts-ignore TS2339
    this._emitter.emit('suspend');
  }

  onLocalPlayerWaiting() {
    // console.log('PlayerApp: onLocalPlayerWaiting');
    // @ts-ignore TS2339
    this._emitter.emit('waiting');
  }

  onLocalPlayerBuffering() {
    // console.log('PlayerApp: onLocalPlayerBuffering');
    // @ts-ignore TS2339
    this._emitter.emit('buffering');
  }

  onLocalPlayerBuffered() {
    // console.log('PlayerApp: onLocalPlayerBuffered');
    // @ts-ignore TS2339
    this._emitter.emit('buffered');
  }

  onLocalPlayerCanplay() {
    // console.log('PlayerApp: onLocalPlayerCanplay');
    this.triggerLoadEvent();
    this.canplayed(true);
    // @ts-ignore TS2339
    this._emitter.emit('canplay');
  }

  onLocalPlayerSeeked() {
    // console.log('PlayerApp: onLocalPlayerSeeked');
    // @ts-ignore TS2339
    if (this.endedTimer) return;

    // @ts-ignore TS2339
    this.seekToEnded = true;
    // @ts-ignore TS2339
    this.__seeking = false;
    // @ts-ignore TS2339
    this._emitter.emit('seeked');

    // @ts-ignore TS2339
    if (this._overSeeking) {
      this.onLocalPlayerEnded();
    }
  }

  onLocalPlayerSeeking() {
    // console.log('PlayerApp: onLocalPlayerSeeking');
    // @ts-ignore TS2339
    if (this.endedTimer) return;

    // @ts-ignore TS2339
    this.__seeking = true;
    // @ts-ignore TS2339
    this._state = null;
    // @ts-ignore TS2339
    this._emitter.emit('seeking');
  }

  onLocalPlayerCanplaythrough() {
    // console.log('PlayerApp: onLocalPlayerCanplaythrough');
    // @ts-ignore TS2339
    this._emitter.emit('canplaythrough');
  }

  onLocalPlayerFirstplay() {
    // console.log('PlayerApp: onLocalPlayerFirstplay');
    // @ts-ignore TS2551
    if (this.__started) {
      // @ts-ignore TS2339
      this._emitter.emit('firstplay');
    } else {
      // @ts-ignore TS2339
      this.__firstPlay = true;
    }
  }

  onLocalPlayerTimeupdate() {
    // console.log('PlayerApp: onLocalPlayerTimeupdate');
    // @ts-ignore TS2339
    if (this.endedTimer) return;

    // @ts-ignore TS2339
    this.__currentPlayerTime = this.localPlayer().currentTime();

    // playbackRateが0に変更されTimeupdateが一度通知されて止まる
    // @ts-ignore TS2339
    if (this.sendEndedTimer) {
      // @ts-ignore TS2339
      clearTimeout(this.sendEndedTimer);
      // @ts-ignore TS2339
      delete this.sendEndedTimer;
    }
    // @ts-ignore TS2339
    this.sendEndedTimer = setTimeout(() => {
      if (!this.localPlayer()) return;
      const duration = this.localPlayer().duration();
      if (
        duration !== Infinity &&
        // @ts-ignore TS2339
        duration - this.__currentPlayerTime < 1 &&
        this.localPlayer().playbackRate() == 0 &&
        !this.localPlayer().paused()
      ) {
        // @ts-ignore TS2339
        this._state = 'ended';
        // @ts-ignore TS2339
        this._emitter.emit('ended');
      }
    }, 1000);

    this.milestone();
    // @ts-ignore TS2339
    this._emitter.emit('timeupdate', this.localPlayer().currentTime());
  }

  onLocalPlayerSrcFallback() {
    // console.log('PlayerApp: onLocalPlayerSrcFallback');
    // @ts-ignore TS2339
    this._emitter.emit('src-fallback');
  }

  onLocalPlayerPlayTerminated() {
    // console.log('PlayerApp: onLocalPlayerPlayTerminated');
    // this._emitter.emit('play-terminated');
    // @ts-ignore TS2339
    this.__autoplayError = true;
    // @ts-ignore TS2339
    this._emitter.emit('autoPlayError');
  }

  onLocalPlayerAutoplayFailure() {
    // console.log('PlayerApp: onLocalPlayerAutoplayFailure');
    // @ts-ignore TS2339
    this.__autoplayError = true;
    // @ts-ignore TS2339
    this._emitter.emit('autoPlayError');
  }

  onLocalPlayerAutoplaySuccess() {
    // console.log('PlayerApp: onLocalPlayerAutoplaySuccess');
  }

  triggerLoadEvent() {
    // console.log('PlayerApp: triggerLoadEvent', this.loaded());
    this.loaded(true);
  }

  localPlayer() {
    if (this._localPlayer) {
      // @ts-ignore TS2551
      if (typeof this._localPlayer.loadMedia === 'function') {
        return this._localPlayer;
        // @ts-ignore TS2551
      } else if (this._localPlayer.player_) {
        return this._localPlayer;
      }
    }
    return null;
  }

  castPlayer() {
    // @ts-ignore TS2551
    if (this._gCastPlayer) return this._gCastPlayer;
    return null;
  }

  retrievePlayerId(userId) {
    // console.log('retrievePlayerId', this._gCastPlayer.playerId)
    const exec = () => {
      return new Promise((resolve, reject) => {
        // @ts-ignore TS2551
        if (this._gCastPlayer.playerId) return resolve();
        // @ts-ignore TS2339
        var session = window.cast.framework.CastContext.getInstance().getCurrentSession();
        if (!session) return reject('no session');

        let mediaSession = session.getMediaSession();
        if (!_.get(mediaSession, 'media')) return reject('no media');

        // @ts-ignore TS2794
        if (userId !== _.get(mediaSession.media, 'playbackData.userId')) return resolve();

        // @ts-ignore TS2794
        if (!_.get(mediaSession.media, 'playbackData.playerId')) return resolve();

        // @ts-ignore TS2339
        this._playerId = mediaSession.media.playbackData.playerId;
        // @ts-ignore TS2551
        this._gCastPlayer.playerId = this._playerId;
        // @ts-ignore TS2551
        this._gCastPlayer.activePlayerId = this._playerId;
        // @ts-ignore TS2794
        resolve();
      });
    };
    return promiseRetry(exec, { retryDelay: 100 });
  }

  gCast(requestParams = {}) {
    //console.log('PlayerApp: gCast');
    // @ts-ignore TS2551
    if (!this._gCastPlayer._apiAvailable) return;
    // @ts-ignore TS2339
    if (this._playerId != this._gCastPlayer.activePlayerId) return;

    // @ts-ignore TS2339
    var session = window.cast.framework.CastContext.getInstance().getCurrentSession();
    if (session) {
      let mediaSession = session.getMediaSession();
      if (mediaSession && mediaSession.media && mediaSession.media.playbackData) {
        // mediaSession.mediaのcustomDataが空になる
        // @ts-ignore TS2339
        this._playerId = mediaSession.media.playbackData.playerId;
        // @ts-ignore TS2551
        this._gCastPlayer.playerId = mediaSession.media.playbackData.playerId;
      } else {
        // @ts-ignore TS2551
        this._gCastPlayer.playerId = this._playerId;
      }
      // @ts-ignore TS2551
      this._gCastActive = true;
    }

    let vuid = VUID;

    // vuidキャッシュがあるときはキャッシュを返す
    if (!vuid) {
      vuid = vuidDataStore.get();
    }

    // vuidないときはキャッシュする
    if (!vuid) {
      vuid = vuidDataStore.create();
      vuidDataStore.set(vuid);
    }

    if (vuid) {
      VUID = vuid;
    }

    if (!DSID) {
      // @ts-ignore TS2339
      DSID = this._cookies.get(DSID_KEY);
      if (!DSID) {
        DSID = generateUuid(false);
      }
      if (window.navigator.cookieEnabled) {
        // @ts-ignore TS2339
        this._cookies.set(DSID_KEY, DSID, { path: '/' });
      }
    }

    // @ts-ignore TS2339
    requestParams.vuid = VUID;
    // @ts-ignore TS2339
    requestParams.dsid = DSID;
    // @ts-ignore TS2339
    requestParams.playerId = this._playerId;

    // @ts-ignore TS2339
    requestParams.device = {
      ua: window.navigator.userAgent,
      // manufacturer: "apple",
      // display_name: "ll_shioiのiPhone",
      // model: "iPhone",
      // localized_model: "iPhone",
      // system_name: "iOS",
      // system_version: "14.8",
      // mccmnc: "440.51",
      // hw_machine: "iPhone13,4",
      // app_version: "1.0.0",
      // app_build_version: "42",
    };

    // @ts-ignore TS2551
    this._gCastPlayer._requestParams = requestParams;
    // @ts-ignore TS2551
    this._gCastPlayer._currentMediaTime = 0;

    // @ts-ignore TS2339
    this._emitter.emit('sessionClose');
    // @ts-ignore TS2551
    this._gCastPlayer.castStart();
  }

  gCastActive() {
    // @ts-ignore TS2551
    return this._gCastPlayer.playerId == this._playerId;
  }

  // castできるか
  isGCastAvailable() {
    // @ts-ignore TS2551
    return this._gCastPlayer && this._gCastPlayer._apiAvailable && this._gCastPlayer.isCastConnected();
  }

  // キャスト中か
  isGCasted() {
    // @ts-ignore TS2551
    return this._gCastPlayer && this._gCastPlayer.isConnected;
  }

  // 自身のキャストか
  isActiveGCasted() {
    // 自身のインスタンスからキャストしたのかを判定する必要があるのでplayerIdを判定する。
    // @ts-ignore TS2551
    return this._gCastPlayer && this._gCastPlayer.isConnected && this._gCastPlayer.playerId == this._playerId;
  }

  isSelfGCasted(userId, checkMetaId = false) {
    // 自身のインスタンスからキャストしたのかを判定
    if (this.isGCasted()) {
      // @ts-ignore TS2339
      var session = window.cast.framework.CastContext.getInstance().getCurrentSession();
      if (session) {
        let mediaSession = session.getMediaSession();
        if (mediaSession && mediaSession.media) {
          if (checkMetaId) {
            return (
              userId === _.get(mediaSession.media, 'playbackData.userId') &&
              // @ts-ignore TS2339
              this._metaId == _.get(mediaSession.media, 'playbackData.metaId')
            );
          }
          return userId === _.get(mediaSession.media, 'playbackData.userId');
        } else {
          throw 'mediaSession is null';
        }
      }
    }
    return false;
  }

  clickGCastButton() {
    // @ts-ignore TS2551
    this._gCastPlayer.activePlayerId = this._playerId;
  }

  getGCastDeviceName() {
    // @ts-ignore TS2551
    return this._gCastPlayer.getFriendlyName();
  }

  gCastAutoplay(flag) {
    if (flag === undefined) {
      // @ts-ignore TS2551
      if (this._gCastAutoplay === undefined) this._gCastAutoplay = false;
      // @ts-ignore TS2551
      return this._gCastAutoplay;
    }
    // @ts-ignore TS2551
    this._gCastAutoplay = flag;
  }

  addEmitterListener(eventName, fn) {
    if (eventName == 'sendEvent') {
      // @ts-ignore TS2554
      this.removeEmitterListener(eventName);
    }
    // @ts-ignore TS2339
    this._listeners.push({
      // @ts-ignore TS2339
      subscription: this._emitter.addListener(eventName, fn),
      eventName: eventName,
      fn: fn,
    });
  }

  removeEmitterListener(eventName, fn) {
    // @ts-ignore TS2339
    let index = _.findIndex(this._listeners, listener => {
      if (fn) {
        // @ts-ignore TS2339
        return listener.eventName == eventName && listener.fn == fn;
      } else {
        // @ts-ignore TS2339
        return listener.eventName == eventName;
      }
    });
    if (index !== -1) {
      // @ts-ignore TS2339
      this._listeners[index].subscription.remove();
      // @ts-ignore TS2339
      this._listeners.splice(index, 1);
    }
  }

  // playerApp interface

  on(eventName, fn) {
    //console.log('PlayerApp: on', eventName);
    if (this.localPlayer()) {
      this._localPlayer.on(eventName, fn);
    }
    this.addEmitterListener(eventName, fn);
    // @ts-ignore TS2551
    if (this._gCastPlayer) {
      // @ts-ignore TS2551
      this._gCastPlayer.on(eventName, fn);
    }
  }

  off(eventName, fn) {
    //console.log('PlayerApp: off', eventName);
    if (this.localPlayer()) {
      this._localPlayer.off(eventName, fn);
    }
    this.removeEmitterListener(eventName, fn);
    // @ts-ignore TS2551
    if (this._gCastPlayer) {
      // @ts-ignore TS2551
      this._gCastPlayer.off(eventName, fn);
    }
  }

  seekable() {
    // console.log('seekable');
    // @ts-ignore TS2551
    if (this.isGCasted()) return this._gCastPlayer.seekable();
    // @ts-ignore TS2551
    if (this.localPlayer()) return this._localPlayer.seekable();
    return false;
  }

  currentTime(currentTime) {
    // console.log('currentTime', currentTime);

    // duration以上を指定された時は擬似的にendedさせる
    // @ts-ignore TS2339
    if (this._overSeeking) return this.duration();
    if (currentTime !== undefined) {
      const duration = this.duration();
      if (duration !== Infinity && duration <= currentTime) {
        // @ts-ignore TS2339
        this._overSeeking = true;
        // @ts-ignore TS2551
        if (this.__paused) {
          // @ts-ignore TS2339
          if (!this.__seeking) this.onLocalPlayerEnded();
        } else {
          this.pause();
        }
        return;
      }
    }

    // @ts-ignore TS2551
    if (this.isGCasted()) return this._gCastPlayer.currentTime(currentTime);
    if (this.localPlayer()) {
      // @ts-ignore TS2339
      if (currentTime === undefined && this._state === 'ended') {
        const duration = this.duration();
        if (duration !== Infinity) return duration;
      }
      // @ts-ignore TS2551
      return this._localPlayer.currentTime(currentTime);
    }
    return 0;
  }

  duration() {
    // console.log('duration', this.playerHandler.getMediaDuration());
    // @ts-ignore TS2551
    if (this.isGCasted()) return this._gCastPlayer.duration();
    // @ts-ignore TS2551
    if (this.localPlayer()) return this._localPlayer.duration();
    return 0;
  }

  volume(volume) {
    // console.log('volume', volume);
    // @ts-ignore TS2551
    if (this.isGCasted()) return this._gCastPlayer.volume(volume);
    // @ts-ignore TS2551
    if (this.localPlayer()) return this._localPlayer.volume(volume);
    return 0;
  }

  muted(muted) {
    // console.log('muted', muted);
    // @ts-ignore TS2551
    if (this.isGCasted()) return this._gCastPlayer.muted(muted);
    // @ts-ignore TS2551
    if (this.localPlayer()) return this._localPlayer.muted(muted);
    return null;
  }

  playbackRate(rate) {
    // console.log('playbackRate', rate);
    // @ts-ignore TS2551
    if (this.isGCasted()) return this._gCastPlayer.playbackRate(rate);
    // @ts-ignore TS2551
    if (this.localPlayer()) return this._localPlayer.playbackRate(rate);
    return null;
  }

  buffered() {
    // console.log('buffered');
    // @ts-ignore TS2551
    if (this.isGCasted()) return this._gCastPlayer.buffered();
    // @ts-ignore TS2551
    if (this.localPlayer()) return this._localPlayer.buffered();
    return null;
  }

  play() {
    // console.log('PlayerApp: play');
    // @ts-ignore TS2554
    if (!this.started() && !this.__paused) {
      // @ts-ignore TS2339
      this.__autoplay = true;
      // @ts-ignore TS2339
      if (!this.__autoplayError) return null;
    }
    // @ts-ignore TS2551
    if (this.isGCasted()) return this._gCastPlayer.play();
    // @ts-ignore TS2551
    if (this.localPlayer()) return this._localPlayer.play();
    return null;
  }

  pause() {
    // console.log('PlayerApp: pause');
    // @ts-ignore TS2339
    this.__autoplay = false;
    // @ts-ignore TS2551
    if (this.isGCasted()) return this._gCastPlayer.pause();
    // @ts-ignore TS2551
    if (this.localPlayer()) return this._localPlayer.pause();
    return null;
  }

  paused() {
    // @ts-ignore TS2551
    if (this.isGCasted()) return this._gCastPlayer.paused();
    // @ts-ignore TS2551
    if (this.localPlayer() && typeof this._localPlayer.paused === 'function') return this._localPlayer.paused();
    // @ts-ignore TS2551
    return this.__paused;
  }

  qualityLevels() {
    //console.log('qualityLevels');
    // @ts-ignore TS2551
    if (this.isGCasted()) return this._gCastPlayer.qualityLevels();
    if (this.localPlayer()) {
      // @ts-ignore TS2551
      if (typeof this._localPlayer.qualityLevels === 'function') {
        // @ts-ignore TS2551
        return this._localPlayer.qualityLevels();
      }
    }
    return null;
  }

  _allTextTracks() {
    let _textTracks = [];
    if (this.isGCasted()) {
      // @ts-ignore TS2551
      _textTracks = this._gCastPlayer.textTracks();
    } else if (this.localPlayer()) {
      // @ts-ignore TS2551
      _textTracks = this._localPlayer.textTracks();
    }
    return _.filter(_textTracks, textTrack => [SUBTITLES, CAPTIONS].includes(textTrack.kind));
  }

  textTracks() {
    let _textTracks = this._allTextTracks();

    // 非表示とマッピングするので出さない
    _textTracks = _.filter(_textTracks, textTrack => ![NONE_FN_LABEL.JA, NONE_FN_LABEL.EN].includes(textTrack.label));

    return _.map(_textTracks, textTrack => {
      const { id, kind, label, language } = textTrack || {};
      return {
        id,
        kind,
        label,
        set mode(mode) {
          textTrack.mode = mode;
        },
        get mode() {
          return textTrack.mode;
        },
        language,
        set default(_default) {
          textTrack.default = _default;
        },
        get default() {
          return textTrack.default;
        },
        disable: false,
      };
    });
  }

  // 非表示（強制）字幕
  noneFnTextTracks() {
    let _textTracks = this._allTextTracks();
    return _.filter(
      _textTracks,
      textTrack => textTrack.label === NONE_FN_LABEL.JA || textTrack.label === NONE_FN_LABEL.EN,
    );
  }

  audioTracks() {
    let _audioTracks;
    if (this.isGCasted()) {
      // @ts-ignore TS2551
      _audioTracks = this._gCastPlayer.audioTracks();
    } else if (this.localPlayer()) {
      // @ts-ignore TS2551
      _audioTracks = this._localPlayer.audioTracks();
    } else {
      return null;
    }
    return _.map(_audioTracks, audioTrack => {
      const { id, kind, label, language } = audioTrack || {};

      let audioLabel = label;

      switch (language) {
        case 'ja':
          audioLabel = '日本語';
          break;
        case 'en':
          audioLabel = '英語';
          break;
        default:
          break;
      }

      return {
        id,
        kind,
        label: audioLabel,
        set enabled(enabled) {
          audioTrack.enabled = enabled;
        },
        get enabled() {
          return audioTrack.enabled;
        },
        language,
        disable: false,
      };
    });
  }

  isInPictureInPicture() {
    // @ts-ignore TS2551
    if (this.localPlayer()) return this._localPlayer.isInPictureInPicture();
    return false;
  }

  requestPictureInPicture() {
    // if (this.isGCasted()) return this._gCastPlayer.requestPictureInPicture();
    // @ts-ignore TS2551
    if (this.localPlayer()) return this._localPlayer.requestPictureInPicture();
    return null;
  }

  exitPictureInPicture() {
    // if (this.isGCasted()) return this._gCastPlayer.exitPictureInPicture();
    // @ts-ignore TS2551
    if (this.localPlayer()) return this._localPlayer.exitPictureInPicture();
    return null;
  }

  reload() {
    // @ts-ignore TS2551
    if (this.isGCasted()) return this._gCastPlayer.reload();
    if (this.localPlayer()) {
      // @ts-ignore TS2339
      this.playReload = true;
      // @ts-ignore TS2339
      if (this.seekToEnded) {
        this.localPlayer().currentTime(this.localPlayer().currentTime() - 1);
      } else {
        this.localPlayer().currentTime(0);
      }
    }
    return null;
  }

  playbackData() {
    // @ts-ignore TS2551
    if (this.isGCasted()) return this._gCastPlayer.playbackData();
    return null;
  }

  settings(settings) {
    // @ts-ignore TS2551
    if (this.isGCasted()) return this._gCastPlayer.settings(settings);
    return null;
  }

  seekPreview() {
    // @ts-ignore TS2551
    if (this.isGCasted()) return this._gCastPlayer.seekPreview();
    return null;
  }

  playbackRule() {
    // @ts-ignore TS2551
    if (this.isGCasted()) return this._gCastPlayer.playbackRule();
    return null;
  }

  milestone() {
    //console.log('PlayerApp: milestone');
    // milestoneの計算を行う
    if (this.duration() && this.duration() !== Infinity) {
      // @ts-ignore TS2339
      const milestone = this.__currentPlayerTime / this.duration();
      // @ts-ignore TS2551
      if (this.__milestone < milestone) {
        // @ts-ignore TS2551
        if (milestone >= 0.25 && this.__milestone < 0.25) {
          // @ts-ignore TS2551
          this.__milestone = 0.25;
          // @ts-ignore TS2554
          this.trackEvent('milestone', { percentage: '25' });
        }
        // @ts-ignore TS2551
        if (milestone >= 0.5 && this.__milestone < 0.5) {
          // @ts-ignore TS2551
          this.__milestone = 0.5;
          // @ts-ignore TS2554
          this.trackEvent('milestone', { percentage: '50' });
        }
        // @ts-ignore TS2551
        if (milestone >= 0.75 && this.__milestone < 0.75) {
          // @ts-ignore TS2551
          this.__milestone = 0.75;
          // @ts-ignore TS2554
          this.trackEvent('milestone', { percentage: '75' });
        }
        // @ts-ignore TS2551
        if (milestone >= 0.9 && this.__milestone < 0.9) {
          // @ts-ignore TS2551
          this.__milestone = 0.9;
          // @ts-ignore TS2554
          this.trackEvent('milestone', { percentage: '90' });
        }
      }
    }
  }

  startBeacon() {
    if (!this.localPlayer()) return;
    //console.log('PlayerApp: beacon::start');

    // @ts-ignore TS2339
    if (this.__beaconIntervalId) return;
    // @ts-ignore TS2339
    this.__beaconIntervalId = setInterval(() => {
      // @ts-ignore TS2339
      this._emitter.emit('beacon');
    }, BEACON_SPAN * 1000);
  }

  stopBeacon() {
    if (!this.localPlayer()) return;
    //console.log('PlayerApp: beacon::stop');

    // @ts-ignore TS2339
    if (this.__beaconIntervalId) {
      // @ts-ignore TS2339
      clearInterval(this.__beaconIntervalId);
      // @ts-ignore TS2339
      this.__beaconIntervalId = undefined;
    }
  }

  trackEvent(event, params = {}, async) {
    if (!this.localPlayer()) return;
    //console.log('PlayerApp: trackEvent', event, params);

    if (!event) return;

    // TODO: この値を毎回そもそも送る必要性があるのか疑問ではある
    params = Object.assign(
      {
        event,
      },
      params,
    );

    // @ts-ignore TS2339
    this._emitter.emit('sendEvent', '/track', params, async);
  }

  beacon(analytics, params = {}, async) {
    if (!this.localPlayer()) return;
    //console.log('PlayerApp: beacon', params);

    if (analytics.psid) {
      // @ts-ignore TS2339
      params.psid = analytics.psid;
    }

    // @ts-ignore TS2339
    this._emitter.emit('sendEvent', '/beacon', params, async);
  }

  sendEvent(path, params = {}, browserInfo, profile, isFullScreen, playbackRate, activeVideo, trackService, async) {
    if (!this.localPlayer()) return;
    //console.log('PlayerApp: sendEvent', path, params);

    const metadata = _.get(activeVideo, 'metadata', {});
    const analytics = _.get(activeVideo, 'analytics', {});

    /**
     * device_type
     * 001=PC（Windows）
     * 002=PC（Mac）
     * 003=PC（その他）
     * 004=iOS
     * 005=Android
     * 006=Fire TVstick
     */
    // let device_type = '001';
    // if (browserInfo.isMac) {
    //   device_type == '002';
    // } else if (browserInfo.isWindows7 || browserInfo.isWindows8 || browserInfo.isWindows8_1 || browserInfo.isWindows10) {
    //   device_type == '001';
    // } else if (browserInfo.isIOS) {
    //   device_type == '004';
    // } else if (browserInfo.isAndroid) {
    //   device_type == '005';
    // } else if (!browserInfo.isMobile) {
    //   device_type == '001';
    // }
    params = Object.assign(
      {
        // resolution: window.screen.availWidth + 'x' + window.screen.availHeight,
        // screen_mode: (isFullScreen) ? '002' : '001',
        // video_type: '001', // video_type: isAdMode ? '002' : '001',
        // kids_flag: (profile && profile.isKids) ? '1' : '0',
        // device_type,
        // delivery_type: metadata.type === 'linear_channel' ? '004' : '001', // 001=vod,002=dic,003=live,004=linear
        ts: new Date().getTime(),
        // player_ver: '0.0.0',
        token: 'web_player',
        // playback_rate: playbackRate,
      },
      analytics,
      params,
    );

    let currentTime = -1;
    if (this.localPlayer()) {
      const playback_rate = this.localPlayer().playbackRate();
      // if (params.delivery_type !== '004' && this.localPlayer().duration() === Infinity) {
      //   params.delivery_type = '003';
      // }
      // iPhoneの場合はplayerのfullscreenを参照
      // if (browserInfo.isIOS && !browserInfo.isIPad) {
      //   params.screen_mode = this.localPlayer().isFullscreen() ? '002' : '001';
      // }

      // TODO: エラーしたらcurrentTimeを取得することはできないため、この値は外部から取得すべきなきがする
      //
      if (typeof playback_rate === 'undefined') {
        currentTime = Math.floor(this.localPlayer().currentTime());
        if (isNaN(currentTime)) {
          currentTime = -1;
        }
      }
    }
    if (currentTime === -1) {
      // @ts-ignore TS2339
      currentTime = this.__currentPlayerTime;
    }
    // @ts-ignore TS2339
    params.s = parseInt(currentTime, 10);
    this.send(`${trackService.protocol}://${trackService.hostname}${path}`, params, async);
  }

  send(host, params, async = true) {
    if (!this.localPlayer()) return;
    //console.log('PlayerApp: send', host, params);

    const query = Object.keys(params)
      .map(function(key) {
        return key + '=' + encodeURIComponent(params[key]);
      })
      .join('&');

    // sendBeaconが利用できるときは利用する
    let status = false;
    if (navigator.sendBeacon) {
      try {
        status = navigator.sendBeacon(`${host}?${query}`);
      } catch (e) {
        // safariでunload時のsendBeaconはエラーする為xhrで処理する
      }
    }
    if (status) return;

    const xhr = new XMLHttpRequest();
    xhr.open('GET', `${host}?${query}`, async);
    xhr.onreadystatechange = function() {
      if (xhr.readyState === XMLHttpRequest.DONE && xhr.status === 200) {
      }
    };
    xhr.send(null);
  }

  getPlaybackSessionId(sessionId, analytics, playContext) {
    if (sessionId) {
      return sessionId;
    } else if (analytics.psid) {
      return analytics.psid;
    } else if (playContext) {
      return playContext.get('session').id;
    }
    return null;
  }

  checkVideo(playbackService, authContext, playbackSessionId, handler) {
    if (!this.localPlayer()) return;
    //console.log('PlayerApp: checkVideo');

    const xhr = new XMLHttpRequest();
    xhr.onreadystatechange = function() {
      if (xhr.readyState === 4) {
        if (xhr.status === 401 || xhr.status === 403) {
          window.location.reload();
          return;
        }
        if (handler) handler(xhr.response, xhr.status);
      }
    };

    xhr.open('POST', `${playbackService.protocol}://${playbackService.hostname}/session/check`, true);
    xhr.responseType = 'json';
    if (authContext) {
      xhr.setRequestHeader('Authorization', `Bearer ${authContext.token}`);
      xhr.setRequestHeader('X-User-Id', authContext.id);
    }
    xhr.setRequestHeader('X-Playback-Session-Id', playbackSessionId);
    xhr.withCredentials = true;
    xhr.send();
  }

  dispose(playbackService, authContext, playbackSessionId, async) {
    // console.log('PlayerApp: dispose', playbackSessionId, async);

    this.stopBeacon();
    // @ts-ignore TS2339
    if (typeof this.__pFrom !== 'undefined') {
      // @ts-ignore TS2345
      let addPt = parseInt((new Date().getTime() - this.__pFrom) / 1000, 10);
      if (!isNaN(addPt)) {
        // @ts-ignore TS2339
        this.__pt += addPt;
      }
    }
    // @ts-ignore TS2339
    this.trackEvent('dispose', { pt: parseInt(this.__pt, 10) }, async);
    // @ts-ignore TS2339
    this.__pt = 0;

    this.sessionClose(playbackService, authContext, playbackSessionId, async);

    if (this.localPlayer()) {
      this._localPlayer.off('loadstart', this.onLocalPlayerLoadstart);
      this._localPlayer.off('progress', this.onLocalPlayerProgress);
      this._localPlayer.off('pause', this.onLocalPlayerPause);
      this._localPlayer.off('play', this.onLocalPlayerPlay);
      // this._localPlayer.off('playing', this.onLocalPlayerPlaying);
      this._localPlayer.off('statechange', this.onLocalPlayerStateChange);
      this._localPlayer.off('loadedmetadata', this.onLocalPlayerLoadedmetadata);
      this._localPlayer.off('ended', this.onLocalPlayerEnded);
      this._localPlayer.off('error', this.onLocalPlayerError);
      this._localPlayer.off('durationchange', this.onLocalPlayerDurationchange);
      this._localPlayer.off('ratechange', this.onLocalPlayerRatechange);
      this._localPlayer.off('volumechange', this.onLocalPlayerVolumeChange);
      this._localPlayer.off('audiotrackchange', this.audioTracksChange);
      this._localPlayer.off('stalled', this.onLocalPlayerStalled);
      this._localPlayer.off('emptied', this.onLocalPlayerEmptied);
      this._localPlayer.off('suspend', this.onLocalPlayerSuspend);
      this._localPlayer.off('waiting', this.onLocalPlayerWaiting);
      this._localPlayer.off('buffering', this.onLocalPlayerBuffering);
      this._localPlayer.off('buffered', this.onLocalPlayerBuffered);
      this._localPlayer.off('canplay', this.onLocalPlayerCanplay);
      this._localPlayer.off('seeked', this.onLocalPlayerSeeked);
      this._localPlayer.off('seeking', this.onLocalPlayerSeeking);
      this._localPlayer.off('canplaythrough', this.onLocalPlayerCanplaythrough);
      this._localPlayer.off('firstplay', this.onLocalPlayerFirstplay);
      this._localPlayer.off('loadeddata', this.onLocalPlayerLoadeddata);
      this._localPlayer.off('timeupdate', this.onLocalPlayerTimeupdate);
      this._localPlayer.off('src-fallback', this.onLocalPlayerSrcFallback);
      this._localPlayer.off('play-terminated', this.onLocalPlayerPlayTerminated);
      this._localPlayer.off('autoplay-failure', this.onLocalPlayerAutoplayFailure);
      this._localPlayer.off('autoplay-success', this.onLocalPlayerAutoplaySuccess);
      this._localPlayer.off('enterpictureinpicture', this.onLocalPlayerEnterPictureInPicture);
      this._localPlayer.off('leavepictureinpicture', this.onLocalPlayerLeavePictureInPicture);
      this._localPlayer.off('webkitpresentationmodechanged', this.onLocalPlayerWebkitPresentationModeChanged);
      this._localPlayer.off('output-restricted', this.onLocalPlayerOutputRestricted);
      this._localPlayer.off('admodestart', this.onLocalPlayerAdModeStart);
      this._localPlayer.off('admodeend', this.onLocalPlayerAdModeEnd);
      this._localPlayer.off('adbreakstart', this.onLocalPlayerAdBreakStart);
      this._localPlayer.off('adbreakend', this.onLocalPlayerAdBreakEnd);
      this._localPlayer.off('ads-ad-started', this.onLocalPlayerAdsAdStarted);
      this._localPlayer.off('ads-ad-ended', this.onLocalPlayerAdsAdEnded);
      this._localPlayer.off('ads-play', this.onLocalPlayerAdsPlay);
      this._localPlayer.off('ads-pause', this.onLocalPlayerAdsPause);
      this._localPlayer.off('ads-ad-timeupdate', this.onLocalPlayerAdsAdTimeUpdate);

      // @ts-ignore TS2551
      const textTracks = this._localPlayer.textTracks();
      if (textTracks) {
        textTracks.removeEventListener('change', this.textTracksChange);
        textTracks.removeEventListener('selectedlanguagechange', this.textTracksSelectedlanguagechange);
        textTracks.removeEventListener('labelchange', this.textTracksLabelchange);
      }

      // @ts-ignore TS2551
      const audioTracks = this._localPlayer.audioTracks();
      if (audioTracks) {
        audioTracks.removeEventListener('change', this.audioTracksChange);
      }
    }

    // @ts-ignore TS2339
    if (this._fsApp) {
      // @ts-ignore TS2339
      this._fsApp.off('onFullScreenChange', this.onFullscreenChange);
      // @ts-ignore TS2339
      this._fsApp.off('beforeFullScreenChange', this.beforeFullScreenChange);
    }
    this.stopReflashSeekThumbnailTrack();
    this.loaded(false);
    this.started(false);
    this.canplayed(false);
    // @ts-ignore TS2339
    this.__autoplay = false;
    // @ts-ignore TS2339
    this.__currentPlayerTime = 0;
    // @ts-ignore TS2551
    this.__paused = true;
    // @ts-ignore TS2339
    this.__seeking = false;
    // @ts-ignore TS2339
    this.__pt = 0;
    // @ts-ignore TS2339
    this.__pFrom = undefined;
    // @ts-ignore TS2339
    this.__beaconIntervalId = undefined;
    // @ts-ignore TS2551
    this.__milestone = 0;
    // @ts-ignore TS2339
    this._overSeeking = false;
    // @ts-ignore TS2339
    this._state = null;
    this._localPlayer = null;
    delete this._localPlayer;
  }

  sessionClose(playbackService, authContext, playbackSessionId, async) {
    // console.log('PlayerApp: sessionClose');
    if (!playbackSessionId) return;

    const url = `${playbackService.protocol}://${playbackService.hostname}/session/close`;

    if (window.fetch) {
      const headers = {
        'Content-Type': 'application/x-www-form-urlencoded',
        'X-Playback-Session-Id': playbackSessionId,
      };
      if (authContext) {
        headers['Authorization'] = `Bearer ${authContext.token}`;
        headers['X-User-Id'] = authContext.id;
      }
      window
        .fetch(url, {
          method: 'POST',
          headers: headers,
          keepalive: !async,
        })
        .then(response => {
          // console.log(response);
          if (response.ok) {
            return response.json();
          }
          throw new Error('Network response was not ok.');
        })
        .then(response => {
          // console.log(response);
        })
        .catch(e => {
          console.log(e);
        });
    } else {
      const xhr = new XMLHttpRequest();
      xhr.onreadystatechange = function() {
        // console.log('sessionClose:xhr', xhr);
      };
      xhr.open('POST', url, async);
      if (authContext) {
        xhr.setRequestHeader('Authorization', `Bearer ${authContext.token}`);
        xhr.setRequestHeader('X-User-Id', authContext.id);
      }
      xhr.setRequestHeader('X-Playback-Session-Id', playbackSessionId);
      xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
      xhr.withCredentials = true;
      xhr.send();
    }
  }

  onetimeToken(tokenService, authContext) {
    //console.log('PlayerApp: onetimeToken');
    // @ts-ignore TS2339
    return this._axios.get('/api/user/onetime-token', {}, {}).then(result => {
      return _.get(result, 'data.onetime_token');
    });

    tokenService.pathname = _.join(_.concat(tokenService.path, 'onetime-token/publish'), '/');

    let headers = {};
    if (authContext) {
      headers['Authorization'] = `Bearer ${authContext.token}`;
      headers['X-Token-Id'] = authContext.id;
      headers['X-Session-Token'] = authContext.sessionToken;
    } else {
      return Promise.resolve();
    }

    // @ts-ignore TS2339
    return this._axios.formPost(url.format(tokenService), {}, { headers }).then(result => {
      return _.get(result, 'data.onetime_token');
    });
  }

  setSeekThumbnailTrack(track) {
    if (!track) {
      // @ts-ignore TS2551
      this.__seekThumbnailTrack = null;
      return;
    }

    const xhr = new XMLHttpRequest();
    xhr.onreadystatechange = () => {
      if (xhr.readyState === 4) {
        if (xhr.status !== 200) {
          return;
        }
        let response = xhr.response;
        const parser = new WebVTTParser();
        const parsed = parser.parse(response);
        if (_.isEmpty(parsed.errors)) {
          // @ts-ignore TS2551
          this.__seekThumbnailTrack = {
            src: track.src,
            cues: parsed.cues,
          };
        }
      }
    };
    xhr.open('GET', track.src, true);
    xhr.send();
  }

  reflashSeekThumbnailTrack() {
    // console.log('reflashSeekThumbnailTrack')
    // @ts-ignore TS2551
    this.setSeekThumbnailTrack(this.__seekThumbnailTrack);
  }

  startReflashSeekThumbnailTrack() {
    // console.log('startReflashSeekThumbnailTrack')
    this.stopReflashSeekThumbnailTrack();
    // @ts-ignore TS2551
    if (this.__seekThumbnailTrack) {
      // @ts-ignore TS2551
      this.__reflashSeekThumbnailTrackTimer = setInterval(this.reflashSeekThumbnailTrack, 1000 * 10);
    }
  }

  stopReflashSeekThumbnailTrack() {
    // console.log('stopReflashSeekThumbnailTrack')
    // @ts-ignore TS2551
    if (this.__reflashSeekThumbnailTrackTimer) {
      // @ts-ignore TS2551
      clearInterval(this.__reflashSeekThumbnailTrackTimer);
      // @ts-ignore TS2551
      delete this.__reflashSeekThumbnailTrackTimer;
    }
  }

  seekThumbnailTrack() {
    if (this.isGCasted()) {
      // @ts-ignore TS2551
      const track = this._gCastPlayer.getSeekThumbnailTrack();
      // @ts-ignore TS2551
      if (track && this.__seekThumbnailTrack) {
        // 同じ場合のみ
        // @ts-ignore TS2551
        if (track.src == this.__seekThumbnailTrack.src) return this.__seekThumbnailTrack;
        // 撮り直す
        // @ts-ignore TS2551
        this.__seekThumbnailTrack = null;
        // @ts-ignore TS2551
        this.__castSeekThumbnailTrack = false;
      }
      if (track) {
        // @ts-ignore TS2551
        if (!this.__castSeekThumbnailTrack) {
          // @ts-ignore TS2551
          this.__castSeekThumbnailTrack = true;
          this.setSeekThumbnailTrack(track);
        }
      } else {
        // @ts-ignore TS2551
        this.__seekThumbnailTrack = null;
        // @ts-ignore TS2551
        this.__castSeekThumbnailTrack = false;
      }
      return null;
    }

    // @ts-ignore TS2551
    return this.__seekThumbnailTrack;
  }

  toggleFullScreen() {
    // console.log('toggleFullScreen');
    // iPhoneの場合のみplayerのfullscreen機能を利用する
    // @ts-ignore TS2339
    const browserInfo = this._options.browserInfo;
    if (
      this.localPlayer() &&
      ((browserInfo.isIOS && !browserInfo.isIPad) ||
        (browserInfo.isRequestDesktopWebsite && !FullScreenApp.enbableFullScreenApi()))
    ) {
      this.localPlayer().requestFullscreen();
    } else {
      // @ts-ignore TS2339
      this._fsApp.toggleFullScreen();
    }
  }

  enterFullscreen() {
    // @ts-ignore TS2339
    const browserInfo = this._options.browserInfo;
    if (
      this.localPlayer() &&
      ((browserInfo.isIOS && !browserInfo.isIPad) ||
        (browserInfo.isRequestDesktopWebsite && !FullScreenApp.enbableFullScreenApi()))
    ) {
      this.localPlayer().requestFullscreen();
    } else {
      // @ts-ignore TS2339
      this._fsApp.enterFullScreen();
    }
  }

  exitFullScreen() {
    // @ts-ignore TS2339
    const browserInfo = this._options.browserInfo;
    if (
      this.localPlayer() &&
      ((browserInfo.isIOS && !browserInfo.isIPad) ||
        (browserInfo.isRequestDesktopWebsite && !FullScreenApp.enbableFullScreenApi()))
    ) {
      this.localPlayer().exitFullscreen();
    } else {
      // @ts-ignore TS2339
      this._fsApp.exitFullScreen();
    }
  }

  onFullscreenChange(isFullScreen) {
    if (isFullScreen) {
      this.onEnterFullScreen();
    } else {
      this.onExitFullScreen();
    }
    // @ts-ignore TS2339
    this._emitter.emit('onFullScreenChange', isFullScreen);
  }

  onEnterFullScreen() {
    // @ts-ignore TS2339
    const browserInfo = this._options.browserInfo;
    // firefoxは非対応だが、フルスクリーンを検知して勝手に横画面にしてくれる
    if (browserInfo.isAndroid && !browserInfo.isFirefox) {
      try {
        // https://developer.mozilla.org/ja/docs/Web/API/ScreenOrientation/lock
        // @ts-ignore TS2339
        window.screen.orientation.lock('landscape');
      } catch (e) {}
    }
    // @ts-ignore TS2554
    this.trackEvent('fullscreen');
  }

  onExitFullScreen() {
    // @ts-ignore TS2339
    const browserInfo = this._options.browserInfo;
    if (browserInfo.isAndroid && !browserInfo.isFirefox) {
      try {
        window.screen.orientation.unlock();
      } catch (e) {}
    }
    // @ts-ignore TS2554
    this.trackEvent('exitFullscreen');
  }

  beforeFullScreenChange(isFullScreen) {
    // @ts-ignore TS2339
    this._emitter.emit('beforeFullScreenChange', isFullScreen);
  }

  onFullScreenError(e) {
    // @ts-ignore TS2339
    const browserInfo = this._options.browserInfo;
    if (browserInfo.isAndroid && !browserInfo.isFirefox) {
      try {
        window.screen.orientation.unlock();
      } catch (e) {}
    }
  }

  getIsFullScreen() {
    // @ts-ignore TS2339
    if (this._fsApp) return this._fsApp.getIsFullScreen();
    return false;
  }
}

export default PlayerApp;
