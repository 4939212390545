import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'src/domain/libs/util';

import CheckboxDiv from '../../../CheckboxDiv';
import SelectBox from '../../../SelectBox';

export default class SuggestForm extends Component {
  static get propTypes() {
    return {
      suggest: PropTypes.object,
      returnTo: PropTypes.string,
    };
  }

  constructor(props, context) {
    super(props, context);

    // @ts-ignore TS2339
    this.formRef = React.createRef();
    // @ts-ignore TS2339
    this.campaignRef = React.createRef();

    this._handleSubmit = this._handleSubmit.bind(this);
    this.handleCampaignClick = this.handleCampaignClick.bind(this);
    this.state = {
      isSubmittable: true,
    };
  }

  _handleSubmit(e) {
    // @ts-ignore TS2339
    if (this.state.isSubmittable) {
      this.setState({ isSubmittable: false });
      setTimeout(() => {
        // @ts-ignore TS2339
        this.formRef.current.submit();
      }, 100);
    }
    e.preventDefault();
    return false;
  }

  handleCampaignClick(e) {
    // @ts-ignore TS2339
    this.campaignRef.current.value = true;
    this._handleSubmit(e);
  }

  render() {
    // @ts-ignore TS2339
    const suggest = this.props.suggest;
    const titles = suggest ? suggest.titles || [] : [];
    // @ts-ignore TS2339
    const returnTo = this.props.returnTo || '';

    if (!titles || titles.length == 0) {
      return null;
    }

    return (
      // @ts-ignore TS2339
      <form action="/plans/answer_question" ref={this.formRef} method="post" onSubmit={this._handleSubmit}>
        <input type="hidden" name="return_to" value={returnTo} />
        <div className="mt-20">
          <h3>作品を「お気に入り」登録してWOWOWオンデマンドをお楽しみください。</h3>
        </div>
        <div className="suggest-area">
          <div className="mb-10 cf">
            {_.map(titles, title => {
              if (!title || !title.meta_id || !title.tall_artwork) return null;
              return (
                <div key={`title-card-key-${title.meta_id}`} className="card title-card size-1x1">
                  <div className="checkbox-cont">
                    <input
                      id={`title-${title.meta_id}`}
                      className="checkbox"
                      type="checkbox"
                      name="favorite_title_id[]"
                      value={title.meta_id}
                    />
                    <label className="check-box-label no-position" htmlFor={`title-${title.meta_id}`}></label>
                  </div>
                  <div
                    className="artwork tall-artwork"
                    style={{ backgroundImage: `url('${title.tall_artwork}')` }}
                  ></div>
                  <div className="video-preload-title">
                    <div className="video-preload-title-label">${title.name}</div>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="form-btn-block center-btn mt-10">
            {/*
             // @ts-ignore TS2339 */}
            {this.state.isSubmittable ? (
              <input
                type="submit"
                className="btn btn-fill btn-large"
                value="お気に入り登録してWOWOWオンデマンドを始める"
              />
            ) : (
              <div className="btn btn-fill btn-large waiting">お気に入り登録してWOWOWオンデマンドを始める</div>
            )}
          </div>
          {this.props.children}
          {this.props.children ? (
            <div className="form-btn-block center-btn mt-10">
              {/*
               // @ts-ignore TS2339 */}
              {this.state.isSubmittable ? (
                <input
                  type="submit"
                  className="btn btn-fill btn-large"
                  value="お気に入り登録してWOWOWオンデマンドを始める"
                />
              ) : (
                <div className="btn btn-fill btn-large waiting">お気に入り登録してWOWOWオンデマンドを始める</div>
              )}
            </div>
          ) : null}
        </div>
      </form>
    );
  }
}
