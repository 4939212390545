const getDisplayName = function getDisplayName(Component, defaultName = 'Component') {
  if (typeof Component === 'string') {
    return Component;
  }

  if (!Component) {
    return undefined;
  }

  return Component.displayName || Component.name || defaultName;
};

export const wrapDisplayName = function wrapDisplayName(BaseComponent, hocName, baseComponentDefaultName) {
  return `${hocName}(${getDisplayName(BaseComponent, baseComponentDefaultName)})`;
};