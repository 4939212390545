import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'src/domain/libs/util';

// @ts-ignore TS2306
import sepDigit from '../../../../../common/sepDigit';
import ProductOrderInfo from '../common/ProductOrderInfo';
import { getPurchaseLabel } from '../../../../../utils/getPurchaseLabel';

const PurchaseFinish = (props, context) => {
  const redirectToText = props.redirectToText || '視聴する';
  const [isSentToGtm, setIsSentToGtm] = useState(false);

  const sendToGtm = () => {
    if (!isSentToGtm) {
      setIsSentToGtm(true);
      context.gtmApp.pushDataLayerOnPurchase({ productOrder: props.productOrder });
    }
  };

  useEffect(() => {
    sendToGtm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <React.Fragment>
      <p className="message">{getPurchaseLabel(props.product, props.meta)}を完了しました</p>

      <ProductOrderInfo {...props} />

      {/* <p className="introduction-text">
        WOWOWオンデマンドのサービスをご購入いただきありがとうございます。<br />
        手続き状況については<a href="/account" target="_blank">アカウントページ</a>にてご確認いただけます。
      </p> */}
      <div className="action-buttons">
        {props.redirectTo ? (
          <a className="btn btn-fill" href="javascript:void(0)" onClick={props.redirectTo}>
            {redirectToText}
          </a>
        ) : (
          <a className="btn btn-fill" href={props.returnTo}>
            視聴する
          </a>
        )}
      </div>
    </React.Fragment>
  );
};

PurchaseFinish.propTypes = {
  productOrder: PropTypes.object.isRequired,
  entitlements: PropTypes.object.isRequired,
  product: PropTypes.object.isRequired,
  returnTo: PropTypes.string.isRequired,
  redirectTo: PropTypes.func,
  redirectToText: PropTypes.string,
};

PurchaseFinish.contextTypes = {
  gtmApp: PropTypes.object,
};

export default PurchaseFinish;
