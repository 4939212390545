import React from 'react';
import { wrapDisplayName } from '../utils/displayName';

// @ts-ignore TS2554
const HtmlContext = React.createContext();
export default HtmlContext;
export const withHtmlContext = Component => {
  const withHtmlContext = React.forwardRef((props, ref) => {
    return (
      <HtmlContext.Consumer>
        {/*
         // @ts-ignore TS2698 */}
        {context => React.createElement(Component, { ref, ...props, ...context })}
      </HtmlContext.Consumer>
    );
  });
  // @ts-ignore TS2554
  withHtmlContext.displayName = wrapDisplayName(Component, 'withHtmlContext');
  return withHtmlContext;
};
