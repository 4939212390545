import React from 'react';
import PropTypes from 'prop-types';

const FullHitZone = props => {
  const onClick = event => {
    if (props.onClickPlay) {
      props.onClickPlay(event);
    }
  };

  return (
    <div className="hit-zone" onClick={onClick}>
      <div className="center-controls">
        <div className="big-play-pause-btn"></div>
      </div>
    </div>
  );
};

FullHitZone.propTypes = {
  clickDelay: PropTypes.number,
  enableClickToPlay: PropTypes.bool,
  focused: PropTypes.string,
  onClickPlay: PropTypes.func,
  onFullScreenChange: PropTypes.func,
  onUserActivityChanged: PropTypes.func,
  paused: PropTypes.bool,
  releaseFocus: PropTypes.func,
};

export default FullHitZone;
