import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'src/domain/libs/util';
import classnames from 'classnames';

import BackgroundImage from '../../BackgroundImage';
import MyListButton from '../../../../bluerose/components/browse/MyListButton';
import MainViewLink from '../../MainViewLink';
import useLinkProps from '../../../hooks/useLinkProps';
import { CLICK_AREA, CONTENT_EVENTS } from '../../../../../common/GtmApp';

const SidelistCard = (props, context) => {
  // @ts-ignore TS2554
  const linkProps = useLinkProps(props.item);
  const [isMouseOnMyListButton, setIsMouseOnMyListButton] = useState(false);
  const bgImgUrl = useMemo(() => props.item.thumbnailUrl, [props.item.thumbnailUrl]);
  const onClickLink = useCallback(() => {
    const schemaType = _.get(props.item, 'type');
    const isLinearChannelSchema = schemaType === 'linear_channel';
    const hasRelationProgram = _.get(props.item, 'seriesMeta') && _.get(props.item, 'seasonMeta');
    const channel = isLinearChannelSchema
      ? {
          refId: _.get(props.item, 'refId'),
          name: _.get(props.item, 'name'),
        }
      : _.get(props.item, 'linearChannelMeta');
    const relationProgram = isLinearChannelSchema
      ? null
      : hasRelationProgram
      ? {
          refId: _.get(props.item, 'seriesMeta.refId'),
          name: _.get(props.item, 'seriesMeta.name'),
        }
      : null;
    const program = isLinearChannelSchema
      ? null
      : schemaType === 'group'
      ? {
          refId: _.get(props.item, 'refId'),
          name: _.get(props.item, 'name'),
        }
      : {
          refId: _.get(props.item, 'seasonMeta.refId') || _.get(props.item, 'seriesMeta.refId'),
          name: _.get(props.item, 'seasonMeta.name') || _.get(props.item, 'seriesMeta.name'),
        };

    let content = null;
    let ad = null;
    if (_.get(props.item, 'advertisingId')) {
      ad = {
        id: _.get(props.item, 'advertisingId'),
        name: _.get(props.item, 'name'),
      };
    } else {
      content = isLinearChannelSchema
        ? {}
        : schemaType === 'group'
        ? {}
        : _.isEmpty(channel)
        ? {
            refId: _.get(props.item, 'refId'),
            name: _.get(props.item, 'name') || _.get(props.item, 'shortName'),
            rental: _.get(props.item, 'tvodFlag'),
            subscription: _.get(props.item, 'svodFlag'),
          }
        : {
            refId: _.get(props.item, 'uniqueId'),
            name: _.get(props.item, 'name'),
          };
    }

    const shelf = {
      id: _.get(props.palette, 'id'),
      name: _.get(props.palette, 'title'),
    };
    const attributes = _.get(props.item, 'attributes');
    const genres = _.get(props.item, 'genres');
    const middleGenres = _.get(props.item, 'middleGenres');
    const schemaId = _.get(props.item, 'schemaId');
    context.gtmApp.pushDataLayerOnContentPageClick(
      CONTENT_EVENTS.CONTENT_CLICK,
      { channel, program, shelf, content, relationProgram, attributes, genres, middleGenres, schemaId, ad },
      { clickArea: CLICK_AREA.CONTENT_CLICK.IN_PLAYER },
    );
  }, [context.gtmApp, props.item, props.palette]);

  const onMouseMyListButtonEnter = useCallback(() => {
    setIsMouseOnMyListButton(true);
  }, []);
  const onMouseMyListButtonLeave = useCallback(() => {
    setIsMouseOnMyListButton(false);
  }, []);
  const myListBtn = useMemo(() => {
    let btn = null;
    if (MyListButton.isUsable(context, props.item)) {
      return (
        <div className="player-side-card__txt__mylist">
          <MyListButton
            // @ts-ignore TS2322
            onMouseEnter={onMouseMyListButtonEnter}
            onMouseLeave={onMouseMyListButtonLeave}
            model={props.model}
            btnType="short"
            item={props.item}
            id={props.item.id}
            sidelist={true}
            btnStyle={true}
            shouldEventPreventDefault={true}
          />
        </div>
      );
    }
    return btn;
  }, [context, onMouseMyListButtonEnter, onMouseMyListButtonLeave, props.item, props.model]);

  return (
    <MainViewLink
      className={classnames('player-side-card', { 'no-underline': isMouseOnMyListButton })}
      onClick={onClickLink}
      {...linkProps}
    >
      <div className="player-side-card__thumbnail">
        {/*
         // @ts-ignore TS2322 */}
        <BackgroundImage className={classnames('artwork', { noImage: !bgImgUrl })} url={bgImgUrl} useLazyload={false} />
      </div>
      <div className="player-side-card__txt">
        <div className="player-side-card__txt__name">{props.item.name}</div>
        {myListBtn}
      </div>
    </MainViewLink>
  );
};

SidelistCard.contextTypes = {
  getModelData: PropTypes.func,
  gtmApp: PropTypes.object,
};

export default React.memo(SidelistCard);
