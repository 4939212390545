import React from 'react';
import GalleryContent from './GalleryContent';

const TrendingNow = props => {
  return (
    <GalleryContent
      header={
        <div className="gallery-header">
          <div className="title">
            <span className="gallery-title">話題の作品</span>
          </div>
        </div>
      }
      model={props.pathEvaluator || props.model.pathEvaluator}
      keyPrefix={'trendingnow'}
    />
  );
};

TrendingNow.getPrefetchPaths = function(models, options, props) {
  return GalleryContent.getPaths(
    models,
    options,
    Object.assign({}, GalleryContent.defaultProps, props, { keyPrefix: 'trendingnow' }),
  );
};

export default TrendingNow;
