import React from 'react';

import * as ERROR from '../../../../../../constants/error';
import CloedCampaignTextContent from './CloedCampaignTextContent';
import HowtoPlayProvider from '../../functional/HowtoPlayProvider';

const CloedCampaignText = props => {
  // 無駄にhowto_playを叩かないための対策
  return props.error && [ERROR.UNAUTHORIZED, ERROR.LICENSE_INVALID].includes(props.error.type) ? (
    <HowtoPlayProvider model={props.model} meta={props.meta}>
      <CloedCampaignTextContent {...props} />
    </HowtoPlayProvider>
  ) : null;
};

export default React.memo(CloedCampaignText);
