import { useEffect, useState } from 'react';
import { Model } from 'falcor';

import useMeta from 'src/apps/common/hooks/useMeta';
import { AuthContext } from '@domain/models/auth';

type UsePPVLimitProps = { model: Model; metaId: string | number; isPPV: boolean; authContext: AuthContext };

const usePPVLimit = (props: UsePPVLimitProps) => {
  const [ppvLimit, setPPVLimit] = useState('');
  const [metaId, setMetaId] = useState<string | number>(null);
  const { meta } = useMeta({ model: props.model, metaId, propKeys: ['ppvLimit', 'isExercised'] });

  useEffect(() => {
    if (props.authContext && props.metaId && props.isPPV) {
      setMetaId(props.metaId);
    } else {
      // ログイン済みかつPPV以外の場合は取得しに行かせない
      setMetaId(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.isPPV, props.metaId]);

  useEffect(() => {
    if (meta) {
      setPPVLimit(meta.ppvLimit);
    }
  }, [meta]);

  return ppvLimit;
};

export default usePPVLimit;
