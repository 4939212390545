import React from 'react';
import _ from 'src/domain/libs/util';

import tokenDataStore from '../../../../../../utils/tokenDataStore';
import validations from '../../../../../../utils/validations';
import Axios from '../../../../../../common/Axios';

export default class VoucherConfirm extends React.PureComponent {
  constructor(props, context) {
    super(props, context);
    this.state = { messages: [], submitting: false };

    // @ts-ignore TS2339
    this._axios = new Axios({ xhr: true });
    this._handleSubmit = this._handleSubmit.bind(this);
    // @ts-ignore TS2339
    this.inputVoucherCodeRef = React.createRef();
  }

  _handleSubmit(e) {
    // @ts-ignore TS2339
    if (this.state.submitting) {
      return;
    }
    if (!_.trim(_.get(this, 'inputVoucherCodeRef.current.value', ''))) {
      return;
    }

    // 半角英数字のチェック
    if (!validations.isAlphaNumeric(_.get(this, 'inputVoucherCodeRef.current.value', ''))) {
      this.setState({
        messages: ['クーポンコードは半角英数字で入力してください'],
      });
      // @ts-ignore TS2339
      this.props.applyVoucherData(null);
      return;
    }

    this.setState({ submitting: true, submitError: null });

    let q = { code: _.get(this, 'inputVoucherCodeRef.current.value') };
    // @ts-ignore TS2339
    if (this.props.course) {
      // @ts-ignore TS2339
      q.course_id = this.props.course.id;
      // @ts-ignore TS2339
    } else if (this.props.product) {
      // @ts-ignore TS2339
      q.product_id = this.props.product.id;
    }

    // @ts-ignore TS2339
    this._axios
      .post('/api/user/estimate', q)
      .then(result => {
        tokenDataStore.setAuthContextData(_.get(result, 'data.authContext'));
        if (result.data.message) {
          this.setState({
            submitError: new Error(result.data.message),
            messages: result.data.message,
            submitting: false,
          });
          // @ts-ignore TS2339
          this.props.applyVoucherData(null);
        } else {
          this.setState({
            submitError: null,
            messages: [],
            submitting: false,
          });
          // @ts-ignore TS2339
          this.props.applyVoucherData(result.data.data);
        }
      })
      .catch(e => {
        console.error('checkVoucherError:', e);
        this.setState({
          submitError: e,
          messages: ['確認中にエラーが発生しました'],
          submitting: false,
        });
        // @ts-ignore TS2339
        this.props.applyVoucherData(null);
      });
  }

  render() {
    let couponCodeErrorMessages = null;
    // @ts-ignore TS2339
    couponCodeErrorMessages = this.state.messages.map((message, i) => {
      return (
        <p className="notes red" key={`message-` + i}>
          <i className="fa fa-exclamation-triangle"></i>
          {message}
        </p>
      );
    });
    return (
      <div className="item">
        <p className="notes">ご利用になるクーポンをお持ちの方は、こちらにご記入ください</p>
        <div className="clearfix">
          {/*
           // @ts-ignore TS2339 */}
          <input ref={this.inputVoucherCodeRef} type="text" className="text-input" />
          <a href="javascript:void(0)" className="submit btn btn-fill btn-small" onClick={this._handleSubmit}>
            {/*
             // @ts-ignore TS2339 */}
            {this.state.submitting ? '確認中' : '適用'}
          </a>
        </div>
        {couponCodeErrorMessages}
      </div>
    );
  }
}
