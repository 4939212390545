  import hoistStatics from 'hoist-non-react-statics';
import invariant from 'invariant';
import React from 'react';

export default function presentationHOC(WrappedComponent) {
  invariant(
    typeof WrappedComponent === 'function',
    `You must pass a component to the function returned by ` +
      `presentationHOC. Instead received ${JSON.stringify(WrappedComponent)}`,
  );

  class Presentation extends React.Component {

    constructor(props, context) {
      super(props, context);
      // @ts-ignore TS2339
      this._isMounted = false;

      this.logCommand = this.logCommand.bind(this);
      this.logFocus = this.logFocus.bind(this);
      this.logPresentationStart = this.logPresentationStart.bind(this);
      this.logPresented = this.logPresented.bind(this);
    }

    componentDidMount() {
      // @ts-ignore TS2339
      this._isMounted = true;
    }

    componentWillUnmount() {
      // @ts-ignore TS2339
      this._isMounted = false;
    }

    logCommand() {
    }

    logFocus() {
    }

    logPresentationStart() {
    }

    logPresented() {
    }

    render() {
      return React.createElement(WrappedComponent, Object.assign({}, this.props, {
        logCommand: this.logCommand,
        logFocus: this.logFocus,
        logPresentationStart: this.logPresentationStart,
        logPresented: this.logPresented
      }));
    }
  };

  const wrappedComponentName = WrappedComponent.displayName
    || WrappedComponent.name
    || 'Component';

  // @ts-ignore TS2339
  Presentation.displayName = `presentationHOC(${wrappedComponentName})`;

  return hoistStatics(Presentation, WrappedComponent);
}
