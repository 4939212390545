export default function columnsInRow(hasComment) {
  if (typeof window === 'undefined') return;

  let columnsInRow = 4;
  if (hasComment) {
    // WatchPartyのコメント欄が表示された場合
    if (window.matchMedia('screen and (min-width: 1799px)').matches) {
      columnsInRow = 6;
    } else if (
      window.matchMedia('screen and (min-width: 768px) and (max-width: 1799px)').matches
    ) {
      columnsInRow = 5;
    }
  } else {
    // WatchPartyのコメント欄が表示されてない場合
    if (window.matchMedia('screen and (min-width: 1800px)').matches) {
      columnsInRow = 6;
    } else if (
      window.matchMedia('screen and (min-width: 768px) and (max-width: 1799px)').matches
    ) {
      columnsInRow = 5;
    }
  }

  return columnsInRow;
}
