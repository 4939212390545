import cookieDough from 'cookie-dough';

import { OLD_VUID_KEY, NEW_VUID_KEY } from '../constants/cookie';
import generateUuid from '../common/generateUuid';
import datetime from 'src/domain/libs/datetime';

const cookie = cookieDough();

const setVuidLocalStorage = function(vuid: string) {
  if (!window.localStorage) return;

  window.localStorage.setItem(NEW_VUID_KEY, JSON.stringify({ vuid: vuid, ca: new Date() }));
};
const getVuidLocalStorage = function(key: string = NEW_VUID_KEY) {
  if (!window.localStorage) return null;

  let data = JSON.parse(window.localStorage.getItem(key));
  if (data && data.vuid) {
    return data.vuid;
  }

  return null;
};

const cookieOptions = {
  path: '/',
  expires: datetime()
    .add(400, 'days')
    .toDate(),
  sameSite: 'none' as const,
  secure: true,
};

const setVuidCookie = function(vuid: string, cookieDough?: cookieDough.CookieDough) {
  if (cookieDough) {
    cookieDough.set(NEW_VUID_KEY, vuid, cookieOptions);
  } else if (window.navigator.cookieEnabled) {
    cookie.set(NEW_VUID_KEY, vuid, cookieOptions);
  }
};

const getVuidCookie = function(cookieDough?: cookieDough.CookieDough) {
  let vuid: string | null = null;
  if (cookieDough) {
    vuid = cookieDough.get(NEW_VUID_KEY);
  } else {
    vuid = cookie.get(NEW_VUID_KEY);
  }
  return vuid;
};

const setVuid = function(vuid: string) {
  if (typeof window === 'undefined') return null;

  try {
    setVuidCookie(vuid);
    // フォールバック用
    setVuidLocalStorage(vuid);
  } catch (e) {
    console.log(e);
  }
};
const getVuid = function() {
  if (typeof window === 'undefined') return null;

  try {
    let vuid = getVuidCookie();
    if (vuid) {
      let _vuid = getVuidLocalStorage();
      if (!_vuid || _vuid != vuid) setVuidLocalStorage(vuid);
      return vuid;
    }

    // 取れなければcookieから取得する
    // @ts-ignore TS2554
    vuid = getVuidCookie();
    if (vuid) {
      // localStrageに入れておく
      setVuidLocalStorage(vuid);
      return vuid;
    }
  } catch (e) {
    console.log(e);
  }

  return null;
};

const initVuid = function() {
  let cookieVuid = getVuidCookie();
  let localStorageVuid = getVuidLocalStorage();
  if (cookieVuid) {
    setVuidLocalStorage(cookieVuid);
  } else if (localStorageVuid) {
    setVuidCookie(localStorageVuid);
  } else {
    const vuid = create();
    setVuidLocalStorage(vuid);
    setVuidCookie(vuid);
  }
  // 旧vuidは不要&紛らわしいので明示的に消しておく
  const cookieOldVuid = cookie.get(OLD_VUID_KEY);
  if (cookieOldVuid) {
    cookie.remove(OLD_VUID_KEY, { path: '/' });
  }
  const localStorageOldVuid = getVuidLocalStorage(OLD_VUID_KEY);
  if (localStorageOldVuid) {
    window.localStorage.removeItem(OLD_VUID_KEY);
  }
};

const create = function() {
  // 背景不明だが、ハイフンを消した方が良いとのことなので消す
  return generateUuid(false);
};

export default {
  init: initVuid,
  set: setVuid,
  get: getVuid,
  setWithCookie: setVuidCookie,
  getWithCookie: getVuidCookie,
  create: create,
  cookieOptions: cookieOptions,
};
