import React, { Component } from 'react';
import PropTypes from 'prop-types';

const ValidationMessage = props => {
  if (!props.message) {
    return null;
  }

  let list = props.message;
  if (typeof list === 'string') {
    list = [list];
  }

  const nodes = list.map(
    ((text, i) => {
      if (props.errorHelperLink && props.errorHelperLinkMessage) {
        return (
          <span key={`err-message-${i}`} className="err-message">
            {text}
            <div className='error-helper-link'>
              <a href={props.errorHelperLink}>{props.errorHelperLinkMessage}</a>
            </div>
          </span>
        );
      } else {
        return (
          <span key={`err-message-${i}`} className="err-message">
            {text}
          </span>
        );
      }
    }).bind(this),
  );

  return <div>{nodes}</div>;
};

ValidationMessage.propTypes = {
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  errorHelperLink: PropTypes.string,
  errorHelperLinkMessage: PropTypes.string,
};

ValidationMessage.defaultProps = {
  message: null,
  errorHelperLink: null,
  errorHelperLinkMessage: null,
};

export default ValidationMessage;
