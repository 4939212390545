import React from 'react';
import _ from '@domain/libs/util';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import tokenDataStore from '../../../utils/tokenDataStore';
import Axios from '../../../common/Axios';
import * as ERROR from '../../../constants/error';
import panelHOC from '../hocs/panelHOC';
import { withModalContext } from '../context/ModalContext';

import ErrorView from './UpSellFlow/ErrorView';
import PurchaseConfirm from './UpSellFlow/Confirm/PurchaseConfirm';
import PurchaseFinish from './UpSellFlow/Finish/PurchaseFinish';
import RegistForm from './UpSellFlow/RegistForm';
import routes from '../routes';

const commitError = function(resData) {
  let err = new Error(_.get(resData, 'message'));
  if (_.get(resData, 'code')) {
    // @ts-ignore TS2339
    err.code = resData.code;
  }
  if (_.get(resData, 'dcode')) {
    // @ts-ignore TS2339
    err.dcode = resData.dcode;
  }
  return err;
};

//
// 商品ページの購入フローを管理するComponent
//
class ProductUpSellFlow extends React.PureComponent {
  static get propTypes() {
    return {
      model: PropTypes.func.isRequired,
      product: PropTypes.object.isRequired,
      rightIds: PropTypes.array.isRequired,
      defaultPaymentInstrumentId: PropTypes.string,
      confirmInfo: PropTypes.object,
      finishInfo: PropTypes.object,
    };
  }

  static get defaultProps() {
    return {
      defaultPaymentInstrumentId: null,
    };
  }

  static get contextTypes() {
    return {
      getModelData: PropTypes.func,
    };
  }

  constructor(props, context) {
    super(props, context);

    this.doCommit = this.doCommit.bind(this);
    this.cancelSelect = this.cancelSelect.bind(this);
    this.redirectToProduct = this.redirectToProduct.bind(this);

    // @ts-ignore TS2339
    this.registFormRef = React.createRef();

    // @ts-ignore TS2339
    this.axios = new Axios({ xhr: true });
    // @ts-ignore TS2339
    this.product = props.product || {};

    this.state = {
      loading: false,
      step: 'started',
    };

    // confirmInfoが渡されたときはConfirmから表示する
    if (props.confirmInfo) {
      this.setConfirmInfo(props.confirmInfo);

      // finishInfoが渡されたときはFinish表示する
    } else if (props.finishInfo) {
      this.setFinishInfo(props.finishInfo);
    }
  }

  componentDidMount() {
    // @ts-ignore TS2339
    this._isMounted = true;

    this.fetchPaymentInfo();
  }

  componentDidUpdate(prevProps, prevState) {
    // @ts-ignore TS2339
    if (prevProps.product.id !== this.props.product.id) {
      this.fetchPaymentInfo();
    }
  }

  componentWillUnmount() {
    // @ts-ignore TS2339
    this._isMounted = false;

    // 購入フローがモーダルの時に
    // 購入後にモーダルを消してしまうと再度購入フローに入ってしまうため
    // モーダルの時かつ購入後の場合はwindowをリロードする
    // @ts-ignore TS2339
    if (typeof this.props.closeModal === 'function' && this.state.step === 'finish') {
      window.location.reload();
    }
  }

  handleError(error) {
    const basicErrors = [ERROR.META_NOT_VIEWABLE, ERROR.AVAILABLE_ENTITLEMENT, ERROR.UNAUTHORIZED];

    // APIエラーまるめる
    if (_.includes(basicErrors, _.get(error, 'commitError.message'))) {
      // @ts-ignore TS2339
      this.error = new Error(error.commitError.message);
    } else {
      // @ts-ignore TS2339
      this.error = error;
    }

    // @ts-ignore TS2339
    if (_.get(this.error, 'message') === ERROR.MATCHING_INFO_LACK) {
      window.location.href = `/signup/info?return_to=${encodeURIComponent(this.returnToUrl())}`;
      return;
    }

    // 視聴可能だった場合はリロード(productページへリダイレクト)する
    // @ts-ignore TS2339
    if (_.get(this.error, 'message') === ERROR.AVAILABLE_ENTITLEMENT) {
      this.redirectToProduct();
      return;
    }

    // basicErrorの場合はerror表示
    // @ts-ignore TS2339
    if (_.includes(basicErrors, _.get(this.error, 'message'))) {
      this.setState({ loading: false, step: 'error' });
      return;
    }

    // 契約/購入系のエラーが生じた場合はConfirmでエラー表示する
    // @ts-ignore TS2339
    if (_.get(this.error, 'commitError') && this.state.step == 'confirm') {
      this.setState({ loading: false, step: 'confirm' });
      return;
    }

    // それ以外の例外はErrorBoundaryで拾う
    // 非同期の場合componentDidCatchが効かないためsetStateで無理やりキャッチさせる
    this.setState({}, () => {
      // @ts-ignore TS2339
      throw this.error;
    });
  }

  // 視聴可能な権利が付与されているか確認する
  checkAvailability(entitlements) {
    if (_.size(entitlements) > 0) {
      return true;
    }

    return false;
  }

  //
  // 権利チェックと支払い方法の取得
  //
  fetchPaymentInfo(props = this.props) {
    // @ts-ignore TS2339
    if (!this._isMounted) return;
    this.setState({ loading: true });

    // @ts-ignore TS2339
    return this.axios
      .get('/api/account/me', { with_matching_info_lack_flag: true })
      .then(result => {
        if (!_.get(result, 'data.result')) {
          throw new Error(_.get(result, 'data.message'));
        }
        tokenDataStore.setAuthContextData(_.get(result, 'data.authContext'));
        // 名寄せされていない
        if (_.get(result, 'data.account.matchingInfoLackFlag')) {
          throw new Error(ERROR.MATCHING_INFO_LACK);
        }

        return (
          // @ts-ignore TS2339
          this.axios
            // @ts-ignore TS2339
            .get('/api/entitlement/users/query', { right_id: props.rightIds })
            .then(result => {
              if (_.get(result, 'data.result') === false) {
                throw new Error(_.get(result, 'data.error.message'));
              }

              const entitlements = _.get(result, 'data.entitlements');

              if (this.checkAvailability(entitlements)) {
                throw new Error(ERROR.AVAILABLE_ENTITLEMENT);
              }
            })
            .catch(e => {
              throw e;
            })
        );
      })
      .then(() => {
        // @ts-ignore TS2339
        const productId = this.product.id;
        const q = {
          product_id: productId,
        };

        // @ts-ignore TS2339
        this.axios
          .multi([
            // @ts-ignore TS2339
            this.axios.get('/api/user/payment_methods', { service_type: 'product' }),
            // @ts-ignore TS2339
            this.axios.get('/api/account/payment_instruments', q),
          ])
          .then(results => {
            _.forEach(results, result => {
              const fetchResult = _.get(result, 'data.result');

              if (!fetchResult) {
                throw new Error(_.get(result, 'data.message'));
              }
            });

            // @ts-ignore TS2339
            this.paymentMethods = _.get(results, '[0].data.paymentMethods');
            // @ts-ignore TS2339
            this.paymentInstruments = _.get(results, '[1].data.paymentInstruments');

            this.setState({
              loading: false,
              step: 'confirm',
            });
          })
          .catch(e => {
            this.handleError(e);
          });
      })
      .catch(e => {
        this.handleError(e);
      });
  }

  //
  // 購入処理
  //
  doCommit(args = {}) {
    // @ts-ignore TS2339
    if (!this._isMounted) return;
    this.setState({ loading: true });

    // @ts-ignore TS2339
    if (args.nextStep) {
      this.addPayment(args);
    } else {
      this.doPurchase(args);
    }
  }

  //
  // キャンセル = モーダル閉じる
  //
  cancelSelect() {
    // @ts-ignore TS2339
    if (typeof this.props.closeModal === 'function') {
      // @ts-ignore TS2339
      this.props.closeModal();
    }
  }

  //
  // 支払い方法追加
  //
  addPayment(args = {}) {
    const browserInfo = this.context.getModelData('browserInfo');
    const isSP = browserInfo.isAndroid || (browserInfo.isIOS && !browserInfo.isiPad);
    // @ts-ignore TS2339
    const productId = args.productId;

    const values = {
      productId: productId,
      cancelTo: window.location.href,
      returnTo: isSP ? `/pusf/${productId}/confirm` : this.returnToUrl(),
    };

    // @ts-ignore TS2339
    values.formAction = '/account/purchase';

    // @ts-ignore TS2339
    if (this.registFormRef.current) {
      // @ts-ignore TS2339
      this.registFormRef.current.formSubmit(values);
    }
  }

  //
  // 購入処理
  //
  doPurchase(args = {}) {
    // @ts-ignore TS2339
    const { productId, userVoucherId, paymentInstrumentInfo = {} } = args;
    let paymentInstrumentInfoId = null;

    // 支払い方法未設定かつバウチャー使用のケースが出てきたので
    // ここで先にPaymentInstrument.idを取得するようにする
    if (paymentInstrumentInfo) {
      paymentInstrumentInfoId = paymentInstrumentInfo.id;
    }

    // 登録済の支払い方法で一撃決済
    const q = {
      product_id: productId,
      // @ts-ignore TS2339
      right_ids: this.props.rightIds,
      payment_instrument_id: paymentInstrumentInfoId,
      user_voucher_id: userVoucherId,
      with_order: true,
      with_payment_instrument: true,
      with_user_vouchers: true,
      with_entitlements: true,
    };
    const purchaseUrl = '/api/account/purchase';

    // @ts-ignore TS2339
    this.axios
      .post(purchaseUrl, q, { timeout: 60000, retry: 0 })
      .then(result => {
        tokenDataStore.setAuthContextData(_.get(result, 'data.authContext'));

        if (!_.get(result, 'data.result')) {
          if (_.get(result, 'data.message.0') === ERROR.AVAILABLE_ENTITLEMENT) {
            throw new Error(ERROR.AVAILABLE_ENTITLEMENT);
          }

          const error = new Error('purchase error');
          // @ts-ignore TS2339
          error.commitError = commitError(result.data);
          throw error;
        } else {
          // @ts-ignore TS2339
          this.productOrder = _.get(result, 'data.productOrder');
          // @ts-ignore TS2339
          this.grantedEntitlements = _.get(this.productOrder, 'entitlements');

          // @ts-ignore TS2339
          if (typeof this.handleAfterCommit === 'function') {
            // @ts-ignore TS2339
            this.handleAfterCommit(this.productOrder.id);
            // @ts-ignore TS2339
          } else if (this._isMounted) {
            this.setState({ loading: false, step: 'finish' });
          }
        }
      })
      .catch(e => {
        this.handleError(e);
      });
  }

  //
  // 購入処理完了後に戻ってくる
  // 再生ページのURLを返す
  //
  returnToUrl(q = {}) {
    // @ts-ignore TS2554
    return routes.product.makePath({ id: this.product.id }, q);
  }

  redirectToProduct() {
    const productUrl = this.returnToUrl();

    window.location.href = productUrl;
  }

  /**
   * データやメソッドを上書きしてConfirm表示させる
   * @param {object} confirmInfo
   * @returns
   */
  setConfirmInfo(confirmInfo = {}) {
    if (typeof confirmInfo !== 'object') return;

    // @ts-ignore TS2339
    this.state.step = confirmInfo.step;
    // @ts-ignore TS2339
    this.state.loading = false;

    // @ts-ignore TS2339
    if (confirmInfo.errorMessage) {
      // @ts-ignore TS2339
      this.error = new Error(confirmInfo.errorMessage);
      // @ts-ignore TS2339
      this.state.step = 'error';
    }

    const fields = ['product', 'paymentMethods', 'paymentInstruments', 'userVoucherInfo', 'estimateInfo', 'error'];

    _.forEach(fields, field => {
      if (confirmInfo[field]) {
        this[field] = confirmInfo[field];
      }
    });

    this.componentDidMount = () => {
      // @ts-ignore TS2339
      this._isMounted = true;
      // propsで表示に必要なデータを渡すのでfetchはしない
      // @ts-ignore TS2339
      if (this.checkAvailability(confirmInfo.entitlements)) {
        this.handleError(new Error(ERROR.AVAILABLE_ENTITLEMENT));
      }
    };

    this.componentDidUpdate = (prevProps, prevState) => {
      // @ts-ignore TS2339
      if (prevProps.product.id !== this.props.product.id) {
        this.fetchPaymentInfo();
      }
    };

    // Confirmからのキャンセル操作を上書き
    this.cancelSelect = () => {
      // @ts-ignore TS2339
      confirmInfo.handleCancel();
    };

    // 契約/購入後の後処理
    // @ts-ignore TS2339
    if (typeof confirmInfo.handleAfterCommit === 'function') {
      // @ts-ignore TS2339
      this.handleAfterCommit = id => {
        // @ts-ignore TS2339
        confirmInfo.handleAfterCommit(id);
      };
    }
  }

  setFinishInfo(finishInfo = {}) {
    if (typeof finishInfo !== 'object') return;

    // @ts-ignore TS2339
    this.state.step = finishInfo.step;
    // @ts-ignore TS2339
    this.state.loading = false;

    // @ts-ignore TS2339
    if (finishInfo.errorMessage) {
      // @ts-ignore TS2339
      this.error = new Error(finishInfo.errorMessage);
      // @ts-ignore TS2339
      this.state.step = 'error';
    }

    const fields = ['product', 'productOrder', 'grantedEntitlements'];

    _.forEach(fields, field => {
      if (finishInfo[field]) {
        this[field] = finishInfo[field];
      }
    });

    this.componentDidMount = () => {
      // @ts-ignore TS2339
      this._isMounted = true;
      // propsで表示に必要なデータを渡すのでfetchはしない
    };
  }

  renderError() {
    // @ts-ignore TS2339
    return <ErrorView error={this.error} onClickCancel={this.cancelSelect} />;
  }

  render() {
    let loadingView;

    // @ts-ignore TS2339
    if (this.state.loading) {
      loadingView = (
        <div className="loading-spinner-container">
          <div className="loading-spinner"></div>
        </div>
      );
    }

    let content;
    // @ts-ignore TS2339
    switch (this.state.step) {
      case 'started':
        break;
      case 'confirm':
        content = (
          <PurchaseConfirm
            // @ts-ignore TS2322
            model={this.props.model}
            // @ts-ignore TS2339
            product={this.product}
            // @ts-ignore TS2339
            paymentMethods={this.paymentMethods}
            // @ts-ignore TS2339
            paymentInstruments={this.paymentInstruments}
            // @ts-ignore TS2339
            userVoucherInfo={this.userVoucherInfo}
            nextAction={this.doCommit}
            prevAction={this.cancelSelect}
            // @ts-ignore TS2339
            estimateInfo={this.estimateInfo}
            // @ts-ignore TS2339
            submitError={_.get(this.error, 'commitError')}
            // @ts-ignore TS2339
            defaultPaymentInstrumentId={this.props.defaultPaymentInstrumentId}
          />
        );
        break;
      case 'finish':
        content = (
          <PurchaseFinish
            // @ts-ignore TS2339
            productOrder={this.productOrder}
            // @ts-ignore TS2339
            entitlements={this.grantedEntitlements}
            // @ts-ignore TS2339
            product={this.product}
            returnTo={this.returnToUrl()}
            redirectTo={this.redirectToProduct}
            redirectToText={'商品ページに戻る'}
          />
        );
        break;
      // @ts-ignore TS7029
      case 'error':
        content = this.renderError();
      default:
        break;
    }

    return (
      // @ts-ignore TS2339
      <div className={classnames('up-sell-flow', { 'error-flow': this.state.step === 'error' })}>
        <div className="flow-header"></div>
        <div className="flow-content">{content}</div>
        {/*
         // @ts-ignore TS2339 */}
        <RegistForm ref={this.registFormRef} />
        {loadingView}
      </div>
    );
  }
}

export default withModalContext(panelHOC(ProductUpSellFlow));
