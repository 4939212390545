import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'src/domain/libs/util';
import classnames from 'classnames';

import Input from './Input';

export default class NicknameEdit extends Component {
  static get contextTypes() {
    return {
      watchPartyApp: PropTypes.object,
    };
  }
  static get propTypes() {
    return {
      watchPartyMode: PropTypes.bool,
      handleWatchPartyModeChange: PropTypes.func,
      closeModal: PropTypes.func,
      titleModel: PropTypes.object,
      seriesModel: PropTypes.object,
    };
  }
  constructor(props, context) {
    super(props, context);

    this.state = {
      profile: context.watchPartyApp.getProfile(),
    };

    this.handleNameChange = this.handleNameChange.bind(this);
  }

  handleNameChange(e) {
    // @ts-ignore TS2339
    const profile = this.state.profile;
    profile.name = e.target.value;
    this.setState({ profile });
  }

  render() {
    // @ts-ignore TS2339
    return <Edit closeModal={this.props.closeModal} onChange={this.handleNameChange} profile={this.state.profile} />;
  }
}

class Edit extends Component {
  static get contextTypes() {
    return {
      watchPartyApp: PropTypes.object,
    };
  }

  static get propTypes() {
    return {
      closeModal: PropTypes.func,
    };
  }

  constructor(props, context) {
    super(props, context);

    this.state = {
      stepNum: 1,
      errorMessageNickname: null,
    };

    this._submit = this._submit.bind(this);
    this._handleChange = this._handleChange.bind(this);
    this._handleEnter = this._handleEnter.bind(this);
  }

  _submit() {
    this.setState({ loading: true }, this._setProfile);
  }

  _setProfile() {
    // @ts-ignore TS2339
    this.context.watchPartyApp.setProfile(this.props.profile, (error, body) => {
      // console.log(error, body);
      this.setState({ loading: false });

      // NGワード
      if (_.has(error, 'message') && -1 !== error.message.indexOf('includes ng word')) {
        this.setState({ errorMessageNickname: '不適切な表現が含まれています' });
        return;
      }

      if (error) {
        console.log(error);
        return;
      }

      // @ts-ignore TS2339
      this.props.closeModal();
    });
  }

  _handleChange(e) {
    this.setState({ errorMessageNickname: null });
    // @ts-ignore TS2339
    this.props.onChange(e);
  }

  _handleEnter(e) {
    if (e.keyCode == 13) {
      this._submit();
    }
  }

  render() {
    return (
      <React.Fragment>
        <div className="text-left">
          <div className="modal-block">
            <Input
              label={'ニックネーム'}
              type="text"
              placeholder={'ニックネームを入力してください'}
              maxLength={8}
              required={true}
              // @ts-ignore TS2322
              onChange={this._handleChange}
              onKeyDown={this._handleEnter}
              // @ts-ignore TS2339
              value={this.props.profile.name}
              // @ts-ignore TS2339
              errorMessage={this.state.errorMessageNickname}
            />
          </div>
          <div className="form-btn-block text-center">
            {/*
             // @ts-ignore TS2339 */}
            <a className="btn btn-gray btn-small" href="javascript:void(0);" onClick={this.props.closeModal}>
              キャンセル
            </a>
            <a
              // @ts-ignore TS2339
              className={classnames('btn btn-fill btn-small', { disabled: _.isEmpty(this.props.profile.name) })}
              href="javascript:void(0);"
              onClick={this._submit}
            >
              変更する
            </a>
          </div>
        </div>
        {/*
         // @ts-ignore TS2339 */}
        {this.state.loading ? (
          <div className="comment-loading">
            <div className="loading-spinner"></div>
          </div>
        ) : null}
      </React.Fragment>
    );
  }
}
