import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';

import DeeplinkApp from './DeepLinkApp';

const DeepLinkLauncher = (props, context) => {
  const lauch = useCallback(() => {
    const authContext = context.getModelData('authContext');
    const deeplinkApp = new DeeplinkApp({ routeHandler: context.routeHandler, authContext });
    deeplinkApp.launch(props);
    if (props.afterDeepLinkLaunch) props.afterDeepLinkLaunch();
  }, [context, props]);

  useEffect(() => {
    lauch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};

DeepLinkLauncher.contextTypes = {
  routeHandler: PropTypes.object,
  getModelData: PropTypes.func,
};
export default DeepLinkLauncher;
