import { useMemo } from 'react';
import _ from 'src/domain/libs/util';

import { Meta } from 'src/types/context/Meta';
import { Event } from 'src/types/context/Event';

const PLAYBACK_RESTRICTION_TYPE = {
  BCAS_ONLY: 100,
  TRIAL_NG: 200,
  TRIAL_OK: 0,
};

const usePlaybackRestrictionType = ({ meta, event }: { meta?: Meta; event?: Event }) => {
  const playbackRestrictionType = useMemo(() => {
    let _playbackRestrictionType = meta?.playbackRestrictionType;
    if (_.isEmpty(event)) {
      return _playbackRestrictionType;
    }

    const permitUserStatuses = event.permitUserStatuses || [];

    if (permitUserStatuses.length == 1 && permitUserStatuses[0] == 'broadcast') {
      _playbackRestrictionType = PLAYBACK_RESTRICTION_TYPE.BCAS_ONLY;
    } else if (
      permitUserStatuses.length == 2 &&
      _.includes(permitUserStatuses, 'stream') &&
      _.includes(permitUserStatuses, 'broadcast')
    ) {
      _playbackRestrictionType = PLAYBACK_RESTRICTION_TYPE.TRIAL_NG;
    } else if (
      permitUserStatuses.length == 3 &&
      _.includes(permitUserStatuses, 'trial') &&
      _.includes(permitUserStatuses, 'stream') &&
      _.includes(permitUserStatuses, 'broadcast')
    ) {
      _playbackRestrictionType = PLAYBACK_RESTRICTION_TYPE.TRIAL_OK;
    }

    return _playbackRestrictionType;
  }, [event, meta?.playbackRestrictionType]);

  return playbackRestrictionType;
};

export default usePlaybackRestrictionType;
