import React from 'react';
import PropTypes from 'prop-types';
import _ from 'src/domain/libs/util';
import * as DOMUtils from '../../../../sketch-platform/utils/DOMUtils';

class VerticalVolumeScrubberBar extends React.PureComponent {
  static get propTypes() {
    return {
      volume: PropTypes.number,
      player: PropTypes.object.isRequired,
      startHover: PropTypes.func,
      stopHover: PropTypes.func,
      step: PropTypes.number,
    };
  }

  constructor(props, context) {
    super(props, context);
    this.onClickCapture = this.onClickCapture.bind(this);
    this.onMouseEnter = this.onMouseEnter.bind(this);
    this.onMouseLeave = this.onMouseLeave.bind(this);
    this.stepBack = this.stepBack.bind(this);
    this.stepForward = this.stepForward.bind(this);

    // @ts-ignore TS2339
    this.barRef = React.createRef();

    this.state = {
      cursorActive: false,
      cursorPosition: 0,
      scrubberDimensions: null,
      windowDimensions: null,
    };
  }

  componentDidMount() {}

  componentWillUnmount() {}

  onClickCapture(event) {
    event.stopPropagation();
    event.preventDefault();
    // @ts-ignore TS2339
    const position = DOMUtils.getPointerPosition(this.barRef.current, event).x;
    // @ts-ignore TS2339
    this.props.player.muted(false);
    // @ts-ignore TS2339
    this.props.player.volume(position.toFixed(8));
    // @ts-ignore TS2339
    if (this.props.releaseFocus) {
      // @ts-ignore TS2339
      this.props.releaseFocus(event);
    }
  }

  onMouseEnter(e) {
    // @ts-ignore TS2339
    if (this.props.startHover) {
      // @ts-ignore TS2339
      this.props.startHover('volume-bar');
    }
    // @ts-ignore TS2339
    if (this.props.enterVolume) {
      // @ts-ignore TS2339
      this.props.enterVolume(e);
    }
  }

  onMouseLeave(e) {
    // @ts-ignore TS2339
    if (this.props.stopHover) {
      // @ts-ignore TS2339
      this.props.stopHover('volume-bar');
    }
    // @ts-ignore TS2339
    if (this.props.leaveVolume) {
      // @ts-ignore TS2339
      this.props.leaveVolume(e);
    }
  }

  stepBack() {
    // @ts-ignore TS2339
    this.props.player.muted(false);
    // @ts-ignore TS2339
    const volume = this.props.player.volume();
    // @ts-ignore TS2339
    this.props.player.volume(_.max([0, volume - this.props.step]));
  }

  stepForward() {
    // @ts-ignore TS2339
    this.props.player.muted(false);
    // @ts-ignore TS2339
    const volume = this.props.player.volume();
    // @ts-ignore TS2339
    this.props.player.volume(_.min([1, volume + this.props.step]));
  }

  render() {
    let volumePercentage = 0;
    // @ts-ignore TS2339
    if (this.props.volume >= 0) {
      // @ts-ignore TS2322
      volumePercentage = (this.props.volume * 100).toFixed(8);
    }

    return (
      <div
        className="progress-control"
        // @ts-ignore TS2322
        tabIndex="0"
        onMouseEnter={this.onMouseEnter}
        onMouseLeave={this.onMouseLeave}
        onFocus={this.onMouseEnter}
        onBlur={this.onMouseLeave}
      >
        {/*
         // @ts-ignore TS2339 */}
        <div className="scrubber-container" ref={this.barRef} onClickCapture={this.onClickCapture}>
          <div className="scrubber-bar">
            <div className="track">
              <div className="current-volume" style={{ width: `${volumePercentage}%` }}></div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default VerticalVolumeScrubberBar;
