import classNames from 'classnames';
import React, { useContext, useEffect, useState } from 'react';
import BackgroundImage from 'src/apps/common/components/BackgroundImage';
import CardContext from 'src/apps/common/context/CardContext';
import routes from 'src/apps/common/routes';
import _ from 'src/domain/libs/util';

type AttributeCardProps = {
  itemData: {
    id: number;
    type: string;
    name: string;
    mylisted: boolean;
    image: string | null;
    middleGenres: MiddleGenreType[];
  };
  isDeleteMode: boolean;
  deleteApp: any;
};
type MiddleGenreType = {
  id: number;
  attribute_type: string;
  values: null;
  publish_start_at: string | null;
  publish_end_at: string | null;
  created_at: string;
  updated_at: string;
  publish_status: 'publish_on' | string;
  deleted_at: string | null;
  name: string;
  import_unique_key: string | number | null;
  push_rank: number;
  ref_id: string;
  name_ruby: string | null;
  slug: string | null;
  keywords: string | null;
  parent_id: number;
  parent_sort: number;
  deleted_at_ts: number;
  publish_on_limited_rule: string | null;
  workflow_status: string;
  reviewer_id: number | null;
  review_comment: string | null;
  admin_tags: string;
  first_imported_at: string | null;
  last_imported_at: string | null;
  created_by: number;
  updated_by: number;
  deleted_by: number | null;
  translations: string | null;
  version_id: number;
  schema_id: number;
  deep_link_id: number;
  publish_start_at_ts: number;
  publish_end_at_ts: number;
};

const AttributeCard = ({ itemData, isDeleteMode, deleteApp }: AttributeCardProps) => {
  // @ts-ignore TS232
  const { history } = useContext(CardContext);
  const { id, type, name, middleGenres, image } = itemData;
  const [isDelete, setIsDelete] = useState(false);

  useEffect(() => {
    if (deleteApp) {
      // @ts-ignore TS2339
      const unregister = deleteApp.register(
        Object.assign({}, itemData, {
          id,
          // @ts-ignore TS2339
          checked: false,
          onModeChange: handleDeleteModeChange,
          onChecked: handleDeleteChecked,
        }),
      );

      return () => {
        unregister();
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isDeleteMode) {
      setIsDelete(false);
    }
  }, [deleteApp, isDeleteMode]);

  const genre = _.get(middleGenres, '[0].name', '');

  const handleDeleteModeChange = (deleteMode: boolean) => {
    //propsからisDeleteModeをもらっているのでfalseになる場合のみisDeleteをfalseにする
    if (!deleteMode) {
      setIsDelete(false);
    }
  };

  const handleDeleteChecked = item => {
    setIsDelete(item.checked);
  };

  const onClick = () => {
    if (isDeleteMode) {
      toggleCheck();
      return;
    }

    let to = '';
    // タイプ別に遷移先を変更
    if (type === 'team') {
      to = routes.team.makePath({ id });
    }
    history.push(to);
  };

  const toggleCheck = () => {
    if (isDelete) {
      deleteApp.remove(id);
      setIsDelete(false);
    } else {
      deleteApp.add(id);
      setIsDelete(true);
    }
  };

  const imageUrl = image ? image : '/images/team_no_img.png';
  return (
    <div className="attr-card">
      {isDeleteMode && (
        <div className="checkbox-cont">
          <input
            id={`delete-${id}`}
            className="checkbox"
            type="checkbox"
            name="delete"
            checked={isDelete}
            onChange={onClick}
          />
          <label htmlFor={`delete-${id}`} className="check-box-label no-position"></label>
        </div>
      )}
      <div className={classNames('attr-card__inner', { checked: isDelete })} onClick={onClick}>
        <div className="emblem">
          {/* @ts-ignore  */}
          <BackgroundImage url={imageUrl} />
        </div>

        <div className="title-container">
          {genre && <div className="item-genre">{genre}</div>}
          <div className="item-name">{name}</div>
        </div>

        <div className="more-icon">
          <div className="fa fa-angle_right"></div>
        </div>
      </div>
    </div>
  );
};

export default AttributeCard;
