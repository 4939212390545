import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'src/domain/libs/util';

import HorizontalProgressScrubberBar from './HorizontalProgressScrubberBar';

type ProgressBarProps = {
  currentScrubTime?: number;
  grabAndHoldFocus?: () => void;
  grabFocus?: () => void;
  isHidden?: boolean;
  onScrubEnd?: () => void;
  onScrubStart?: () => void;
  player: any;
  playerDimensions?: any;
  releaseFocus?: () => void;
  releaseFocusHold?: () => void;
  setKeepActive?: () => void;
  startHover?: () => void;
  stopHover?: () => void;
  step?: number;
  updateCurrentScrubTime?: (time: number) => void;
  isAdMode?: boolean;
  adDuration?: number;
  currentAdTime?: number;
  onMouseMove?: any;
  seekPreview?: any;
  mediaQueryCheck?: any;
  watchSpMode?: any;
};

const ProgressBar = (props: ProgressBarProps) => {
  const {
    player,
    isHidden,
    isAdMode,
    adDuration,
    currentAdTime,
    updateCurrentScrubTime,
    onScrubStart,
    onScrubEnd,
  } = props;
  const [currentPlayerTime, setCurrentPlayerTime] = useState(0);
  const [isScrubbing, setIsScrubbing] = useState(false);
  const scrubberRef = useRef(null);

  const update = useCallback(() => {
    setCurrentPlayerTime(isAdMode ? currentAdTime : player.currentTime());
  }, [isAdMode, player, currentAdTime]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const throttleUpdate = useCallback(_.throttle(update, 200), [update]);

  useEffect(() => {
    if (!isHidden) {
      player.on('timeupdate', throttleUpdate);
      player.on('seeked', update);
    }

    return () => {
      player.off('timeupdate', throttleUpdate);
      player.off('seeked', update);
    };
  }, [isHidden, isAdMode, player, throttleUpdate, update]);

  useEffect(() => {
    setCurrentPlayerTime(isAdMode ? currentAdTime : player.currentTime());
  }, [isAdMode, currentAdTime, player]);

  const handleScrubStart = (scrubTime: number) => {
    updateCurrentScrubTime?.(scrubTime);
    onScrubStart?.();
  };

  const handleScrubEnd = (scrubTime: number) => {
    updateCurrentScrubTime?.(-1);
    onScrubEnd?.();
  };

  const handleScrub = (scrubTime: number) => {
    updateCurrentScrubTime?.(scrubTime);
  };

  let duration = player.duration();
  let seekableDuration = duration;
  let buffered = player.buffered();
  let seekableStart = 0;
  let seekableEnd = 0;

  if (duration === Infinity) {
    try {
      seekableStart = player.seekable().start(0);
      seekableEnd = player.seekable().end(0);
      duration = seekableEnd;
      seekableDuration = seekableEnd - seekableStart;
    } catch (e) {}
  }

  let bufferedDuration = 0;
  if (buffered && buffered.length) {
    for (let i = 0; i < buffered.length; i++) {
      let start = buffered.start(i);
      let end = buffered.end(i);
      if (end > duration) {
        end = duration;
      }
      bufferedDuration += end - start;
    }
  }

  if (isAdMode) {
    duration = adDuration || 0;
    seekableDuration = duration;
  }

  return (
    <div className="player-control-element progress-control">
      <HorizontalProgressScrubberBar
        ref={scrubberRef}
        player={player}
        onScrubStart={handleScrubStart}
        onScrub={handleScrub}
        onScrubEnd={handleScrubEnd}
        // @ts-ignore TS2322
        onMouseMove={props.onMouseMove}
        isHidden={isHidden}
        buffered={bufferedDuration}
        seekPreview={props.seekPreview}
        duration={duration}
        seekableDuration={seekableDuration}
        seekableStart={seekableStart}
        seekableEnd={seekableEnd}
        step={props.step}
        currentScrubTime={props.currentScrubTime}
        mediaQueryCheck={props.mediaQueryCheck}
        watchSpMode={props.watchSpMode}
        isAdMode={isAdMode}
        {...{ currentPlayerTime, isScrubbing }}
      />
    </div>
  );
};

ProgressBar.propTypes = {
  currentScrubTime: PropTypes.number,
  grabAndHoldFocus: PropTypes.func,
  grabFocus: PropTypes.func,
  isHidden: PropTypes.bool,
  onScrubEnd: PropTypes.func,
  onScrubStart: PropTypes.func,
  player: PropTypes.object.isRequired,
  playerDimensions: PropTypes.object,
  releaseFocus: PropTypes.func,
  releaseFocusHold: PropTypes.func,
  setKeepActive: PropTypes.func,
  startHover: PropTypes.func,
  stopHover: PropTypes.func,
  step: PropTypes.number,
  updateCurrentScrubTime: PropTypes.func,
  isAdMode: PropTypes.bool,
  adDuration: PropTypes.number,
  currentAdTime: PropTypes.number,
  onMouseMove: PropTypes.func,
  seekPreview: PropTypes.any,
  mediaQueryCheck: PropTypes.any,
  watchSpMode: PropTypes.any,
};

export default ProgressBar;
