import React from 'react';
import PropTypes from 'prop-types';
import Falcor from 'falcor';
import _ from 'src/domain/libs/util';

import TitleCard from 'src/apps/bluerose/components/browse/TitleCard';
import AdvertisingCard from 'src/apps/bluerose/components/browse/AdvertisingCard';
import SideMenu from './SideMenu';
import Header from './Header';
import { BrowserInfo } from '@domain/models/browserInfo';
import lastChannelDataStore from 'src/utils/lastChannelDataStore';
import { Palette } from 'src/types/context/Palette';
import routes from 'src/apps/common/routes';
import FeatureHome from 'src/apps/bluerose/components/browse/FeatureHome';

export type OpenMenu = string;
export type GlobalMenuLink = any;
export type GlobalMenuGenre = any;
type MenuType = 'sidemenu' | 'header';
export type InfoData = any;
export type MainMenu = {
  title: string;
  url?: string;
  name: 'what_is_wowow' | 'home' | 'live' | 'feature' | 'linear' | 'headerGenreMenu' | 'myMenu';
  dropdown?: boolean;
  dropdownMenuName?: string;
  hidden?: boolean;
  refId?: string;
  // liveのみ
  items?: any[];
  // 特集のみ
  palette?: Palette;
};
export const LINEAR_MENU = 'linear-menu';
export const MYMENU_MENU = 'mymenu-menu';
export const FEATURE_MENU = 'feature-menu';
export const LIVE_MENU = 'live-menu';
export const HEADER_GENRE_MENU = 'headerGenre-menu';
export const ACTIVE_MENU = 'active';

const JOIN_CANVAS_IDKEY = 'join';

type GlobalMenuProps = {
  onChangeSideMenuOpened: (opened: boolean) => void;
  // TODO:なおす
  [key: string]: any;
};
class GlobalMenu extends React.PureComponent<GlobalMenuProps> {
  static getMenuType = function(models, options, props: any = {}): MenuType {
    const browserInfo = _.get(models, 'browserInfo.data') as BrowserInfo;
    if (!browserInfo.isCarMonitor || props.menuType === 'header') {
      return 'header';
    } else {
      return 'sidemenu';
    }
  };

  static getMaintenanceRootPath = (models, options, props = {}): any[] => {
    const config = _.get(models, 'config.data.link_set_keys', {});
    const keys = _.get(config, 'info', '');
    return ['link_sets', [keys.maintenance]];
  };

  static getInfoRootPath = (models, options, props = {}): any[] => {
    let keys;
    const config = _.get(models, 'config.data.link_set_keys', {});
    keys = _.get(config, 'info', '');
    return ['link_sets', [keys.release]];
  };

  static getLinkRootPath = (models, options, props = {}): any[] => {
    const key = _.get(models, 'config.data.link_set_keys.global_menu') as string;
    return ['link_sets', [key]];
  };

  static getGenreRootPath = (models, options, props = {}): any[] => {
    let generalKey;
    if (models.browserInfo.data.isIOS || models.browserInfo.data.isAndroid) {
      generalKey = _.get(models, 'config.data.attribute_set_keys.menu.sp_genre');
    } else {
      generalKey = _.get(models, 'config.data.attribute_set_keys.menu.genre');
    }
    if (generalKey) {
      return ['attribute_sets', [generalKey]];
    }
    return [];
  };

  static getFeatureRootPath = (models, options, props = {}): any[] => {
    const paletteIdOrKey = FeatureHome.getFeaturePaletteId(models, options, props);
    if (paletteIdOrKey) {
      return ['palette', [paletteIdOrKey]];
    }
    return [];
  };

  static getPaths = (models, options, props = {}) => {
    const menuType = GlobalMenu.getMenuType(models, options, props);
    const genreRootPath = GlobalMenu.getGenreRootPath(models, options, props);
    const linkRootPath = GlobalMenu.getLinkRootPath(models, options, props);
    const infoRootPath = GlobalMenu.getInfoRootPath(models, options, props);
    const maintenanceRootPath = GlobalMenu.getMaintenanceRootPath(models, options, props);
    const featureRootPath = GlobalMenu.getFeatureRootPath(models, options, props);
    let paths = [
      genreRootPath.concat([{ from: 0, to: 49 }, ['id', 'refId', 'name', 'canvas', 'type']]),
      linkRootPath.concat([{ from: 0, to: 49 }, ['id', 'title', 'url', 'target']]),
      infoRootPath.concat([{ from: 0, to: 49 }, ['id', 'title', 'url', 'thumbnailUrl', 'postAt']]),
      maintenanceRootPath.concat([{ from: 0, to: 49 }, ['id', 'title', 'url', 'thumbnailUrl', 'postAt']]),
      infoRootPath.concat(['additional']),
      maintenanceRootPath.concat(['additional']),
    ];
    if (!_.isEmpty(featureRootPath)) {
      paths = paths.concat([
        // @ts-ignore TS2339
        ...TitleCard.getPaths().map(path => featureRootPath.concat(['objects', { from: 0, to: 9 }]).concat(path)),
        // @ts-ignore TS2339
        ...AdvertisingCard.getPaths().map(path => featureRootPath.concat(['objects', { from: 0, to: 9 }]).concat(path)),
        featureRootPath.concat([
          [
            'id',
            'title',
            'hasObjects',
            'artType',
            'cardSize',
            'artKind',
            'titleType',
            'popType',
            'cardTitleDisplaySetting',
          ],
        ]),
      ]);
    }
    if (menuType === 'header') {
      const headerPaths = Header.getPaths(models, options, props);
      paths = paths.concat(headerPaths);
    }
    return paths;
  };

  static contextTypes = {
    getModelData: PropTypes.func,
    routeHandler: PropTypes.object,
    models: PropTypes.object,
  };
  model: Falcor.Model;
  globalMenuGenres: GlobalMenuGenre[];
  globalMenuLinks: GlobalMenuLink[];
  infoData?: InfoData;
  featurePalette: any;
  menuType: MenuType;
  mainMenus: MainMenu[];
  constructor(props, context) {
    super(props, context);
    this.fetchData = this.fetchData.bind(this);
    this.convertFeaturePalette = this.convertFeaturePalette.bind(this);
    this.getCurrentCanvasIdKey = this.getCurrentCanvasIdKey.bind(this);
    this.buildMainMenus = this.buildMainMenus.bind(this);

    this.menuType = GlobalMenu.getMenuType(context.models, {}, props);
    this.model = props.pathEvaluator || props.model.pathEvaluator;
    this.globalMenuGenres = _.values(
      _.omitBy(this.model.getSync(GlobalMenu.getGenreRootPath(context.models, {}, props)), (val, key) => {
        return key.indexOf('$') === 0 || !_.get(val, 'id');
      }),
    );
    this.globalMenuLinks = _.values(
      _.omitBy(this.model.getSync(GlobalMenu.getLinkRootPath(context.models, {}, props)), (val, key) => {
        return key.indexOf('$') === 0 || !_.get(val, 'id');
      }),
    );
    this.featurePalette = this.convertFeaturePalette(
      _.omitBy(this.model.getSync(GlobalMenu.getFeatureRootPath(context.models, {}, props)), (val, key) => {
        return key.indexOf('$') === 0 || !_.get(val, 'id');
      }),
    );
    this.mainMenus = this.buildMainMenus();
  }

  componentDidMount() {
    this.fetchData();
  }

  convertFeaturePalette(rawPaletteData: any) {
    // const _palette = _.omitBy(rawPaletteData, (val, key) => key.indexOf('$') === 0);
    const _palette = rawPaletteData;
    if (_palette && _palette.objects) {
      _palette.objects = _.values(
        _.omitBy(_palette.objects, (val, key) => {
          return key.indexOf('$') === 0 || key === 'length';
        }),
      );
    }
    return _palette;
  }

  getCurrentCanvasIdKey() {
    const currentPageComponent = _.last(this.context.routeHandler.components) as any;
    if (currentPageComponent && typeof currentPageComponent.getCanvasId === 'function') {
      // @ts-ignore TS2339
      return currentPageComponent.getCanvasId(
        this.context.models,
        {},
        { routeHandler: this.context.routeHandler, ...this.context.routeHandler.params },
      );
    }
    return null;
  }

  buildMainMenus() {
    const browserInfo = this.context.getModelData('browserInfo');
    const config = this.context.getModelData('config');
    const whatIsWowow = _.get(config, 'header.what_is_wowow');
    const userInfo = this.context.getModelData('userInfo');
    // 先頭固定
    const fixedMenu: MainMenu[] = [
      {
        title: 'WOWOWとは',
        url: whatIsWowow,
        name: 'what_is_wowow',
        dropdown: false,
        hidden: !whatIsWowow || userInfo.status !== 'NON_REGISTERED_MEMBER',
      },
      {
        title: 'ホーム',
        url: '/',
        name: 'home',
        dropdown: false,
        hidden: browserInfo.isIOS || browserInfo.isAndroid,
      },
    ];

    const dynamicMenu: MainMenu[] = _.map(this.globalMenuLinks, link => {
      if (routes.liveSchedule.match(link.url)) {
        link.name = 'live';
        link.dropdownMenuName = LIVE_MENU;
        // ドロップダウン表示する際はtrueにする
        link.dropdown = false;
      } else if (routes.featureHome.match(link.url)) {
        link.name = 'feature';
        link.palette = this.featurePalette;
        link.hidden = !this.featurePalette || _.isEmpty(this.featurePalette.objects);
        link.dropdownMenuName = FEATURE_MENU;
        link.dropdown = this.menuType === 'header';
      }
      return link;
    });
    dynamicMenu.push({
      title: '放送同時配信',
      name: 'linear',
      dropdown: true,
      dropdownMenuName: LINEAR_MENU,
      refId: lastChannelDataStore.get(this.context.models),
    });
    dynamicMenu.push({
      title: 'ジャンル',
      name: 'headerGenreMenu',
      dropdown: true,
      // 表示する時にfalseにする
      hidden: true,
      dropdownMenuName: HEADER_GENRE_MENU,
    });
    dynamicMenu.push({
      title: 'マイページ',
      name: 'myMenu',
      dropdown: true,
      dropdownMenuName: MYMENU_MENU,
    });
    return [...fixedMenu, ...dynamicMenu];
  }

  async fetchData() {
    const paths = GlobalMenu.getPaths(this.context.models, {}, this.props);
    try {
      const res = await this.model.fetch(paths);
      this.globalMenuGenres = _.values(
        _.omitBy(_.get(res.json, GlobalMenu.getGenreRootPath(this.context.models, {}, this.props)), (val, key) => {
          return key.indexOf('$') === 0 || key === 'length' || !_.get(val, 'id');
        }),
      );
      this.globalMenuLinks = _.values(
        _.omitBy(_.get(res.json, GlobalMenu.getLinkRootPath(this.context.models, {}, this.props)), (val, key) => {
          return key.indexOf('$') === 0 || key === 'length' || !_.get(val, 'id');
        }),
      );
      const infoRootPath = GlobalMenu.getInfoRootPath(this.context.models, {}, this.props);
      const maintenanceInfoRootPath = GlobalMenu.getMaintenanceRootPath(this.context.models, {}, this.props);
      const infoData = _.values(
        _.omitBy(_.get(res.json, infoRootPath), (val, key) => {
          return key.indexOf('$') === 0 || key === 'length' || !_.get(val, 'id');
        }),
      );
      const maintenanceInfoData = _.values(
        _.omitBy(_.get(res.json, maintenanceInfoRootPath), (val, key) => {
          return key.indexOf('$') === 0 || key === 'length' || !_.get(val, 'id');
        }),
      );
      const additionalInfo = _.get(res.json, infoRootPath.concat('additional'));
      const additionalMaintenanceInfo = _.get(res.json, maintenanceInfoRootPath.concat('additional'));
      _.forEach(infoData, (link, i) => {
        Object.assign(link, additionalInfo[i]);
      });
      _.forEach(maintenanceInfoData, (link, i) => {
        Object.assign(link, additionalMaintenanceInfo[i]);
      });

      this.infoData = infoData.concat(maintenanceInfoData);
      this.featurePalette = this.convertFeaturePalette(
        _.get(res.json, GlobalMenu.getFeatureRootPath(this.context.models, {}, this.props)),
      );
      this.mainMenus = this.buildMainMenus();
      this.forceUpdate();
    } catch (e) {}
  }
  render() {
    return this.menuType === 'sidemenu' ? (
      <SideMenu
        mainMenus={this.mainMenus}
        globalMenuGenres={this.globalMenuGenres}
        infoData={this.infoData}
        isJoinCanvas={this.getCurrentCanvasIdKey() === JOIN_CANVAS_IDKEY}
        onChangeSideMenuOpened={this.props.onChangeSideMenuOpened}
        //TODO: この渡し方やめたい
        {...this.props}
      />
    ) : (
      <Header
        mainMenus={this.mainMenus}
        globalMenuGenres={this.globalMenuGenres}
        infoData={this.infoData}
        isJoinCanvas={this.getCurrentCanvasIdKey() === JOIN_CANVAS_IDKEY}
        //TODO: この渡し方やめたい
        {...this.props}
      />
    );
  }
}

export default GlobalMenu;
