import Axios from 'src/common/Axios';
import { ApiConfig, ApiInterface, ApiOption, ApiPostData, ApiPrams } from 'src/interface/api/ApiInterface';
export class Api implements ApiInterface {
  api: Axios;
  constructor(config: ApiConfig) {
    this.api = new Axios(config);
  }
  async get<T = any>(url: string, params?: ApiPrams, option: ApiOption = {}) {
    return this.api.get<T>(url, params, option);
  }
  async post<T = any>(url: string, data?: ApiPostData, option: ApiOption = {}) {
    return this.api.post<T>(url, data, option);
  }
  async del() {}
  async patch() {}
}
