export const PROFILE_ID = 'prfid';
export const PROFILE_UID = 'prfuid';
// fingerprintを使用して作成していたvuid
// 多数デバイス間で重複するため使用しない
export const OLD_VUID_KEY = 'vuid';
// uuidで新しくりなおしたもの
// vuidにはこちらを使用する
export const NEW_VUID_KEY = 'nvuid';
export const DSID_KEY = 'dsid';
export const PSID_KEY = 'psid';
export const RESUME_POINT_KEY = 'rp';
export const TOKEN_KEY = 'tokenkey';
export const VOLUME_KEY = 'volume';
export const SETTINGS_KEY = 'settings';
export const DARK_KEY = 'dark';
export const WIDE_KEY = 'wide';
export const LOGINED_KEY = 'logined';
export const NOTIFICATION_KEY = 'notification';
export const KEYWORD_KEY = 'keyword';
export const WOL_TOKEN = 'wowow2_MGSID';
export const FALLBACK_MODAL_ALERT = 'fma';
export const RELOAD_NEED_DATE = 'rnd';
export const COUPON = 'cpn';
export const ENQUETE = 'enq';
export const LAST_CHANNEL = 'lch';
// 楽天アフィリエイトのcv計測用
export const RAKUTEN_CV_HIST_ID = 'r_amaterasu_cv_histid';
export const USER_APP_SETTINGS_KEY = 'uaskey';
export const SUBSCRIPTION_HISTORY_FLAG = 'subscription_history_flag';
