import React from 'react';
import Falcor from 'falcor';
import PropTypes from 'prop-types';
import _ from 'src/domain/libs/util';
import GalleryContent from './GalleryContent';
import MyListDeleteApp from '../../../../common/MyListDeleteApp';
import MyListGalleryHeader from './MyListGallaryHeader';
import GalleryDeleteContent from './GalleryDeleteContent';
import SortGallery from './SortGallery';
import { withLayoutContext } from 'src/apps/common/context/LayoutContext';
import routes from 'src/apps/common/routes';
import Canvas from './Canvas';

export type MyListTabs = 'programs' | 'teams';

class MyList extends React.Component {
  model: Falcor.Model;
  searchTeamCanvasId: string | undefined;
  static getPrefetchPaths = function(models, options, props) {
    const so = _.get(props, 'routeHandler.query.so', 'cd');
    const galleryContentPath = GalleryContent.getPaths(
      models,
      options,
      Object.assign({}, GalleryContent.defaultProps, props, { keyPrefix: 'mylist', sortOrder: so }),
    );

    const searchTeamCanvasId = _.get(models, 'config.data.search_team_canvas_id', undefined);
    const tab = _.get(props, 'routeHandler.query.tab', 'programs');
    if (searchTeamCanvasId && tab === 'teams') {
      const canvasPaths = Canvas.getPaths(models, options, {
        canvasId: searchTeamCanvasId,
      });
      return galleryContentPath.concat(canvasPaths);
    } else {
      return galleryContentPath;
    }
  };

  static get contextTypes() {
    return {
      models: PropTypes.object,
      getModelData: PropTypes.func,
      routeHandler: PropTypes.object,
      gtmApp: PropTypes.object,
      history: PropTypes.object,
    };
  }

  constructor(props, context) {
    super(props, context);
    // @ts-ignore TS2339
    this.model = (props.pathEvaluator || props.model.pathEvaluator).batch(100);
    // @ts-ignore TS2339
    this._deleteApp = new MyListDeleteApp(context.getModelData('services', 'cms'), this.context.gtmApp, this.model);
    this.handleDeleted = this.handleDeleted.bind(this);
    this.handleChangeQuery = this.handleChangeQuery.bind(this);
    this.handleFetchedCount = this.handleFetchedCount.bind(this);
    this.sendToGtm = this.sendToGtm.bind(this);
    this.handleChangeMylistTab = this.handleChangeMylistTab.bind(this);
    this.handleChangeDeleteMode = this.handleChangeDeleteMode.bind(this);
    const isProgramsTab = props.routeHandler.query['tab'] ? props.routeHandler.query['tab'] === 'programs' : true;
    this.state = {
      showDeleteContent: false,
      mylistSelectedTab: isProgramsTab ? 'programs' : 'teams',
      isDeleteMode: false,
    };

    // urlのtabパラメータによってDeleteAppのModelTypeを変更する
    // @ts-ignore TS2339
    this._deleteApp.setModelType(isProgramsTab ? 'meta' : 'attribute');
    this.searchTeamCanvasId = _.get(context, 'models.config.data.search_team_canvas_id', undefined);
  }

  componentDidMount() {
    // @ts-ignore TS2339
    this._isMounted = true;
    // @ts-ignore TS2339
    this._deleteApp.on('onDeleted', this.handleDeleted);
    this.sendToGtm();
  }

  componentWillUnmount() {
    // @ts-ignore TS2339
    this._isMounted = false;
    // @ts-ignore TS2339
    this._deleteApp.off('onDeleted', this.handleDeleted);
  }

  sendToGtm() {
    if (!_.get(this.context, 'gtmApp')) return;
    this.context.gtmApp.pageView('マイリスト');
  }

  handleDeleted() {
    // キャッシュされているマイリスト一覧を削除する
    // @ts-ignore TS2339
    if (this.state.mylistSelectedTab === 'programs') {
      // @ts-ignore TS2339
      this.model.invalidate(['mylist']);
    } else {
      // @ts-ignore TS2339
      this.model.invalidate(['mylistattr']);
    }
  }

  handleChangeDeleteMode(isDeleteMode) {
    this.setState({ isDeleteMode });
  }

  handleChangeQuery(query) {
    // @ts-ignore TS2339
    this._deleteApp.flush();
    this.setState({ query });
  }

  handleFetchedCount(count) {
    this.setState({ showDeleteContent: count > 0 });
  }

  handleChangeMylistTab = (mylistSelectedTab: MyListTabs) => {
    // タブの変更に合わせてDeleteAppのModelTypeを変更し、sendメソッドのparamsを変更する
    // @ts-ignore TS2339
    this._deleteApp.setModelType(mylistSelectedTab === 'programs' ? 'meta' : 'attribute');
    if (_.get(this.context, 'gtmApp')) {
      // GTMにスキップイベントを送信
      this.context.gtmApp.pushDataLayerOnMylistFavoriteTabClick(mylistSelectedTab === 'programs' ? '番組' : 'チーム');
    }
    const to = routes.mylist.makePath({}, { tab: mylistSelectedTab });
    this.context.history.replace(to);
  };

  render() {
    // @ts-ignore TS2339
    const deleteContent = this.state.showDeleteContent ? <GalleryDeleteContent deleteApp={this._deleteApp} /> : null;
    const sortOrder = this.context.routeHandler.query['so'] || 'cd';
    // @ts-ignore TS2339
    const sortComponent = this.state.showDeleteContent ? (
      <SortGallery
        // @ts-ignore TS2322
        sortOrder={sortOrder}
        sortOrders={[
          { so: 'cd', name: '追加順（降順）' },
          { so: 'ca', name: '追加順（昇順）' },
        ]}
        onChangeQuery={this.handleChangeQuery}
        positionRight={true}
      />
    ) : null;

    return (
      <>
        <GalleryContent
          // @ts-ignore TS2322
          header={
            <MyListGalleryHeader
              deleteContent={deleteContent}
              sortComponent={sortComponent}
              // @ts-ignore TS2322
              mylistSelectedTab={this.state.mylistSelectedTab}
              handleChangeMylistTab={this.handleChangeMylistTab}
              // @ts-ignore TS2322
              handleChangeDeleteMode={this.handleChangeDeleteMode}
            />
          }
          // @ts-ignore TS2322
          deleteApp={this._deleteApp}
          onFetchedCount={this.handleFetchedCount}
          // @ts-ignore TS2339
          model={this.model}
          // @ts-ignore TS2339
          keyPrefix={this.state.mylistSelectedTab === 'programs' ? 'mylist' : 'mylistattr'}
          sortOrder={sortOrder}
          showEpisodeNumber={true}
          showPopCardMylistButton={false}
          // @ts-ignore TS2339
          classes={this.state.mylistSelectedTab === 'programs' ? null : ['mylist-attribute']}
          // @ts-ignore TS2339
          spMode={!!this.props.spMode}
          // @ts-ignore TS2339
          isDeleteMode={this.state.isDeleteMode}
          noResults={
            // @ts-ignore TS2339
            this.state.mylistSelectedTab === 'teams' ? (
              <div className={'gallery-message mylistattr-no-team'}>登録されているチームがありません。</div>
            ) : null
          }
        />

        {/* 番組が選択されている時は表示させない */}
        {/* @ts-ignore TS2322 */}
        {this.state.mylistSelectedTab === 'teams' && this.searchTeamCanvasId && (
          <Canvas model={this.model} canvasId={this.searchTeamCanvasId} />
        )}
      </>
    );
  }
}

export default withLayoutContext(MyList);
