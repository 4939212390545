import React from 'react';
import PropTypes from 'prop-types';
import _ from 'src/domain/libs/util';

import SubscribeConfirm from './Confirm/SubscribeConfirm';
import PurchaseConfirm from './Confirm/PurchaseConfirm';

const Confirm = props => {
  let content;

  switch (props.itemType) {
    case 'course':
      content = <SubscribeConfirm {...props} course={props.item} />;
      break;
    case 'product':
      content = <PurchaseConfirm {...props} product={props.item} />;
      break;
    default:
      throw new TypeError('Confirm.props.itemType must be "product" or "course".');
  }

  return content;
};

export default Confirm;
