import warning from 'tiny-warning';
import invariant from 'invariant';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import hoistStatics from 'hoist-non-react-statics';

export default function routing() {
  return function provideRoutingContext(WrappedComponent) {
    class Router extends Component {
      constructor(props, context) {
        super(props, context);
        this.state = {
          match: this.computeMatch(props.history.location.pathname),
        };

        const { children, history } = props;

        invariant(
          children == null || React.Children.count(children) === 1,
          'A <Router> may have only one child element',
        );

        // @ts-ignore TS2339
        this.unlisten = history.listen(() => {
          this.setState({
            match: this.computeMatch(history.location.pathname),
          });
        });
      }

      getChildContext() {
        return {
          // @ts-ignore TS2339
          routeHandler: this.props.routeHandler,
          // @ts-ignore TS2339
          history: this.props.history,
        };
      }

      computeMatch(pathname) {
        return {
          path: '/',
          url: '/',
          params: {},
          isExact: pathname === '/',
        };
      }

      componentWillReceiveProps(nextProps) {
        // @ts-ignore TS2339
        warning(this.props.history === nextProps.history, 'You cannot change <Router history>');
      }

      componentWillUnmount() {
        // @ts-ignore TS2339
        this.unlisten();
      }

      render() {
        return <WrappedComponent {...this.props} />;
      }
    }

    // @ts-ignore TS2339
    Router.displayName = 'Router';

    // @ts-ignore TS2339
    Router.childContextTypes = {
      routeHandler: PropTypes.object.isRequired,
      history: PropTypes.object.isRequired,
    };

    // @ts-ignore TS2339
    Router.propTypes = {
      routeHandler: PropTypes.object.isRequired,
      history: PropTypes.object.isRequired,
    };

    return hoistStatics(Router, WrappedComponent);
  };
}
