import React from 'react';
import PropTypes from 'prop-types';
import LoadingListBox from './LoadingListBox';

const LoadingListTitle = props => {
  let loadingBox = React.createElement(LoadingListBox, Object.assign(props));
  if (props.lockupClassName) {
    // @ts-ignore TS2322
    loadingBox = React.createElement('div', { className: props.lockupClassName }, loadingBox);
  }
  return <div className="card title-card loading-card loading-list-card">{loadingBox}</div>;
};

LoadingListTitle.propTypes = {
  lockupClassName: PropTypes.string,
  delay: PropTypes.number,
  displayWhenNotPulsing: PropTypes.bool,
  pulsate: PropTypes.bool,
};

LoadingListTitle.defaultProps = {
  className: '',
  delay: 0,
  displayWhenNotPulsing: true,
  pulsate: false,
};

export default LoadingListTitle;
