import _ from 'src/domain/libs/util';

const callbacks = {
  onDisplay: [],
  onChange: [],
  onDeleted: [],
  onRefresh: [],
};

class DeleteApp {
  constructor() {
    // @ts-ignore TS2551
    this._targets = [];
    // @ts-ignore TS2339
    this._mode = false;
  }

  register(item) {
    item._checked = flag => {
      item.checked = flag;
      if (typeof item.onChecked === 'function') item.onChecked(item);
    };
    // @ts-ignore TS2551
    if (_.isEmpty(this._targets)) {
      this._onDisplay(true);
    }
    // @ts-ignore TS2551
    this._targets.push(item);
    return () => {
      // @ts-ignore TS2551
      this._targets = _.reject(this._targets, target => target.id == item.id);
    };
  }

  flush() {
    // @ts-ignore TS2551
    this._targets = [];
    // @ts-ignore TS2339
    this._mode = false;
    this._onDisplay(false);
    this._onChange();
  }

  on(eventName, cb) {
    var cbs = callbacks[eventName];
    if (Array.isArray(cbs)) {
      cbs.push(cb);
    }
  }

  off(eventName, cb) {
    var cbs = callbacks[eventName];
    if (Array.isArray(cbs)) {
      let index = cbs.indexOf(cb);
      if (index !== -1) {
        cbs.splice(index, 1);
      }
    }
  }

  isDeleteMode() {
    // @ts-ignore TS2339
    return this._mode;
  }

  toggleMode() {
    // @ts-ignore TS2339
    this._mode = !this._mode;
    // @ts-ignore TS2551
    this._targets.forEach(item => {
      if (typeof item.onModeChange === 'function') {
        // @ts-ignore TS2339
        item.onModeChange(this._mode);
      }
    });
  }

  allChecked() {
    // @ts-ignore TS2551
    this._targets.forEach(item => {
      if (!item.checked) {
        item._checked(true);
      }
    });
    this._onChange();
  }

  resetAllChecked() {
    // @ts-ignore TS2551
    this._targets.forEach(item => {
      if (item.checked) {
        item._checked(false);
      }
    });
    this._onChange();
  }

  add(id) {
    // @ts-ignore TS2551
    const item = _.find(this._targets, item => item.id === id);
    if (item) {
      item._checked(true);
    }
    this._onChange();
  }

  remove(id) {
    // @ts-ignore TS2551
    const item = _.find(this._targets, item => item.id === id);
    if (item) {
      item._checked(false);
    }
    this._onChange();
  }

  targetIds() {
    // @ts-ignore TS2551
    return _.map(this._targets, item => item.id);
  }

  deleteIds() {
    return _.map(
      // @ts-ignore TS2551
      _.filter(this._targets, item => item.checked),
      item => item.id,
    );
  }

  // 加工前のデータを返却
  getCheckedItems() {
    // @ts-ignore TS2551
    return _.filter(this._targets, item => item.checked);
  }

  refresh() {
    if (this.isDeleteMode()) this.toggleMode();
    this._onRefresh();
  }

  send(params = {}, authContext) {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          // 401はログイン画面に遷移
          if (xhr.status === 401) {
            if (window) {
              window.location.href = `/login?return_to=${window.encodeURIComponent(window.location.href)}`;
              return;
            }
          }

          let response = xhr.response;
          if (typeof response === 'string') {
            response = JSON.parse(response);
          }

          if (this._checkResponse(response)) {
            this._onDeleted();
            return resolve(response);
          }

          reject(response);
        }
      };

      const query = Object.keys(params)
        .map(function(key) {
          return key + '=' + encodeURIComponent(params[key]);
        })
        .join('&');

      // @ts-ignore TS2339
      xhr.open('POST', this._url, true);
      xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
      if (authContext) {
        xhr.setRequestHeader('Authorization', `Bearer ${authContext.token}`);
        xhr.setRequestHeader('X-User-Id', authContext.id);
        xhr.setRequestHeader('X-Session-Token', authContext.sessionToken);
      }
      xhr.send(query);
    });
  }

  _onChange() {
    callbacks.onChange.forEach(cb => {
      if (typeof cb === 'function') {
        cb();
      }
    });
  }

  _onDeleted() {
    callbacks.onDeleted.forEach(cb => {
      if (typeof cb === 'function') {
        cb();
      }
    });
  }

  _onRefresh() {
    callbacks.onRefresh.forEach(cb => {
      if (typeof cb === 'function') {
        cb();
      }
    });
  }

  _onDisplay(mode) {
    callbacks.onDisplay.forEach(cb => {
      if (typeof cb === 'function') {
        cb(mode);
      }
    });
  }

  _checkResponse(response) {
    return response.result;
  }
}

export default DeleteApp;
