import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'src/domain/libs/util';
import classnames from 'classnames';

import WatchPartyApp from '../../../../../common/WatchPartyApp';

export default class TargetTitle extends Component {
  static get propTypes() {
    return {
      titleModel: PropTypes.object,
      seriesModel: PropTypes.object,
      linearModel: PropTypes.object,
      roomInfoView: PropTypes.bool,
    };
  }
  static get contextTypes() {
    return {
      watchPartyApp: PropTypes.object,
    };
  }
  static get defaultProps() {
    return {
      roomInfoView: true,
    };
  }

  constructor(props, context) {
    super(props, context);
  }

  render() {
    // @ts-ignore TS2339
    const isChannel = this.props.titleModel.type == 'linear_channel' && !!this.props.linearModel;
    // const isLiveEvent = this.props.titleModel.type == "linear_channel" && (this.props.titleModel.isMulti || this.props.titleModel.isLiveEvent);

    const _titleLink = () => {
      if (isChannel) {
        return (
          <div className="target-title">
            {/*
             // @ts-ignore TS2339 */}
            {this.props.linearModel && this.props.linearModel.name ? (
              // @ts-ignore TS2339
              <h4 className="episode-title">{this.props.linearModel.name}</h4>
            ) : (
              '休止中'
            )}
          </div>
        );
      } else {
        return (
          <div className="target-title">
            {/*
             // @ts-ignore TS2339 */}
            {!!this.props.seriesModel && this.props.seriesModel.name ? (
              // @ts-ignore TS2339
              <p className="season-title">{this.props.seriesModel.name}</p>
            ) : // @ts-ignore TS2339
            this.props.titleModel.title &&
              // @ts-ignore TS2339
              this.props.titleModel.playableTitle &&
              // @ts-ignore TS2339
              this.props.titleModel.title !== this.props.titleModel.playableTitle ? (
              // @ts-ignore TS2339
              <p className="season-title">{this.props.titleModel.title}</p>
            ) : null}
            {/*
             // @ts-ignore TS2339 */}
            {this.props.titleModel.playableTitle ? (
              // @ts-ignore TS2339
              <h4 className="episode-title">{this.props.titleModel.playableTitle}</h4>
            ) : null}
          </div>
        );
      }
    };

    return (
      <div className="target-wrapper">
        {/*
         // @ts-ignore TS2339 */}
        {!this.props.roomInfoView ? (
          _titleLink()
        ) : (
          <React.Fragment>
            {/*
             // @ts-ignore TS2339 */}
            {this.props.room ? (
              <div className="target-title">
                {/*
                 // @ts-ignore TS2339 */}
                <h4 className="episode-title">{this.props.room.name}</h4>
              </div>
            ) : null}
          </React.Fragment>
        )}
      </div>
    );
  }
}
