import React from 'react';
import SimpleNotification from './SimpleNotification';

const NetworkNotification = props => {
  return (
    <SimpleNotification
      title="WOWOWオンデマンドで映画やドラマをお楽しみいただくには、インターネット接続が必要です。インターネットに再接続してから続けてください。"
      type="warning"
      message="インターネットに接続されていません。"
      animated={true}
    />
  );
};

export default NetworkNotification;
