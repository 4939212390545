import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import FadingInput from './FadingInput';

class SearchInput extends React.Component {
  static get propTypes() {
    return {
      direction: PropTypes.string,
      term: PropTypes.string,
      onChange: PropTypes.func,
      onFocus: PropTypes.func,
      onBlur: PropTypes.func,
      onClickSearchButton: PropTypes.func,
    };
  }

  static get contextTypes() {
    return {
      getModelData: PropTypes.func.isRequired,
    };
  }

  static get defaultProps() {
    return {
      direction: 'ltr',
    };
  }

  constructor(props, context) {
    super(props);
    // @ts-ignore TS2339
    this._browserInfo = context.getModelData('browserInfo');
    // @ts-ignore TS2339
    this.searchInputRef = React.createRef();
    this.onNewSearchTerm = this.onNewSearchTerm.bind(this);
    this.onFocus = this.onFocus.bind(this);
    this.onBlur = this.onBlur.bind(this);
    this.onKeyDown = this.onKeyDown.bind(this);
    this.handleSearchButton = this.handleSearchButton.bind(this);
    this.handleClear = this.handleClear.bind(this);
    this.onMouseDown = this.onMouseDown.bind(this);
    this.state = {
      isFocused: false,
      term: props.term,
    };
  }

  componentWillReceiveProps(nextProps) {
    // @ts-ignore TS2339
    if (!this.onChangeTermTimeId) {
      this.setState({ term: nextProps.term });
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    // @ts-ignore TS2339
    return nextState.term !== this.state.term || nextState.isFocused !== this.state.isFocused;
  }

  onNewSearchTerm(e) {
    // iOS,Mac Safariの場合、文字確定のタイミングでvalueがブランクのイベントが発生する為
    // props.onChangeは少しずらして通知する
    // if (this._browserInfo.isIOS || (this._browserInfo.isMac && this._browserInfo.isSafari)) {
    // @ts-ignore TS2339
    this.searchTerm = e.target.value;
    // @ts-ignore TS2339
    this.setState({ term: this.searchTerm });
    // @ts-ignore TS2339
    if (this.onChangeTermTimeId) {
      // @ts-ignore TS2339
      clearTimeout(this.onChangeTermTimeId);
      // @ts-ignore TS2339
      delete this.onChangeTermTimeId;
    }
    // @ts-ignore TS2339
    this.onChangeTermTimeId = setTimeout(() => {
      // @ts-ignore TS2339
      if (this.props.onChange) {
        // @ts-ignore TS2339
        this.props.onChange(this.searchTerm);
      }
      // @ts-ignore TS2339
      clearTimeout(this.onChangeTermTimeId);
      // @ts-ignore TS2339
      delete this.onChangeTermTimeId;
    }, 100);
    // } else {
    //   this.setState({term: e.target.value});
    //   if (this.props.onChange) {
    //     this.props.onChange(e.target.value);
    //   }
    // }
  }

  handleSearchButton(e) {
    // @ts-ignore TS2339
    if (this.props.onClickSearchButton) {
      // @ts-ignore TS2339
      this.props.onClickSearchButton(e);
    }
  }

  handleClear(e) {
    this.setState({ term: '', isFocused: false });
    // @ts-ignore TS2339
    if (this.props.onChange) {
      // @ts-ignore TS2339
      this.props.onChange('');
    }
  }

  onMouseDown() {
    // @ts-ignore TS2554
    this.handleClear();
  }

  onKeyDown(e) {
    // @ts-ignore TS2339
    if (this.props.onKeyDown) {
      // @ts-ignore TS2339
      this.props.onKeyDown(e);
    }
  }

  onFocus(e) {
    this.setState({ isFocused: true });
    // @ts-ignore TS2339
    if (this.props.onFocus) {
      // @ts-ignore TS2339
      this.props.onFocus(e);
    }
  }

  onBlur(e) {
    this.setState({ isFocused: false });
    // @ts-ignore TS2339
    if (this.props.onBlur) {
      // @ts-ignore TS2339
      this.props.onBlur(e);
    }
  }

  close(cb) {
    if (cb) {
      setTimeout(cb, 0);
    }
  }

  render() {
    return (
      <div className="search-input">
        <div className="search-input-container">
          <div className="btn-search fa fa-search" onClick={this.handleSearchButton}></div>
          <input
            // @ts-ignore TS2339
            ref={this.searchInputRef}
            type="text"
            onChange={this.onNewSearchTerm}
            onKeyDown={this.onKeyDown}
            onFocus={this.onFocus}
            onBlur={this.onBlur}
            // @ts-ignore TS2339
            placeholder={this.state.term.length === 0 ? 'タイトル、出演者で検索' : ''}
            // @ts-ignore TS2339
            value={this.state.term}
          />
          <span
            // @ts-ignore TS2339
            className={classnames('icon-close', { empty: this.state.term.length === 0 })}
            onClick={this.handleClear}
            onMouseDown={this.onMouseDown}
          />
        </div>
      </div>
    );
  }
}

export default SearchInput;
