import { connect, createProvider } from 'react-redux';

const STORE_KEY = 'discoveryStore';
export const Provider = createProvider(STORE_KEY);

const connectToApps = function(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
  options = {}
) {
  // @ts-ignore TS2339
  options.storeKey = STORE_KEY;
  // @ts-ignore TS2339
  options.getDisplayName = (name) => `ConnectToApps(${name})`
  // @ts-ignore TS2339
  options.renderCountProp = 'forceUpdateCount';
  // @ts-ignore TS2339
  options.shouldHandleStateChanges = true;
  return connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps,
    options
  );
}

export default connectToApps;
