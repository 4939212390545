import qs from 'query-string';

export default function parseUrl(href) {
  const indexOfQuery = href.indexOf('?');
  const indexOfHash = href.indexOf('#');
  const containsQuery = indexOfQuery !== -1;
  const containsHash = indexOfHash !== -1;

  let path;
  let hash;
  let url;
  let query;

  if (containsHash && containsQuery) {
    const endOfPath = indexOfQuery < indexOfHash ? indexOfQuery : indexOfHash;
    path = href.substr(0, endOfPath);
    if (endOfPath !== indexOfHash) {
      query = href.substr(indexOfQuery + 1, indexOfHash - indexOfQuery - 1);
    }
    (url = href.substr(0, indexOfHash)), (hash = href.substr(indexOfHash + 1));
  } else if (containsHash) {
    path = href.substr(0, indexOfHash);
    url = path;
    hash = href.substr(indexOfHash + 1);
  } else if (containsQuery) {
    path = href.substr(0, indexOfQuery);
    query = href.substr(indexOfQuery + 1);
    url = href;
  } else {
    path = href;
    url = href;
  }

  return {
    url,
    path,
    query: qs.parse(query || ''),
    hash: hash || '',
  };
}
