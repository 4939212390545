import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import _ from 'src/domain/libs/util';
import classnames from 'classnames';

import routes from '../../../common/routes';

import EpisodesRow from './titlemeta/EpisodesRow';
import EpisodeCard from './EpisodeCard';
import GalleryContent from './GalleryContent';
import GalleryTab from './GalleryTab';
import SwitchGallery from './SwitchGallery';
import DropDown from '../../../common/components/DropDown';

import { NotFoundError } from '../../../common/components/ErrorBoundary';
import HtmlContext from '../../../common/context/HtmlContext';
import { CardContextProvider } from '../../../common/context/CardContext';
import JsonLd from '../../../common/components/JsonLd';

class TitleAssets extends Component {
  static pathKeys = [
    'id',
    'refId',
    'name',
    'shortName',
    'schemaId',
    'schemaKey',
    'slug',
    'type',
    'seriesId',
    'hierarchyTypes',
    'seasons',
    'languageSupportTypes',
    'ogImage',
    'defaultSort',
    'cardInfo',
    'genres',
    'seriesMeta',
  ];

  static getPaths = function(models, options, props) {
    let paths = [];
    if (props.id) {
      paths = paths.concat(EpisodesRow.getPaths(models, options, { id: props.id }));
      paths = paths.concat([['meta', props.id, this.pathKeys]]);
    }
    if (props.titleMetaId) {
      paths = paths.concat(this.getTitleMetaPaths(models, options, props));
    }
    return paths;
  };

  static getRootPath = function(models, options, props) {
    if (props.id) {
      return ['meta', props.id];
    }
    return [];
  };

  static getTitleMetaRootPath = function(models, options, props) {
    if (props.titleMetaId) {
      return ['meta', props.titleMetaId];
    }
    return [];
  };

  static getTitleMetaPaths = function(models, options, props) {
    let paths = [];
    const rootPath = this.getTitleMetaRootPath(models, options, props);
    return paths.concat([rootPath.concat([['id', 'refId', 'name', 'shortName']])]);
  };

  static getPrefetchPaths = function(models, options, props) {
    return this.getPaths(models, options, props);
  };

  static getPrefetchedPaths = function(models, options, props) {
    return data => {
      const item = _.omit(_.get(data.json, this.getRootPath(models, options, props)), ['$__path']);
      props = Object.assign({}, props, {
        id: item.id,
        titleMetaId: _.get(item, 'seriesMeta.metaId'),
      });
      const galleryPathProps = {
        keyPrefix: 'meta',
        modelRoot: 'episodes',
        entitled: _.get(props, 'routeHandler.query.entitled') == 1 ? 't' : 'f',
        cardComponent: EpisodeCard,
        sortOrder: _.get(props, 'routeHandler.query.so') || item.defaultSort || 'ena',
        hierarchyTypeKey: _.get(props, 'routeHandler.query.ht'),
      };
      if (!galleryPathProps.hierarchyTypeKey) {
        let hierarchyTypes = [];
        // @ts-ignore TS2448
        if (!seasonId) {
          if (item.hierarchyTypes) hierarchyTypes = item.hierarchyTypes;
        } else {
          hierarchyTypes = _.filter(item.hierarchyTypes, hierarchyType => {
            return _.includes(hierarchyType.season_ids, seasonId);
          });
          if (_.isEmpty(hierarchyTypes)) {
            hierarchyTypes = _.filter(item.hierarchyTypes, hierarchyType => {
              return hierarchyType.season_ids === null;
            });
          }
        }
        if (_.isEmpty(hierarchyTypes)) {
          hierarchyTypes.push({ key: 'df', name: 'エピソード' });
        }
        galleryPathProps.hierarchyTypeKey = _.get(_.first(hierarchyTypes), 'key');
      }
      const seasonId = _.get(props, 'routeHandler.query.season_id');
      if (!seasonId || seasonId == item.id) galleryPathProps.modelRoot = 'seriesEpisodes';
      // シーズンなら固定
      if (item.schemaId == 2) galleryPathProps.modelRoot = 'episodes';

      const galleryPaths = GalleryContent.getPaths(
        models,
        options,
        Object.assign({}, GalleryContent.defaultProps, props, galleryPathProps),
      );
      return this.getPaths(models, options, props).concat(galleryPaths);
    };
  };

  static get propTypes() {
    return {
      history: PropTypes.object,
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      isKidsPage: PropTypes.bool,
      isPrefetchingPaths: PropTypes.bool,
    };
  }

  static get contextTypes() {
    return {
      models: PropTypes.object,
      history: PropTypes.object,
      routeHandler: PropTypes.object,
      getModelData: PropTypes.func,
      cookies: PropTypes.object,
      isKidsPage: PropTypes.bool,
      // service: PropTypes.string,
      // i18n: PropTypes.object.isRequired,
      // i18nContext: PropTypes.string.isRequired,
      spMode: PropTypes.bool,
      gtmApp: PropTypes.object,
      isStore: PropTypes.bool,
      columnsInRow: PropTypes.number,
    };
  }

  static OTHER = -1;

  static get childContextTypes() {
    return {
      showSeasonTitle: PropTypes.bool,
      allSeasonCount: PropTypes.number,
      showViewLimit: PropTypes.bool,
      columnsInRow: PropTypes.number,
    };
  }

  constructor(props, context) {
    super(props, context);
    // this.model = props.model ? props.model : (props.pathEvaluator || props.model.pathEvaluator).batch(100);
    // @ts-ignore TS2339
    this.model = (props.pathEvaluator || props.model.pathEvaluator).batch(100);

    // @ts-ignore TS2339
    const rootPath = this.constructor.getRootPath(context.models, null, props);
    // @ts-ignore TS2339
    this.item = this.model.getSync(rootPath) || {};
    // @ts-ignore TS2339
    if (_.get(this.item, 'seriesMeta.metaId')) {
      // @ts-ignore TS2339
      const titleMetaRootPath = this.constructor.getTitleMetaRootPath(context.models, null, {
        // @ts-ignore TS2339
        titleMetaId: this.item.seriesMeta.metaId,
      });
      // @ts-ignore TS2339
      this.titleMeta = this.model.getSync(titleMetaRootPath) || {};
    }

    // @ts-ignore TS2339
    if (context.history && (this.item.type === 'media_meta' || this.item.type === 'linear_channel_meta')) {
      let to = routes.watchNow;
      let params = { id: props.id };
      // @ts-ignore TS2339
      if (this.item.refId) {
        to = routes.content;
        // @ts-ignore TS2339
        params.id = this.item.refId;
        // @ts-ignore TS2339
        if (this.item.type === 'linear_channel_meta') {
          // @ts-ignore TS2339
          const simulcast = _.find(this.context.getModelData('simulcast'), item => item.refId == this.item.refId);
          to = routes.simulcast;
          // @ts-ignore TS2322
          params = { channelName: simulcast.name };
        }
      }
      // @ts-ignore TS2554
      context.history.replace(to.makePath(params));
    }

    // @ts-ignore TS2339
    this.othersSeasonHierarchyTypeList = [];
    this.onSeasonChange = this.onSeasonChange.bind(this);
    this.onHierarchyTypeChange = this.onHierarchyTypeChange.bind(this);
    this.handleSortOrderChange = this.handleSortOrderChange.bind(this);

    this.state = {
      hierarchyType: _.get(context, 'routeHandler.query.ht'),
      episodeType: 'seriesEpisodes',
      dispose: null,
      // @ts-ignore TS2339
      generation: this.model.getVersion(rootPath),
      fetchDataError: null,
    };
    // @ts-ignore TS2339
    this.state.sortOrder = _.get(context, 'routeHandler.query.so') || this.item.defaultSort || 'ena';
    // @ts-ignore TS2339
    this.state.supportSorts = [
      { so: 'ena', name: '新しい順' },
      { so: 'end', name: '古い順' },
    ];
    const seasonId = _.get(context, 'routeHandler.query.season_id');
    if (seasonId) {
      // @ts-ignore TS2339
      this.state.seasonId = parseInt(seasonId, 10);
      // @ts-ignore TS2339
      this.state.episodeType = 'episodes';
    }
  }

  getChildContext() {
    const columnsInRow = 1;

    return {
      // @ts-ignore TS2339
      showSeasonTitle: this.state.episodeType === 'seriesEpisodes',
      // @ts-ignore TS2339
      allSeasonCount: _.get(this.item, 'seasons', []).length,
      showViewLimit: true,
      columnsInRow,
    };
  }

  componentDidMount() {
    // @ts-ignore TS2339
    if (!this.item || Object.keys(this.item).length === 0) {
      // componentWillMountでスローしたいが、SSRのタイミングでも呼ばれるので不可
      // https://reactjs.org/docs/error-boundaries.html
      // @ts-ignore TS2554
      throw new NotFoundError();
    }

    // const userInfo = _.get(this.context.getModelData('memberContext'), 'userInfo');
    // // キッズプロフィールでキッズ以外のメタにアクセスした場合
    // if (userInfo && userInfo.isKids && !this.item.isKids) {
    //   window.location.href = '/profiles/select';
    //   return;
    // }

    // @ts-ignore TS2339
    this._isMounted = true;
    // let params = {}
    // if (this.context.models && this.context.models.memberContext && this.context.models.memberContext.data.profileData.active) {
    //   params.profileID = this.context.models.memberContext.data.profileData.active.uuidInSchema;
    // }
    // if(_.get(this.context, 'gtmApp')) this.context.gtmApp.pushLayer(params);
    this.fetchData(this.props);
  }

  componentWillReceiveProps(nextProps) {
    // 対象のメタがMediaMetaの場合はリダイレクトする
    // SSRの場合のリダイレクトはconstructor側でやる?
    // componentWillReceiveProps

    const sortOrder = _.get(nextProps, 'routeHandler.query.so') || 'ena';
    // @ts-ignore TS2339
    if (sortOrder != this.state.sortOrder) {
      this.setState({ sortOrder });
    }
  }

  componentWillUpdate(nextProps, nextState, nextContext) {
    // @ts-ignore TS2339
    if (this.item.type === 'media_meta' || this.item.type === 'linear_channel_meta') {
      let to = routes.watchNow;
      // @ts-ignore TS2339
      let params = { id: this.props.id };
      // @ts-ignore TS2339
      if (this.item.refId) {
        to = routes.content;
        // @ts-ignore TS2339
        params.id = this.item.refId;
        // @ts-ignore TS2339
        if (this.item.type === 'linear_channel_meta') {
          // @ts-ignore TS2339
          const simulcast = _.find(this.context.getModelData('simulcast'), item => item.refId == this.item.refId);
          // @ts-ignore TS2339
          to = this.simulcast;
          // @ts-ignore TS2322
          params = { channelName: simulcast.name };
        }
      }
      // @ts-ignore TS2554
      nextContext.history.replace(to.makePath(params));
    }
  }

  componentWillUnmount() {
    // @ts-ignore TS2339
    this._isMounted = false;
    // @ts-ignore TS2339
    if (this.state.dispose) this.state.dispose();
    // 常に最新の状態を取得する為
    // @ts-ignore TS2339
    if (this.deletePath && _.has(this.model._root.cache, this.deletePath)) {
      // @ts-ignore TS2339
      this.model.invalidate(this.deletePath.split('.'));
    }
  }

  fetchData(props) {
    const pathProps = _.clone(props);
    //if (this.item && this.item.schemaId == 3) {
    //  // シリーズにslugでアクセスされた場合はprops.idにslugが入るので、取得したIDで上書き
    //  pathProps = Object.assign({}, props, {id: this.item.id});
    //}
    // @ts-ignore TS2339
    if (_.get(this.item, 'seriesMeta.metaId')) {
      // @ts-ignore TS2339
      pathProps.titleMetaId = this.item.seriesMeta.metaId;
    }
    // @ts-ignore TS2339
    const paths = this.constructor.getPaths(this.context.models, {}, pathProps);

    // すでに通信している場合は実行しない
    if (this.state[JSON.stringify(paths)]) return;

    // @ts-ignore TS2339
    if (this.state.dispose) {
      // 過去のObservableを削除する、これによって通信が止まるわけではなく
      // Observableがなくなるのでイベントが発火されなくなる、というだけなので注意
      // @ts-ignore TS2339
      this.state.dispose();
    }

    this.state[JSON.stringify(paths)] = paths;

    // @ts-ignore TS2339
    const evaluator = this.model.fetch(paths);
    const dispose = evaluator.dispose;
    // @ts-ignore TS2339
    if (this._isUpdated === false || !this._isMounted) {
      Object.assign(this.state, { dispose });
    } else {
      this.setState({ dispose });
    }
    // @ts-ignore TS2339
    const rootPath = this.constructor.getRootPath(this.context.models, {}, pathProps);
    // @ts-ignore TS2339
    const titleMetaRootPath = this.constructor.getTitleMetaPaths(this.context.models, null, {
      titleMetaId: pathProps.titleMetaId,
    });
    evaluator
      .then(res => {
        // @ts-ignore TS2339
        this.item = _.get(res, ['json'].concat(rootPath), {});
        if (pathProps.titleMetaId) {
          // @ts-ignore TS2339
          this.titleMeta = _.get(res, ['json'].concat(titleMetaRootPath), {});
        }

        const othersSeasonName = 'その他';

        // @ts-ignore TS2339
        this.othersSeasonHierarchyTypeList = _.filter(
          // @ts-ignore TS2339
          this.item.hierarchyTypes,
          hierarchyType => hierarchyType.season_ids === null,
        );
        // @ts-ignore TS2339
        this.hasOtherSeasons = this.othersSeasonHierarchyTypeList.length > 0 && this.item.seasons.length > 0;
        // @ts-ignore TS2339
        this.seasonsWithOther = _.clone(this.item.seasons);
        // @ts-ignore TS2339
        if (this.hasOtherSeasons) {
          // @ts-ignore TS2339
          this.seasonsWithOther.push({ id: this.item.id, name: othersSeasonName });
        }
        // @ts-ignore TS2339
        if (!_.isEmpty(this.seasonsWithOther)) {
          // @ts-ignore TS2339
          this.seasonsWithOther.unshift({ id: 'all', name: 'すべてのシーズン' });
        }

        delete this.state[JSON.stringify(paths)];

        const newState = {
          fetchDataError: null,
          dispose: null,
          // @ts-ignore TS2339
          generation: this.model.getVersion(rootPath),
        };

        // @ts-ignore TS2339
        if (this._isMounted) this.setState(newState);
        else Object.assign(this.state, newState);
      })
      .catch(e => {
        const newState = {
          fetchDataError: e,
          fetchingMoreRows: undefined,
          dispose: null,
        };
        delete this.state[JSON.stringify(paths)];
        // @ts-ignore TS2339
        if (this._isMounted) this.setState(newState);
        else Object.assign(this.state, newState);
      });
  }

  onSeasonChange(seasonId) {
    if (seasonId === 'all') seasonId = undefined;
    // @ts-ignore TS2339
    if (this.seasonsWithOther) {
      // @ts-ignore TS2339
      if (this.state.seasonId != seasonId) {
        const { routeHandler, history } = this.context;
        const query = Object.assign({}, routeHandler.query, { season_id: seasonId, ht: null });
        // @ts-ignore TS2554
        history.replace(routes.titleEpisodes.makePath({ id: this.item.id }, query), { norender: true });
        this.setState({ seasonId, hierarchyType: null, episodeType: seasonId ? 'episodes' : 'seriesEpisodes' });
      }
    }
  }

  onHierarchyTypeChange(hierarchyType) {
    const { routeHandler, history } = this.context;
    // @ts-ignore TS2339
    const query = Object.assign({}, routeHandler.query, { season_id: this.state.seasonId, ht: hierarchyType });
    // @ts-ignore TS2554
    history.replace(routes.titleEpisodes.makePath({ id: this.item.id }, query), { norender: true });
    this.setState({ hierarchyType });
  }

  handleSortOrderChange(so) {
    if (so.so) so = so.so;
    // @ts-ignore TS2339
    if (so !== this.state.sortOrder) {
      // @ts-ignore TS2339
      this.isFirstSetOfResults = true;
      // @ts-ignore TS2339
      this.episodes = [];
      // @ts-ignore TS2339
      if (this.state.dispose) this.state.dispose();
      this.setState({ sortOrder: so, dispose: null, generation: -1, fetchDataError: null, fromNum: 0 }, () => {
        this.fetchData(this.props);
      });
    }
  }

  render() {
    // @ts-ignore TS2339
    if (!this.item) return null;
    // @ts-ignore TS2339
    if (!this.item || Object.keys(this.item).length === 0) return null;

    // @ts-ignore TS2339
    const seasons = this.seasonsWithOther;
    const hasSeason = seasons && seasons.length > 0;

    // @ts-ignore TS2339
    let seasonId = this.state.seasonId;
    let hierarchyTypes = [];
    if (!seasonId) {
      // @ts-ignore TS2339
      if (this.item.hierarchyTypes) hierarchyTypes = this.item.hierarchyTypes;
    } else {
      // @ts-ignore TS2339
      hierarchyTypes = _.filter(this.item.hierarchyTypes, hierarchyType => {
        return _.includes(hierarchyType.season_ids, seasonId);
      });
      if (_.isEmpty(hierarchyTypes)) {
        // @ts-ignore TS2339
        hierarchyTypes = _.filter(this.item.hierarchyTypes, hierarchyType => {
          return hierarchyType.season_ids === null;
        });
      }
    }

    if (_.isEmpty(hierarchyTypes)) {
      hierarchyTypes.push({ key: 'df', name: 'エピソード' });
    }

    // @ts-ignore TS2339
    let hierarchyTypeKey = this.state.hierarchyType;
    if (!hierarchyTypeKey) {
      hierarchyTypeKey = _.get(_.first(hierarchyTypes), 'key');
    }

    const userInfo = this.context.getModelData('userInfo');
    const sortGalleryProps = {
      // @ts-ignore TS2339
      model: this.model,
      // showSort: !_.isEmpty(this.item.supportSorts),
      // @ts-ignore TS2339
      sortOrder: this.state.sortOrder,
      onChangeQuery: this.handleSortOrderChange,
      // tvodAssets: userInfo.status !== 'NON_REGISTERED_MEMBER' && this.context.isStore ? true : false,
    };

    // @ts-ignore TS2339
    let title = this.item.name;

    let tab;
    let activeTabIndex = 0;
    if (hierarchyTypes) {
      tab = hierarchyTypes.map((hierarchyType, i) => {
        if (hierarchyTypeKey == hierarchyType.key) activeTabIndex = i;
        return (
          <span
            key={`season_id_list_${i}`}
            tabIndex={0}
            onClick={e => this.onHierarchyTypeChange(hierarchyType.key)}
            className="active"
          >
            {hierarchyType.name}
          </span>
        );
      });
    }

    let seasonSelector;
    if (hasSeason) {
      let label = {};
      const options = seasons.map((season, i) => {
        if (!seasonId && i === 0) {
          label = season;
        } else if (season.id == seasonId) {
          label = season;
        }
        return (
          <span
            className={classnames({ selected: season.id == seasonId || (!seasonId && i === 0) })}
            key={`season_id_list_${season.id}`}
            onClick={e => this.onSeasonChange(season.id)}
          >
            {season.name}
          </span>
        );
      });
      const dropDownProps = {
        cols: 1,
        // @ts-ignore TS2339
        defaultValue: label.name,
        options: options,
        widthRestricted: false,
      };
      seasonSelector = <DropDown {...dropDownProps} />;
    }

    const header = (
      <React.Fragment>
        <div className="gallery-header tab-header episode-header">
          <div className="title">
            <span className="gallery-title">{title || '読み込み中...'}</span>
            <div className="dropdown-wrapper">
              {seasonSelector}
              <SwitchGallery {...sortGalleryProps} />
            </div>
          </div>
        </div>
      </React.Fragment>
    );

    // @ts-ignore TS2339
    const id = seasonId || this.item.id;
    let modelRoot = 'episodes';
    // @ts-ignore TS2339
    if (!seasonId || seasonId == this.item.id) {
      modelRoot = 'seriesEpisodes';
    }
    // シーズンなら固定
    // @ts-ignore TS2339
    if (this.item.schemaId == 2) {
      modelRoot = 'episodes';
    }
    // falcor
    // @ts-ignore TS2339
    this.deletePath = `meta.${id}.${modelRoot}`;

    // 構造化タグ
    const jsonLdProps = {};
    const host = this.context.getModelData('hosts', 'host');
    const itemListElement = [];

    // ジャンル
    // @ts-ignore TS2339
    const genre = _.first(this.item.genres);
    // @ts-ignore TS2339
    if (genre && genre.id && genre.name) {
      // @ts-ignore TS2554
      let url = routes.genre.makePath({ id: genre.id }, { type: 'od' });
      // @ts-ignore TS2339
      if (genre.refId) url = routes.genreRef.makePath({ id: genre.refId }, { type: 'od' });
      // @ts-ignore TS2339
      itemListElement.push({ name: genre.name, item: host + url });
    }

    // 親
    // @ts-ignore TS2339
    if (this.titleMeta && this.titleMeta.id && this.item.id !== this.titleMeta.id && this.titleMeta.name) {
      // @ts-ignore TS2554
      let url = routes.title.makePath({ id: this.titleMeta.id });
      // @ts-ignore TS2339
      if (this.titleMeta.refId) url = routes.program.makePath({ id: this.titleMeta.refId });
      // @ts-ignore TS2339
      const name = _.get(this.titleMeta, 'shortName', _.get(this.titleMeta, 'name'));
      itemListElement.push({ name: name, item: host + url });
    }

    // 自分
    // @ts-ignore TS2339
    if (this.item.id && this.item.name) {
      // @ts-ignore TS2554
      let url = routes.title.makePath({ id: this.item.id });
      // @ts-ignore TS2339
      if (this.item.refId) url = routes.program.makePath({ id: this.item.refId });
      // @ts-ignore TS2339
      const name = _.get(this.item, 'shortName', _.get(this.item, 'name'));
      itemListElement.push({ name: name, item: host + url });

      // @ts-ignore TS2554
      const episodeListUrl = routes.titleEpisodes.makePath({ id: this.item.id });
      // @ts-ignore TS2339
      const episodeListName = _.get(this.item, 'shortName', _.get(this.item, 'name')) + 'のエピソード';
      itemListElement.push({ name: episodeListName, item: host + episodeListUrl });
    }

    if (!_.isEmpty(itemListElement)) {
      // @ts-ignore TS2339
      jsonLdProps.breadcrumbList = { itemListElement };
    }

    return (
      <React.Fragment>
        <HtmlContext.Consumer>
          {({ title }) => {
            const metas = [];
            // @ts-ignore TS2339
            const episodeTitle = _.get(this.item, 'shortName', _.get(this.item, 'name'));
            if (episodeTitle) {
              metas.push({ property: 'og:title', content: title(`${episodeTitle} エピソード`) });
              metas.push({
                name: 'description',
                // @ts-ignore TS2339
                content: (_.get(this.item, 'cardInfo.description') || '').replace(/\r?\n/g, ''),
              });
              metas.push({
                property: 'og:description',
                // @ts-ignore TS2339
                content: (_.get(this.item, 'cardInfo.description') || '').replace(/\r?\n/g, ''),
              });
            }
            // @ts-ignore TS2339
            if (this.item && this.item.ogImage) {
              // @ts-ignore TS2339
              metas.push({ name: 'thumbnail', content: this.item.ogImage });
              // @ts-ignore TS2339
              metas.push({ property: 'og:image', content: this.item.ogImage });
            }
            return <Helmet title={`${episodeTitle} エピソード`} meta={metas} />;
          }}
        </HtmlContext.Consumer>
        <div className="title-assets-wrapper">
          <CardContextProvider value={this.getChildContext()}>
            <GalleryContent
              id={id}
              header={header}
              // @ts-ignore TS2322
              tabHeader={<GalleryTab links={tab} activeTabIndex={activeTabIndex} />}
              keyPrefix={'meta'}
              modelRoot={modelRoot}
              entitled={_.get(this.context, 'routeHandler.query.entitled') == 1 ? 't' : 'f'}
              cardComponent={EpisodeCard}
              // @ts-ignore TS2339
              sortOrder={this.state.sortOrder}
              // @ts-ignore TS2339
              model={this.model}
              listType={true}
              hierarchyTypeKey={hierarchyTypeKey}
            />
          </CardContextProvider>
          <JsonLd {...jsonLdProps} />
        </div>
      </React.Fragment>
    );
  }
}

export default TitleAssets;
