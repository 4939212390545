import React, { useMemo } from 'react';
import classnames from 'classnames';

// type TabKey = 'plan' | 'info'
// type TabProps = {
//   tabKey: TabKey;
//   onChange: () => void;
// }
const TAB_DATA = [
  { label: '視聴プラン', key: 'plan' },
  { label: '番組詳細', key: 'info' },
];

const PlanInfoTab = props => {
  // 関数の配列
  const onClicks = useMemo(() => {
    return TAB_DATA.map(tab => {
      return () => {
        props.onChange(tab.key);
      };
    });
  }, [props]);
  return (
    <div className="below-tab">
      {TAB_DATA.map((tab, idx) => {
        return (
          <div
            key={`tab_data_${tab.key}`}
            className={classnames('below-tab__item', { active: tab.key === props.tabKey })}
            onClick={onClicks[idx]}
          >
            {tab.label}
          </div>
        );
      })}
    </div>
  );
};

export default PlanInfoTab;
