// このファイル以外での直接import禁止
/* eslint-disable no-restricted-imports */
import get from 'lodash/get';
import set from 'lodash/set';
import map from 'lodash/map';
import findIndex from 'lodash/findIndex';
import find from 'lodash/find';
import filter from 'lodash/filter';
import some from 'lodash/some';
import startsWith from 'lodash/startsWith';
import endsWith from 'lodash/endsWith';
import omit from 'lodash/omit';
import omitBy from 'lodash/omitBy';
import trim from 'lodash/trim';
import merge from 'lodash/merge';
import has from 'lodash/has';
import isEmpty from 'lodash/isEmpty';
import difference from 'lodash/difference';
import throttle from 'lodash/throttle';
import remove from 'lodash/remove';
import compact from 'lodash/compact';
import flatten from 'lodash/flatten';
import each from 'lodash/each';
import forEach from 'lodash/forEach';
import reduce from 'lodash/reduce';
import pick from 'lodash/pick';
import isNil from 'lodash/isNil';
import isUndefined from 'lodash/isUndefined';
import assign from 'lodash/assign';
import includes from 'lodash/includes';
import concat from 'lodash/concat';
import defaultTo from 'lodash/defaultTo';
import castArray from 'lodash/castArray';
import indexOf from 'lodash/indexOf';
import chunk from 'lodash/chunk';
import camelCase from 'lodash/camelCase';
import cloneDeep from 'lodash/cloneDeep';
import replace from 'lodash/replace';
import clone from 'lodash/clone';
import mapKeys from 'lodash/mapKeys';
import transform from 'lodash/transform';
import first from 'lodash/first';
import last from 'lodash/last';
import uniq from 'lodash/uniq';
import keyBy from 'lodash/keyBy';
import values from 'lodash/values';
import max from 'lodash/max';
import min from 'lodash/min';
import slice from 'lodash/slice';
import head from 'lodash/head';
import lowerCase from 'lodash/lowerCase';
import isArray from 'lodash/isArray';
import isNaN from 'lodash/isNaN';
import join from 'lodash/join';
import result from 'lodash/result';
import random from 'lodash/random';
import split from 'lodash/split';
import extend from 'lodash/extend';
import size from 'lodash/size';
import assignIn from 'lodash/assignIn';
import isNumber from 'lodash/isNumber';
import sortBy from 'lodash/sortBy';
import isNull from 'lodash/isNull';
import toArray from 'lodash/toArray';
import toLower from 'lodash/toLower';
import dropRightWhile from 'lodash/dropRightWhile';
import reject from 'lodash/reject';
import take from 'lodash/take';
import every from 'lodash/every';
import maxBy from 'lodash/maxBy';
import uniqBy from 'lodash/uniqBy';
import isInteger from 'lodash/isInteger';
import padStart from 'lodash/padStart';
import reverse from 'lodash/reverse';
import keys from 'lodash/keys';
import isEqual from 'lodash/isEqual';
import times from 'lodash/times';
import pull from 'lodash/pull';
import hasIn from 'lodash/hasIn';
import isObject from 'lodash/isObject';
import unset from 'lodash/unset';
import isString from 'lodash/isString';
import sample from 'lodash/sample';
import takeRight from 'lodash/takeRight';
import orderBy from 'lodash/orderBy';
import fill from 'lodash/fill';
import flatMap from 'lodash/flatMap';

const util = {
  get: get,
  set: set,
  isEmpty: isEmpty,
  replace: replace,
  map: map,
  find: find,
  filter: filter,
  some: some,
  every: every,
  includes: includes,
  each: each,
  forEach: forEach,
  reduce: reduce,
  pick: pick,
  omit: omit,
  omitBy: omitBy,
  assign: assign,
  concat: concat,
  findIndex: findIndex,
  indexOf: indexOf,
  startsWith: startsWith,
  endsWith: endsWith,
  trim: trim,
  merge: merge,
  has: has,
  difference: difference,
  throttle: throttle,
  chunk: chunk,
  split: split,
  compact: compact,
  uniq: uniq,
  values: values,
  flatten: flatten,
  remove: remove,
  max: max,
  min: min,
  size: size,
  slice: slice,
  join: join,
  cloneDeep: cloneDeep,
  clone: clone,
  camelCase: camelCase,
  lowerCase: lowerCase,
  transform: transform,
  first: first,
  last: last,
  isNumber: isNumber,
  isNaN: isNaN,
  isNil: isNil,
  isNull: isNull,
  isUndefined: isUndefined,
  isArray: isArray,
  toArray: toArray,
  toLower: toLower,
  mapKeys: mapKeys,
  keyBy: keyBy,
  sortBy: sortBy,
  reject: reject,
  result: result,
  random: random,
  defaultTo: defaultTo,
  castArray: castArray,
  extend: extend,
  dropRightWhile: dropRightWhile,
  assignIn: assignIn,
  take: take,
  head: head,
  maxBy: maxBy,
  uniqBy: uniqBy,
  isInteger: isInteger,
  padStart: padStart,
  reverse: reverse,
  keys: keys,
  isEqual: isEqual,
  times: times,
  pull: pull,
  hasIn: hasIn,
  isObject: isObject,
  unset: unset,
  isString: isString,
  sample: sample,
  takeRight: takeRight,
  orderBy: orderBy,
  fill: fill,
  flatMap: flatMap,
};

export default util;
