import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

class Spinner extends React.PureComponent {
  static get propTypes() {
    return {
      isBuffering: PropTypes.bool,
      isNetworkCongested: PropTypes.bool,
      hasTransparentBackground: PropTypes.bool,
    };
  }

  constructor(props, context) {
    super(props, context);
    this.state = {
      buffering: props.isBuffering,
      opaque: false,
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ buffering: nextProps.isBuffering });
  }

  render() {
    // @ts-ignore TS2339
    if (!this.state.buffering) return null;
    return (
      <div className="player-spinner-container w-100 h-100 pos-abs d-flex top-0 bottom-0 left-0 right-0 jc-center ac-center ai-center flx-dir-col opacity-0 opacity-1">
        <div className="pos-rel w-100 overflow-hidden h-12-em d-flex jc-center ai-center min-h-5-rem">
          <div className="loading-spinner"></div>
          {/*
           // @ts-ignore TS2339 */}
          {this.props.isNetworkCongested ? (
            <h3 className="player-congnested-network-title">
              <span>現在、アクセスが大変混み合っております。</span>
            </h3>
          ) : null}
          {/*
           // @ts-ignore TS2339 */}
          {this.props.isNetworkCongested ? (
            <h4 className="player-congnested-network-message">
              <span>支障なくご視聴いただくために、一時的に接続速度を制限いたしますのでご了承ください。</span>
            </h4>
          ) : null}
        </div>
      </div>
    );
  }
}

export default Spinner;
