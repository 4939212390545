import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import _ from 'src/domain/libs/util';

import HeroImages from './HeroImages';
import Duration from './Duration';
import activeTags from '../../utils/activeTags';
import canAccessBySlug from '../../utils/slug';
import EventInfo from './Epg/EventInfo';
import EpgEvent from './Epg/EpgEvent';

import MainViewLink from '../../../common/components/MainViewLink';
import routes from '../../../common/routes';

import activeProfile from '../../../../utils/activeProfile';
import OAPPlayer from '../../../common/components/player/OAPPlayer';
import { CLICK_AREA, CONTENT_EVENTS } from '../../../../common/GtmApp';
import CardBadge from './CardBadge';
import SetDate from './SetDate';
import { getIsDelivery } from '../../../common/utils/metaUtil';
import { getIsLiveOnAir } from '../../../common/utils/liveUtil';
import getRatingText from 'src/utils/getRatingText';

const popTitleArtStyle = function(zoom) {
  const opacity = zoom ? 0 : 1;
  return {
    opacity: `${opacity}`,
    transitionDelay: '0ms',
    transitionTimingFunction: 'linear',
    transitionDuration: '400ms',
  };
};

export default class PopCard extends Component {
  static getPaths = function(models, options, props = {}) {
    let path = [];
    // @ts-ignore TS2339
    if (props.titleId) {
      // @ts-ignore TS2339
      path = path.concat(['meta', props.titleId]);
    }
    let paths = [
      path.concat([
        [
          'name',
          'shortName',
          'thumbnailUrl',
          'leadEpisodeId',
          'edgeEpisodeId',
          'leadEpisode',
          'edgeEpisode',
          'type',
          'schemaId',
          'mylisted',
          'canMyList',
          'cardInfo',
          'seriesMeta',
          'seasonMeta',
          'tvodBadge',
          'rental',
          'subscription',
        ],
      ]),
    ];
    // @ts-ignore TS2339
    if (props.titleModel) {
      // @ts-ignore TS2339
      if (props.artKind === 'edge_episode' && props.titleModel.edgeEpisodeId) {
        paths = paths.concat([
          [
            'meta',
            // @ts-ignore TS2339
            props.titleModel.edgeEpisodeId,
            [
              'id',
              'refId',
              'name',
              'shortName',
              'schemaId',
              'genres',
              'middleGenres',
              'attributes',
              'thumbnailUrl',
              'cardInfo',
              'seriesMeta',
              'seasonMeta',
              'type',
              'tvodBadge',
              'rental',
              'subscription',
            ],
          ],
        ]);
        // @ts-ignore TS2339
      } else if (props.titleModel.leadEpisodeId) {
        paths = paths.concat([
          [
            'meta',
            // @ts-ignore TS2339
            props.titleModel.leadEpisodeId,
            [
              'id',
              'refId',
              'name',
              'shortName',
              'schemaId',
              'genres',
              'middleGenres',
              'attributes',
              'thumbnailUrl',
              'cardInfo',
              'seriesMeta',
              'seasonMeta',
              'type',
              'tvodBadge',
              'rental',
              'subscription',
            ],
          ],
        ]);
      }
      // @ts-ignore TS2339
      if (!!props.titleModel.schemaId) {
        // @ts-ignore TS2339
        if (props.titleModel.schemaId === 2 || props.titleModel.schemaId === 1) {
          paths = paths.concat([
            [
              'meta',
              // @ts-ignore TS2339
              props.titleModel.id,
              'viewingEpisode',
              [
                'id',
                'refId',
                'name',
                'shortName',
                'schemaId',
                'genres',
                'middleGenres',
                'attributes',
                'bookmarkPosition',
                'thumbnailUrl',
                'cardInfo',
                'seriesMeta',
                'seasonMeta',
                'type',
                'tvodBadge',
                'rental',
              ],
            ],
          ]);
        } else {
          paths = paths.concat([
            [
              'meta',
              // @ts-ignore TS2339
              props.titleModel.id,
              [
                'id',
                'refId',
                'name',
                'shortName',
                'schemaId',
                'genres',
                'middleGenres',
                'attributes',
                'resumePoint',
                'cardInfo',
                'seriesMeta',
                'seasonMeta',
                'type',
                'tvodBadge',
                'rental',
                'subscription',
              ],
            ],
          ]);
        }
      }
      // @ts-ignore TS2339
      if (props.titleModel.type === 'linear_channel') {
        // @ts-ignore TS2339
        paths = paths.concat(EventInfo.getPaths(models, options, { id: props.titleModel.id }));
      }
    }
    return paths;
  };

  static get contextTypes() {
    return {
      getModelData: PropTypes.func,
      history: PropTypes.object,
      isInitialRender: PropTypes.bool,
      isOverlayPage: PropTypes.bool,
      isTallRow: PropTypes.bool,
      listContext: PropTypes.string,
      listType: PropTypes.string,
      routeHandler: PropTypes.object,
      rowNum: PropTypes.number,
      deleteApp: PropTypes.object,
      models: PropTypes.object,
      gtmApp: PropTypes.object,
    };
  }

  static get propTypes() {
    return {
      isDoubleWide: PropTypes.bool,
      isTallPanel: PropTypes.bool,
      model: PropTypes.object.isRequired,
      onPopClose: PropTypes.func,
      onPopOpen: PropTypes.func,
      onJawOpen: PropTypes.func,
      titleCardImage: PropTypes.string,
      titleModel: PropTypes.object.isRequired,
      popType: PropTypes.string,
      classes: PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.string]),
      artKind: PropTypes.string,
    };
  }

  static get defaultProps() {
    return {
      popType: 'zoom',
      artKind: 'default',
      useViewingModel: true,
    };
  }

  constructor(props) {
    super(props);

    this.state = {
      generation: -1,
      fetchDataError: null,
      rotateImages: true,
      showRatingProductization: true,
    };
    this.handleClickPlayButton = this.handleClickPlayButton.bind(this);
    this.handleDeleteSend = this.handleDeleteSend.bind(this);
    this.sendToGtm = this.sendToGtm.bind(this);
    // @ts-ignore TS2339
    this.titleModel = _.clone(props.titleModel);
    // @ts-ignore TS2339
    this._isMounted = false;
  }

  componentDidMount() {
    // @ts-ignore TS2339
    this._isMounted = true;
    this.fetchData(this.props);
  }

  componentWillUnmount() {
    // @ts-ignore TS2339
    this._isMounted = false;
    // @ts-ignore TS2339
    if (this.state.dispose) this.state.dispose();
    // @ts-ignore TS2339
    if (this.animationId) {
      // @ts-ignore TS2339
      clearTimeout(this.animationId);
      // @ts-ignore TS2339
      delete this.animationId;
    }
    // @ts-ignore TS2339
    if (this.props.onPopClose) {
      // @ts-ignore TS2339
      this.props.onPopClose();
    }
  }

  sendToGtm(clickArea) {
    if (!_.get(this.context, 'gtmApp')) return;
    // @ts-ignore TS2339
    const episodeMeta = this.viewingModel;
    const channel = _.get(episodeMeta, 'linearChannelMeta');
    const hasRelationProgram = _.get(episodeMeta, 'seriesMeta') && _.get(episodeMeta, 'seasonMeta');
    const relationProgram = hasRelationProgram
      ? {
          refId: _.get(episodeMeta, 'seriesMeta.refId'),
          name: _.get(episodeMeta, 'seriesMeta.name'),
        }
      : null;
    const program = {
      refId: _.get(episodeMeta, 'seasonMeta.refId') || _.get(episodeMeta, 'seriesMeta.refId'),
      name: _.get(episodeMeta, 'seasonMeta.name') || _.get(episodeMeta, 'seriesMeta.name'),
    };
    const content = _.isEmpty(channel)
      ? {
          refId: _.get(episodeMeta, 'refId'),
          name: _.get(episodeMeta, 'name') || _.get(episodeMeta, 'shortName'),
          rental: _.get(episodeMeta, 'rental'),
          subscription: _.get(episodeMeta, 'subscription'),
        }
      : {
          refId: _.get(episodeMeta, 'uniqueId'),
          name: _.get(episodeMeta, 'name'),
        };
    const attributes = _.get(episodeMeta, 'attributes');
    const genres = _.get(episodeMeta, 'genres');
    const middleGenres = _.get(episodeMeta, 'middleGenres');
    const schemaId = _.get(episodeMeta, 'schemaId');
    this.context.gtmApp.pushDataLayerOnContentPageClick(
      CONTENT_EVENTS.CONTENT_CLICK,
      { content, program, relationProgram, channel, attributes, genres, middleGenres, schemaId },
      { clickArea },
    );
  }

  render() {
    let resumeKey = 'resumePoint';
    // 視聴中のみbookmarkPositionを参照する
    if (
      this.context.listContext === 'continueWatching' ||
      this.context.routeHandler.route._regex === routes.continueWatching._regex
    ) {
      resumeKey = 'bookmarkPosition';
    }
    return (
      <div
        className={classnames([
          'pop-card',
          // @ts-ignore TS2339
          `pop-${this.props.popType}`,
          {
            'pop-card-tall-panel': this.context.isTallRow === true,
            'has-thumbs': true,
          },
          // @ts-ignore TS2339
          this.props.classes,
        ])}
      >
        {this.renderHeroImages()}
        <div className="pop-outline"></div>
        {/*
         // @ts-ignore TS2339 */}
        <img src={this.props.titleArt} className="pop-title-art" style={popTitleArtStyle(false)} />
        {this.renderOverlay()}
        {/*
         // @ts-ignore TS2339 */}
        {(!this.viewingModel || !_.has(this.viewingModel, resumeKey)) && (
          // @ts-ignore TS2322
          <OAPPlayer oap={this.titleModel.oap} muteOnly={true} />
        )}
        {this.renderBounceArea()}
        <div
          className="hiddenActionItems"
          style={{
            display: 'none',
          }}
        ></div>
      </div>
    );
  }

  handleClickPlayButton(e) {
    e.preventDefault();
    e.stopPropagation();
    this.sendToGtm(CLICK_AREA.THUMBNAIL);
    const { routeHandler, history } = this.context;
    // @ts-ignore TS2339
    if (this.titleModel.type === 'linear_channel' && this.titleModel.refId) {
      // @ts-ignore TS2339
      const simulcast = _.find(this.context.getModelData('simulcast'), item => item.refId == this.titleModel.refId);
      // @ts-ignore TS2554
      const to = routes.simulcast.makePath({ channelName: simulcast.name });
      if (routeHandler.path.indexOf('/simulcast') === 0) {
        history.replace(to);
      } else {
        history.push(to);
      }
      return;
    }
    let query = {};
    // 視聴中経由の場合はカードのプログレスバーと開始時間との整合性を保つために開始時間をセットする
    if (
      // @ts-ignore TS2339
      this.viewingModel &&
      (this.context.listContext === 'continueWatching' || routeHandler.route._regex === routes.continueWatching._regex)
    ) {
      // @ts-ignore TS2339
      query.t = _.get(this.viewingModel, 'bookmarkPosition', 0);
    }
    // @ts-ignore TS2339
    let refId = this.viewingModel ? this.viewingModel.refId : this.titleModel.leadEpisode.refId;
    if (refId) {
      // @ts-ignore TS2554
      const to = routes.content.makePath({ id: refId }, query);
      if (routeHandler.path.indexOf('/content') === 0) {
        history.replace(to);
      } else {
        history.push(to);
      }
      return;
    }

    // @ts-ignore TS2339
    let id = this.viewingModel ? this.viewingModel.id : this.titleModel.leadEpisodeId;
    // @ts-ignore TS2339
    if (this.titleModel.type === 'linear_channel') id = this.titleModel.id;
    if (!id) return;
    // @ts-ignore TS2554
    const to = routes.watchNow.makePath({ id }, query);
    if (routeHandler.path.indexOf('/watch') === 0) {
      history.replace(to);
    } else {
      history.push(to);
    }
  }

  handleDeleteSend(e) {
    e.preventDefault();
    e.stopPropagation();
    // @ts-ignore TS2339
    if (this.state.deleting) return false;
    this.setState({ deleting: true });

    const authContext = this.context.getModelData('authContext');
    // @ts-ignore TS2554
    const profile = activeProfile(this.context.models);
    this.context.deleteApp
      // @ts-ignore TS2339
      .one(this.titleModel.id, authContext, profile.id)
      .then(response => {
        // @ts-ignore TS2339
        if (!this._isMounted) return;
        this.setState({ deleting: false });
      })
      .catch(e => {
        // @ts-ignore TS2339
        if (!this._isMounted) return;
        window.location.reload();
      });
  }

  renderHeroImages() {
    const getImages = () => {
      // @ts-ignore TS2339
      if (this.props.useTitleCardImage) {
        // @ts-ignore TS2339
        return [this.props.titleCardImage];
        // @ts-ignore TS2339
      } else if (_.get(this.viewingModel, 'thumbnailUrl')) {
        // @ts-ignore TS2339
        return [this.viewingModel.thumbnailUrl];
        // @ts-ignore TS2339
      } else if (this.props.artType && this.props.artType !== 'default' && this.props.titleCardImage) {
        // @ts-ignore TS2339
        return [this.props.titleCardImage];
        // @ts-ignore TS2339
      } else if (_.get(this.titleModel, 'thumbnailUrl') && this.props.artKind === 'default') {
        // @ts-ignore TS2339
        return [this.titleModel.thumbnailUrl];
        // @ts-ignore TS2339
      } else if (this.props.titleCardImage) {
        // @ts-ignore TS2339
        return [this.props.titleCardImage];
      }
      return null;
    };
    return (
      <HeroImages
        auto={true}
        classes={{ 'pop-background': true }}
        duration={3500}
        firstDelay={3500}
        // @ts-ignore TS2322
        height={480}
        isStandalone={false}
        // @ts-ignore TS2339
        preloadImage={this.props.titleCardImage}
        images={getImages()}
        size={'_665x375'}
        stop={false}
        watched={false}
        // @ts-ignore TS2339
        model={this.props.model}
      >
        {/*
         // @ts-ignore TS2339 */}
        {this.props.popType === 'bounce' ? this.renderPlayButton() : null}
      </HeroImages>
    );
  }

  renderPlayButton() {
    const props = {};
    // @ts-ignore TS2339
    props.to = routes.watchNow;
    // @ts-ignore TS2339
    if (this.titleModel.type === 'linear_channel' && this.titleModel.refId) {
      // @ts-ignore TS2339
      const simulcast = _.find(this.context.getModelData('simulcast'), item => item.refId == this.titleModel.refId);
      // @ts-ignore TS2339
      props.to = routes.simulcast;
      // @ts-ignore TS2339
      props.params = { channelName: simulcast.name };
      // @ts-ignore TS2339
    } else if (!this.titleModel.leadEpisodeId) {
      return null;
    } else {
      // @ts-ignore TS2339
      if (this.viewingModel && this.viewingModel.refId) {
        // @ts-ignore TS2339
        props.params = { id: this.viewingModel.refId };
        // @ts-ignore TS2339
        props.to = routes.content;
      } else {
        // @ts-ignore TS2339
        props.params = { id: this.titleModel.leadEpisodeId };
      }
    }
    // if (this.titleModel.schemaId === 10) {
    //   props.to = routes.featureDetail;
    // }

    // 配信期間中のもののみ再生アイコンを表示する
    // @ts-ignore TS2339
    if (!getIsDelivery(this.titleModel.cardInfo.deliveryStartAt, this.titleModel.cardInfo.deliveryEndAt)) return null;

    return (
      <MainViewLink
        {...props}
        // @ts-ignore TS2322
        onClick={this.handleClickPlayButton}
        role="link"
        ariaLabel="再生"
        className="pop-play pop-play-top play-link"
      />
    );
  }

  getTitleModelId() {
    // @ts-ignore TS2339
    if (this.titleModel.type !== 'media' && this.titleModel.type !== 'linear_channel') {
      // @ts-ignore TS2339
      return canAccessBySlug(this.titleModel) ? this.titleModel.slug : this.titleModel.id;
    }
    // @ts-ignore TS2339
    return this.titleModel.id;
  }
  renderRating() {
    // @ts-ignore TS2339
    const cardInfo = this.titleModel.cardInfo || {};
    const ratingText = getRatingText(this.context.models, cardInfo.rating);
    if (!ratingText) return null;
    if (_.isArray(ratingText)) {
      return _.map(ratingText, (rating, i) => {
        return (
          <span className="rating" key={`rating-${i}`}>
            {rating}
          </span>
        );
      });
    } else {
      if (ratingText) {
        return (
          <span className="rating" key="rating">
            {ratingText}
          </span>
        );
      }
    }
    return null;
  }

  renderPopInfo() {
    let props = {};
    // @ts-ignore TS2339
    const cardInfo = this.titleModel.cardInfo || {};

    let channelName;
    // @ts-ignore TS2339
    const simulcast = _.find(this.context.getModelData('simulcast'), item => item.refId == this.titleModel.refId);
    if (simulcast) channelName = _.lowerCase(simulcast.name);
    let linkProps = {
      to:
        // @ts-ignore TS2339
        this.titleModel.type === 'media' || this.titleModel.type === 'linear_channel' ? routes.watchNow : routes.title,
      params: { id: this.getTitleModelId() },
    };
    // @ts-ignore TS2339
    if (this.titleModel.refId) {
      linkProps = {
        // @ts-ignore TS2339
        to: this.titleModel.type === 'media' ? routes.content : routes.program,
        // @ts-ignore TS2339
        params: { id: this.titleModel.refId },
      };
      // @ts-ignore TS2339
      if (this.titleModel.type === 'linear_channel') {
        linkProps = {
          to: routes.simulcast,
          // @ts-ignore TS2322
          params: { channelName: simulcast.name },
        };
      }
    }

    let _renderMeta, _linearTime;
    if (
      // @ts-ignore TS2339
      this.titleModel.type === 'linear_channel' &&
      // @ts-ignore TS2339
      this.viewingModel &&
      // @ts-ignore TS2339
      this.viewingModel.startAt &&
      // @ts-ignore TS2339
      this.viewingModel.endAt
    ) {
      _linearTime = (
        <div className={classnames('event-time')}>
          {/*
           // @ts-ignore TS2322 */}
          <SetDate date={this.viewingModel.startAt} format={'short'} /> - {/*
           // @ts-ignore TS2322 */}
          <SetDate date={this.viewingModel.endAt} format={'time'} />
        </div>
      );
    }
    if (
      !_linearTime &&
      !cardInfo.broadcastStartAt &&
      !cardInfo.episodeNumberTitle &&
      !cardInfo.productionYear &&
      !cardInfo.seasonCount &&
      !cardInfo.episodeCount
    ) {
      //表示するものがなければ表示しない
    } else {
      _renderMeta = (
        <div className="meta">
          {_linearTime}
          {/*
           // @ts-ignore TS2339 */}
          {this.props.deliveryStartVisible &&
          // @ts-ignore TS2339
          this.titleModel.schemaId === 4 &&
          !!cardInfo.broadcastStartAt &&
          getIsLiveOnAir(cardInfo.broadcastStartAt, cardInfo.broadcastEndAt) ? (
            <span className="date">
              {/*
               // @ts-ignore TS2322 */}
              <SetDate date={cardInfo.broadcastStartAt} format={'short'} />
            </span>
          ) : null}
          {/*
           // @ts-ignore TS2339 */}
          {this.titleModel.type === 'media' && !!cardInfo.episodeNumberTitle ? (
            <span className="episode-number">{cardInfo.episodeNumberTitle}</span>
          ) : null}
          {/*
           // @ts-ignore TS2339 */}
          {!this.props.deliveryStartVisible && !!cardInfo.productionYear ? (
            <span className="year">{cardInfo.productionYear}</span>
          ) : null}
          {this.renderRating()}
          <Duration seasonCount={cardInfo.seasonCount} episodeCount={cardInfo.episodeCount} />
        </div>
      );
    }

    // @ts-ignore TS2339
    let title = this.titleModel.name || this.titleModel.shortName;

    if (
      !title &&
      !cardInfo.broadcastStartAt &&
      !cardInfo.broadcastEndAt &&
      !cardInfo.episodeNumberTitle &&
      !cardInfo.productionYear &&
      !cardInfo.seasonCoun &&
      !cardInfo.episodeCount &&
      _.isEmpty(cardInfo.description) &&
      // @ts-ignore TS2339
      !_.get(this.viewingModel, 'startAt') &&
      // @ts-ignore TS2339
      !_.get(this.viewingModel, 'endAt') &&
      // @ts-ignore TS2339
      !_.get(this.viewingModel, 'header') &&
      // @ts-ignore TS2339
      !_.get(this.viewingModel, 'shortMessage') &&
      // @ts-ignore TS2339
      !_.get(this.viewingModel, 'description') &&
      // @ts-ignore TS2339
      !_.get(this.viewingModel, 'header') &&
      // @ts-ignore TS2339
      !this.props.myListButton
    ) {
      return null;
    } else {
      return (
        <div className="pop-info" {...props}>
          <div className="pop-info-main">
            <div className="pop-text">
              {/*
               // @ts-ignore TS2339 */}
              {!title && this.titleModel.type !== 'linear_channel' ? null : (
                <div className="pop-title">
                  {/*
                   // @ts-ignore TS2339 */}
                  {this.titleModel.type === 'linear_channel' ? (
                    <div className={classnames('linear_logo', channelName)}></div>
                  ) : null}
                  {title ? (
                    <React.Fragment>
                      <MainViewLink {...linkProps}>{title}</MainViewLink>
                      <i className="fa fa-angle_right"></i>
                    </React.Fragment>
                  ) : null}
                </div>
              )}
              {_renderMeta}
              {/*
               // @ts-ignore TS2339 */}
              {this.titleModel.type !== 'linear_channel' && this.titleModel.header ? (
                // @ts-ignore TS2339
                <div className="synopsis">{this.titleModel.header}</div>
              ) : cardInfo.description ? (
                <div className="synopsis">{cardInfo.description}</div>
              ) : null}
              {/*
               // @ts-ignore TS2339 */}
              {this.titleModel.type === 'linear_channel' && _.get(this.viewingModel, 'shortMessage') ? (
                // @ts-ignore TS2339
                <div className="synopsis">{this.viewingModel.shortMessage}</div>
              ) : // @ts-ignore TS2339
              this.titleModel.type === 'linear_channel' && _.get(this.viewingModel, 'header') ? (
                // @ts-ignore TS2339
                <div className="synopsis">{this.viewingModel.header}</div>
              ) : // @ts-ignore TS2339
              this.titleModel.type === 'linear_channel' && _.get(this.viewingModel, 'description') ? (
                // @ts-ignore TS2339
                <div className="synopsis">{this.viewingModel.description}</div>
              ) : null}
              {/*
               // @ts-ignore TS2339 */}
              {!this.props.myListButton && this.context.listContext !== 'continueWatching' ? null : (
                <div className="button-box">
                  {/*
                   // @ts-ignore TS2339 */}
                  {this.props.myListButton ? this.props.myListButton : null}
                  {this.context.listContext === 'continueWatching' ? (
                    <div className="action-btn delete-button btn-very-small" onClick={this.handleDeleteSend}>
                      <i className="fa fa-delete"></i>
                      <span>削除</span>
                    </div>
                  ) : null}
                </div>
              )}
            </div>
          </div>
        </div>
      );
    }
  }

  renderOverlay() {
    // @ts-ignore TS2339
    const cardInfo = this.viewingModel ? this.viewingModel.cardInfo || {} : {};
    const userInfo = this.context.getModelData('userInfo');

    let resumeKey = 'resumePoint';
    // 視聴中のみbookmarkPositionを参照する
    if (
      this.context.listContext === 'continueWatching' ||
      this.context.routeHandler.route._regex === routes.continueWatching._regex
    ) {
      resumeKey = 'bookmarkPosition';
    }

    let showProgress =
      // @ts-ignore TS2339
      _.has(this.viewingModel, resumeKey) &&
      userInfo.status !== 'NON_REGISTERED_MEMBER' &&
      // @ts-ignore TS2339
      _.get(this.viewingModel, 'schemaId') != 4;
    let progress = 0;
    // @ts-ignore TS2339
    if (this.viewingModel && cardInfo.episodeRuntime && this.viewingModel[resumeKey]) {
      // @ts-ignore TS2339
      if (!!cardInfo.episodeRuntime && !!this.viewingModel[resumeKey]) {
        // @ts-ignore TS2345
        progress = Math.min(parseInt((this.viewingModel[resumeKey] / cardInfo.episodeRuntime) * 100, 10), 100);
      }
    }
    // @ts-ignore TS2339
    if (this.props.popType === 'bounce') {
      // @ts-ignore TS2339
      if (!!this.viewingModel && Object.keys(this.viewingModel).length > 0) {
        // @ts-ignore TS2339
        let tags = activeTags(this.viewingModel, this.context);
        let cardBadge = (
          <CardBadge
            // @ts-ignore TS2339
            item={this.viewingModel}
            // @ts-ignore TS2339
            keyPrefix={this.props.keyPrefix}
            // @ts-ignore TS2339
            listContext={this.props.listContext}
            // @ts-ignore TS2339
            listType={this.props.listType}
          />
        );

        // @ts-ignore TS2339
        if (this.titleModel.type === 'linear_channel' && this.viewingModel.isFirst) {
          tags.unshift('初回');
          // アートの上には一つのタグしか表示させない
          cardBadge = (
            <div className="card-badge-wrapper">
              <div className="card-badge">{tags[0]}</div>
            </div>
          );
        }

        return (
          <div className={classnames('pop-overlay', { noProgress: userInfo.status == 'NON_REGISTERED_MEMBER' })}>
            <div className="pop-play-hitzone" onClick={this.handleClickPlayButton}>
              {cardBadge}
              {this.renderPlayButton()}
            </div>
            <div className="title">
              {/*
               // @ts-ignore TS2339 */}
              <p className="ellipsized">{this.viewingModel.shortName || this.viewingModel.name}</p>
            </div>
            {/*
             // @ts-ignore TS2339 */}
            {this.titleModel.type !== 'linear_channel' ? (
              <React.Fragment>
                <Duration episodeRuntime={cardInfo.episodeRuntime} />
                {showProgress && (
                  <div className="progress">
                    <span className="progress-bar">
                      <span role="presentation" className="progress-completed" style={{ width: `${progress}%` }}></span>
                    </span>
                  </div>
                )}
              </React.Fragment>
            ) : null}
          </div>
        );
      } else {
        return null;
      }
    } else {
      let linkProps = {
        to:
          // @ts-ignore TS2339
          this.titleModel.type === 'media' || this.titleModel.type === 'linear_channel'
            ? routes.watchNow
            : routes.title,
        params: { id: this.getTitleModelId() },
      };
      // @ts-ignore TS2339
      if (this.titleModel.refId) {
        linkProps = {
          // @ts-ignore TS2339
          to: this.titleModel.type === 'media' ? routes.content : routes.program,
          // @ts-ignore TS2339
          params: this.titleModel.refId,
        };
        // @ts-ignore TS2339
        if (this.titleModel.type === 'linear_channel') {
          // @ts-ignore TS2339
          const simulcast = _.find(this.context.getModelData('simulcast'), item => item.refId == this.titleModel.refId);
          linkProps = {
            to: routes.simulcast,
            // @ts-ignore TS2322
            params: { channelName: simulcast.name },
          };
        }
      }
      // if (this.titleModel.schemaId === 10) {
      //   linkProps.to = routes.featureDetail;
      // }
      return (
        <div className={classnames('pop-overlay', { noProgress: userInfo.status == 'NON_REGISTERED_MEMBER' })}>
          <div className="pop-play-hitzone">{this.renderPlayButton()}</div>
          {/*
           // @ts-ignore TS2322 */}
          <MainViewLink {...linkProps} className="pop-titlerow-hitzone pop-titlerow-hitzone-half"></MainViewLink>
          {this.renderPopInfo()}
        </div>
      );
    }
  }

  renderBounceArea() {
    // @ts-ignore TS2339
    if (this.props.popType === 'bounce') {
      return (
        <div className="pop-bouncearea">
          <div className="pop-bumper" />
          {this.renderPopInfo()}
        </div>
      );
    } else {
      return null;
    }
  }

  async fetchData(props) {
    // @ts-ignore TS2339
    const paths = this.constructor.getPaths(this.context.models, {}, props);
    const eventInfoRootPath = EventInfo.getRootPath(this.context.models, {}, { id: props.titleModel.id });
    const evaluator = props.model.fetch(paths);
    // @ts-ignore TS2339
    this.state.dispose = evaluator.dispose;
    evaluator
      .then(res => {
        // @ts-ignore TS2339
        if (!this.titleModel) this.titleModel = {};
        // @ts-ignore TS2339
        if (!this.viewingModel) this.viewingModel = {};
        let titleModel = _.get(res, ['json', 'meta', props.titleId], {});
        // 渡されたcardInfoを優先する
        // @ts-ignore TS2339
        if (this.titleModel.cardInfo) {
          titleModel = _.clone(titleModel); // falcorのキャッシュが消えるのでcloneする
          delete titleModel.shortName;
          delete titleModel.cardInfo;
        }
        // @ts-ignore TS2339
        Object.assign(this.titleModel, titleModel);
        let onairEvent = _.get(res.json, eventInfoRootPath);
        // @ts-ignore TS2554
        onairEvent = EpgEvent.additionalData(onairEvent);
        // @ts-ignore TS2339
        if (!this.props.useViewingModel) {
        } else if (onairEvent) {
          // @ts-ignore TS2339
          Object.assign(this.viewingModel, onairEvent);
          // @ts-ignore TS2339
        } else if (this.titleModel.schemaId === 3) {
          //episode schema id = 3;
          // @ts-ignore TS2339
          Object.assign(this.viewingModel, this.titleModel);
          // @ts-ignore TS2339
        } else if (this.titleModel.viewingEpisode) {
          // @ts-ignore TS2339
          Object.assign(this.viewingModel, this.titleModel.viewingEpisode);
          // @ts-ignore TS2339
        } else if (props.artKind === 'edge_episode' && this.titleModel.edgeEpisodeId) {
          // @ts-ignore TS2339
          Object.assign(this.viewingModel, _.get(res, ['json', 'meta', this.titleModel.edgeEpisodeId]));
          // @ts-ignore TS2339
        } else if (this.titleModel.leadEpisode && this.titleModel.leadEpisode.id) {
          // @ts-ignore TS2339
          Object.assign(this.viewingModel, _.get(res, ['json', 'meta', this.titleModel.leadEpisode.id]));
          // @ts-ignore TS2339
        } else if (this.titleModel.leadEpisodeId) {
          // @ts-ignore TS2339
          Object.assign(this.viewingModel, _.get(res, ['json', 'meta', this.titleModel.leadEpisodeId]));
        }
        const newState = {
          dispose: null,
          fetchDataError: null,
          // @ts-ignore TS2339
          generation: this.state.generation + 1,
        };
        // @ts-ignore TS2339
        if (this._isMounted) this.setState(newState);
        Object.assign(this.state, newState);
      })
      .catch(e => {
        console.error(e.stack);
        const newState = {
          dispose: null,
          fetchDataError: e,
        };
        // @ts-ignore TS2339
        if (this._isMounted) this.setState(newState);
        else Object.assign(this.state, newState);
      });
  }
}
