import _ from '@domain/libs/util';
import datetime from 'src/domain/libs/datetime';

export const META_DELIVERY_STATUS = {
  /**ライブ開始前 */
  BEFORE_LIVE: 1,
  /**まもなくライブ配信 */
  LIVE_COMING_SOON: 2,
  /**ライブ中 */
  LIVE_ON_AIR: 3,
  /**配信開始前 */
  BEFORE_DELIVERY: 4,
  /** 配信中（x日以内に配信終了予定なし） */
  END_DELIVERY_AFTER_X_DAYS: 5,
  /** 配信中（x日以内に配信終了予定あり） */
  END_DELIVERY_WITHIN_X_DAYS: 6,
  /** 配信中（まもなく終了） */
  END_DELIVERY_SOON: 7,
  /** 配信終了 */
  DELIVERY_ENDED: 8,
  /** その他(データが壊れている場合など) */
  OTHER: 9,
};

export const getIsDelivery = function(_deliveryStartAt, _deliveryEndAt) {
  let isDelivery = false;
  const deliveryStartAt = _deliveryStartAt ? datetime(_deliveryStartAt) : null;
  const deliveryEndAt = _deliveryEndAt ? datetime(_deliveryEndAt) : null;
  const now = datetime();

  if (deliveryStartAt && deliveryEndAt) {
    isDelivery = deliveryStartAt.isSameOrBefore(now) && deliveryEndAt.isSameOrAfter(now);
  } else if (deliveryStartAt) {
    isDelivery = deliveryStartAt.isSameOrBefore(now);
  } else if (deliveryEndAt) {
    isDelivery = deliveryEndAt.isSameOrAfter(now);
  } else {
    isDelivery = true;
  }
  return isDelivery;
};

export const getIsDeliveryStarted = function(_deliveryStartAt) {
  let isDeliveryStarted = true;
  const deliveryStartAt = _deliveryStartAt ? datetime(_deliveryStartAt) : null;
  const now = datetime();

  if (deliveryStartAt) {
    isDeliveryStarted = deliveryStartAt.isSameOrBefore(now);
  }
  return isDeliveryStarted;
};

export const getIsDeliveryEnded = function(_deliveryEndAt) {
  let isDeliveryEnded = false;
  const deliveryEndAt = _deliveryEndAt ? datetime(_deliveryEndAt) : null;
  const now = datetime();

  if (deliveryEndAt) {
    isDeliveryEnded = deliveryEndAt.isBefore(now);
  }
  return isDeliveryEnded;
};

export const getMetaDeliveryStatus = function(meta, opt = {}) {
  // @ts-ignore TS2339
  const days = opt.days || 90; // デフォルト90日

  const isLiveSchema = meta.schemaId == 4;
  const now = datetime();
  const broadcastStartAt = _.get(meta, 'cardInfo.broadcastStartAt') ? datetime(meta.cardInfo.broadcastStartAt) : null;
  const broadcastEndAt = _.get(meta, 'cardInfo.broadcastEndAt') ? datetime(meta.cardInfo.broadcastEndAt) : null;
  const deliveryStartAt = _.get(meta, 'cardInfo.deliveryStartAt') ? datetime(meta.cardInfo.deliveryStartAt) : null;
  const deliveryEndAt = _.get(meta, 'cardInfo.deliveryEndAt') ? datetime(meta.cardInfo.deliveryEndAt) : null;
  const liveComingSoonFrom =
    // @ts-ignore TS2339
    opt.liveComingSoonHour && broadcastStartAt
      ? // @ts-ignore TS2339
        datetime(broadcastStartAt).subtract(opt.liveComingSoonHour, 'hours')
      : null;
  const endDeliverySoonFrom =
    // @ts-ignore TS2339
    opt.endDeliverySoonHour && deliveryEndAt
      ? // @ts-ignore TS2339
        datetime(deliveryEndAt).subtract(opt.endDeliverySoonHour, 'hours')
      : null;

  let isLiveOnAir = false;
  if (isLiveSchema) {
    if (broadcastStartAt && broadcastEndAt) {
      isLiveOnAir = now >= broadcastStartAt && now <= broadcastEndAt;
    } else if (broadcastStartAt) {
      isLiveOnAir = now >= broadcastStartAt;
    } else if (broadcastEndAt) {
      isLiveOnAir = now <= broadcastEndAt;
    }
  }

  if (isLiveSchema && broadcastStartAt && now < broadcastStartAt) {
    // ライブ開始前
    if (liveComingSoonFrom && now > liveComingSoonFrom) {
      // まもなく配信(optで指定がある場合のみ)
      return META_DELIVERY_STATUS.LIVE_COMING_SOON;
    } else {
      // ライブ開始前
      return META_DELIVERY_STATUS.BEFORE_LIVE;
    }
  } else if (isLiveOnAir) {
    // ライブ中
    return META_DELIVERY_STATUS.LIVE_ON_AIR;
  } else if (deliveryStartAt && now < deliveryStartAt) {
    // 配信前
    return META_DELIVERY_STATUS.BEFORE_DELIVERY;
  } else if (!deliveryEndAt || now <= deliveryEndAt) {
    // 配信中
    if (endDeliverySoonFrom && now > endDeliverySoonFrom) {
      // まもなく配信終了(optで指定がある場合のみ)
      return META_DELIVERY_STATUS.END_DELIVERY_SOON;
    } else if (deliveryEndAt && datetime(deliveryEndAt).subtract(days, 'days') <= now) {
      // x日以内に配信終了予定あり
      return META_DELIVERY_STATUS.END_DELIVERY_WITHIN_X_DAYS;
    } else {
      // x日以内に配信終了予定なし
      return META_DELIVERY_STATUS.END_DELIVERY_AFTER_X_DAYS;
    }
  } else if (deliveryEndAt && now > deliveryEndAt) {
    // 配信終了
    return META_DELIVERY_STATUS.DELIVERY_ENDED;
  } else {
    return META_DELIVERY_STATUS.OTHER;
  }
};
