import React from 'react';
import _ from 'src/domain/libs/util';
import sepDigit from '../../../../../common/sepDigit';

export default props => {
  if (!props.courses) {
    return null;
  }

  return props.courses.map((course, i) => {
    if (_.isEmpty(course.activePricing)) {
      return null;
    }
    return (
      <div className="product-content-row" key={`course-${i}`}>
        <div className="product-info">
          <p className="product-name">{course.displayName}</p>
          <p className="product-description">{course.description}</p>
          <div className="product-condition">
            <div className="price">
              <span className="sub">価格</span>
              <p>
                {sepDigit(course.activePricing.paymentAmount)}
                <span>{course.activePricing.displayUnit}（税込）/月</span>
              </p>
            </div>
            <div className="button">
              <div
                className="submit-button btn btn-fill"
                onClick={e => {
                  props.nextAction(course, 'course');
                }}
              >
                契約へ進む
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  });
};
