import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import _ from 'src/domain/libs/util';
import url from 'url';
import window from 'global/window';
import datetime from 'src/domain/libs/datetime';

import * as browserEvents from '../../../../sketch-platform/utils/browserEvents';

const getSharingText = metadata => {
  const liveSchemaId = 4;
  if (metadata.schemaId != liveSchemaId) {
    return metadata.name;
  }
  /*
  下記文言はライブコンテンツのみ
  ① ライブ開始日時アリ＆ライブ開始前：WOWOWオンデマンドで「～」を ○月○日 ○時○分よりライブ配信
  ② ライブ開始日時ナシ：WOWOWオンデマンドで「～」を配信　※キスマイケアのため「ライブ」はいれない
  ③ ライブ期間中：WOWOWオンデマンドで「～」をライブ視聴中
  ④ ライブ終了日時～配信終了日時：WOWOWオンデマンドで「～」をアーカイブ視聴中
  ⑤ 上記のいずれにも該当しない場合：WOWOWオンデマンドで「～」を公開中
  */
  const now = datetime();
  const broadcastStartAt = _.get(metadata, 'cardInfo.broadcastStartAt')
    ? datetime(metadata.cardInfo.broadcastStartAt)
    : null;
  const broadcastEndAt = _.get(metadata, 'cardInfo.broadcastEndAt') ? datetime(metadata.cardInfo.broadcastEndAt) : null;
  const deliveryEndAt = _.get(metadata, 'cardInfo.deliveryEndAt') ? datetime(metadata.cardInfo.deliveryEndAt) : null;

  const name = metadata.name;
  let text = `WOWOWオンデマンドで「${name}」を公開中`;

  if (broadcastStartAt) {
    if (broadcastStartAt > now) {
      text = `WOWOWオンデマンドで「${name}」を${broadcastStartAt.formatYYYYMMDDddJa()}${broadcastStartAt.formatahmm()}よりライブ配信`;
    } else if (broadcastEndAt && broadcastStartAt <= now && broadcastEndAt >= now) {
      text = `WOWOWオンデマンドで「${name}」をライブ視聴中`;
    }
  } else {
    text = `WOWOWオンデマンドで「${name}」を配信`;
  }
  if (broadcastEndAt && deliveryEndAt && broadcastEndAt <= now && deliveryEndAt >= now) {
    text = `WOWOWオンデマンドで「${name}」をアーカイブ視聴中`;
  }
  return text;
};

const SHARE_TARGETS = [
  {
    key: 'x',
    label: 'X',
    isMobile: true,
    target: '_blank',
    svg: '/images/icon-x.svg',
    href: function(_this) {
      const urlObj = url.parse('https://x.com/intent/tweet');
      _.assign(urlObj, {
        query: {
          url: _this.getShareUrl(),
          text: getSharingText(_this.props.metadata),
          // via: '',
          // related: '',
        },
      });
      return url.format(urlObj);
    },
  },
  {
    key: 'facebook',
    label: 'Facebook',
    isMobile: true,
    target: '_blank',
    //svg: '/images/share/facebook.svg',
    svg: '/images/fb_logo_color.svg',
    href: function(_this) {
      const urlObj = url.parse('http://www.facebook.com/dialog/share');
      _.assign(urlObj, {
        query: {
          app_id: _.get(_this.context.getModelData('externalApps'), 'facebookAppId'),
          href: _this.getShareUrl(),
          // app_id: 966242223397117,
          // href: 'https://wod.wowow.co.jp/series/15039',
          display: 'popup',
          //redirect_uri: 'https://www.youtube.com/facebook_redirect',
          quote: getSharingText(_this.props.metadata),
        },
      });
      return url.format(urlObj);
    },
  },
  {
    key: 'line',
    label: 'LINE',
    isMobile: true,
    target: '_blank',
    svg: '/images/line_logo.svg',
    href: function(_this) {
      const urlObj = url.parse('https://social-plugins.line.me/lineit/share');
      _.assign(urlObj, {
        query: {
          url: _this.getShareUrl(),
          text: getSharingText(_this.props.metadata),
        },
      });
      return url.format(urlObj);
    },
  },
];

// 秒をhh:mm:ssに変換する
const getFormatTime = currentTime => {
  const h = Math.floor(currentTime / 3600);
  if (h > 0) currentTime = currentTime - h * 3600;
  const m = Math.floor(currentTime / 60);
  if (m > 0) currentTime = currentTime - m * 60;
  const s = currentTime;
  let format = '';
  if (h > 0) {
    // @ts-ignore TS2345
    format = '' + h + ':' + _.padStart(m, 2, 0) + ':' + _.padStart(s, 2, 0);
  } else {
    // @ts-ignore TS2345
    format = '' + m + ':' + _.padStart(s, 2, 0);
  }
  return format;
};

// hh:mm:ssを秒に変換する
const getSecondTime = formatTime => {
  const time = formatTime.split(':');
  _.reverse(time);
  let s = 0,
    m = 0,
    h = 0;
  for (let i = 0; i < time.length; i++) {
    if (i == 0) {
      if (!isNaN(parseInt(time[i], 10))) {
        s = time[i];
        m = Math.floor(s / 60);
        s = s - m * 60;
      }
    } else if (i == 1) {
      if (!isNaN(parseInt(time[i], 10))) {
        m = m + time[i];
        h = Math.floor(m / 60);
        m = m - h * 60;
      }
    } else {
      if (!isNaN(parseInt(time[i], 10))) {
        h = h + time[i];
      }
    }
  }
  return h * 3600 + m * 60 + s;
};

const ShareItem = props => {
  return (
    <div className={classnames('share-item', props.target.key)} role="option">
      <a
        key={`share-link-${props.target.key}`}
        className="target"
        target={props.target.target}
        href={props.target.href(props.parent)}
      >
        <div className={classnames('icon-resize', props.target.key)}>
          <img src={props.target.svg} />
        </div>
        <div className="title">{props.target.label}</div>
      </a>
    </div>
  );
};

class ShareContents extends React.Component {
  static get contextTypes() {
    return {
      getModelData: PropTypes.func,
      // columnsInRow: PropTypes.number,
    };
  }
  static get propTypes() {
    return {
      position: PropTypes.string,
      onclick: PropTypes.func,
    };
  }

  constructor(props) {
    super(props);
    // @ts-ignore TS2339
    this.shareUrlRef = React.createRef();
    this.handleCopyClick = this.handleCopyClick.bind(this);
    this.handleChangeCheckbox = this.handleChangeCheckbox.bind(this);
    this.handleChangeInput = this.handleChangeInput.bind(this);
    this.handleBlurInput = this.handleBlurInput.bind(this);
    let currentTime = parseInt(props.currentTime, 10);
    if (isNaN(currentTime)) currentTime = 0;
    this.state = {
      startAt: false,
      formatTime: getFormatTime(currentTime),
      currentTime: currentTime,
    };
  }

  componentDidMount() {
    // @ts-ignore TS2339
    this._isMounted = true;
  }

  componentWillUnmount() {
    // @ts-ignore TS2339
    this._isMounted = false;
  }

  handleCopyClick() {
    // @ts-ignore TS2339
    const el = this.shareUrlRef.current;
    if (el) {
      el.select();
      const retVal = document.execCommand('copy');
      //console.log(retVal);
    }
  }

  handleChangeCheckbox(e) {
    this.setState({ startAt: e.target.checked });
  }

  handleChangeInput(e) {
    this.setState({ formatTime: e.target.value });
  }

  handleBlurInput() {
    // @ts-ignore TS2339
    const currentTime = getSecondTime(this.state.formatTime);
    this.setState({ currentTime, formatTime: getFormatTime(currentTime) });
  }

  getShareUrl() {
    let shareUrl = window.location.href;
    //shareUrl = "https://stg-br-web.logica.io/watch/23430";
    const urlObj = url.parse(shareUrl);
    // @ts-ignore TS2339
    if (this.state.startAt) {
      _.assign(urlObj, {
        query: {
          // @ts-ignore TS2339
          t: parseInt(this.state.currentTime, 10),
        },
      });
    }
    return url.format(urlObj);
  }

  render() {
    return (
      <React.Fragment>
        {/*
         // @ts-ignore TS2339 */}
        <div className="bg-share" onClick={this.props.onclick}></div>
        <div
          className={classnames('share-contents', {
            // @ts-ignore TS2339
            right: this.props.position == 'right',
            // @ts-ignore TS2339
            left: this.props.position == 'left',
          })}
          // @ts-ignore TS2339
          onMouseLeave={!this.props.mobileType ? this.props.onclick : null}
        >
          <div className="modal-title">シェアする</div>
          <div className="share-targets">
            {SHARE_TARGETS.map(target => {
              return <ShareItem key={`share-item-${target.key}`} target={target} parent={this} />;
            })}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default ShareContents;
