import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import _ from 'src/domain/libs/util';

import URLGenerator from '../../../../sketch-platform/ui/routing/URLGenerator';

const SwitchGallery = (props, context) => {
  if (props.sortOrders.length < 2) return null;

  const ch = () => {
    const { history, routeHandler } = context;
    return props.sortOrders.map(sortOrder => {
      if (props.sortOrder == sortOrder) {
        return null;
      }
      if (props.onSortOrderChange) {
        return props.onSortOrderChange(sortOrder);
      }
      delete routeHandler.query.p;
      const options = {
        path: routeHandler.path,
        query: Object.assign({}, routeHandler.query, {
          so: sortOrder,
        }),
      };
      const to = URLGenerator.createRelative(options);
      if (props.onChangeQuery) {
        history.push(to, { norender: true });
        routeHandler.query = options.query;
        props.onChangeQuery(options.query);
      } else {
        history.push(to);
      }
    });
  };

  return (
    <div className="sort-gallery switch">
      <span
        tabIndex={0}
        onKeyDown={e => {
          if (e.which === 13 || e.keyCode === 13) {
            e.preventDefault();
            ch();
          }
        }}
        onClick={e => {
          e.preventDefault();
          ch();
        }}
      >
        <i
          className={classnames('fa', { 'fa-sort-ena': props.sortOrder == 'ena', 'fa-sort': props.sortOrder == 'end' })}
        ></i>
        <span>並べ替え</span>
      </span>
    </div>
  );
};

SwitchGallery.propTypes = {
  sortOrders: PropTypes.array,
};

SwitchGallery.defaultProps = {
  sortOrders: ['ena', 'end'],
};

SwitchGallery.contextTypes = {
  history: PropTypes.object,
  routeHandler: PropTypes.object,
};

export default SwitchGallery;
