import React from 'react';
import ErrorView from '../howto_play/ErrorView';

const PlayerErrorView = props => {
  return (
    <div className="wod-player-error-view">
      <ErrorView {...props} />
    </div>
  );
};

export default PlayerErrorView;
