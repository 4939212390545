import React from 'react';

const Loading = props => {
  return (
    <div className="player-loading">
      <div>
        <div className="loading-children-container">
          <div className="loading-spinner-container w-100 h-100 pos-abs d-flex top-0 bottom-0 left-0 right-0 jc-center ac-center ai-center flx-dir-col opacity-0 opacity-1">
            <div className="pos-rel w-100 overflow-hidden h-12-em d-flex jc-center ai-center min-h-5-rem">
              <div className="loading-spinner"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Loading;
