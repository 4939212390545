import React from 'react';
import classnames from 'classnames';
import _ from 'src/domain/libs/util';
import PropTypes from 'prop-types';
import Button from './Button';

class StepButton extends React.PureComponent {
  static get propTypes() {
    return {
      isHidden: PropTypes.bool,
      classes: PropTypes.object,
      step: PropTypes.number,
      label: PropTypes.string,
      player: PropTypes.object,
      startHover: PropTypes.func,
      stopHover: PropTypes.func,
    };
  }

  static get contextTypes() {
    return {
      getModelData: PropTypes.func,
    };
  }

  constructor(props, context) {
    super(props, context);
    this.handleClick = this.handleClick.bind(this);
    this.onMouseEnter = this.onMouseEnter.bind(this);
    this.onMouseLeave = this.onMouseLeave.bind(this);
  }

  handleClick(e) {
    // @ts-ignore TS2339
    if (this.props.isHidden) {
      return;
    }
    // @ts-ignore TS2339
    if (this.props.onClick) {
      // @ts-ignore TS2339
      this.props.onClick(e);
      if (e.defaultPrevented) {
        return;
      }
    }
    // @ts-ignore TS2339
    if (this.props.releaseFocus) {
      // @ts-ignore TS2339
      this.props.releaseFocus(e);
    }
    // @ts-ignore TS2339
    this.props.player.currentTime(this.props.player.currentTime() + this.props.step);
  }

  onMouseEnter(e) {
    // @ts-ignore TS2339
    if (this.props.isHidden) {
      return;
    }
    // @ts-ignore TS2339
    if (this.props.startHover) {
      // @ts-ignore TS2339
      this.props.startHover(`step-${this.props.step < 0 ? 'back' : 'forward'}-${Math.abs(this.props.step)}`);
    }
  }

  onMouseLeave(e) {
    // @ts-ignore TS2339
    if (this.props.isHidden) {
      return;
    }
    // @ts-ignore TS2339
    if (this.props.stopHover) {
      // @ts-ignore TS2339
      this.props.stopHover('');
    }
  }

  render() {
    // @ts-ignore TS2339
    if (!this.props.player || !this.props.step) return null;
    const props = _.omit(this.props, 'step', 'className', 'classes', 'player', 'isHidden', 'startHover', 'stopHover');
    const browserInfo = this.context.getModelData('browserInfo');
    if (!browserInfo.isIOS && !browserInfo.isAndroid) {
      // @ts-ignore TS2339
      props.onMouseEnter = this.onMouseEnter;
      // @ts-ignore TS2339
      props.onMouseLeave = this.onMouseLeave;
      // @ts-ignore TS2339
      props.onFocus = this.onMouseEnter;
      // @ts-ignore TS2339
      props.onBlur = this.onMouseLeave;
    }
    return (
      <Button
        {...props}
        type="button"
        // @ts-ignore TS2339
        className={classnames('touchable', 'player-control-element', 'player-button-control', this.props.classes, {
          // @ts-ignore TS2339
          disabled: this.props.isHidden,
        })}
        // HACK disabled属性を付与したいが付与すると隣接するコンポーネントでmouseenterイベントなどが発生しなくなるため擬似的に対応
        // https://github.com/facebook/react/issues/19419
        // @ts-ignore TS2339
        tabIndex={this.props.isHidden ? '-1' : '0'}
        onClick={this.handleClick}
        // @ts-ignore TS2339
        aria-label={this.props.label}
      />
    );
  }
}

// export default StepButton;
export default React.forwardRef(function stepButton(props, ref) {
  // @ts-ignore TS2322
  return <StepButton {...props} forwardedRef={ref} />;
});
