import React from 'react';
import GalleryContent from './GalleryContent';

const NewEpisodeAdded = props => {
  return (
    <GalleryContent
      header={
        <div className="gallery-header">
          <div className="title">
            <span className="gallery-title">新着エピソードあり</span>
          </div>
        </div>
      }
      model={props.pathEvaluator || props.model.pathEvaluator}
      keyPrefix={'newepisodeadded'}
    />
  );
};

NewEpisodeAdded.getPrefetchPaths = function(models, options, props) {
  return GalleryContent.getPaths(
    models,
    options,
    Object.assign({}, GalleryContent.defaultProps, props, { keyPrefix: 'newepisodeadded' }),
  );
};

export default NewEpisodeAdded;
