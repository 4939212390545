import cookieDough from 'cookie-dough';
import { NOTIFICATION_KEY } from '../constants/cookie';

const cookie = cookieDough();
const set = function(value, profileId) {
  if (!value) {
    remove(profileId);
    return;
  }
  const key = profileId ? `${NOTIFICATION_KEY}:${profileId}` : NOTIFICATION_KEY;
  if (typeof window !== 'undefined') {
    if (window.localStorage) {
      window.localStorage.setItem(key, JSON.stringify(value));
    } else if (window.navigator.cookieEnabled) {
      cookie.set(key, JSON.stringify(value), { path: '/' });
    }
  }
};
const get = function(profileId) {
  try {
    const key = profileId ? `${NOTIFICATION_KEY}:${profileId}` : NOTIFICATION_KEY;
    if (typeof window !== 'undefined') {
      if (window.localStorage) {
        return JSON.parse(window.localStorage.getItem(key));
      } else if (window.navigator.cookieEnabled) {
        return JSON.parse(cookie.get(key));
      }
    }
  } catch (e) {
    return null;
  }
};
const remove = function(profileId) {
  try {
    const key = profileId ? `${NOTIFICATION_KEY}:${profileId}` : NOTIFICATION_KEY;
    if (typeof window !== 'undefined') {
      if (window.localStorage) {
        // @ts-ignore TS2345
        return JSON.parse(window.localStorage.removeItem(key));
      } else if (window.navigator.cookieEnabled) {
        // @ts-ignore TS2345
        return JSON.parse(cookie.remove(key, { path: '/' }));
      }
    }
  } catch (e) {
    return null;
  }
};
const isNew = function(value, profileId) {
  if (typeof window === 'undefined') return false;
  const time = get(profileId);
  // 保存されていない場合
  if (!time) {
    return true;
    // チェック対象がない場合
  } else if (!value) {
    return false;
    // 比較
  } else if (time < value) {
    return true;
  }
  return false;
};

export default {
  set: set,
  isNew: isNew,
};
