import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Button from './Button';

class ReloadButton extends React.PureComponent {
  static get propTypes() {
    return {
      isHidden: PropTypes.bool,
      onClick: PropTypes.func,
      player: PropTypes.object.isRequired,
      startHover: PropTypes.func,
      stopHover: PropTypes.func,
    };
  }

  static get contextTypes() {
    return {
      getModelData: PropTypes.func,
    };
  }

  constructor(props, context) {
    super(props, context);
    this.onClick = this.onClick.bind(this);
    this.onMouseEnter = this.onMouseEnter.bind(this);
    this.onMouseLeave = this.onMouseLeave.bind(this);
  }

  onClick(e) {
    // @ts-ignore TS2339
    if (this.props.isHidden) {
      return;
    }
    // @ts-ignore TS2339
    if (this.props.onClick) {
      // @ts-ignore TS2339
      this.props.onClick(e);
    }
    // @ts-ignore TS2339
    if (this.props.releaseFocus) {
      // @ts-ignore TS2339
      this.props.releaseFocus(e);
    }
  }

  onMouseEnter(e) {
    // @ts-ignore TS2339
    if (this.props.startHover) {
      // @ts-ignore TS2339
      this.props.startHover('reload');
    }
  }

  onMouseLeave(e) {
    // @ts-ignore TS2339
    if (this.props.stopHover) {
      // @ts-ignore TS2339
      this.props.stopHover('');
    }
  }

  render() {
    // @ts-ignore TS2339
    const props = { forwardedRef: this.props.forwardedRef };
    const browserInfo = this.context.getModelData('browserInfo');
    if (!browserInfo.isIOS && !browserInfo.isAndroid) {
      // @ts-ignore TS2339
      props.onMouseEnter = this.onMouseEnter;
      // @ts-ignore TS2339
      props.onMouseLeave = this.onMouseLeave;
      // @ts-ignore TS2339
      props.onFocus = this.onMouseEnter;
      // @ts-ignore TS2339
      props.onBlur = this.onMouseLeave;
    }
    return (
      <Button
        {...props}
        type="button"
        className={classnames(
          'touchable',
          'player-control-element',
          'player-control-element-blurred',
          'player-button-control',
          'fa',
          'fa-undo-alt',
        )}
        tabIndex="0"
        role="button"
        aria-label={'リロード'}
        onClick={this.onClick}
      ></Button>
    );
  }
}

// export default ReloadButton;
export default React.forwardRef(function reloadButton(props, ref) {
  // @ts-ignore TS2322
  return <ReloadButton {...props} forwardedRef={ref} />;
});
