import { EventEmitter } from 'fbemitter';
import _ from 'src/domain/libs/util';

import playbackRule from '../models/playbackRule';
import { SUBTITLES, CAPTIONS } from '../constants/player';
import tokenDataStore from '../utils/tokenDataStore';
import { TokenApp } from './TokenApp';

class GCastPlayerApp {
  static PLAYER_STATE = {
    IDLE: 'IDLE',
    LOADING: 'LOADING',
    LOADTOKEN: 'LOADTOKEN',
    LOADED: 'LOADED',
    PLAYING: 'PLAYING',
    PAUSED: 'PAUSED',
    STOPPED: 'STOPPED',
    ENDED: 'ENDED',
    UPNEXT: 'UPNEXT',
    ERROR: 'ERROR',
  };

  constructor(options = {}) {
    // @ts-ignore TS2339
    if (window._gcastPlayerApp) {
      // @ts-ignore TS2339
      return window._gcastPlayerApp;
    }
    // @ts-ignore TS2339
    this.tokenApp = new TokenApp();
    // @ts-ignore TS2339
    this._emitter = new EventEmitter();
    // @ts-ignore TS2339
    this._options = options;
    // @ts-ignore TS2339
    this._apiAvailable = false;
    // @ts-ignore TS2339
    this.initialized = false;
    // @ts-ignore TS2551
    this.player = null;
    // @ts-ignore TS2339
    this.playerController = null;
    // @ts-ignore TS2339
    this.playerId = null;
    // @ts-ignore TS2339
    this.activePlayerId = null;
    // NO_DEVICES_AVAILABLE NOT_CONNECTED CONNECTING CONNECTED
    // @ts-ignore TS2551
    this.castState = null;
    // NO_SESSION SESSION_STARTING SESSION_STARTED SESSION_START_FAILED SESSION_ENDING SESSION_ENDED SESSION_RESUMED
    // @ts-ignore TS2339
    this.sessionState = null;
    // @ts-ignore TS2339
    this.isConnected = false;

    // @ts-ignore TS2339
    this._listeners = [];
    // @ts-ignore TS2551
    this._playerState = null;
    // @ts-ignore TS2339
    this._requestParams = null;
    // @ts-ignore TS2551
    this._currentMediaTime = null;
    // @ts-ignore TS2339
    this._savedPlayerState = null;
    // @ts-ignore TS2339
    this.__started = false;
    // @ts-ignore TS2339
    this._sessionResumed = false;
    // @ts-ignore TS2339
    this._sessionEnd = false;
    // @ts-ignore TS2339
    this._metaId = null;
    // @ts-ignore TS2339
    this._lastMediaInfo = null;

    this.messageListener = this.messageListener.bind(this);
    this.setCastState = this.setCastState.bind(this);
    this.eventChangedIsConnected = this.eventChangedIsConnected.bind(this);
    this.eventChangedIsMediaLoaded = this.eventChangedIsMediaLoaded.bind(this);
    this.eventChangedDuration = this.eventChangedDuration.bind(this);
    this.eventChangedCurrentTime = this.eventChangedCurrentTime.bind(this);
    this.eventChangedIsPaused = this.eventChangedIsPaused.bind(this);
    this.eventChangedVolumeLevel = this.eventChangedVolumeLevel.bind(this);
    this.eventChangedCanControlVolume = this.eventChangedCanControlVolume.bind(this);
    this.eventChangedIsMuted = this.eventChangedIsMuted.bind(this);
    this.eventChangedCanPause = this.eventChangedCanPause.bind(this);
    this.eventChangedCanSeek = this.eventChangedCanSeek.bind(this);
    this.eventChangedDisplayName = this.eventChangedDisplayName.bind(this);
    this.eventChangedStatusText = this.eventChangedStatusText.bind(this);
    this.eventChangedTitle = this.eventChangedTitle.bind(this);
    this.eventChangedDisplayStatus = this.eventChangedDisplayStatus.bind(this);
    this.eventChangedMediaInfo = this.eventChangedMediaInfo.bind(this);
    this.eventChangedImageUrl = this.eventChangedImageUrl.bind(this);
    this.eventChangedPlayerState = this.eventChangedPlayerState.bind(this);

    this.init();
  }

  init() {
    // @ts-ignore TS2339
    if (!this._options.googlecast.receiverApplicationId) return;
    // @ts-ignore TS2339
    if (_.get(this._options, 'browserInfo.isIOS')) return;

    // @ts-ignore TS2339
    if ((!window.chrome || !chrome.cast || !chrome.cast.isAvailable) && !this.initialized) {
      // @ts-ignore TS2339
      this.initialized = true;
      // @ts-ignore TS2339
      window.__onGCastApiAvailable = loaded => {
        if (loaded) this.init();
      };
      let scriptTag = document.createElement('script');
      scriptTag.setAttribute('src', '//www.gstatic.com/cv/js/sender/v1/cast_sender.js?loadCastFramework=1');
      document.head.appendChild(scriptTag);
      return;
    }

    // @ts-ignore TS2339
    delete window.__onGCastApiAvailable;
    // @ts-ignore TS2339
    if (!window.chrome || !chrome.cast) {
      return;
    }

    // @ts-ignore TS2339
    this._apiAvailable = true;

    // @ts-ignore TS2339
    let context = window.cast.framework.CastContext.getInstance();
    // @ts-ignore TS2339
    context.addEventListener(window.cast.framework.CastContextEventType.CAST_STATE_CHANGED, event => {
      // console.log('gCast: CastContextEventType.CAST_STATE_CHANGED', event);
      this.setCastState(event.castState);
    });
    // @ts-ignore TS2339
    context.addEventListener(window.cast.framework.CastContextEventType.SESSION_STATE_CHANGED, event => {
      // console.log('gCast: CastContextEventType.SESSION_STATE_CHANGED', event);
      // @ts-ignore TS2339
      if (event.sessionState == 'SESSION_ENDED') this._sessionEnd = true;
      // @ts-ignore TS2339
      this.sessionState = event.sessionState;
    });

    // Init cast
    context.setOptions({
      // @ts-ignore TS2339
      receiverApplicationId: this._options.googlecast.receiverApplicationId,
      // @ts-ignore TS2304
      autoJoinPolicy: chrome.cast.AutoJoinPolicy.ORIGIN_SCOPED,
    });

    // Init player controller
    // @ts-ignore TS2551
    this.player = new window.cast.framework.RemotePlayer();
    // @ts-ignore TS2339
    this.playerController = new window.cast.framework.RemotePlayerController(this.player);

    // @ts-ignore TS2339
    this.playerController.addEventListener(window.cast.framework.RemotePlayerEventType.ANY_CHANGE, event => {
      // console.log('gCast: RemotePlayerEvent.ANY_CHANGE', event);
      switch (event.field) {
        case 'isConnected':
          this.eventChangedIsConnected(event);
          break;
        case 'isMediaLoaded':
          this.eventChangedIsMediaLoaded(event);
          break;
        case 'currentTime':
          this.eventChangedCurrentTime(event);
          break;
        case 'volumeLevel':
          this.eventChangedVolumeLevel(event);
          break;
        case 'isMuted':
          this.eventChangedIsMuted(event);
          break;
        case 'mediaInfo':
          this.eventChangedMediaInfo(event);
          break;
        case 'playerState':
          this.eventChangedPlayerState(event);
          break;
        case 'savedPlayerState':
          // @ts-ignore TS2339
          this._savedPlayerState = event.value;
          break;
      }
    });
  }

  messageListener(ns, event) {
    if (typeof event === 'string') {
      event = JSON.parse(event);
    }
    // console.log('gCast: IPC message', ns, event);

    switch (event.type) {
      case 'PLAYER_STATE':
        // console.log('gCast: IPC message', ns, event);
        switch (event.data) {
          // receiver側でendedがコールされたタイミング
          case 'ended':
            // @ts-ignore TS2551
            this._playerState = GCastPlayerApp.PLAYER_STATE.ENDED;
            if (this.isActive()) {
              // @ts-ignore TS2339
              this._emitter.emit('ended');
            }
            break;
          // receiver側で連続再生の処理を開始したタイミング
          case 'upnext':
            // @ts-ignore TS2551
            this._playerState = GCastPlayerApp.PLAYER_STATE.UPNEXT;
            // 閲覧しているページが同じメタの場合のみ
            // @ts-ignore TS2339
            if (this._metaId == this._lastMediaInfo.playbackData.metaId) {
              // @ts-ignore TS2339
              this._emitter.emit('upnext');
              // @ts-ignore TS2339
              this.__started = false;
            }
            // @ts-ignore TS2339
            this.isMediaLoaded = false;
            break;
          // receiver側でloadのtoken処理が終了したタイミング
          case 'loadtoken':
            // @ts-ignore TS2551
            this._playerState = GCastPlayerApp.PLAYER_STATE.LOADTOKEN;
            break;
          // receiver側でdisposeのSessionClose処理が終わったタイミング
          case 'dispose_session_close':
            // @ts-ignore TS2339
            this.isDisposeSessionClosed = true;
            break;
        }
        break;
      case 'MEDIA_DATA':
        // console.log('gCast: MEDIA_DATA', event.data);
        if (event.data && !event.data.playbackData) return;

        if (
          // @ts-ignore TS2339
          this._lastMediaInfo &&
          // @ts-ignore TS2339
          this._lastMediaInfo.playbackData &&
          // @ts-ignore TS2339
          this._lastMediaInfo.playbackData.metaId !== event.data.playbackData.metaId
        ) {
          // @ts-ignore TS2339
          this.isMediaLoaded = true;
        }

        // playerIdを復元
        if (
          // @ts-ignore TS2339
          !this.playerId &&
          // @ts-ignore TS2339
          this._lastMediaInfo &&
          // @ts-ignore TS2339
          this._requestParams &&
          // @ts-ignore TS2339
          this._metaId == event.data.playbackData.metaId &&
          // @ts-ignore TS2339
          this._requestParams.appId == event.data.playbackData.appId
        ) {
          // @ts-ignore TS2551
          this._playerState = GCastPlayerApp.PLAYER_STATE.LOADTOKEN;
          // @ts-ignore TS2339
          this.playerId = event.data.playbackData.playerId;
        }

        // @ts-ignore TS2339
        const beforeMediaInfo = this._lastMediaInfo;
        // @ts-ignore TS2339
        this._lastMediaInfo = event.data;

        // 設定の値が変わったら通知
        // @ts-ignore TS2554
        if (!_.isEqual(this.getSettings(beforeMediaInfo), this.getSettings())) {
          // @ts-ignore TS2339
          this._emitter.emit('gCastChangeSettings');
        }
        break;
      case 'SEEKABLE':
        // console.log('gCast: SEEKABLE', event.data);
        // @ts-ignore TS2551
        this._seekable = event.data;
        break;
      case 'TOKEN_REFRESHED':
        // console.log('gCast: TOKEN_REFRESHED', event.data);
        // @ts-ignore TS2339
        this.tokenApp.tokenRefresh();
        break;
      case 'ERROR':
        console.error('gCast: ERROR', event.data);
        if (event.data === 'LEAVE') {
          // @ts-ignore TS2551
          this._playerState = GCastPlayerApp.PLAYER_STATE.PAUSED;
          // @ts-ignore TS2339
          this._emitter.emit('pause');
        } else {
          // @ts-ignore TS2551
          this._playerState = GCastPlayerApp.PLAYER_STATE.ERROR;
          // @ts-ignore TS2339
          this._emitter.emit('gCastError', event.data);
        }
        break;
    }
  }

  eventChangedIsConnected(event) {
    if (event.value) {
      // @ts-ignore TS2339
      this._emitter.emit('pause');
      // @ts-ignore TS2339
      this.isConnected = event.value;
      // @ts-ignore TS2339
      this.session = window.cast.framework.CastContext.getInstance().getCurrentSession();
      // @ts-ignore TS2339
      this.session.addMessageListener(this._options.googlecast.namespace, this.messageListener);

      // sessionが存在する場合
      // @ts-ignore TS2339
      if (this.sessionState == 'SESSION_RESUMED') {
        // @ts-ignore TS2339
        this._sessionResumed = true;
        // キャスト中にキャストした場合
        // @ts-ignore TS2339
        if (this._sessionEnd) {
          // @ts-ignore TS2339
          this._sessionEnd = false;
          // @ts-ignore TS2339
          this._emitter.emit('gCastStart');
        }
        return;
      }
      // @ts-ignore TS2339
      this._emitter.emit('gCastStart');
    } else {
      // @ts-ignore TS2339
      this.isConnected = event.value;
      const option = {};
      // コネクションが切れた時にreceiver側でdisposeの処理が検知できていなかった場合
      // @ts-ignore TS2339
      if (!this.isDisposeSessionClosed && this._lastMediaInfo && this._lastMediaInfo.playbackData.playbackSessionId) {
        // @ts-ignore TS2339
        option.playbackSessionId = this._lastMediaInfo.playbackData.playbackSessionId;
      }
      // @ts-ignore TS2339
      if (this._lastMediaInfo && this._metaId == this._lastMediaInfo.playbackData.metaId) {
        // @ts-ignore TS2339
        option.currentTime = this._currentMediaTime;
      }
      // @ts-ignore TS2339
      this._emitter.emit('gCastEnd', option);
      // @ts-ignore TS2339
      this.isDisposeSessionClosed = false;
    }
  }

  eventChangedIsMediaLoaded(event) {
    if (
      // @ts-ignore TS2551
      this._playerState !== GCastPlayerApp.PLAYER_STATE.ENDED &&
      // @ts-ignore TS2551
      this._playerState !== GCastPlayerApp.PLAYER_STATE.UPNEXT &&
      // @ts-ignore TS2339
      this.isMediaLoaded &&
      !event.value
    ) {
      // @ts-ignore TS2339
      this._emitter.emit('idle');
    }
    // @ts-ignore TS2339
    this.isMediaLoaded = event.value;
  }

  eventChangedDuration(event) {}

  eventChangedCurrentTime(event) {
    // @ts-ignore TS2551
    if (this._playerState === GCastPlayerApp.PLAYER_STATE.ENDED) return;
    // @ts-ignore TS2551
    if (this._playerState === GCastPlayerApp.PLAYER_STATE.LOADTOKEN) {
      // @ts-ignore TS2551
      this._playerState = GCastPlayerApp.PLAYER_STATE.PLAYING;
      // @ts-ignore TS2339
      this._emitter.emit('firstplay');
      // @ts-ignore TS2339
      this._emitter.emit('playing');
    }
    if (this.isActive()) {
      // @ts-ignore TS2551
      this._currentMediaTime = event.value;
      // @ts-ignore TS2339
      this._emitter.emit('timeupdate', event.value);
    }
  }

  eventChangedIsPaused(event) {}

  eventChangedVolumeLevel(event) {
    if (this.isActive()) {
      // @ts-ignore TS2551
      this._volume = event.value;
      // @ts-ignore TS2339
      this._emitter.emit('volumechange', event.value);
    }
  }

  eventChangedCanControlVolume(event) {}

  eventChangedIsMuted(event) {
    if (this.isActive()) {
      // @ts-ignore TS2551
      this._muted = event.value;
      // @ts-ignore TS2339
      this._emitter.emit('muted', event.value);
    }
  }

  eventChangedCanPause(event) {}
  eventChangedCanSeek(event) {}
  eventChangedDisplayName(event) {}
  eventChangedStatusText(event) {}
  eventChangedTitle(event) {}
  eventChangedDisplayStatus(event) {}

  eventChangedMediaInfo(event) {
    if (event.value) {
      // 受け取るmediaの情報が更新されない
      //this._lastMediaInfo = event.value;
      this.requestMediaData();
    }
  }

  eventChangedImageUrl(event) {}

  eventChangedPlayerState(event) {
    // 初期化
    // @ts-ignore TS2551
    if (!this._playerState) this._playerState = event.value;

    if (!this.isActive()) return;

    switch (event.value) {
      case 'IDLE':
        // @ts-ignore TS2339
        this._emitter.emit('idle');
        break;
      case 'PLAYING':
        // @ts-ignore TS2551
        this._playerState = GCastPlayerApp.PLAYER_STATE.PLAYING;
        this.play();
        // @ts-ignore TS2339
        if (!this.__started) {
          // @ts-ignore TS2339
          this.__started = true;
          // @ts-ignore TS2339
          this._emitter.emit('firstplay');
        }
        // @ts-ignore TS2339
        this._emitter.emit('play');
        // @ts-ignore TS2339
        this._emitter.emit('playing');
        break;
      case 'PAUSED':
        this.pause();
        // @ts-ignore TS2339
        this._emitter.emit('pause');
        break;
      case 'BUFFERING':
        // @ts-ignore TS2551
        if (this._playerState === GCastPlayerApp.PLAYER_STATE.PAUSED) return;
        // @ts-ignore TS2339
        this._emitter.emit('rebuffering');
        break;
    }
  }

  requestMediaData() {
    // @ts-ignore TS2339
    this.session
      // @ts-ignore TS2339
      .sendMessage(this._options.googlecast.namespace, JSON.stringify({ type: 'MEDIA_DATA', data: null }))
      .then(
        () => {
          // console.log('gCast: IPC success');
        },
        error => {
          // console.log('gCast: IPC error: ', error);
        },
      );
  }

  setMetaId(metaId) {
    // @ts-ignore TS2339
    this._metaId = metaId;
  }

  setCastState(castState) {
    // @ts-ignore TS2551
    this.castState = castState;
    // @ts-ignore TS2339
    this._emitter.emit('gCastConnectedChange', this.isCastConnected());
  }

  playerState() {
    // @ts-ignore TS2551
    return this._playerState;
  }

  isCastConnected() {
    // @ts-ignore TS2551
    return this.castState == 'NOT_CONNECTED' || this.castState == 'CONNECTING' || this.castState == 'CONNECTED';
  }

  isActive() {
    // @ts-ignore TS2339
    if (this._lastMediaInfo) {
      // @ts-ignore TS2339
      return this.playerId == this._lastMediaInfo.playbackData.playerId && this.isMediaLoaded;
    } else {
      // @ts-ignore TS2339
      return this.playerId && this.isMediaLoaded;
    }
  }

  getFriendlyName() {
    // @ts-ignore TS2339
    if (this.session) {
      // @ts-ignore TS2339
      const receiver = this.session.getCastDevice();
      return receiver.friendlyName;
    }
  }

  addEmitterListener(eventName, fn) {
    if (eventName == 'sendEvent') {
      // @ts-ignore TS2554
      this.removeEmitterListener(eventName);
    }
    // @ts-ignore TS2339
    this._listeners.push({
      // @ts-ignore TS2339
      subscription: this._emitter.addListener(eventName, fn),
      eventName: eventName,
      fn: fn,
    });
  }

  removeEmitterListener(eventName, fn) {
    // @ts-ignore TS2339
    let index = _.findIndex(this._listeners, listener => {
      if (fn) {
        // @ts-ignore TS2339
        return listener.eventName == eventName && listener.fn == fn;
      } else {
        // @ts-ignore TS2339
        return listener.eventName == eventName;
      }
    });
    if (index !== -1) {
      // @ts-ignore TS2339
      this._listeners[index].subscription.remove();
      // @ts-ignore TS2339
      this._listeners.splice(index, 1);
    }
  }

  // playerApp interface

  on(eventName, fn) {
    //console.log('on', eventName);
    this.addEmitterListener(eventName, fn);
  }

  off(eventName, fn) {
    //console.log('off', eventName);
    this.removeEmitterListener(eventName, fn);
  }

  seekable() {
    // console.log('GCastPlayer.seekable');
    return {
      start: () => {
        // @ts-ignore TS2551
        if (this._seekable) return this._seekable.start;
        return 0;
      },
      end: () => {
        // @ts-ignore TS2551
        if (this._seekable) return this._seekable.end;
        return 0;
      },
    };
  }

  currentTime(currentTime) {
    if (!this.isActive()) return 0;

    //console.log('GCastPlayer.currentTime', currentTime);
    if (currentTime === undefined) {
      // @ts-ignore TS2551
      if (this._playerState == GCastPlayerApp.PLAYER_STATE.ENDED) return this.duration();
      // @ts-ignore TS2551
      return this.player.currentTime;
    } else {
      // @ts-ignore TS2551
      this.player.currentTime = currentTime;
      // @ts-ignore TS2339
      this.playerController.seek();
    }
  }

  duration() {
    if (!this.isActive()) return 0;

    //console.log('GCastPlayer.duration', this.playerHandler.getMediaDuration());
    // @ts-ignore TS2339
    if (this._lastMediaInfo && this._lastMediaInfo.streamType === 'LIVE' && this.player.duration < 0) {
      return Infinity;
    }
    // @ts-ignore TS2551
    return this.player.duration;
  }

  volume(volume) {
    if (!this.isActive()) return 0;

    //console.log('GCastPlayer.volume', volume);
    if (volume) {
      // @ts-ignore TS2551
      this.player.volumeLevel = volume * 1;
      // @ts-ignore TS2339
      this.playerController.setVolumeLevel();
    } else {
      // @ts-ignore TS2551
      return this.player.volumeLevel;
    }
  }

  muted(muted) {
    if (!this.isActive()) return null;

    //console.log('GCastPlayer.muted', muted);
    if (muted) {
      // @ts-ignore TS2551
      if (!this.player.isMuted) {
        // @ts-ignore TS2339
        this.playerController.muteOrUnmute();
      }
    } else {
      // @ts-ignore TS2551
      if (this.player.isMuted) {
        // @ts-ignore TS2339
        this.playerController.muteOrUnmute();
      }
    }
  }

  playbackRate(rate) {
    //console.log('GCastPlayer.playbackRate', rate);
    // @ts-ignore TS2554
    const settings = this.getSettings();
    if (rate) {
      settings.playbackRate = rate;
      this.settings(settings);
    } else {
      return settings.playbackRate;
    }
  }

  buffered() {
    //console.log('GCastPlayer.buffered');
  }

  play() {
    // console.log('GCastPlayer.play', this._playerState);
    if (
      // @ts-ignore TS2551
      this._playerState !== GCastPlayerApp.PLAYER_STATE.PLAYING &&
      // @ts-ignore TS2551
      this._playerState !== GCastPlayerApp.PLAYER_STATE.PAUSED &&
      // @ts-ignore TS2551
      this._playerState !== GCastPlayerApp.PLAYER_STATE.LOADTOKEN &&
      // @ts-ignore TS2551
      this._playerState !== GCastPlayerApp.PLAYER_STATE.LOADED &&
      // @ts-ignore TS2551
      this._playerState !== GCastPlayerApp.PLAYER_STATE.ENDED &&
      // @ts-ignore TS2551
      this._playerState !== GCastPlayerApp.PLAYER_STATE.UPNEXT
    ) {
      this.load();
      return;
    }
    // @ts-ignore TS2551
    if (this.player.isPaused || this._playerState === GCastPlayerApp.PLAYER_STATE.PAUSED) {
      // @ts-ignore TS2339
      this.playerController.playOrPause();
    }
    // @ts-ignore TS2551
    this._playerState = GCastPlayerApp.PLAYER_STATE.PLAYING;
  }

  pause() {
    // console.log('GCastPlayer.pause', this._playerState);
    // @ts-ignore TS2551
    if (this._playerState !== GCastPlayerApp.PLAYER_STATE.PLAYING) {
      return;
    }
    // @ts-ignore TS2551
    if (!this.player.isPaused) {
      // @ts-ignore TS2339
      this.playerController.playOrPause();
    }
    // @ts-ignore TS2551
    this._playerState = GCastPlayerApp.PLAYER_STATE.PAUSED;
  }

  paused() {
    // console.log('GCastPlayer.paused', this.player.isPaused);
    // @ts-ignore TS2551
    if (this.player) return this.player.isPaused;
    // @ts-ignore TS2551
    return this._playerState === GCastPlayerApp.PLAYER_STATE.PAUSED;
  }

  qualityLevels() {
    //console.log('GCastPlayer.qualityLevels');
  }

  reload() {
    // @ts-ignore TS2551
    this._currentMediaTime = 0;
    // @ts-ignore TS2339
    this._emitter.emit('gCastStart');
  }

  // origin

  stop() {
    //console.log('GCastPlayer.stop');
    // @ts-ignore TS2339
    this.playerController.stop();
    this.pause();
    // @ts-ignore TS2551
    this._playerState = GCastPlayerApp.PLAYER_STATE.STOPPED;
  }

  load() {
    // console.log('GCastPlayer.load');
    // @ts-ignore TS2551
    this._playerState = GCastPlayerApp.PLAYER_STATE.LOADING;
    // @ts-ignore TS2304
    let mediaInfo = new chrome.cast.media.MediaInfo(null, null);
    // console.log('GCastPlayer.requestParams', this._requestParams);
    // @ts-ignore TS2339
    mediaInfo.customData = this._requestParams;
    // @ts-ignore TS2304
    let request = new chrome.cast.media.LoadRequest(mediaInfo);
    // @ts-ignore TS2339
    this.session.loadMedia(request).then(
      () => {
        // @ts-ignore TS2339
        const media = this.session.getMediaSession();
        media.addUpdateListener(() => {
          if (media.playerState == 'IDLE' && media.idleReason == 'FINISHED') {
          }
        });
        this.loaded();
      },
      errorCode => {
        //console.log('errorCode', errorCode);
      },
    );
  }

  loaded() {
    // console.log('GCastPlayer.loaded');
    // @ts-ignore TS2339
    this._lastMediaInfo = null;
    // @ts-ignore TS2551
    this._currentMediaDuration = this.getMediaDuration();
    // @ts-ignore TS2551
    this._playerState = GCastPlayerApp.PLAYER_STATE.LOADED;
    // @ts-ignore TS2339
    this.__started = false;
    this.play();
  }

  getCurrentMediaTime() {
    //console.log('GCastPlayer.getCurrentMediaTime');
    // @ts-ignore TS2551
    return this.player.currentTime;
  }

  getMediaDuration() {
    //console.log('GCastPlayer.getMediaDuration');
    // @ts-ignore TS2551
    return this.player.duration;
  }

  mute() {
    //console.log('GCastPlayer.mute');
    // @ts-ignore TS2551
    if (!this.player.isMuted) {
      // @ts-ignore TS2339
      this.playerController.muteOrUnmute();
    }
  }

  unMute() {
    //console.log('GCastPlayer.unMute');
    // @ts-ignore TS2551
    if (this.player.isMuted) {
      // @ts-ignore TS2339
      this.playerController.muteOrUnmute();
    }
  }

  isMuted() {
    //console.log('GCastPlayer.isMuted');
    // @ts-ignore TS2551
    return this.player.isMuted;
  }

  seekTo(time) {
    //console.log('GCastPlayer.seekTo', time);
    // @ts-ignore TS2551
    this.player.currentTime = time;
    // @ts-ignore TS2339
    this.playerController.seek();
  }

  seekPreview() {
    // @ts-ignore TS2339
    const urls = _.get(this._lastMediaInfo, 'playbackData.seekPreview.urls', []);
    if (urls.length > 0) {
      // @ts-ignore TS2339
      return this._lastMediaInfo.playbackData.seekPreview;
    }
  }

  getSeekThumbnailTrack() {
    // @ts-ignore TS2339
    return _.find(_.get(this._lastMediaInfo, 'playbackData.tracks'), track => track.kind === 'thumbnails');
  }

  playbackRule() {
    // @ts-ignore TS2339
    if (_.get(this._lastMediaInfo, 'playbackData.playbackRule')) {
      // @ts-ignore TS2339
      return playbackRule(this._lastMediaInfo.playbackData.playbackRule);
    }
  }

  textTracks() {
    // console.log(_.get(this._lastMediaInfo, 'playbackData.tracks'))
    // @ts-ignore TS2339
    return _.get(this._lastMediaInfo, 'playbackData.textTracks');
  }

  audioTracks() {
    // @ts-ignore TS2339
    return _.get(this._lastMediaInfo, 'playbackData.audioTracks');
  }

  playbackData() {
    // @ts-ignore TS2339
    return _.get(this._lastMediaInfo, 'playbackData');
  }

  getSettings(playbackData) {
    if (!playbackData) playbackData = this.playbackData();
    return _.pick(playbackData, ['locale', 'autoAdvance', 'playbackRate', 'subtitleLanguage', 'audioLanguage']);
  }

  settings(settings) {
    if (settings === undefined) {
      // @ts-ignore TS2554
      return this.getSettings();
    }
    // @ts-ignore TS2339
    this.session
      // @ts-ignore TS2339
      .sendMessage(this._options.googlecast.namespace, JSON.stringify({ type: 'SETTING', data: settings }))
      .then(
        () => {
          this.requestMediaData();
          //console.log('gCast: IPC success');
        },
        error => {
          //console.log('gCast: IPC error: ', error);
        },
      );
  }

  castStart() {
    //console.log('GCastPlayer.castStart');
    // @ts-ignore TS2551
    this._playerState = GCastPlayerApp.PLAYER_STATE.IDLE;
    // @ts-ignore TS2339
    if (window.cast && window.cast.framework) {
      // @ts-ignore TS2551
      if (this.player.isConnected) {
        // Setup remote player volume right on setup
        // The remote player may have had a volume set from previous playback
        // @ts-ignore TS2551
        if (this.player.isMuted) {
          this.mute();
        }
        this.play();
      }
    }
  }

  getErrorMessage(error) {
    if (!error) return '';

    switch (error.code) {
      // @ts-ignore TS2304
      case chrome.cast.ErrorCode.API_NOT_INITIALIZED:
        return 'The API is not initialized.' + (error.description ? ' :' + error.description : '');
      // @ts-ignore TS2304
      case chrome.cast.ErrorCode.CANCEL:
        return 'The operation was canceled by the user' + (error.description ? ' :' + error.description : '');
      // @ts-ignore TS2304
      case chrome.cast.ErrorCode.CHANNEL_ERROR:
        return 'A channel to the receiver is not available.' + (error.description ? ' :' + error.description : '');
      // @ts-ignore TS2304
      case chrome.cast.ErrorCode.EXTENSION_MISSING:
        return 'The Cast extension is not available.' + (error.description ? ' :' + error.description : '');
      // @ts-ignore TS2304
      case chrome.cast.ErrorCode.INVALID_PARAMETER:
        return 'The parameters to the operation were not valid.' + (error.description ? ' :' + error.description : '');
      // @ts-ignore TS2304
      case chrome.cast.ErrorCode.RECEIVER_UNAVAILABLE:
        return (
          'No receiver was compatible with the session request.' + (error.description ? ' :' + error.description : '')
        );
      // @ts-ignore TS2304
      case chrome.cast.ErrorCode.SESSION_ERROR:
        return (
          'A session could not be created, or a session was invalid.' +
          (error.description ? ' :' + error.description : '')
        );
      // @ts-ignore TS2304
      case chrome.cast.ErrorCode.TIMEOUT:
        return 'The operation timed out.' + (error.description ? ' :' + error.description : '');
    }
  }
}

// @ts-ignore TS2339
GCastPlayerApp.getInstance = function(options) {
  if (typeof window === 'undefined') return null;

  // @ts-ignore TS2339
  if (!window._gcastPlayerApp) {
    // @ts-ignore TS2339
    window._gcastPlayerApp = new GCastPlayerApp(options);
  }
  // @ts-ignore TS2339
  return window._gcastPlayerApp;
};

export default GCastPlayerApp;
