import React, { useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'src/domain/libs/util';

import GenreContent, { type as GenreType } from './GenreContent';
import { NotfoundError } from '../../../../constants/error';
import { NotFoundError as NFErr } from '../../../common/components/ErrorBoundary';

const type = 'genreRef';

const GenreRef = props => {
  const model = (props.pathEvaluator || props.model.pathEvaluator).batch(100);
  const rootPath = GenreRef.getRootPath(null, null, props);
  const _item = model.getSync(rootPath) || {};
  const [item, setItem] = useState(_item);
  if (!item || _.isEmpty(item)) {
    console.log('ここ');
    // @ts-ignore TS2554
    throw new NFErr();
  }
  // const newProps = Object.assign({}, props, {id: item.id, model});
  const newProps = Object.assign({}, props, { id: item.id });
  return <GenreContent {...newProps} />;
};
GenreRef.getRootPath = function(models, options, props) {
  if (props.id) {
    return [type, props.id];
  }
  return [];
};
GenreRef.getPrefetchPaths = function(models, options, props) {
  props = Object.assign({ type }, props);
  return GenreContent.getPrefetchPaths(models, options, props);
};
GenreRef.getPrefetchedPaths = function(models, options, props) {
  return data => {
    let paths = [];
    const pathProps = { ...props };
    const rootPath = this.getRootPath(models, options, props);
    const genre = _.get(data.json, rootPath) || {};
    if (genre.id) {
      _.set(data, `json.${GenreType}.${genre.id}`, genre);
      pathProps.id = genre.id;
      paths = paths.concat(GenreContent.getPrefetchedPaths(models, options, pathProps)(data));
    }
    return paths;
  };
};
GenreRef.afterPrefetch = function(models, options, props) {
  return data => {
    const rootPath = this.getRootPath(models, options, props);
    const genre = _.get(data.json, rootPath) || {};
    const pathProps = { ...props };
    if (genre.id) {
      _.set(data, `json.${GenreType}.${genre.id}`, genre);
      pathProps.id = genre.id;
    }
    return GenreContent.afterPrefetch(models, options, pathProps)(data);
  };
};
export default GenreRef;
