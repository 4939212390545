import React from 'react';

const Duration = props => {
  let text;
  if (!!props.seasonCount) {
    text = `${props.seasonCount}シーズン`;
  } else if (!!props.episodeCount) {
    text = `${props.episodeCount}エピソード`;
  } else if (!!props.episodeRuntime) {
    const episodeRuntime = parseInt(props.episodeRuntime, 10);
    // 時間に合わせる
    let seconds = episodeRuntime % 60;
    let minutes = ((episodeRuntime - seconds) / 60) % 60;
    // @ts-ignore TS2345
    let hour = parseInt(episodeRuntime / 3600, 10);
    if (hour > 0) {
      if (minutes > 0) {
        text = `${hour}時間${minutes}分`;
      } else {
        text = `${hour}時間`;
      }
    } else if (minutes > 0) {
      text = `${minutes}分`;
    } else {
      text = `${seconds}秒`;
    }
  }
  if (!text) return null;

  return <span className="duration">{text}</span>;
};

export default Duration;
