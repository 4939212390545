import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'src/domain/libs/util';
import classnames from 'classnames';

export default class Input extends Component {
  static get propTypes() {
    return {
      required: PropTypes.bool,
      disabled: PropTypes.bool,
      label: PropTypes.string,
      maxLength: PropTypes.number,
      type: PropTypes.string,
      placeholder: PropTypes.string,
      value: PropTypes.string,
    };
  }
  static get defaultProps() {
    return {
      value: '',
    };
  }

  constructor(props, context) {
    super(props, context);

    // @ts-ignore TS2339
    this.inputRef = React.createRef();
    // @ts-ignore TS2339
    this.countRef = React.createRef();
    this.textCount = this.textCount.bind(this);

    this.state = {
      errorMessage: null,
    };
  }

  textCount(e) {
    let value = e.currentTarget.value;
    // @ts-ignore TS2339
    this.countRef.current.innerText = value.length;

    // @ts-ignore TS2339
    if (value.length >= this.props.maxLength) {
      // @ts-ignore TS2339
      this.inputRef.current.style.color = '#2CB5FF';
      // @ts-ignore TS2339
      this.countRef.current.style.color = '#2CB5FF';
    } else {
      // @ts-ignore TS2339
      this.inputRef.current.style = '';
      // @ts-ignore TS2339
      this.countRef.current.style = '';
    }
  }

  render() {
    // @ts-ignore TS2339
    if (this.props.required && !!this.inputRef.current) {
      // @ts-ignore TS2339
      this.inputRef.current.required = true;
    }
    // @ts-ignore TS2339
    if (this.props.disabled && !!this.inputRef.current) {
      // @ts-ignore TS2339
      this.inputRef.current.disabled = true;
    }
    return (
      <div className="input-block">
        <label>
          {/*
           // @ts-ignore TS2339 */}
          {this.props.label}
          {/*
           // @ts-ignore TS2339 */}
          {this.props.required ? <span className="required">必須</span> : null}
          <div className="count">
            {/*
             // @ts-ignore TS2339 */}
            <span ref={this.countRef}>{this.props.value.length > 0 ? this.props.value.length : 0}</span>/
            {/*
             // @ts-ignore TS2339 */}
            {this.props.maxLength}
          </div>
        </label>
        <input
          // @ts-ignore TS2339
          ref={this.inputRef}
          // @ts-ignore TS2339
          type={this.props.type}
          className="text-input"
          // @ts-ignore TS2339
          placeholder={this.props.placeholder}
          // @ts-ignore TS2339
          maxLength={this.props.maxLength}
          onKeyUp={this.textCount}
          // @ts-ignore TS2339
          onChange={this.props.onChange}
          // @ts-ignore TS2339
          onKeyDown={this.props.onKeyDown}
          // @ts-ignore TS2339
          value={this.props.value}
        />
        {/*
         // @ts-ignore TS2339 */}
        {this.props.errorMessage ? (
          <div className="error-wrapper clearfix">
            {/*
             // @ts-ignore TS2339 */}
            <div className="error">{this.props.errorMessage}</div>
          </div>
        ) : null}
      </div>
    );
  }
}
