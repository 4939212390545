import React from 'react';
import _ from 'src/domain/libs/util';

class HtmlSnippet extends React.Component {
  static getPaths = function(models, options, props = {}) {
    let rootPath = this.getRootPath(models, options, props);
    return [rootPath.concat([['id', 'content', 'javascript', 'style']])];
  };

  static getRootPath = function(models, options, props = {}) {
    // @ts-ignore TS2339
    if (props.snippetId) {
      // @ts-ignore TS2339
      return ['html_snippet', props.snippetId];
    }
    return [];
  };

  htmlSnippet: any;
  constructor(props, context) {
    super(props, context);
    // @ts-ignore TS2339
    const rootPath = this.constructor.getRootPath(context.models, {}, props);
    this.htmlSnippet = props.model.getSync(rootPath);
    this.execSnippet = this.execSnippet.bind(this);
    // @ts-ignore TS2339
    this.htmlSnippetRef = React.createRef();
    this.state = {
      fetchDataError: null,
      generation: props.model.getVersion(rootPath),
    };
  }

  componentDidMount() {
    // @ts-ignore TS2339
    this._isMounted = true;
    this.fetchData(this.props, this.context);
  }

  componentWillReceiveProps(nextProps) {
    // @ts-ignore TS2339
    if (this.props.snippetId != nextProps.snippetId) {
      this.fetchData(nextProps, this.context);
    }
  }

  componentWillUnmount() {
    // @ts-ignore TS2339
    this._isMounted = false;
    // @ts-ignore TS2339
    if (this.state.dispose) {
      // @ts-ignore TS2339
      this.state.dispose();
    }
    // @ts-ignore TS2339
    if (this.__$promise && this.__$promise.dispose) {
      // @ts-ignore TS2339
      this.__$promise.dispose();
    }

    if (!this.htmlSnippet) return;
    // @ts-ignore TS2339
    _.pull(window.prvExecutedScripts, this.htmlSnippet.id);
  }

  execSnippet() {
    if (!this.htmlSnippet.javascript) return;
    // @ts-ignore TS2339
    if (typeof window.prvExecutedScripts == 'undefined') {
      // @ts-ignore TS2339
      window.prvExecutedScripts = [];
    }
    // @ts-ignore TS2339
    if (window.prvExecutedScripts.indexOf(this.htmlSnippet.id) != -1) return;
    const script = `(function () {
        try {
          ${this.htmlSnippet.javascript}
          window.prvExecutedScripts.push(${this.htmlSnippet.id});
        } catch (e) {
          console.error(e);
        }
      }());`;
    const scriptTag = document.createElement('script');
    scriptTag.innerHTML = script;
    // @ts-ignore TS2339
    const el = this.htmlSnippetRef.current;
    el.appendChild(scriptTag);
  }

  render() {
    if (!this.htmlSnippet) {
      return null;
    }
    let html = '';
    if (_.get(this, 'htmlSnippet.style')) {
      html = '<style>' + this.htmlSnippet.style + '</style>';
    }
    html += _.get(this, 'htmlSnippet.content') || '';
    if (_.get(this, 'htmlSnippet.javascript')) {
      html += `<script>
          (function () {
            try {
              ${this.htmlSnippet.javascript}
              if(typeof window.prvExecutedScripts == 'undefined'){
                window.prvExecutedScripts = [];
              }
              window.prvExecutedScripts.push(${this.htmlSnippet.id});
            } catch (e) {
              console.error(e);
            }
          }());
        </script>`;
    }

    // @ts-ignore TS2339
    return <div className="snippet" ref={this.htmlSnippetRef} dangerouslySetInnerHTML={{ __html: html }} />;
  }

  fetchData(props, context) {
    // @ts-ignore TS2339
    const paths = this.constructor.getPaths(context.models, {}, props);
    // @ts-ignore TS2339
    const rootPath = this.constructor.getRootPath(context.models, {}, props);
    const evaluator = props.model.fetch(paths);
    // @ts-ignore TS2339
    this.state.dispose = evaluator.dispose;
    evaluator
      .then(res => {
        this.htmlSnippet = _.get(res.json, rootPath);
        if (_.isEmpty(this.htmlSnippet.content) && props.noContents) props.noContents(props.snippetId);
        const newState = {
          fetchDataError: null,
          dispose: null,
          generation: props.model.getVersion(rootPath),
        };
        // @ts-ignore TS2339
        if (this._isMounted)
          this.setState(newState, () => {
            this.execSnippet();
          });
        else Object.assign(this.state, newState);
      })
      .catch(e => {
        const newState = {
          fetchDataError: e,
          dispose: null,
        };
        // @ts-ignore TS2339
        if (this._isMounted) this.setState(newState);
        else Object.assign(this.state, newState);
      });
  }
}

export default HtmlSnippet;
