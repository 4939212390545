import React from 'react';
import PropTypes from 'prop-types';
import _ from 'src/domain/libs/util';

import activeProfile, { showNotTrial, canPlayBroadcast } from '../../../../../utils/activeProfile';
import MainViewLink from '../../../../common/components/MainViewLink';
import routes from '../../../../common/routes';

const Tags = (props, context) => {
  if (props.isSuspend) return null;
  // @ts-ignore TS2554
  const profile = activeProfile(context.models) || {};

  const tags = [];

  if (!props.tagHidden) {
    // free：無料（トライアル可）
    // trial_used：無料（トライアル不可）
    // trial：トライアル
    // stream：配信契約
    // broadcast：放送契約
    if (
      props.permitUserStatuses &&
      props.permitUserStatuses.length == 1 &&
      props.permitUserStatuses[0] == 'broadcast' &&
      !canPlayBroadcast(profile)
    ) {
      tags.push(
        <span key="permitUserStatuses-broadcast" className="tag grey-tag">
          放送視聴登録者 限定
        </span>,
      );
    } else if (
      props.permitUserStatuses &&
      props.permitUserStatuses.length == 2 &&
      _.includes(props.permitUserStatuses, 'stream') &&
      _.includes(props.permitUserStatuses, 'broadcast') &&
      showNotTrial(profile, context.models)
    ) {
      tags.push(
        <span key={`permitUserStatuses-trial`} className="tag grey-tag">
          無料トライアル 対象外
        </span>,
      );
    }
    if (_.includes(props.permitUserStatuses, 'free') || _.includes(props.permitUserStatuses, 'trial_used')) {
      tags.push(
        <span key={`permitUserStatuses-free`} className="tag grey-tag">
          無料
        </span>,
      );
    }

    let langTag;
    if (props.isLangSubtitle && props.isLangDubbed) {
      langTag = '字幕/吹替';
    } else if (props.isLangSubtitle) {
      langTag = '字幕';
    } else if (props.isLangDubbed) {
      langTag = '吹替';
    }
    if (langTag)
      tags.push(
        <span key="language" className="tag">
          {langTag}
        </span>,
      );
    if (props.isCc)
      tags.push(
        <span key="cc" className="tag">
          字
        </span>,
      );
    if (props.isFirst)
      tags.push(
        <span key="first" className="tag first">
          初回
        </span>,
      );
    if (props.inewpgmflag && props.inewpgmflag.value == '1')
      tags.push(
        <span key="new" className="tag">
          新
        </span>,
      );
    if (props.inewpgmflag && props.inewpgmflag.value == '3')
      tags.push(
        <span key="end" className="tag">
          終
        </span>,
      );
    if (props.isBroadcastNow)
      tags.push(
        <span key="broadcast-now" className="tag">
          生
        </span>,
      );
  }

  return tags.length > 0 ? <div className="tag-list">{tags}</div> : null;
};

Tags.addTagFrag = v => {
  v.isFirst = v.scheduleFirstRun || v['schedule/first-run']; // 初回
  v.isLangSubtitle = (v.scheduleLanguageCode || v['schedule/language-code']) == '0001'; // 字幕版
  v.isLangDubbed = (v.scheduleLanguageCode || v['schedule/language-code']) == '0002'; // 吹替版
  // v.isLangBilingual = v['schedule/language-code'] == '0003'; //  二カ国語版
  // v.is5_1 = v['schedule/sound-code'] == '0005'; // 5.1
  // v.isCc = v['schedule/closed-caption'] == 1; // 字
  v.isBroadcastNow = v.scheduleBroadcastTypeCode || v['schedule/broadcast-type-code']; // 生
  v.isRating = v.ritem && v.rating != 'g';
  v.isMask = (v.scheduleIpSimulcast || v['schedule/ip-simulcast']) == 0; //IP同時配信権区分なし
  v.permitUserStatuses = v.permitUserStatuses || [];
  return v;
};

Tags.contextTypes = {
  models: PropTypes.object,
};

export default Tags;
