import React from 'react';
import PropTypes from 'prop-types';
import _ from 'src/domain/libs/util';
import window from 'global/window';

import * as ERROR from '../../../../../constants/error';
import Axios from '../../../../../common/Axios';
import ErrorBoundary from '../../../components/ErrorBoundary';
import TermsConfirm from './AgreementRequiredView/TermsConfirm';
import isAccountOwnerProfile from '../../../../../utils/isAccountOwnerProfile';
import LoadingView from '../LoadingView';

class AgreementRequiredView extends React.Component {
  static get contextTypes() {
    return {
      getModelData: PropTypes.func,
      models: PropTypes.object,
    };
  }

  constructor(props, context) {
    super(props, context);
    // @ts-ignore TS2339
    this.axios = new Axios({ xhr: true });
    this.handleAgree = this.handleAgree.bind(this);
    this.showTerms = this.showTerms.bind(this);

    this.state = {
      showType: 'loading',
    };
  }

  componentDidMount() {
    // @ts-ignore TS2339
    this._isMounted = true;
    this.fetchData(this.props, this.context);
  }

  componentWillReceiveProps(nextProps, nextContext) {
    // @ts-ignore TS2339
    if (this.props.metaId != nextProps.metaId) {
      this.fetchData(nextProps, nextContext);
    }
  }

  componentWillUnmount() {
    // @ts-ignore TS2339
    this._isMounted = false;
  }

  fetchData(props, context) {
    // @ts-ignore TS2339
    if (this._isMounted) {
      this.setState({ showType: 'loading' });
    }

    // @ts-ignore TS2339
    this.axios
      .post(`/api/meta/${_.get(props, 'metaId')}/terms`, {})
      .then(result => {
        const terms = _.get(result, 'data.terms', []);
        // @ts-ignore TS2339
        if (this._isMounted) {
          // @ts-ignore TS2339
          this.terms = terms;
          this.setState({ showType: 'terms' });
        }
      })
      .catch(e => {
        const newState = {
          fetchDataError: e,
          dispose: null,
          showType: 'error',
        };
        // @ts-ignore TS2339
        if (this._isMounted) this.setState(newState);
        else Object.assign(this.state, newState);
      });
  }

  handleAgree(e) {
    // @ts-ignore TS2339
    if (this.state.submitting) {
      return;
    }

    // @ts-ignore TS2339
    if (this._isMounted) {
      this.setState({ submitting: true });
    }

    let revisionUniqueKeys = [];
    // @ts-ignore TS2339
    _.forEach(this.terms || [], term => {
      const uniqueKey = _.get(term, 'currentRevision.uniqueKey');
      if (uniqueKey) {
        revisionUniqueKeys.push(uniqueKey);
      }
    });
    const params = { revision_unique_key: revisionUniqueKeys.join(',') };

    // @ts-ignore TS2339
    this.axios
      .post('/api/term/agree', params)
      .then(result => {
        if (_.get(result, 'data.result')) {
          return window.location.reload();
        } else {
          throw _.get(result, 'data.message');
        }
      })
      .catch(e => {
        throw e;
      });
  }

  showTerms(term) {
    return e => {
      // @ts-ignore TS2339
      this.props.showModal(
        <ErrorBoundary>
          {/*
           // @ts-ignore TS2339 */}
          <TermsConfirm model={this.props.model} snippetId={_.get(term, 'idKey')} closeModal={this.props.closeModal} />
        </ErrorBoundary>,
      );
    };
  }

  render() {
    let title;
    let content;
    let termLinks;
    let buttonNode;
    // @ts-ignore TS2339
    const terms = this.terms;

    // @ts-ignore TS2339
    switch (this.state.showType) {
      case 'loading':
        return (
          <div>
            <LoadingView />
          </div>
        );
      case 'terms':
        if (!terms || _.isEmpty(terms)) {
          title = '規約が更新されましたが、同意済です。';
          buttonNode = (
            <button
              className="btn btn-fill"
              // @ts-ignore TS2322
              tabIndex="0"
              role="button"
              onClick={e => {
                window.location.reload();
              }}
            >
              視聴を開始する
            </button>
          );
        } else {
          title = '規約が更新されました。';
          content = 'ご視聴いただくには、以下の規約への同意が必要です。';

          termLinks = [];
          _.forEach(terms, term => {
            termLinks.push(
              // @ts-ignore TS2554
              <a href="javascript:void(0)" className="btn btn-border" onClick={this.showTerms()} tabIndex="0">
                {term.displayName}
              </a>,
            );
          });

          let userType;
          const authContext = this.context.getModelData('authContext');
          if (authContext && isAccountOwnerProfile(this.context.models)) {
            userType = 'ACCOUNT';
          } else if (authContext && !isAccountOwnerProfile(this.context.models)) {
            userType = 'PROFILE';
          }

          if (userType == 'PROFILE') {
            buttonNode = '※同意して視聴するにはアカウントプロフィールで操作をしてください 。';
          } else {
            // @ts-ignore TS2339
            if (this.state.submitting) {
              buttonNode = (
                // @ts-ignore TS2322
                <button className="btn btn-fill waiting" tabIndex="0" role="button">
                  処理中...
                </button>
              );
            } else {
              buttonNode = (
                // @ts-ignore TS2322
                <button className="btn btn-fill" tabIndex="0" role="button" onClick={this.handleAgree}>
                  同意して視聴する
                </button>
              );
            }
          }
        }
        break;
      default:
        title = '予期せぬエラーが発生しました。';
        // @ts-ignore TS2339
        console.error('AgreementRequiredViewError', this.state.fetchDataError);
    }

    return (
      <div className="error-view">
        <div className="error-view-content">
          <div className="error-view-content__dialog">
            {title ? (
              <div className="error-view-heading">
                <p className="whoops">{title}</p>
              </div>
            ) : null}
            <div className="error-view-body">
              <div className="error-view-detail">
                <p className="error-view-title">{content}</p>
                <div className="error-view-links">{termLinks}</div>
                {buttonNode}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AgreementRequiredView;
