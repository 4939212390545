import React, { useMemo } from 'react';

import Attribute from './Attribute';

const type = 'attr';

const tabInfo = [
  { key: 'attr', name: 'オンデマンド', sort: true, query: { type: 'od' } },
  { key: 'attrEvent', name: '放送同時配信', sort: false, query: { type: 'ev' } },
];

const Attr = props => {
  const model = useMemo(() => (props.pathEvaluator || props.model.pathEvaluator).batch(100), [
    props.model.pathEvaluator,
    props.pathEvaluator,
  ]);

  return <Attribute {...props} model={model} tabInfo={tabInfo} type={type} />;
};

Attr.getPaths = function(models, options, props) {
  const pathProps = Object.assign({ tabInfo: tabInfo, type: type }, props);
  return Attribute.getPaths(models, options, pathProps);
};
Attr.getRootPath = function(models, options, props = {}) {
  // @ts-ignore TS2339
  if (props.id) {
    // @ts-ignore TS2339
    return [type, props.id];
  }
  return [];
};
Attr.getPrefetchPaths = function(models, options, props) {
  const pathProps = Object.assign({ tabInfo: tabInfo, type: type }, props);
  const paths = Attribute.getPrefetchPaths(models, options, pathProps);
  return paths;
};
Attr.getPrefetchedPaths = function(models, options, props) {
  return data => {
    const pathProps = Object.assign({ tabInfo: tabInfo, type: type }, props);
    return Attribute.getPrefetchedPaths(models, options, pathProps)(data);
  };
};
Attr.afterPrefetch = function(models, options, props) {
  return data => {
    const pathProps = Object.assign({ tabInfo: tabInfo, type: type }, props);
    return Attribute.afterPrefetch(models, options, pathProps)(data);
  };
};

export default Attr;
