import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import _ from 'src/domain/libs/util';

import FullHitZone from './FullHitZone';
import TimeRemaining from './TimeRemaining';

import PlayButton from './PlayButton';
import VolumeButton from './VolumeButton';

import * as DOMUtils from '../../../../sketch-platform/utils/DOMUtils';

class OAPPlayerControls extends React.PureComponent {
  static get defaultProps() {
    return {
      enablePause: true,
      enableSeek: false,
    };
  }

  static get propTypes() {
    return {
      audioTracks: PropTypes.array,
      textTracks: PropTypes.array,
      controls: PropTypes.bool,
      currentScrubTime: PropTypes.number,
      exitPlayer: PropTypes.func,
      getEvidence: PropTypes.func,
      getPlayerNode: PropTypes.func,
      mode: PropTypes.string,
      hasPreplay: PropTypes.bool,
      isActive: PropTypes.bool,
      isFullScreen: PropTypes.bool,
      metadata: PropTypes.object,
      muted: PropTypes.bool,
      onCastToTarget: PropTypes.func,
      onClickPlay: PropTypes.func,
      onClickPlayNext: PropTypes.func,
      onClickReload: PropTypes.func,
      onClickSeekBack: PropTypes.func,
      onClickSeekForward: PropTypes.func,
      onFullScreenChange: PropTypes.func,
      onMouseMove: PropTypes.func,
      onUserActivityChanged: PropTypes.func,
      onClickVolume: PropTypes.func,
      onClickCastButton: PropTypes.func,
      ready: PropTypes.bool,
      paused: PropTypes.bool,
      playStartCompleted: PropTypes.bool,
      playNextTitle: PropTypes.func,
      playbackEnded: PropTypes.bool,
      playbackTimeOut: PropTypes.bool,
      player: PropTypes.object,
      playerDimensions: PropTypes.object,
      scrubbing: PropTypes.bool,
      selectedAudioTrack: PropTypes.object,
      selectedTextTrack: PropTypes.object,
      setAudioTrack: PropTypes.func,
      setKeepActive: PropTypes.func,
      setTimedTextTrack: PropTypes.func,
      shouldFadePlayerControlsBar: PropTypes.bool,
      skipCreditsEnabled: PropTypes.bool,
      timedTracksLoaded: PropTypes.bool,
      uiState: PropTypes.string,
      updateCurrentScrubTime: PropTypes.func,
      volume: PropTypes.number,
      playbackRate: PropTypes.number,
      enablePlaybackRateChange: PropTypes.bool,
      quality: PropTypes.number,
      playToggleButton: PropTypes.string,
      enableAutoplay: PropTypes.bool,
      onToggleAutoplay: PropTypes.func,
      onChangeQuality: PropTypes.func,
      spMode: PropTypes.bool,
    };
  }

  static get contextTypes() {
    return {
      getModelData: PropTypes.func,
      isIframe: PropTypes.bool,
    };
  }

  constructor(props, context) {
    super(props, context);

    // @ts-ignore TS2339
    this.buttonRefs = {};
    // @ts-ignore TS2339
    this.progressBarRef = React.createRef();
    // @ts-ignore TS2339
    this.tooltipRef = React.createRef();
    // @ts-ignore TS2339
    this.settingsButtonRef = React.createRef();
    // @ts-ignore TS2339
    this.controlButtonsElement = null;

    this.drowTooltip = this.drowTooltip.bind(this);
    this.onMouseMove = this.onMouseMove.bind(this);
    this.onMouseLeave = this.onMouseLeave.bind(this);
    this.onClickControls = this.onClickControls.bind(this);
    this.onPointerEnter = this.onPointerEnter.bind(this);
    this.onPointerLeave = this.onPointerLeave.bind(this);
    this.releaseFocus = this.releaseFocus.bind(this);
    this.startHover = this.startHover.bind(this);
    this.stopHover = this.stopHover.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.state = {
      focused: '',
      holdingFocus: false,
      hovered: '',
      showSettings: false,
      keyboardFocus: false,
    };
  }

  componentDidMount() {
    if (!Element.prototype.matches) {
      // @ts-ignore TS2339
      Element.prototype.matches = Element.prototype.msMatchesSelector || Element.prototype.webkitMatchesSelector;
    }

    if (!Element.prototype.closest) {
      Element.prototype.closest = function(s) {
        var el = this;
        if (!document.documentElement.contains(el)) return null;
        do {
          if (el.matches(s)) return el;
          el = el.parentElement || el.parentNode;
        } while (el !== null && el.nodeType === 1);
        return null;
      };
    }
  }

  componentWillReceiveProps(nextProps, nextContext) {
    if (
      // @ts-ignore TS2339
      !this.state.hoveredSettings &&
      // @ts-ignore TS2339
      (this.props.isActive || this.props.paused) &&
      !(nextProps.isActive || nextProps.paused)
    ) {
      this.setState({ hovered: '', showSettings: false });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    this.drowTooltip();

    // @ts-ignore TS2339
    if (this.props.onHover && prevState.hovered != this.state.hovered) {
      // @ts-ignore TS2339
      this.props.onHover(!!this.state.hovered);
    }
    // @ts-ignore TS2339
    if (prevState.keyboardFocus != this.state.keyboardFocus) {
      // @ts-ignore TS2339
      this.props.onKeyboardFocus(this.state.keyboardFocus);
    }
  }

  componentWillUnmount() {
    // @ts-ignore TS2339
    if (this.settingsCloseTimmer) {
      // @ts-ignore TS2339
      clearTimeout(this.settingsCloseTimmer);
      // @ts-ignore TS2339
      delete this.settingsCloseTimmer;
    }
  }

  drowTooltip() {
    // @ts-ignore TS2339
    if (this.buttonRefs[this.state.hovered]) {
      // @ts-ignore TS2339
      const el = ReactDOM.findDOMNode(this.buttonRefs[this.state.hovered]);
      // @ts-ignore TS2339
      if (el && this.tooltipRef.current) {
        // @ts-ignore TS2339
        this.tooltipRef.current.classList.add('tooltip-visible');
        const position = DOMUtils.findPosition(el);
        const rect = DOMUtils.getRect(el);
        // @ts-ignore TS2339
        const parentPosition = DOMUtils.findPosition(el.closest('.button-controls-row'));
        // @ts-ignore TS2339
        const tooltipRect = DOMUtils.getRect(ReactDOM.findDOMNode(this.tooltipRef.current));
        // @ts-ignore TS2339
        if (this.state.hovered == 'play-next') {
          // @ts-ignore TS2339
          this.tooltipRef.current.setAttribute('style', `right: 0px;`);
        } else if (
          position.left - parentPosition.left + rect.width / 2 + tooltipRect.width >
          // @ts-ignore TS2339
          DOMUtils.getRect(el.closest('.button-controls-row')).width + tooltipRect.width / 2
        ) {
          // @ts-ignore TS2339
          this.tooltipRef.current.setAttribute('style', `right: ${-tooltipRect.width / 2}px;`);
        } else {
          // @ts-ignore TS2339
          this.tooltipRef.current.setAttribute(
            'style',
            `left: ${Math.max(
              // @ts-ignore TS2345
              parseInt(tooltipRect.width / 2, 10),
              // @ts-ignore TS2345
              parseInt(position.left - parentPosition.left + rect.width / 2),
            )}px;`,
          );
        }
      }
    } else {
      // @ts-ignore TS2339
      if (this.tooltipRef.current) {
        // @ts-ignore TS2339
        this.tooltipRef.current.classList.remove('tooltip-visible');
        // @ts-ignore TS2339
        this.tooltipRef.current.setAttribute('style', ``);
      }
    }
  }

  onPointerEnter() {}

  onPointerLeave() {}

  releaseFocus(e) {}

  isTriggeredByMouseClick(e) {
    // NOTE Enter/Spaceキーによるクリック判定の場合は座標が０以下になる
    return e.clientX > 0 && e.clientY > 0;
  }

  startHover(hovered) {
    // @ts-ignore TS2339
    if (this.state.showSettings) return;
    this.setState({ hovered });
  }

  stopHover(e) {
    // @ts-ignore TS2339
    if (this.state.showSettings) return;
    this.setState({ hovered: '' });
  }

  onMouseMove(e) {
    this.setState({ keyboardFocus: false });
    // @ts-ignore TS2339
    if (this.props.onMouseMove) {
      // @ts-ignore TS2339
      this.props.onMouseMove(e);
    }
  }

  onMouseLeave(e) {
    this.setState({ keyboardFocus: false });
    if (!e.target.classList.contains('fa-cog') && !e.target.closest('.tooltip')) {
      this.setState({ hovered: '', showSettings: false });
    }
  }

  onClickControls(e) {
    if (!e.target.classList.contains('fa-cog') && !e.target.closest('.tooltip')) {
      this.setState({ hovered: '', showSettings: false });
    }
  }

  handleKeyDown(e) {
    // Tab
    if (e.which === 9) {
      this.setState({ keyboardFocus: true });
      // @ts-ignore TS2339
      if (this.props.onMouseMove) {
        // @ts-ignore TS2339
        this.props.onMouseMove(e);
      }
    }
  }

  render() {
    // @ts-ignore TS2339
    const isHidden = !(this.props.uiState === 'active' || this.props.paused) && this.props.isActive !== true;
    let tooltip;
    // @ts-ignore TS2339
    if (this.state.hovered === 'play') {
      // @ts-ignore TS2339
      if (this.props.playToggleButton == 'play') {
        tooltip = '一時停止';
      } else {
        tooltip = '再生';
      }
      // @ts-ignore TS2339
    } else if (this.state.hovered === 'volume') {
      // @ts-ignore TS2339
      if (this.props.volumeMute) {
        tooltip = 'ミュート解除';
      } else {
        tooltip = 'ミュート（消音）';
      }
    }

    const browserInfo = this.context.getModelData('browserInfo');

    return (
      <div
        className={classnames('controls', {
          // @ts-ignore TS2339
          active: this.props.isActive,
          // @ts-ignore TS2339
          inactive: !this.props.isActive,
          // @ts-ignore TS2339
          'keyboard-focus': this.state.keyboardFocus,
        })}
        onMouseMove={this.onMouseMove}
        onMouseLeave={this.onMouseLeave}
        onClick={this.onClickControls}
        onKeyDown={this.handleKeyDown}
      >
        <div className="controls-container">
          {/*
           // @ts-ignore TS2339 */}
          <FullHitZone onClickPlay={this.props.onClickPlay} />
          <div className="main-controls">
            <div
              className={classnames('player-controls-row bottom-controls', {
                // @ts-ignore TS2339
                hoverProgress: this.state.hovered === 'progressbar',
                // @ts-ignore TS2339
                hoverButtons: !!tooltip || this.state.hovered == 'volume-bar',
              })}
              // @ts-ignore TS2339
              ref={elem => (this.controlButtonsElement = elem)}
            >
              <div className="button-controls-row">
                <div className="left-btn">
                  {/*
                   // @ts-ignore TS2339 */}
                  {!this.props.muteOnly && (
                    <PlayButton
                      // @ts-ignore TS2339
                      ref={el => (this.buttonRefs['play'] = el)}
                      // @ts-ignore TS2322
                      player={this.props.player}
                      onPointerEnter={this.onPointerEnter}
                      onPointerLeave={this.onPointerLeave}
                      startHover={this.startHover}
                      stopHover={this.stopHover}
                      releaseFocus={this.releaseFocus}
                      isHidden={isHidden}
                      // @ts-ignore TS2339
                      paused={this.props.paused}
                      // @ts-ignore TS2339
                      playToggleButton={this.props.playToggleButton}
                      // @ts-ignore TS2339
                      onClick={this.props.onClickPlay}
                    />
                  )}
                  {!(browserInfo.isIOS || browserInfo.isAndroid) ? (
                    <VolumeButton
                      // @ts-ignore TS2339
                      ref={el => (this.buttonRefs['volume'] = el)}
                      // @ts-ignore TS2322
                      onClick={this.props.onClickVolume}
                      releaseFocus={this.releaseFocus}
                      // @ts-ignore TS2339
                      onMouseMove={this.props.onMouseMove}
                      // @ts-ignore TS2339
                      muted={this.props.muted}
                      // @ts-ignore TS2339
                      volume={this.props.volume}
                      step={0.05}
                      // @ts-ignore TS2339
                      isShowContorls={this.props.isActive}
                      startHover={this.startHover}
                      stopHover={this.stopHover}
                      // @ts-ignore TS2339
                      player={this.props.player}
                    />
                  ) : null}
                  {/*
                   // @ts-ignore TS2339 */}
                  {this.props.player && this.props.enableSeek ? (
                    // @ts-ignore TS2322
                    <TimeRemaining player={this.props.player} isHidden={isHidden} />
                  ) : null}
                </div>
                {browserInfo.isIOS || browserInfo.isAndroid ? (
                  <div className="right-btn">
                    <VolumeButton
                      // @ts-ignore TS2339
                      ref={el => (this.buttonRefs['volume'] = el)}
                      // @ts-ignore TS2322
                      onClick={this.props.onClickVolume}
                      releaseFocus={this.releaseFocus}
                      // @ts-ignore TS2339
                      onMouseMove={this.props.onMouseMove}
                      // @ts-ignore TS2339
                      muted={this.props.muted}
                      // @ts-ignore TS2339
                      volume={this.props.volume}
                      step={0.05}
                      // @ts-ignore TS2339
                      isShowContorls={this.props.isActive}
                      startHover={this.startHover}
                      stopHover={this.stopHover}
                      // @ts-ignore TS2339
                      player={this.props.player}
                    />
                  </div>
                ) : null}
                <div className="player-control-spacer-element"></div>
                {tooltip && typeof tooltip === 'string' ? (
                  // @ts-ignore TS2339
                  <div className="tooltip tooltip-text-only" ref={this.tooltipRef}>
                    <div className="tooltip-text">{tooltip}</div>
                  </div>
                ) : tooltip ? (
                  // @ts-ignore TS2339
                  <div className={classnames('tooltip', this.state.hovered)} ref={this.tooltipRef}>
                    {tooltip}
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default OAPPlayerControls;
