import React from 'react';
import _ from 'src/domain/libs/util';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import activeProfile from '../../../../utils/activeProfile';

export default class GalleryDeleteContent extends React.Component {
  static get contextTypes() {
    return {
      getModelData: PropTypes.func,
      models: PropTypes.object,
    };
  }

  constructor(props, context) {
    super(props, context);

    // @ts-ignore TS2339
    this._deleteApp = props.deleteApp;
    this.handleDeleteOnDisplay = this.handleDeleteOnDisplay.bind(this);
    this.handleDeleteOnChange = this.handleDeleteOnChange.bind(this);
    this.handleDeleteMode = this.handleDeleteMode.bind(this);
    this.handleDeleteAllChecked = this.handleDeleteAllChecked.bind(this);
    this.handleDeleteResetAllChecked = this.handleDeleteResetAllChecked.bind(this);
    this.handleDeleteSend = this.handleDeleteSend.bind(this);

    this.state = {
      display: 'hide',
      deleteMode: '',
      deleting: false,
    };
  }

  componentDidMount() {
    // @ts-ignore TS2339
    this._isMounted = true;
    // @ts-ignore TS2339
    if (this._deleteApp) {
      // @ts-ignore TS2339
      this._deleteApp.on('onDisplay', this.handleDeleteOnDisplay);
      // @ts-ignore TS2339
      this._deleteApp.on('onChange', this.handleDeleteOnChange);
    }
  }

  componentWillUnmount() {
    // @ts-ignore TS2339
    this._isMounted = false;
    // @ts-ignore TS2339
    if (this._deleteApp) {
      // @ts-ignore TS2339
      this._deleteApp.off('onDisplay', this.handleDeleteOnDisplay);
      // @ts-ignore TS2339
      this._deleteApp.off('onChange', this.handleDeleteOnChange);
    }
  }

  handleDeleteOnDisplay(display) {
    if (display) {
      this.setState({ display: '' });
    } else {
      this.setState({ display: 'hide' });
    }
  }

  handleDeleteOnChange() {
    let deleteMode;
    // @ts-ignore TS2339
    if (_.isEmpty(this._deleteApp.targetIds())) {
      deleteMode = '';
      // @ts-ignore TS2339
    } else if (_.isEmpty(this._deleteApp.deleteIds())) {
      deleteMode = 'form';
    } else {
      deleteMode = 'selected';
    }
    this.setState({ deleteMode });
    // @ts-ignore TS2339
    if (this.props.onChangeDeleteMode) this.props.onChangeDeleteMode(deleteMode);
  }

  handleDeleteMode() {
    // @ts-ignore TS2339
    const deleteMode = this.state.deleteMode !== 'form' ? 'form' : '';
    this.setState({ deleteMode });
    // @ts-ignore TS2339
    this._deleteApp.toggleMode();
    // @ts-ignore TS2339
    if (this.props.onChangeDeleteMode) this.props.onChangeDeleteMode(deleteMode);
  }

  handleDeleteAllChecked() {
    // @ts-ignore TS2339
    this._deleteApp.allChecked();
  }

  handleDeleteResetAllChecked() {
    // @ts-ignore TS2339
    this._deleteApp.resetAllChecked();
  }

  handleDeleteSend() {
    // @ts-ignore TS2339
    if (this.state.deleting) return false;
    this.setState({ deleting: true });

    const authContext = this.context.getModelData('authContext');
    // @ts-ignore TS2554
    const profile = activeProfile(this.context.models);
    // @ts-ignore TS2339
    this._deleteApp
      .send(authContext, profile.id)
      .then(response => {
        // @ts-ignore TS2339
        if (!this._isMounted) return;
        this.setState({ deleting: false, display: 'hide', deleteMode: '' }, () => {
          // @ts-ignore TS2339
          this._deleteApp.refresh();
          // @ts-ignore TS2339
          if (this.props.onChangeDeleteMode) this.props.onChangeDeleteMode(this.state.deleteMode);
        });
      })
      .catch(e => {
        // @ts-ignore TS2339
        if (!this._isMounted) return;
        window.location.reload();
      });
  }

  render() {
    // @ts-ignore TS2339
    if (this._deleteApp) {
      return (
        // @ts-ignore TS2339
        <ul className={classnames('delete-contents', this.state.deleteMode, this.state.display)}>
          <li className="edit">
            <a
              href="javascript:void(0)"
              className="btn btn-line white-color btn-very-small"
              onClick={this.handleDeleteMode}
            >
              編集する
            </a>
          </li>
          <li className="reset-all-checked">
            <a
              href="javascript:void(0)"
              className="btn btn-line white-color btn-very-small"
              onClick={this.handleDeleteResetAllChecked}
            >
              選択を解除する
            </a>
          </li>
          <li className="delete">
            <a
              href="javascript:void(0)"
              // @ts-ignore TS2339
              className={classnames('btn btn-border btn-very-small red-color', { waiting: this.state.deleting })}
              onClick={this.handleDeleteSend}
            >
              <i className="fa fa-delete"></i>削除する
            </a>
          </li>
          <li className="all-checked">
            <a
              href="javascript:void(0)"
              className="btn btn-line white-color btn-very-small"
              onClick={this.handleDeleteAllChecked}
            >
              <i className="fa fa-ok"></i>全て選択する
            </a>
          </li>
          <li className="cancel">
            <a href="javascript:void(0)" className="text-btn" onClick={this.handleDeleteMode}>
              編集を終了する
            </a>
          </li>
        </ul>
      );
    }

    return null;
  }
}
