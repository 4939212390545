import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

class Timelimit extends React.Component {
  static get contextTypes() {
    return {
      watchPartyApp: PropTypes.object,
    };
  }
  constructor(props, context) {
    super(props, context);
    this.timeLimitUpdate = this.timeLimitUpdate.bind(this);
  }
  shouldComponentUpdate(nextProps, nextState) {
    return false;
  }
  componentDidMount() {
    // @ts-ignore TS2339
    this._isMounted = true;
    // @ts-ignore TS2339
    this.time = this.context.watchPartyApp.getTimeLimit();
    this.startInterval();
  }
  componentWillUnmount() {
    // @ts-ignore TS2339
    this._isMounted = false;
    this.stopInterval();
  }
  startInterval() {
    // @ts-ignore TS2339
    this.interval = setInterval(this.timeLimitUpdate, 1000);
  }
  stopInterval() {
    // @ts-ignore TS2339
    if (this.interval) {
      // @ts-ignore TS2339
      clearInterval(this.interval);
      // @ts-ignore TS2339
      delete this.interval;
    }
  }
  timeLimitUpdate() {
    // @ts-ignore TS2339
    this.time = this.context.watchPartyApp.getTimeLimit();
    // @ts-ignore TS2339
    if (this.time <= 0) {
      this.stopInterval();
      // WP強制終了
      this.context.watchPartyApp.forceClose();
    }
    // @ts-ignore TS2339
    if (this._isMounted) this.forceUpdate();
  }
  render() {
    // @ts-ignore TS2339
    let h = Math.floor(this.time / 3600000); //時間
    // @ts-ignore TS2339
    let m = Math.floor((this.time - h * 3600000) / 60000); //分
    // @ts-ignore TS2339
    let s = Math.round((this.time - h * 3600000 - m * 60000) / 1000); //秒
    var timeLimit = '';
    if (h > 0) {
      // 10時間2分3秒 -> 10:02:03
      // 1時間2分3秒 -> 1:02:03
      timeLimit += h + ':' + ('000' + m).slice(-2) + ':' + ('000' + s).slice(-2);
    } else {
      // 2分3秒 -> 2:03
      // 3秒 -> 0:03
      timeLimit += m + ':' + ('000' + s).slice(-2);
    }

    return (
      <div className="view-element" aria-label="視聴制限時間">
        視聴制限時間：
        {timeLimit}
      </div>
    );
  }
}

export default Timelimit;
