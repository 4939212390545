const SUPPORT_BASE_URL = 'https://support.wowow.co.jp';
const WOL_BASE_URL = 'https://www.wowow.co.jp/';
const WIP_BASE_URL = 'https://auth.wowow.co.jp/realms/wip';
const FAQ_URL = `${SUPPORT_BASE_URL}/answer`;
export const LINKS = {
  SUPPORT: {
    /**放送視聴登録について */
    ABOUT_BCAS_REGISTER: `${FAQ_URL}/61c4255e66cf3b001e9d1301`,
    /**対応機器一覧 */
    ENABLE_DEVICES: `${FAQ_URL}/62bbc61b1e5eea9d5b24a4f6`,
    /**重複契約 */
    DUP_SUBSCRIPTIONS: `${FAQ_URL}/64cca0dcbe51439962a677cf`,
    /**契約紐付け */
    REGISTER_SELF: `${FAQ_URL}/61eface26ebad800223c40c9`,
  },
  WOL: {
    /**特商法 */
    TOKUSHOHO: `${WOL_BASE_URL}/guidance/tokutei/`,
    /**配信加入サービス規約 */
    STREAMING_SERVICE_TERM: `${WOL_BASE_URL}/guidance/haishin/`,
    /**情報セキュリティおよび個人情報保護 */
    INFO_SECURTY: `${WOL_BASE_URL}/guidance/pii/`,
    /**WEB会員規約 */
    WEB_MEMBER_TERM: `${WOL_BASE_URL}/form_common/term_webmem_print.html`,
    /**ケーブルTV加入 */
    CABLE_TV_SIGNUP: `/support/subscribe/regist_entry.php?cp_code=4058&catv_code=7777777777777&ev_code=001596`,
  },
  APPLE: {
    BILLING_HISTORY: 'https://support.apple.com/ja-jp/HT204088',
  },
  WIP: {
    FORGOT_EMAIL: `${WIP_BASE_URL}/forgot-email`,
    FORGOT_PASS: `${WIP_BASE_URL}/login-actions/reset-credentials?client_id=wod-automobile`,
  },
};
