import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import _ from 'src/domain/libs/util';

import EpisodeCard from '../browse/EpisodeCard';
import TableContent from '../browse/TableContent';
import routes from '../../../common/routes';

export default class NextEpisode extends Component {
  static getPaths = function(models, options, props = {}) {
    let path = [];
    // @ts-ignore TS2339
    if (props.id) {
      path = path.concat(this.getRootPath(models, options, props));
    }
    return [
      path.concat([
        [
          'id',
          'refId',
          'name',
          'shortName',
          'thumbnailUrl',
          'description',
          'cardInfo',
          'tags',
          'genres',
          'systemGenres',
          'playbackRestrictionType',
          'languageCodes',
          'leadSeasonId',
          'titleMetaId',
        ],
      ]),
    ];
  };

  static getRootPath = function(models, options, props = {}) {
    // @ts-ignore TS2339
    return ['meta', props.id, 'next'];
  };

  static get propTypes() {
    return {
      id: PropTypes.number.isRequired,
      model: PropTypes.object,
      autoplayOffsetSeconds: PropTypes.number,
      showNextEpisode: PropTypes.bool,
      enableAutoplay: PropTypes.bool,
      onToggleAutoplay: PropTypes.func,
    };
  }

  constructor(props, context) {
    super(props, context);
    this.handleToggleAutoplay = this.handleToggleAutoplay.bind(this);
    this.state = { toggle: props.enableAutoplay };
  }

  componentDidMount() {
    // @ts-ignore TS2339
    this._isMounted = true;
    this.fetchData(this.props);
  }

  componentWillReceiveProps(nextProps, nextContext) {
    this.setState({ toggle: nextProps.enableAutoplay });
  }

  componentWillUnmount() {
    // @ts-ignore TS2339
    this._isMounted = false;
  }

  handleToggleAutoplay() {
    // @ts-ignore TS2339
    if (this.props.onToggleAutoplay) this.props.onToggleAutoplay(!this.state.toggle);
  }

  render() {
    // @ts-ignore TS2339
    if (!this.item) return null;
    // @ts-ignore TS2339
    if (!this.props.showNextEpisode) return null;

    return (
      <div className="nextEpisodeWapper">
        <div className="nextEpisodeHeader wod-compact-autoplay-renderer">
          <div id="upnext" className="wod-compact-autoplay-renderer">
            次の動画
          </div>
          <div id="autoplay" className="wod-compact-autoplay-renderer">
            連続再生
          </div>
          <div
            id="toggle"
            noink=""
            // @ts-ignore TS2339
            className={classnames('wod-compact-autoplay-renderer', { checked: this.state.toggle })}
            role="button"
            // @ts-ignore TS2339
            aria-pressed={this.state.toggle}
            // @ts-ignore TS2322
            tabIndex="0"
            style={{ touchAction: 'pan-y' }}
            toggles=""
            aria-disabled="false"
            aria-label="連続再生"
            // @ts-ignore TS2339
            active={this.state.toggle.toString()}
            onClick={this.handleToggleAutoplay}
          >
            <div className="toggle-container paper-toggle-button">
              <div id="toggleBar" className="toggle-bar paper-toggle-button"></div>
              <div id="toggleButton" className="toggle-button paper-toggle-button">
                <div
                  id="ink"
                  // @ts-ignore TS2322
                  recenters=""
                  // @ts-ignore TS2339
                  className={classnames('circle toggle-ink paper-toggle-button', { checked: this.state.toggle })}
                >
                  <div id="background" className="paper-ripple" style={{ opacity: 0 }}></div>
                  <div id="waves" className="paper-ripple"></div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <EpisodeCard
          // @ts-ignore TS2322
          model={this.props.model}
          // @ts-ignore TS2339
          key={`next_episode_${this.item.id}`}
          // @ts-ignore TS2339
          episodeId={this.item.id}
          showEpisodeNumber={false}
          beginning={true}
        />
      </div>
    );
  }

  fetchData(props) {
    // @ts-ignore TS2339
    const paths = this.constructor.getPaths(this.context.models, null, props);
    // @ts-ignore TS2339
    const rootPath = this.constructor.getRootPath(this.context.models, {}, props);
    const evaluator = props.model.fetch(paths);
    // @ts-ignore TS2339
    this.state.dispose = evaluator.dispose;
    evaluator
      .then(res => {
        // @ts-ignore TS2339
        this.item = _.get(res.json, rootPath, {});
        const newState = {
          fetchDataError: null,
          dispose: null,
          // @ts-ignore TS2339
          generation: this.state.generation + 1,
        };
        // @ts-ignore TS2339
        if (this._isMounted) {
          this.setState(newState);
        } else {
          Object.assign(this.state, newState);
        }
      })
      .catch(e => {
        console.error(e.stack);
        const newState = {
          fetchDataError: e,
          dispose: null,
        };
        // @ts-ignore TS2339
        if (this._isMounted) this.setState(newState);
        else this.state = newState;
      });
  }
}
