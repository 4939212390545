import React from 'react';
import _ from 'src/domain/libs/util';
import datetime from 'src/domain/libs/datetime';

//
// props
// - subscription
//
const SubscriptionPriceNote = props => {
  if (!_.get(props, 'subscription.initialTransactionAt')) {
    return null;
  }

  if (!datetime(props.subscription.initialTransactionAt, 'YYYY/MM/DD HH:mm:ss').isValid()) {
    return null;
  }

  const d = datetime(props.subscription.initialTransactionAt, 'YYYY/MM/DD HH:mm:ss').format('YYYY/MM/DD');
  if (d == datetime().format('YYYY/MM/DD')) {
    return <p>請求日：本日</p>;
  } else {
    return null;
  }
};

export default SubscriptionPriceNote;
