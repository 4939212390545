import React from 'react';
import _ from 'src/domain/libs/util';

class DisableRateModal extends React.Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
    this.state = {};
  }

  componentDidMount() {
    // @ts-ignore TS2339
    this._isMounted = true;
  }

  componentWillUnmount() {
    // @ts-ignore TS2339
    this._isMounted = false;
  }

  handleClick() {
    // @ts-ignore TS2339
    if (this.props.closeModal) this.props.closeModal();
  }

  render() {
    return (
      <div className="fallback-modal">
        <p>この動画は権利の都合で速度変更が制限されています。</p>
        <div className="btn-block">
          <button className="btn btn-fill" name="fallbackModalClose" onClick={this.handleClick}>
            閉じる
          </button>
        </div>
      </div>
    );
  }
}

export default DisableRateModal;
