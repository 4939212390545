import { useCallback, useEffect, useState } from 'react';
import _ from 'src/domain/libs/util';

import usePrevious from './usePrevious';

const usePaletteObjectsLength = ({ model, paletteId }) => {
  const [paletteObjectsLength, setPaletteObjectsLength] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const prevPaletteId = usePrevious(paletteId);

  const getPalette = useCallback(async () => {
    if (!paletteId) return;

    setLoading(true);
    if (error) setError(null);
    const rootPath = ['palette', paletteId, 'objects'];
    const paths = [rootPath.concat(['length'])];
    try {
      const res = await model.fetch(paths);
      const length = _.get(res.json, rootPath.concat(['length']));
      setPaletteObjectsLength(length);
    } catch (e) {
      setError(e);
      console.error(e);
    }
    setLoading(false);
  }, [error, model, paletteId]);

  useEffect(() => {
    if (paletteId && paletteId !== prevPaletteId) {
      getPalette();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paletteId]);

  return { paletteObjectsLength, loading, error };
};

export default usePaletteObjectsLength;
