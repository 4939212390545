import PriceList from './PriceList';
import Meta from './Meta';
import Episodes from './titlemeta/Episodes';

class TitleOverview  {
  static getPaths = function(models, options = {}, props = {}) {
    const rootPath = this.getRootPath(models, options, props);
    let paths = [
      rootPath.concat([
        'viewingEpisode',
        ['id', 'refId', 'name', 'shortName', 'casts', 'thumbnailUrl', 'bookmarkPosition', 'cardInfo'],
      ]),
    ];
    // @ts-ignore TS2339
    if (props.defaultSort) {
      paths = paths.concat(
        // @ts-ignore TS2339
        Episodes.getPaths(models, options, { sortOrder: props.defaultSort }).map(path => {
          return rootPath.concat(path);
        }),
      );
    }
    return paths
      .concat(
        PriceList.getPaths(models, options, {}).map(path => {
          return rootPath.concat(path);
        }),
      )
      .concat(
        Meta.getPaths(models, options, {}).map(path => {
          return rootPath.concat(path);
        }),
      )
      .concat([
        rootPath.concat([
          [
            'id',
            'name',
            'shortName',
            'casts',
            'copyright',
            'schemaId',
            'type',
            'thumbnailUrl',
            'xlargeThumb',
            'header',
            'mylisted',
            'canMyList',
            'officialSiteUrl',
            'leadEpisodeId',
            'leadEpisode',
            'titleMetaId',
            'leadSeasonId',
            'slug',
            'cardInfo',
          ],
        ]),
      ]);
  };

  static getRootPath = function(models, options, props = {}) {
    // @ts-ignore TS2339
    if (props.id) {
      // @ts-ignore TS2339
      return ['meta', props.id];
    }
    return [];
  };

}

export default TitleOverview;
