import React from 'react';
import { wrapDisplayName } from '../utils/displayName';

// @ts-ignore TS2554
const ModalContext = React.createContext();
export default ModalContext;
export const withModalContext = Component => {
  const withModalContext = React.forwardRef((props, ref) => {
    return (
      <ModalContext.Consumer>
        {/*
         // @ts-ignore TS2698 */}
        {context => React.createElement(Component, { ref, ...props, ...context })}
      </ModalContext.Consumer>
    );
  });
  // @ts-ignore TS2554
  withModalContext.displayName = wrapDisplayName(Component, 'withModalContext');
  // @ts-ignore TS2339
  withModalContext.headTitle = Component.headTitle;
  // @ts-ignore TS2339
  withModalContext.bundleName = Component.bundleName;
  if (typeof Component.getPaths === 'function') {
    // @ts-ignore TS2339
    withModalContext.getPaths = Component.getPaths.bind(Component);
  }
  if (typeof Component.getPrefetchPaths === 'function') {
    // @ts-ignore TS2339
    withModalContext.getPrefetchPaths = Component.getPrefetchPaths.bind(Component);
  }
  if (typeof Component.getPrefetchedPaths === 'function') {
    // @ts-ignore TS2339
    withModalContext.getPrefetchedPaths = Component.getPrefetchedPaths.bind(Component);
  }
  if (typeof Component.afterPrefetch === 'function') {
    // @ts-ignore TS2339
    withModalContext.afterPrefetch = Component.afterPrefetch.bind(Component);
  }
  if (typeof Component.getCanvasId === 'function') {
    // @ts-ignore TS2339
    withModalContext.getCanvasId = Component.getCanvasId.bind(Component);
  }
  return withModalContext;
};
