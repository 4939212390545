import tokenDataStore from '../utils/tokenDataStore';

// token関連のクライアント側のロジック
export class TokenApp {
  constructor(options = {}) {}

  async tokenRefresh() {
    return this._tokenRefresh();
  }

  async _tokenRefresh(async = true) {
    const tokenData = tokenDataStore.get();
    tokenData.isRefresh = true;
    tokenDataStore.set(tokenData);

    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.open('POST', '/api/user/token/refresh', async);
      xhr.onreadystatechange = () => {
        if (xhr.readyState === XMLHttpRequest.DONE) {
          tokenData.isRefresh = false;
          if (xhr.status !== 200) {
            // bff介しており200以外は基本的に通信エラーのため
            tokenDataStore.set(tokenData);
            resolve({ result: true });
          } else {
            let response = JSON.parse(xhr.responseText);
            if (response.result) {
              tokenDataStore.set(tokenData);
              tokenDataStore.setAuthContextData(response.authContext);
            }
            resolve(response);
          }
        }
      };
      xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
      xhr.send();
    });
  }
}
