import React from 'react';
import HtmlSnippet from '../../../common/components/HtmlSnippet';

const HtmlSnippetRow = props => {
  return (
    <div className="canvas-row snippet-row">
      <HtmlSnippet {...props} />
    </div>
  );
};

HtmlSnippetRow.getPaths = HtmlSnippet.getPaths;
HtmlSnippetRow.getRootPath = HtmlSnippet.getRootPath;

export default HtmlSnippetRow;
