import appContext from './appContext';

export default {
  getModelData: function(data, key, node) {
    if (!process.env.BROWSER) {
      if (node.res) {
        node.res.reactContext = node.res.reactContext || {};
        node.res.reactContext.models = node.res.reactContext.models || {};
        node.res.reactContext.models[key] = {
          data,
          type: 'api',
        };
      }
      return data;
    }
    // @ts-ignore TS2554
    data = appContext.getModelData(key);
    return (data && data[key]) || data || {};
  },
};
