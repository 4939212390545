import { useMemo } from 'react';

import useOapLoopConfig from './useOapLoopConfig';
import usePalette from '../../../../hooks/usePalette';
import usePaletteObjectsLength from '../../../../hooks/usePaletteObjectsLength';
import usePaletteObjects from '../../../../hooks/usePaletteObjects';

const useSidelistPalette = ({ model, meta, propKeys, config, withObjects, withObjectsLength, from, to }) => {
  const oapLoopConfig = useOapLoopConfig(config, meta);
  const paletteId = useMemo(() => {
    return (oapLoopConfig || {}).palette_id;
  }, [oapLoopConfig]);

  // @ts-ignore TS2345
  const paletteData = usePalette({ model, paletteId, propKeys, withObjects, withObjectsLength, from, to });
  const paletteObjectsData = usePaletteObjects({ model, paletteId: withObjects ? paletteId : undefined, from, to });
  const paletteObjectsLengthData = usePaletteObjectsLength({
    model,
    paletteId: withObjectsLength ? paletteId : undefined,
  });

  const loading = useMemo(() => paletteData.loading || paletteObjectsData.loading || paletteObjectsLengthData.loading, [
    paletteData.loading,
    paletteObjectsData.loading,
    paletteObjectsLengthData.loading,
  ]);

  return {
    palette: paletteData.palette,
    paletteObjects: paletteObjectsData.paletteObjects,
    paletteObjectsLength: paletteObjectsLengthData.paletteObjectsLength,
    loading,
  };
};

export default useSidelistPalette;
