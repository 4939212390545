/*
      最高画質 1080p PerTitle
      高画質　720p 1800K - 2400K
      標準 540p 1000K - 1400K  出力固定
      低画質 360p 600K - 900K
      360p 300K - 500K
    */
export const renditionsPresets = {
  default: [
    {
      label: '自動',
      max_bitrate: 4000000 + 100000,
      min_bitrate: 0,
    },
    {
      label: '最高画質',
      max_bitrate: 4000000 + 100000,
      // min_bitrate: 2400001 + 100000
      min_bitrate: 0, // キャップされている動画に対応できないので一旦0にする
    },
    {
      label: '高画質',
      max_bitrate: 2400000 + 100000,
      min_bitrate: 1400001 + 100000,
    },
    {
      label: '標準',
      max_bitrate: 1400000 + 100000,
      min_bitrate: 900001 + 100000,
    },
    {
      label: '低画質',
      max_bitrate: 900000 + 100000,
      min_bitrate: 0,
    },
  ],
};
