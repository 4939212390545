import { Api } from 'src/api/Api';
import {
  BffPlaybackAuthPostRes,
  BffPlaybackCheckAvailabilityPostRes,
  BffPlaybackReauthPostRes,
} from 'src/types/api/bffPlayback';
import {
  PlaybackApiAuthOptions,
  PlaybackApiAuthParams,
  PlaybackApiCheckAvailabilityOptions,
  PlaybackApiCheckAvailabilityParams,
  PlaybackApiConfig,
  PlaybackApiInterface,
  PlaybackApiReauthOptions,
  PlaybackApiReauthParams,
} from 'src/interface/api/PlaybackApiInterface';

export class BffPlaybackApi extends Api implements PlaybackApiInterface {
  constructor(config?: PlaybackApiConfig) {
    super({
      ...config,
      baseURL: '/api/playback',
    });
  }
  auth(params: PlaybackApiAuthParams, options?: PlaybackApiAuthOptions) {
    this.api;
    return this.post<BffPlaybackAuthPostRes>('/auth', params, options);
  }
  reauth(params: PlaybackApiReauthParams, options?: PlaybackApiReauthOptions) {
    return this.post<BffPlaybackReauthPostRes>('/reauth', params, options);
  }
  checkAvailability(params: PlaybackApiCheckAvailabilityParams, options?: PlaybackApiCheckAvailabilityOptions) {
    return this.post<BffPlaybackCheckAvailabilityPostRes>('/check_availability', params, options);
  }
}
