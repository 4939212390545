import { useCallback, useEffect, useState } from 'react';
import _ from 'src/domain/libs/util';

import usePrevious from './usePrevious';

const usePalette = ({ model, paletteId, propKeys = ['id'] }) => {
  const [palette, setPalette] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const prevPaletteId = usePrevious(paletteId);
  const prevPropKeys = usePrevious(propKeys);

  const fetch = useCallback(async () => {
    if (!paletteId) return;

    setLoading(true);
    if (error) {
      setError(null);
    }
    const rootPath = ['palette', paletteId];
    const paths = [rootPath.concat([propKeys])];
    try {
      const res = await model.fetch(paths);
      const _palette = _.omit(_.get(res.json, rootPath), ['$__path']);
      setPalette(_palette);
    } catch (e) {
      setError(e);
      console.error(e);
    }
    setLoading(false);
  }, [error, model, paletteId, propKeys]);

  useEffect(() => {
    // @ts-ignore TS2339
    if ((paletteId && paletteId !== prevPaletteId) || !prevPropKeys || propKeys.length != prevPropKeys.length) {
      if (paletteId !== prevPaletteId) {
        setPalette(null);
      }
      fetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paletteId, propKeys]);

  return { palette, loading, error };
};

export default usePalette;
