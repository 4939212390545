import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'src/domain/libs/util';
import { Helmet } from 'react-helmet';

import routes from '../../../common/routes';
import PaletteContent from './PaletteContent';
import HtmlContext from '../../../common/context/HtmlContext';
import JsonLd from '../../../common/components/JsonLd';

export default class FeatureHome extends Component {
  static getPaths = function(models, options, props) {
    return PaletteContent.getPaths(models, options, { ...props, id: this.getFeaturePaletteId(models, options, props) });
  };

  static getRootPath = function(models, options, props) {
    const paletteIdOrKey = this.getFeaturePaletteId(models, options, props);
    if (paletteIdOrKey) {
      return ['palette', paletteIdOrKey];
    }
    return [];
  };

  static getFeaturePaletteId(models, options, props) {
    return _.get(models, 'config.data.palette.feature');
  }

  static getPrefetchPaths = function(models, options, props) {
    return this.getPaths(models, options, props);
  };

  static getPrefetchedPaths = function(models, options, props) {
    return PaletteContent.getPrefetchedPaths(models, options, {
      ...props,
      id: this.getFeaturePaletteId(models, options, props),
    });
  };

  static get propTypes() {
    return {
      history: PropTypes.object,
      id: PropTypes.string,
      routeHandler: PropTypes.object,
      pathEvaluator: PropTypes.object,
      perPage: PropTypes.number,
    };
  }

  static get contextTypes() {
    return {
      routeHandler: PropTypes.object,
      models: PropTypes.object,
      getModelData: PropTypes.func,
    };
  }

  constructor(props, context) {
    super(props, context);

    // @ts-ignore TS2339
    this.model = (props.pathEvaluator || props.model.pathEvaluator).batch(100);
    // @ts-ignore TS2339
    const rootPath = this.constructor.getRootPath(context.models, {}, props);
    // @ts-ignore TS2339
    this.palette = this.model.getSync(rootPath) || {};
  }

  componentDidMount() {
    // @ts-ignore TS2339
    this._isMounted = true;
  }

  componentWillUnmount() {
    // @ts-ignore TS2339
    this._isMounted = false;
  }

  render() {
    // @ts-ignore TS2339
    const idKey = this.constructor.getFeaturePaletteId(this.context.models, {}, this.props);
    const host = this.context.getModelData('hosts', 'host');
    // @ts-ignore TS2554
    const url = routes.featureHome.makePath();
    // @ts-ignore TS2339
    const paletteTitle = this.palette.title;
    const jsonLdProps = {
      breadcrumbList: {
        itemListElement: [{ name: paletteTitle, item: host + url }],
      },
    };
    return (
      <div className="feature">
        <HtmlContext.Consumer>
          {({ title }) => {
            const links = [];
            const metas = [];
            links.push({ rel: 'canonical', href: host + url });
            metas.push({ property: 'og:title', content: title(paletteTitle) });
            // @ts-ignore TS2339
            metas.push({ name: 'description', content: this.palette.description });
            return <Helmet title={paletteTitle} link={links} metas={metas} />;
          }}
        </HtmlContext.Consumer>
        <JsonLd {...jsonLdProps} />
        {/*
         // @ts-ignore TS2322 */}
        <PaletteContent headerVisuable={true} id={idKey} model={this.model} useHelmet={false} title={paletteTitle} />
      </div>
    );
  }
}
