import _ from 'src/domain/libs/util';
import { createRoute } from '../../sketch-platform/ui/routing';
import isKidsProfile from '../../utils/isKidsProfile';

const routes = {
  home: createRoute({ path: '/' }),
  browse: createRoute({ path: '/browse' }),
  titleEpisodes: createRoute({ path: '/browse/title/:id' }),
  title: createRoute({ path: '/:locale?/title/:id' }),
  titleShowEpisodes: createRoute({ path: '/title/:id#showEpisodes' }),
  program: createRoute({ path: '/:locale?/program/:id' }),
  attr: createRoute({ path: '/browse/attr/:id' }),
  genre: createRoute({ path: '/browse/genre/:id' }),
  genreRef: createRoute({ path: '/genre/:id' }),
  person: createRoute({ path: '/browse/person/:id' }),
  team: createRoute({ path: '/browse/team/:id' }),
  league: createRoute({ path: '/browse/league/:id' }),
  editorial: createRoute({ path: '/browse/editorial/:id' }),
  exclusiveUi: createRoute({ path: '/browse/exclusive-ui/:id' }),
  mylist: createRoute({ path: '/browse/mylist' }),
  continueWatching: createRoute({ path: '/browse/continue-watching' }),
  onRental: createRoute({ path: '/browse/on-rental' }),
  trendingNow: createRoute({ path: '/browse/trending-now' }),
  popularTitles: createRoute({ path: '/browse/popular-titles' }),
  recentlyAdded: createRoute({ path: '/browse/recently-added' }),
  newEpisodeAdded: createRoute({ path: '/browse/new-episode-added' }),
  search: createRoute({ path: '/search' }),
  deviceManagement: createRoute({ path: '/device/management' }),
  downloadDevice: createRoute({ path: '/device/download_management' }),
  simulcastDetail: createRoute({ path: '/simulcast/:channelName/:uniqueId' }),
  simulcast: createRoute({ path: '/simulcast/:channelName' }), // Prime, Live, Cinema
  watchNow: createRoute({ path: '/watch/:id', query: ['trkid', 'tctx', 'ad'] }),
  content: createRoute({ path: '/content/:id' }),
  pv: createRoute({ path: '/pv/:id' }),
  product: createRoute({ path: '/product/:id' }),
  display: createRoute({ path: '/display/:id' }),
  featureHome: createRoute({ path: '/feature' }),
  ranking: createRoute({ path: '/ranking' }),
  displayRanking: createRoute({ path: '/display/ranking' }),
  liveSchedule: createRoute({ path: '/live-schedule' }),
  curation: createRoute({ path: '/curation' }),
  recommended: createRoute({ path: '/recommended/:id' }),
  info: createRoute({ path: '/info' }),
  infoDetail: createRoute({ path: '/info/:id' }),

  // ssr only
  viewingHistory: createRoute({ path: '/account/viewing_history' }),
  settings: createRoute({ path: '/settings' }),
  couponBox: createRoute({ path: '/account/coupon_box' }),
  login: createRoute({ path: '/login' }),
};

export function getHomeRoute(models, isKidsPage) {
  const userInfo = _.get(models, 'userInfo.data');
  if (isKidsProfile(models) || isKidsPage) {
    // 現在のページがKidsの場合
    // @ts-ignore TS2339
    return routes.kidsHome;
  } else {
    // ログインユーザーの場合は/browseに遷移させる
    if (userInfo.status === 'NOT_REGISTERED_MEMBER') {
      return routes.browse;
    } else {
      return routes.home;
    }
  }
}

export function isSearchRoute(target) {
  // @ts-ignore TS2339
  return [routes.search, routes.isKidsSearch].some(route => {
    return target === route;
  });
}

export default routes;
