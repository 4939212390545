import React, { Component } from 'react';
import PropTypes from 'prop-types';
import DropDown from '../../../common/components/DropDown';
import classnames from 'classnames';
import TabDropDown from '../../../common/components/TabDropDown';

const SortRow = (props, context) => {
  if (props.sortOrders.length == 0) return null;

  const renderOptions = () => {
    const { history, routeHandler } = context;
    return props.sortOrders.map((option, key) => {
      const execSort = () => {
        if (props.onSortOrderChange) {
          props.onSortOrderChange(option.so);
        }
      };

      return (
        <a
          className="sub-menu-link"
          role="link"
          // @ts-ignore TS2322
          tabIndex="0"
          onClick={e => {
            e.preventDefault();
            execSort();
          }}
          onKeyDown={e => {
            if (e.which === 13 || e.keyCode === 13) {
              e.preventDefault();
              execSort();
            }
          }}
        >
          {option.name}
        </a>
      );
    });
  };

  const getOptionName = so => {
    let name = null;
    props.sortOrders.forEach(option => {
      if (option.so == so) {
        name = option.name;
      }
    });
    return name;
  };

  const dropDownProps = {
    cols: 1,
    defaultValue: getOptionName(props.sortOrder),
    options: renderOptions(),
    widthRestricted: true,
  };

  return (
    <div className={classnames('sort-row', { right: props.positionRight })}>
      {!props.hideSortLabel ? (
        <span>
          <i className="fa fa-sort"></i>
        </span>
      ) : null}
      {/*
       // @ts-ignore TS2559 */}
      {props.tabDropdown ? <TabDropDown {...dropDownProps} /> : <DropDown {...dropDownProps} />}
    </div>
  );
};

SortRow.getPaths = function() {
  return [];
};

SortRow.propTypes = {
  sortOrders: PropTypes.array.isRequired,
};

SortRow.defaultProps = {
  sortOrders: [],
};

SortRow.contextTypes = {
  history: PropTypes.object,
  routeHandler: PropTypes.object,
};

export default SortRow;
