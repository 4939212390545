import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'src/domain/libs/util';

import * as COOKIE from '../../../../../constants/cookie';
import EventDetails from './EventDetails';
import Tags from './Tags';
import EpgEvent from './EpgEvent';
import MainViewLink from '../../../../common/components/MainViewLink';
import routes from '../../../../common/routes';
// @ts-ignore TS1192
import reactNl2br from '../../../../../common/reactNl2br';
import BackgroundImage from '../../../../common/components/BackgroundImage';
import DetailText from '../../player/DetailText';

import * as browserEvents from '../../../../../sketch-platform/utils/browserEvents';
import Meta from '../Meta';
import SetDate from '../SetDate';
import classnames from 'classnames';

export default class EventInfo extends Component {
  static getPaths = function(models, options, props = {}) {
    let path = [];
    // @ts-ignore TS2339
    if (props.id) {
      path = path.concat(this.getRootPath(models, options, props));
    }
    EpgEvent.getPaths(models, options, {}).map(_path => {
      path = path.concat(_path);
    });
    return [path];
  };

  static getRootPath = function(models, options, props = {}) {
    // @ts-ignore TS2339
    return ['meta', props.id, 'onair'];
  };

  static get propTypes() {
    return {
      onSliderMove: PropTypes.func,
      model: PropTypes.object.isRequired,
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      myListButton: PropTypes.object,
    };
  }
  static get contextTypes() {
    return {
      getModelData: PropTypes.func,
      cookies: PropTypes.object,
    };
  }

  constructor(props, context) {
    super(props, context);
    // @ts-ignore TS2339
    const rootPath = this.constructor.getRootPath(context.models, {}, props);
    this.state = {
      fetchDataError: null,
      dispose: null,
      generation: props.model.getVersion(rootPath),
    };
    // @ts-ignore TS2339
    this.item = props.model.getSync(rootPath);
    // @ts-ignore TS2339
    if (this.item) {
      // @ts-ignore TS2339
      this.item = EpgEvent.additionalData(this.item);
      // @ts-ignore TS2339
      if (props.onEventChange) props.onEventChange(this.item);
    }
    this.refresh = this.refresh.bind(this);
  }

  componentDidMount() {
    // @ts-ignore TS2339
    this._isMounted = true;
    this.fetchData(this.props);

    // @ts-ignore TS2551
    this.refreshId = setInterval(this.refresh, 1000 * 60);
  }

  componentWillReceiveProps(nextProps, nextContext) {
    // @ts-ignore TS2339
    if (nextProps.id !== this.props.id) {
      // @ts-ignore TS2339
      const rootPath = this.constructor.getRootPath(nextContext.models, {}, nextProps);
      // @ts-ignore TS2339
      this.item = nextProps.model.getSync(rootPath);
      this.setState({ generation: nextProps.model.getVersion(rootPath), fetchDataError: null }, () => {
        this.fetchData(nextProps);
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    // @ts-ignore TS2339
    if (prevState.generation !== this.state.generation) {
      // @ts-ignore TS2339
      if (this.props.onEventChange) this.props.onEventChange(this.item);
    }
  }

  componentWillUnmount() {
    // @ts-ignore TS2339
    this._isMounted = false;
    // @ts-ignore TS2551
    if (this.refreshId) {
      // @ts-ignore TS2551
      clearInterval(this.refreshId);
      // @ts-ignore TS2551
      delete this.refreshId;
    }
    // @ts-ignore TS2339
    if (this.state.dispose) this.state.dispose();
  }

  refresh() {
    this.fetchData(this.props);
  }

  fetchData(props) {
    if (!props.id) return;

    // @ts-ignore TS2339
    const paths = this.constructor.getPaths(this.context.models, {}, props);

    // すでに通信している場合は実行しない
    //if (this.state[JSON.stringify(paths)]) return;

    // @ts-ignore TS2339
    if (this.state.dispose) {
      // 過去のObservableを削除する、これによって通信が止まるわけではなく
      // Observableがなくなるのでイベントが発火されなくなる、というだけなので注意
      // @ts-ignore TS2339
      this.state.dispose();
    }

    //this.state[JSON.stringify(paths)] = paths;

    const evaluator = props.model.fetch(paths);
    const dispose = evaluator.dispose;
    // @ts-ignore TS2339
    if (this._isUpdated === false || !this._isMounted) {
      Object.assign(this.state, { dispose });
    } else {
      this.setState({ dispose });
    }
    // @ts-ignore TS2339
    const rootPath = this.constructor.getRootPath(this.context.models, {}, props);
    evaluator
      .then(res => {
        // @ts-ignore TS2339
        this.item = _.get(res, ['json'].concat(rootPath));
        // @ts-ignore TS2339
        this.item = EpgEvent.additionalData(this.item);
        delete this.state[JSON.stringify(paths)];

        const newState = {
          fetchDataError: null,
          dispose: null,
          generation: props.model.getVersion(rootPath),
        };
        // @ts-ignore TS2339
        if (this._isMounted) this.setState(newState);
        else Object.assign(this.state, newState);
      })
      .catch(e => {
        const newState = {
          fetchDataError: e,
          fetchingMoreRows: undefined,
          dispose: null,
        };
        delete this.state[JSON.stringify(paths)];
        // @ts-ignore TS2339
        if (this._isMounted) this.setState(newState);
        else Object.assign(this.state, newState);
      });
  }

  render() {
    const browserInfo = this.context.getModelData('browserInfo');
    const darkKey = this.context.cookies.get(COOKIE.DARK_KEY);
    let channelName;
    // @ts-ignore TS2339
    const simulcast = _.find(this.context.getModelData('simulcast'), item => item.refId == this.props.metadata.refId);
    if (simulcast) channelName = _.lowerCase(simulcast.name);

    let catchupLinkProps;
    // @ts-ignore TS2339
    if (this.item && this.item.catchupMeta) {
      // @ts-ignore TS2339
      if (this.item.catchupMeta.refId) {
        catchupLinkProps = {
          to: routes.content,
          // @ts-ignore TS2339
          params: { id: this.item.catchupMeta.refId },
        };
      } else {
        catchupLinkProps = {
          to: routes.watchNow,
          // @ts-ignore TS2339
          params: { id: this.item.catchupMeta.metaId },
        };
      }
    }
    return (
      <div className="watch-info with-event">
        <div className="watch-info__colum_left" id="columLeft">
          {/*
           // @ts-ignore TS2339 */}
          {this.props.titleHidden == false && (
            <p className={classnames('playable-title', channelName)} id="infoTitle">
              {darkKey == 'true' ? (
                <img src={`../images/logo_${channelName}_white.svg`} />
              ) : (
                <img src={`../images/logo_${channelName}.svg`} />
              )}
              <span className="playable-title__date" key={`date`}>
                {/*
                 // @ts-ignore TS2339 */}
                {this.item && this.item.startAt && this.item.endAt ? (
                  <React.Fragment>
                    {/*
                     // @ts-ignore TS2322 */}
                    <SetDate date={this.item.startAt} format={'short'} /> -{' '}
                    {/*
                     // @ts-ignore TS2322 */}
                    <SetDate date={this.item.endAt} format={'time'} />
                  </React.Fragment>
                ) : // @ts-ignore TS2339
                this.item && this.item.startAt && !this.item.endAt ? (
                  // @ts-ignore TS2322
                  <SetDate date={this.item.endAt} format={'short'} />
                ) : null}
                {/*
                 // @ts-ignore TS2339 */}
                {this.item && this.item.isMask && (
                  <span className="tag-list" key={`isMask`}>
                    <span key="isMask" className="tag grey-tag">
                      同時配信 対象外
                    </span>
                  </span>
                )}
              </span>
              {/*
               // @ts-ignore TS2339 */}
              {this.item && this.item.name ? this.item.name : '休止中'}
            </p>
          )}
          {/*
           // @ts-ignore TS2339 */}
          {this.props.titleHidden == false && this.item ? <Meta metadata={this.item} /> : null}
          {/*
           // @ts-ignore TS2339 */}
          {this.props.titleHidden == false ? <Tags {...this.item} /> : null}
          {/* @ts-ignore TS2339 */}
          {this.props.watchSpMode && this.item && this.item.isCatchup && this.item.catchupMeta ? (
            <MainViewLink {...catchupLinkProps} className="btn btn-fill accent-color btn-very-small catchup-btn">
              <i className="fa fa-play-circle"></i>
              オンデマンド視聴に切り替える
            </MainViewLink>
          ) : null}
          {/* @ts-ignore TS2339 */}
          {this.item && (
            <DetailText
              // @ts-ignore TS2339
              planInfoRenderCheck={this.props.planInfoRenderCheck}
              // @ts-ignore TS2339
              titleHidden={this.props.titleHidden}
              // @ts-ignore TS2339
              metadata={this.item}
              // @ts-ignore TS2339
              model={this.props.model}
              linearFlag={true}
              // @ts-ignore TS2339
              mediaQueryCheck={this.props.mediaQueryCheck}
            />
          )}
        </div>
        <div className="watch-info__colum_right" id="columRight">
          {/* @ts-ignore TS2339 */}
          {this.props.titleHidden == false ? (
            <div className="action-box">
              <ul className="action-box__inner">
                {/* @ts-ignore TS2339 */}
                {this.props.scheduleButton ? <li>{this.props.scheduleButton}</li> : null}
                {/* @ts-ignore TS2339 */}
                {this.props.myListButton ? <li>{this.props.myListButton}</li> : null}
                {/* @ts-ignore TS2339 */}
                {this.props.watchPartyButton ? <li>{this.props.watchPartyButton}</li> : null}
                {/* @ts-ignore TS2339 */}
                {this.props.chatButton ? <li>{this.props.chatButton}</li> : null}
                {/* @ts-ignore TS2339 */}
                {this.props.shareButton ? <li>{this.props.shareButton}</li> : null}
              </ul>
            </div>
          ) : null}
          {/* @ts-ignore TS2339 */}
          {!this.props.watchSpMode && this.item && this.item.isCatchup && this.item.catchupMeta ? (
            <MainViewLink {...catchupLinkProps} className="btn btn-fill accent-color catchup-btn">
              <i className="fa fa-play-circle"></i>
              オンデマンド視聴に切り替える
            </MainViewLink>
          ) : null}
          {/*
           // @ts-ignore TS2339 */}
          {this.item && (
            <React.Fragment>
              {/*
               // @ts-ignore TS2339 */}
              {_.map(this.item.links, (link, i) => {
                return (
                  // @ts-ignore TS2322
                  <MainViewLink key={`link-${i}`} href={link.url} target={'_blank'} className="watch-info__link">
                    {link.name}
                  </MainViewLink>
                );
              })}
            </React.Fragment>
          )}
        </div>
        {/* <div className="watch-info-title">
          <div className="action-box">
            <div className="title">
              <BackgroundImage className="artwork" url={this.props.metadata.thumbnailUrl} />
            </div>
            {this.props.myListButton ? (this.props.myListButton) : null}
          </div>
        </div> */}
      </div>
    );
  }
}
