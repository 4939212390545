import React, { Component, useContext } from 'react';
import PropTypes from 'prop-types';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import classnames from 'classnames';
import _ from 'src/domain/libs/util';

import PresTrackedElement from '../../../common/components/PresTrackedElement';
import PopCard from './PopCard';
import Meta from './Meta';
import routes from '../../../common/routes';
import MainViewLink from '../../../common/components/MainViewLink';
import canAccessBySlug from '../../utils/slug';
import BackgroundImage from '../../../common/components/BackgroundImage';
import MyListButton from './MyListButton';
import CardContext, { withCardContext } from '../../../common/context/CardContext';
import SetDate from './SetDate';
import CardBadge from './CardBadge';
import { getShowChasingPlayBack } from '../../../common/utils/liveUtil';

const _TextArea = props => {
  const context = useContext(CardContext);
  const cardInfo = _.get(props, 'item.cardInfo', {});
  const playbackBadge = (
    <span className="chasingPlayback" key={'chasingPlayback'}>
      追っかけ再生あり
    </span>
  );
  // 追っかけ再生表示
  const showChaseingPlayBack = getShowChasingPlayBack(props.item);
  const key = _.get(props.item, 'schemaId') == 4 ? 'broadcastStartAt' : 'deliveryStartAt';
  // @ts-ignore TS2322
  const time = cardInfo[key] ? <SetDate date={cardInfo[key]} format={'short'} /> : null;

  if (time) {
    return (
      <React.Fragment>
        <div className="top-metadata">
          <span key={'start-live'}>{time}</span>
          {showChaseingPlayBack ? playbackBadge : null}
        </div>
      </React.Fragment>
    );
  }
  return null;
};
const TextArea = withCardContext(_TextArea);

class LiveCard extends Component {
  static getPaths = function(models, options, props = {}) {
    let rootPath = [];
    // @ts-ignore TS2339
    if (props.titleId) {
      // @ts-ignore TS2339
      rootPath = rootPath.concat(['meta', props.titleId]);
    }
    const paths = [
      rootPath.concat([
        [
          'id',
          'refId',
          'type',
          'name',
          'shortName',
          'schemaId',
          'copyright',
          'reviewCount',
          'reviewScore',
          'leadEpisodeImage',
          'rental',
          'subscription',
          'edgeEpisodeImage',
          'tallArtwork',
          'leadSeasonId',
          'slug',
          'cardInfo',
          'oap',
          'seriesMeta',
          'seasonMeta',
          'genres',
          'attributes',
          'middleGenres',
          'chasingPlayback',
        ],
      ]),
    ];
    return paths
      .concat(
        // @ts-ignore TS2554
        PopCard.getPaths().map(path => {
          return rootPath.concat(path);
        }),
      )
      .concat(
        // @ts-ignore TS2554
        Meta.getPaths().map(path => {
          return rootPath.concat(path);
        }),
      );
  };

  static getRootPath = function(models, options, props = {}) {
    // @ts-ignore TS2339
    if (!props.titleId) return;
    // @ts-ignore TS2339
    return ['meta', props.titleId];
  };

  static get propTypes() {
    return {
      size: PropTypes.oneOf(['1x1', '2x1', '1x2', '2x2', '3x2']).isRequired,
      sliderItemId: PropTypes.string,
      getRowHasPopOpen: PropTypes.func,
      isDoubleWide: PropTypes.bool,
      isTallPanel: PropTypes.bool,
      itemTabbable: PropTypes.bool,
      model: PropTypes.object.isRequired,
      onPopClose: PropTypes.func,
      onPopLeave: PropTypes.func,
      onPopOpen: PropTypes.func,
      rowModel: PropTypes.object,
      rankNum: PropTypes.number,
      rowNum: PropTypes.number,
      titleId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
      popType: PropTypes.string.isRequired,
      popClasses: PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.string]),
      showScore: PropTypes.bool,
      titleDisplaySetting: PropTypes.oneOf(['default', 'under_card', 'media_only', 'on_card', 'none']),
      showPopCardMylistButton: PropTypes.bool,
      isPosterPanel: PropTypes.bool,
      deliveryStartVisible: PropTypes.bool,
    };
  }

  static get defaultProps() {
    return {
      size: '1x1',
      ellipsized: false,
      rankNum: 0,
      rowNum: 0,
      popType: 'none',
      showScore: false,
      titleDisplaySetting: 'default',
      showPopCardMylistButton: true,
      deliveryStartVisible: false,
    };
  }

  static contextType = CardContext;

  constructor(props, context) {
    super(props, context);
    // @ts-ignore TS2339
    const rootPath = this.constructor.getRootPath(context.models, {}, props);
    if (props.itemData) {
      // @ts-ignore TS2339
      this.item = props.itemData;
    } else if (rootPath) {
      // @ts-ignore TS2339
      this.item = props.model.getSync(rootPath);
    }
    this.state = {
      fetchDataError: null,
      generation: props.model.getVersion(rootPath),
      displayTitle: true,
      deleteMode: context.deleteApp ? context.deleteApp.isDeleteMode() : false,
      isDelete: !!(_.find(_.get(context.deleteApp, '_targets', []), target => target.id === props.titleId) || {})
        .checked,
      hasPop: false,
    };
    this.onPopOpen = this.onPopOpen.bind(this);
    this.onPopWillClose = this.onPopWillClose.bind(this);
    this.onPopClose = this.onPopClose.bind(this);
    // @ts-ignore TS2339
    this.titleCardRef = React.createRef();
    // @ts-ignore TS2339
    this.openedPop = false;
    // @ts-ignore TS2339
    this._isMounted = false;

    this.handleClick = this.handleClick.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleMouseOver = this.handleMouseOver.bind(this);
    this.handleMouseOut = this.handleMouseOut.bind(this);
    this.handleDeleteModeChange = this.handleDeleteModeChange.bind(this);
    this.handleDeleteChecked = this.handleDeleteChecked.bind(this);
    this.handleChangeMylisted = this.handleChangeMylisted.bind(this);
  }

  componentDidMount() {
    // @ts-ignore TS2339
    this._isMounted = true;
    if (this.context.deleteApp) {
      // @ts-ignore TS2339
      this.unregister = this.context.deleteApp.register(
        // @ts-ignore TS2339
        Object.assign({}, this.props.itemData, {
          // @ts-ignore TS2339
          id: this.props.titleId,
          // @ts-ignore TS2339
          checked: this.state.isDelete,
          onModeChange: this.handleDeleteModeChange,
          onChecked: this.handleDeleteChecked,
        }),
      );
    }
    // @ts-ignore TS2339
    if (!this.props.itemData) this.fetchData(this.props);
  }

  componentWillUnmount() {
    // @ts-ignore TS2339
    this._isMounted = false;
    // @ts-ignore TS2339
    if (this.mouseOutTimeoutId) {
      // @ts-ignore TS2339
      clearTimeout(this.mouseOutTimeoutId);
      // @ts-ignore TS2339
      delete this.mouseOutTimeoutId;
    }
    // @ts-ignore TS2339
    if (this.mouseOverTimeoutId) {
      // @ts-ignore TS2339
      clearTimeout(this.mouseOverTimeoutId);
      // @ts-ignore TS2339
      delete this.mouseOverTimeoutId;
    }
    // @ts-ignore TS2339
    if (this.displayTimeoutId) {
      // @ts-ignore TS2339
      clearTimeout(this.displayTimeoutId);
      // @ts-ignore TS2339
      delete this.displayTimeoutId;
    }
    // @ts-ignore TS2339
    if (this.unregister) this.unregister();
  }

  handleDeleteModeChange(deleteMode) {
    // @ts-ignore TS2339
    if (!this._isMounted) return;
    this.setState({ deleteMode });
  }

  handleDeleteChecked(item) {
    // @ts-ignore TS2339
    if (!this._isMounted) return;
    this.setState({ isDelete: item.checked });
  }

  handleChangeMylisted(mylisted) {
    // PopCardを閉じると元に戻る為更新しておく
    // @ts-ignore TS2339
    this.item.mylisted = mylisted;
  }

  render() {
    // @ts-ignore TS2339
    if (!this.item) {
      // TODO: 読み込み中
      return null;
    }

    const getImage = () => {
      let image;
      // @ts-ignore TS2339
      if (this.context.artKind === 'lead_episode' || this.props.artKind === 'lead_episode') {
        // @ts-ignore TS2339
        image = this.item.leadEpisodeImage;
        // @ts-ignore TS2339
      } else if (this.context.artKind === 'edge_episode' || this.props.artKind === 'edge_episode') {
        // @ts-ignore TS2339
        image = this.item.edgeEpisodeImage;
      }
      if (image) return image;
      // @ts-ignore TS2339
      return this.item.thumbnailUrl;
    };

    let detailNode = null;
    // @ts-ignore TS2339
    if (!_.isEmpty(this.item) && this.state.hasPop && this.props.popType !== 'none') {
      let myListButton;
      // @ts-ignore TS2339
      if (this.props.showPopCardMylistButton) {
        const myListProps = {
          // @ts-ignore TS2339
          model: this.props.model,
          btnType: 'short',
          classes: { 'btn-very-small': true },
          btnStyle: false,
        };

        // if (this.item.schemaId == 1) {
        //   myListProps.item = this.item;
        // } else if (!this.item.leadSeasonId) {
        // @ts-ignore TS2339
        myListProps.id = this.item.id;
        // @ts-ignore TS2339
        myListProps.item = this.item;
        // } else {
        //   myListProps.id = this.item.leadSeasonId;
        // }
        // @ts-ignore TS2322
        myListButton = <MyListButton {...myListProps} onChange={this.handleChangeMylisted} />;
      }

      detailNode = (
        <PopCard
          // @ts-ignore TS2339
          model={this.props.model}
          // @ts-ignore TS2339
          key={`item_${this.item.id}`}
          // @ts-ignore TS2339
          classes={classnames(this.props.popClasses)}
          // @ts-ignore TS2339
          popType={this.props.popType}
          // @ts-ignore TS2339
          titleCardImage={this.props.isTallPanel === true ? this.item.tallThumbnailUrl : getImage()}
          // @ts-ignore TS2339
          titleModel={this.item}
          // @ts-ignore TS2322
          titleId={this.props.titleId}
          artKind={this.context.artKind}
          myListButton={myListButton}
          // @ts-ignore TS2339
          showEpisodeNumber={this.props.showEpisodeNumber}
          // @ts-ignore TS2339
          deliveryStartVisible={this.props.deliveryStartVisible}
        />
      );
    }

    // @ts-ignore TS2339
    const key = `live-card-${this.props.rowNum}-${this.props.rankNum}-${this.props.titleId}`;
    // @ts-ignore TS2339
    const tabIndex = this.props.itemTabbable ? 0 : -1;

    let titleArtwork = (
      <BackgroundImage
        // @ts-ignore TS2322
        className={classnames('artwork', {
          // @ts-ignore TS2339
          'double-wide': this.props.isDoubleWide === true && this.props.isTallPanel !== true,
          noImage: !getImage(),
        })}
        url={getImage()}
      />
    );

    let cardBadge = (
      <CardBadge
        // @ts-ignore TS2339
        item={this.item}
        // @ts-ignore TS2339
        mbListLayout={this.props.mbListLayout}
        // @ts-ignore TS2339
        deleteMode={this.state.deleteMode}
        // @ts-ignore TS2339
        keyPrefix={this.props.keyPrefix}
        // @ts-ignore TS2339
        listContext={this.props.listContext}
        // @ts-ignore TS2339
        listType={this.props.listType}
      />
    );

    const browserInfo = this.context.getModelData('browserInfo');

    let itemId;
    // @ts-ignore TS2339
    if (this.item && this.item.id) {
      // @ts-ignore TS2339
      itemId = canAccessBySlug(this.item) ? this.item.slug : this.item.id;
    }

    // @ts-ignore TS2339
    let titleDisplaySetting = this.props.titleDisplaySetting;
    if (titleDisplaySetting === 'default') {
      titleDisplaySetting = 'under_card';
      // @ts-ignore TS2339
    } else if (this.item && titleDisplaySetting === 'media_only' && this.item.type !== 'media') {
      titleDisplaySetting = 'none';
    }

    const routeHandler = this.context.routeHandler;
    const WatchNow = props => {
      if (routeHandler.route._regex === routes.title._regex || routeHandler.route._regex === routes.watchNow._regex) {
        return <div className={props.classname}>{props.children}</div>;
      } else {
        return <h3 className={props.classname}>{props.children}</h3>;
      }
    };

    const titleClassname = classnames('title-card-title', {
      // @ts-ignore TS2339
      ellipsized: this.props.ellipsized,
    });

    // ライブ配信ページの時はtitle,shortNameで2段
    let title = (
      <WatchNow {...this.props} classname={titleClassname}>
        {itemId ? (
          <p onClick={!(browserInfo.isIOS || browserInfo.isAndroid) ? this.handleClick : null} className="link">
            {/*
             // @ts-ignore TS2339 */}
            {this.item.seriesMeta &&
            // @ts-ignore TS2339
            this.item.seriesMeta.shortName &&
            // @ts-ignore TS2339
            this.item.seriesMeta.shortName !== this.item.shortName ? (
              // @ts-ignore TS2339
              <span className="title ellipsized">{this.item.seriesMeta.shortName}</span>
            ) : null}
            {/*
             // @ts-ignore TS2339 */}
            <span className="short_name">{this.item.shortName}</span>
          </p>
        ) : (
          <p>
            {/*
             // @ts-ignore TS2339 */}
            {this.item.seriesMeta &&
            // @ts-ignore TS2339
            this.item.seriesMeta.shortName &&
            // @ts-ignore TS2339
            this.item.seriesMeta.shortName !== this.item.shortName ? (
              // @ts-ignore TS2339
              <span className="title ellipsized">{this.item.seriesMeta.shortName}</span>
            ) : null}
            {/*
             // @ts-ignore TS2339 */}
            <span className="short_name">{this.item.shortName}</span>
          </p>
        )}
      </WatchNow>
    );

    let episodeNumberTitle;
    // @ts-ignore TS2339
    if (this.item.cardInfo && this.item.cardInfo.episodeNumberTitle) {
      episodeNumberTitle = (
        <div className="top-metadata">
          {/*
           // @ts-ignore TS2339 */}
          <span className="episode-number">{this.item.cardInfo.episodeNumberTitle}</span>
        </div>
      );
    }

    // @ts-ignore TS2322
    let textAreaBox = <TextArea item={this.item} mbListLayout={this.props.mbListLayout} />;
    let cardContent = (
      <React.Fragment>
        <div
          className={classnames('sliderRefocus', {
            // @ts-ignore TS2339
            hasPop: this.state.hasPop === true,
            lockup: true,
          })}
          // @ts-ignore TS2339
          aria-label={this.item.name}
          // @ts-ignore TS2339
          ref={this.titleCardRef}
          role={'link'}
          tabIndex={
            !(browserInfo.isIOS || browserInfo.isAndroid || browserInfo.isRequestDesktopWebsite) ? tabIndex : null
          }
          key={key}
          id={key}
          onClick={!(browserInfo.isIOS || browserInfo.isAndroid) ? this.handleClick : null}
          onKeyDown={
            !(browserInfo.isIOS || browserInfo.isAndroid || browserInfo.isRequestDesktopWebsite)
              ? this.handleKeyDown
              : null
          }
          onMouseEnter={this.handleMouseOver}
          onMouseLeave={this.handleMouseOut}
        >
          {/*
           // @ts-ignore TS2322 */}
          <PresTrackedElement idDoubleWide={this.props.isDoubleWide}>
            {cardBadge}
            {titleArtwork}
            <div className="video-preload-title">
              {/*
               // @ts-ignore TS2339 */}
              <div className="video-preload-title-label">{this.item.name}</div>
            </div>
          </PresTrackedElement>
          <TransitionGroup component={null}>
            {detailNode ? (
              <CSSTransition
                classNames="pop-bounce"
                onEnter={this.onPopOpen}
                onExit={this.onPopWillClose}
                onExited={this.onPopClose}
                timeout={{ exit: 400, enter: 400 }}
              >
                {detailNode}
              </CSSTransition>
            ) : null}
          </TransitionGroup>
        </div>
        <div
          className={classnames('text-wrapper', {
            // @ts-ignore TS2339
            hasTextArea: this.context.textArea !== 'hidden' || this.props.textArea !== 'hidden',
          })}
        >
          {textAreaBox}
          {title}
          {episodeNumberTitle}
        </div>
      </React.Fragment>
    );

    if (browserInfo.isIOS || browserInfo.isAndroid) {
      let linkProps = {
        // @ts-ignore TS2339
        to: this.item.type === 'media' ? routes.watchNow : routes.title,
        params: { id: itemId },
      };
      // @ts-ignore TS2339
      if (_.get(this.item, 'refId')) {
        linkProps = {
          // @ts-ignore TS2339
          to: this.item.type === 'media' ? routes.content : routes.program,
          // @ts-ignore TS2339
          params: { id: this.item.refId },
        };
      }
      return (
        <MainViewLink
          {...linkProps}
          // @ts-ignore TS2322
          role="link"
          aria-label="card"
          onClick={this.handleClick}
          // @ts-ignore TS2339
          className={classnames('card', 'title-card', 'live-card', `size-${this.props.size}`)}
        >
          {cardContent}
        </MainViewLink>
      );
    } else {
      return (
        // @ts-ignore TS2339
        <div className={classnames('card', 'title-card', 'live-card', `size-${this.props.size}`)}>{cardContent}</div>
      );
    }
  }

  handleClick(e) {
    e.preventDefault();
    // @ts-ignore TS2339
    if (this.props.titleId) {
      // @ts-ignore TS2339
      if (this.state.deleteMode) {
        // @ts-ignore TS2339
        if (this.state.isDelete) {
          // @ts-ignore TS2339
          this.context.deleteApp.remove(this.props.titleId);
        } else {
          // @ts-ignore TS2339
          this.context.deleteApp.add(this.props.titleId);
        }
        return;
      }

      const { history } = this.context;
      let to;
      // @ts-ignore TS2339
      let params = { id: this.props.titleId };
      let query = {};
      if (_.get(this, 'item.type') === 'media') {
        to = routes.watchNow;
      } else {
        to = routes.title;
        // @ts-ignore TS2339
        if (this.item && this.item.id) {
          // @ts-ignore TS2339
          params.id = canAccessBySlug(this.item) ? this.item.slug : this.item.id;
        }
      }
      // @ts-ignore TS2339
      if (this.item.refId) {
        to = _.get(this, 'item.type') === 'media' ? routes.content : routes.program;
        // @ts-ignore TS2339
        params.id = this.item.refId;
        if (_.get(this, 'item.type') === 'linear_channel') {
          // @ts-ignore TS2339
          const simulcast = _.find(this.context.getModelData('simulcast'), item => item.refId == this.item.refId);
          to = routes.simulcast;
          // @ts-ignore TS2322
          params = { channelName: simulcast.name };
        }
      }
      const path = to.makePath(params, query);
      const routesMatchFlag =
        !!this.context.routeHandlers &&
        _.some(this.context.routeHandlers, handler => handler.route._regex === to._regex);
      if (routesMatchFlag) {
        history.push(path);
      } else {
        window.location.href = path;
      }
    }
    // @ts-ignore TS2339
    if (this.props.onCardClick) this.props.onCardClick(this.item);
  }

  handleKeyDown(e) {
    if (e.which === 13 || e.keyCode === 13) {
      this.handleClick(e);
    }
  }

  handleMouseOver(e) {
    // タッチデバイスはpopさせない
    const browserInfo = this.context.getModelData('browserInfo');
    if (browserInfo.isTouchDevice || browserInfo.isRequestDesktopWebsite) {
      return;
      // @ts-ignore TS2339
    } else if (this.props.popType === 'none') {
      return;
      // @ts-ignore TS2339
    } else if (this.state.deleteMode) {
      return;
    }

    // @ts-ignore TS2339
    if (this.state.hasPop && this.mouseOutTimeoutId && this.props.onPopOpen) {
      // @ts-ignore TS2339
      this.props.onPopOpen(this);
    }

    // @ts-ignore TS2339
    if (this.displayTimeoutId) {
      // @ts-ignore TS2339
      clearTimeout(this.displayTimeoutId);
      // @ts-ignore TS2339
      delete this.displayTimeoutId;
    }

    // @ts-ignore TS2339
    if (this.mouseOutTimeoutId) {
      // @ts-ignore TS2339
      clearTimeout(this.mouseOutTimeoutId);
      // @ts-ignore TS2339
      delete this.mouseOutTimeoutId;
      return;
    }

    let timeout = 300;
    // @ts-ignore TS2339
    if (this.props.getRowHasPopOpen) {
      // @ts-ignore TS2339
      if (this.props.getRowHasPopOpen()) {
        timeout = 100;
      }
    }
    // @ts-ignore TS2339
    if (this.popUnmounting === true) {
      timeout = 300;
    }

    // @ts-ignore TS2339
    this.alreadyPopOpened = false;
    // @ts-ignore TS2339
    this.mouseOverTimeoutId = setTimeout(() => {
      // @ts-ignore TS2339
      delete this.mouseOverTimeoutId;
      this.setState({ hasPop: true, displayTitle: false });
    }, timeout);
  }

  handleMouseOut(e) {
    // @ts-ignore TS2339
    if (this.props.popType === 'none') {
      return;
    }

    // まだ拡大が始まっていない時は拡大しない
    // @ts-ignore TS2339
    if (this.mouseOverTimeoutId) {
      // @ts-ignore TS2339
      clearTimeout(this.mouseOverTimeoutId);
      // @ts-ignore TS2339
      delete this.mouseOverTimeoutId;
    }

    // @ts-ignore TS2339
    if (!this.state.hasPop) return;

    // @ts-ignore TS2339
    const delay = this.alreadyPopOpened ? 150 : 1;
    // @ts-ignore TS2339
    if (this.props.onPopLeave) {
      // @ts-ignore TS2339
      this.props.onPopLeave(this, delay);
    }

    // @ts-ignore TS2339
    this.mouseOutTimeoutId = setTimeout(() => {
      // @ts-ignore TS2339
      delete this.mouseOutTimeoutId;
      // @ts-ignore TS2339
      this.popUnmounting = true;
      this.setState({ hasPop: false });
    }, delay);
    // @ts-ignore TS2339
    this.displayTimeoutId = setTimeout(() => {
      // @ts-ignore TS2339
      delete this.displayTimeoutId;
      this.setState({ displayTitle: true });
    }, 500);
  }

  onPopOpen() {
    // @ts-ignore TS2339
    if (this.props.popType === 'none') {
      return;
    }

    // @ts-ignore TS2339
    this.alreadyPopOpened = true;
    // @ts-ignore TS2339
    if (this.props.onPopOpen && this.state.hasPop === true) {
      // @ts-ignore TS2339
      this.props.onPopOpen(this);
    }

    // @ts-ignore TS2339
    this.openedPop = true;
  }

  onPopWillClose() {
    // @ts-ignore TS2339
    this.openedPop = false;
  }

  onPopClose() {
    // @ts-ignore TS2339
    if (this.props.popType === 'none') {
      return;
    }

    // @ts-ignore TS2339
    delete this.popUnmounting;
    // @ts-ignore TS2339
    if (this.props.onPopClose) {
      // @ts-ignore TS2339
      this.props.onPopClose(this);
    }

    this.forceUpdate();
  }

  fetchData(props) {
    // @ts-ignore TS2339
    const rootPath = this.constructor.getRootPath(this.context.models, {}, props);
    if (!rootPath) return;

    // @ts-ignore TS2339
    const paths = this.constructor.getPaths(this.context.models, {}, props);
    const evaluator = props.model.fetch(paths);
    // @ts-ignore TS2339
    this.state.dispose = evaluator.dispose;
    evaluator
      .then(res => {
        // @ts-ignore TS2339
        this.item = _.get(res.json, rootPath, {});
        const newState = {
          fetchDataError: null,
          dispose: null,
          generation: props.model.getVersion(rootPath),
        };
        // @ts-ignore TS2339
        if (this._isMounted) this.setState(newState);
        else Object.assign(this.state, newState);
      })
      .catch(e => {
        console.error(e.stack);
        const newState = {
          fetchDataError: e,
          dispose: null,
        };
        // @ts-ignore TS2339
        if (this._isMounted) this.setState(newState);
        else this.state = Object.assign(newState);
      });
  }

  getZoomScale() {
    // @ts-ignore TS2339
    return this.props.isTallRow === true ? 1.2 : this.props.popType === 'bounce' ? 1.55 : 1.95;
  }
}

const root = withCardContext(LiveCard);
// @ts-ignore TS2339
root.getPaths = LiveCard.getPaths;
export default root;
